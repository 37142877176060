import { TParcelCreation } from 'app/codecs/parcel'
import { foldService } from 'app/codecs/parcel'
import { Parcel } from 'app/codecs/parcel'
import { PARCEL_COUNT_QUERY_KEY } from 'components/layout/app-count-branch-parcels'
import { useNotificationContext } from 'hooks/use-notification'
import { concatQueryParams } from 'lib/rest-query/common'
import { EntityDialog } from 'lib/route-generator/entity-dialog'
import { formatPhone } from 'meest-domain/format'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { ParcelActions } from '../parcel-actions'
import { ParcelFieldValues, ParcelForm } from './parcel-form'

type Props = {
  isOpen: boolean
  onClose: () => void
  branchId: string
}

const CREATE_PARCEL_STEPS_COUNT = 4

export const setAdditionalOptions = (values: ParcelFieldValues) => {
  if (values.branchAdditionalOption) {
    return ['UNPACKING_AT_BRANCH']
  }

  if (values.courierAdditionalOptions.length > 0) {
    return values.courierAdditionalOptions
  }
}

export const CreateParcelDialog = ({ isOpen, onClose, branchId }: Props) => {
  const queryClient = useQueryClient()

  const [createdParcelId, setCreatedParcelId] = useState<string | null>(null)

  const { t } = useTranslation()

  const { showSuccessNotification } = useNotificationContext()

  const url = concatQueryParams(
    '/api/pudo/parcels',
    new URLSearchParams({ branchId }),
  )

  return (
    <>
      <EntityDialog
        action="create"
        steps={CREATE_PARCEL_STEPS_COUNT}
        isOpen={isOpen}
        onClose={onClose}
        codec={TParcelCreation}
        renderForm={ParcelForm}
        url={url}
        title="parcel_options.create"
        submitButtonLabel="parcel_options.create_btn"
        closeDialogTitle="dialog_confirm.title_parcel_create"
        onSuccess={data => {
          setCreatedParcelId(data.parcelId)
          showSuccessNotification(t('notifications.parcel_create_success'))
          queryClient.invalidateQueries([PARCEL_COUNT_QUERY_KEY, branchId])
        }}
        refetch={() => {}}
        defaultValues={{
          places: [
            {
              size: undefined,
              insurance: '500',
            },
          ],
          receiverPay: '',
          sender: {
            firstname: '',
            middleName: '',
            lastname: '',
            number: '',
          },
          receiver: {
            firstname: '',
            middleName: '',
            lastname: '',
            number: '',
            branchCity: null,
            deliveryPlaceId: null,
            addressRequest: {
              street: null,
              building: '',
              corps: '',
              flat: '',
              city: null,
            },
          },
          notes: '',
          branchAdditionalOption: false,
          courierAdditionalOptions: [],
          deliveryCash: {
            cardNumber: '',
            cod: '',
          },
          branchForCOD: {
            branchId: null,
            branchCity: null,
            receiver: {
              firstname: '',
              middleName: '',
              lastname: '',
            },
            payer: {
              firstname: '',
              middleName: '',
              lastname: '',
            },
          },
          afterPay: '',
          isCreateError: false,
        }}
        mapFieldValuesToPayload={values => ({
          places: values.places,
          receiverPay: values.receiverPay === 'true',
          deliveryCash: values.deliveryCash.cod
            ? {
                cardNumber: values.deliveryCash.cardNumber,
                cod: Number(values.deliveryCash.cod),
              }
            : undefined,
          sender: {
            firstname: values.sender.firstname,
            middleName: values.sender.middleName,
            lastname: values.sender.lastname,
            number: formatPhone(values.sender.number),
          },
          receiver: {
            firstname: values.receiver.firstname,
            middleName: values.receiver.middleName,
            lastname: values.receiver.lastname,
            number: formatPhone(values.receiver.number),
            service: values.receiver.service,
            ...(values.receiver.service
              ? foldService({
                  onBranch: () => ({
                    deliveryPlaceId: values.receiver.deliveryPlaceId?.value,
                  }),
                  onCourier: () => ({
                    deliveryPlaceId:
                      values.receiver.addressRequest.city?.value.cityId,
                    addressRequest: values.receiver.addressRequest.city?.value
                      .cityNameUA
                      ? {
                          cityNameUA:
                            values.receiver.addressRequest.city?.value
                              .cityNameUA,
                          cityNameRU:
                            values.receiver.addressRequest.city?.value
                              .cityNameRU,
                          cityType:
                            values.receiver.addressRequest.city?.value
                              .cityNameUA,
                          streetNameUA:
                            values.receiver.addressRequest.street?.value
                              .streetNameUA,
                          streetNameRU:
                            values.receiver.addressRequest.street?.value
                              .streetNameRU,
                          streetType:
                            values.receiver.addressRequest.street?.value
                              .streetType,
                          streetId:
                            values.receiver.addressRequest.street?.value
                              .streetId,
                          building: values.receiver.addressRequest.building,
                          corps: values.receiver.addressRequest.corps,
                          flat: values.receiver.addressRequest.flat,
                        }
                      : undefined,
                  }),
                  onParcelMachine: () => ({
                    deliveryPlaceId: values.receiver.deliveryPlaceId?.value,
                  }),
                })(values.receiver.service)
              : {}),
          },
          notes: values.notes || undefined,
          additionalOptions: setAdditionalOptions(values),
          branchForCOD: {
            branchId: values.branchForCOD.branchId?.value,
            receiver: {
              firstname: values.receiver.firstname,
              middleName: values.receiver.middleName,
              lastname: values.receiver.lastname,
            },
            payer: {
              firstname: values.sender.firstname,
              middleName: values.sender.middleName,
              lastname: values.sender.lastname,
            },
          },
        })}
      />
      {createdParcelId !== null && (
        <ParcelActions
          mode="parcel-reception"
          isOpen={true}
          onClose={() => {
            setCreatedParcelId(null)
          }}
          onSuccess={() => {}}
          parcelIds={[createdParcelId]}
          branchId={branchId}
          renderParcelDetails={(parcel: Parcel) => [
            {
              label: 'parcel.place_quantity',
              value: parcel.quantity,
              labelColor: parcel.quantity > 1 ? 'textPrimary' : 'textSecondary',
              valueVariant: 'body1',
              highlighted: parcel.quantity > 1,
            },
            {
              label: 'parcel.number',
              value: parcel.parcelNumber,
              labelColor: 'textSecondary',
              valueVariant: 'body1',
              isCopied: true,
            },
            {
              label: 'form_fields.receiver_name',
              value: parcel.receiverName,
              labelColor: 'textSecondary',
              valueVariant: 'body1',
            },
            {
              label: 'form_fields.receiver_phone',
              value: parcel.receiverPhone,
              labelColor: 'textSecondary',
              valueVariant: 'body1',
            },
            {
              label: 'parcel.weight',
              value: parcel.weight + t('parcel.kg'),
              labelColor: 'textSecondary',
              valueVariant: 'body1',
            },
          ]}
        />
      )}
    </>
  )
}
