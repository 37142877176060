import { IconProps, SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconCourierParcelIssuance = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1554 17.1388L16.3368 14.3613L15.8202 14.8847L17.7408 16.7773L13.5049 16.8067L13.51 17.5493L17.746 17.5199L15.8518 19.439L16.3756 19.9551L19.1554 17.1388ZM21.0397 17.3733C21.0487 18.6603 20.5989 19.7691 19.6904 20.6995C18.7819 21.6299 17.6787 22.0996 16.3807 22.1086C15.0826 22.1176 13.968 21.6633 13.0368 20.7456C12.1056 19.828 11.6355 18.7257 11.6266 17.4386C11.6176 16.1515 12.0713 15.0427 12.9874 14.1123C13.9037 13.1818 15.0163 12.712 16.3253 12.703C17.6206 12.694 18.7279 13.1483 19.6473 14.0661C20.5667 14.9838 21.0308 16.0862 21.0397 17.3733Z"
      fill={(props.sx as IconProps).color}
    />
    <path
      d="M6.40479 4.14409C6.40479 2.34258 7.48569 0.901367 10.3681 0.901367C13.2505 0.901367 14.3314 2.34258 14.3314 4.14409"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6917 5.94556L14.4611 6.63734C14.3926 6.83911 14.1657 6.93999 13.9711 6.85352C13.3009 6.54726 11.7949 5.94556 10.3681 5.94556C8.75031 5.94556 7.39197 6.51484 6.76864 6.8283C6.57408 6.92558 6.33628 6.8283 6.26782 6.61932L6.04443 5.94556"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20654 4.50435C8.20654 4.50435 9.64775 4.14404 10.3684 4.14404C11.089 4.14404 12.5302 4.50435 12.5302 4.50435"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4487 11.9339C12.9008 11.534 13.6754 10.3018 13.9708 8.82812"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.76514 8.82812C7.06058 10.3018 7.83524 11.534 9.29086 11.9339"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20649 11.7102C8.20649 11.7102 8.29657 12.9316 7.84619 13.872"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3683 15.3135C9.24417 15.3135 8.20649 14.5929 7.84619 13.8723"
      stroke="#2C363F"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M7.84575 13.8723L4.43368 14.5893C2.434 15.0109 1 16.7764 1 18.8193V21.4351"
      stroke="#2C363F"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
