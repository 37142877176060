import { textFieldError } from 'components/form/utils'
import { phone_form } from 'components/form/validations'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PhoneInput } from '../phone-input'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>
  required?: boolean
  label: string
  disabled?: boolean
  helperText?: string
  control: Control<TFieldValues>
  placeholder?: string
}

export const ControlledPhoneTextField = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()

  const {
    field: { onChange, onBlur, value },
    fieldState,
  } = useController({
    control: props.control,
    name: props.name,
    rules: {
      required: props.required,
      pattern: {
        value: phone_form.pattern.value,
        message: t(phone_form.pattern.message),
      },
    },
  })

  return (
    <PhoneInput
      {...textFieldError(fieldState.error)}
      value={value as string}
      onChange={onChange}
      onBlur={onBlur}
      required={props.required}
      name={props.name}
      label={t(props.label)}
      helperText={fieldState.error?.message || props.helperText}
      placeholder={props.placeholder}
    />
  )
}
