import { createSvgIcon } from '@material-ui/core'

export const IconExcel = createSvgIcon(
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4883 13.2675H16.9895V11.4767H20.4883V13.2675ZM20.4883 14.2907H16.9895V16.0814H20.4883V14.2907ZM20.4883 5.84883H16.9895V7.63951H20.4883V5.84887V5.84883ZM20.4883 8.66278H16.9895V10.4535H20.4883V8.66278ZM20.4883 17.1046H16.9895V18.8954H20.4883V17.1046V17.1046ZM22.905 20.6349C22.805 21.167 22.1802 21.1798 21.7629 21.1977H13.9906V23.5H12.4386L1 21.4535V3.54909L12.5061 1.5H13.9906V3.53884H21.4955C21.9179 3.55674 22.3827 3.52604 22.75 3.78439C23.0075 4.163 22.9825 4.64396 23 5.07882L22.99 18.3965C22.9775 19.1409 23.0574 19.9007 22.905 20.6349ZM10.1643 16.6212C9.4746 15.1886 8.77236 13.7663 8.08507 12.3337C8.76484 10.9395 9.43463 9.54024 10.1019 8.14092C9.53458 8.16907 8.96726 8.20487 8.40248 8.24582C7.9801 9.29719 7.48776 10.3205 7.15789 11.4077C6.85049 10.3819 6.44315 9.3944 6.07078 8.39418C5.52096 8.42488 4.97113 8.45813 4.42134 8.49138C5.00112 9.8012 5.61845 11.093 6.18074 12.4104C5.51846 13.6895 4.89868 14.9865 4.25639 16.2733C4.80368 16.2963 5.35101 16.3193 5.89831 16.327C6.28821 15.3088 6.77302 14.3291 7.1129 13.2905C7.41781 14.4058 7.93511 15.4368 8.35999 16.5035C8.96228 16.547 9.56208 16.5854 10.1644 16.6212H10.1643ZM21.808 4.75378H13.9906V5.84883H15.9899V7.63951H13.9906V8.66278H15.9899V10.4535H13.9906V11.4767H15.9899V13.2675H13.9906V14.2907H15.9899V16.0814H13.9906V17.1046H15.9899V18.8954H13.9906V20.0784H21.808V4.75378Z"
      fill="#0061AF"
    />
  </svg>,
  'Excel',
)
