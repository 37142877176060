import { Parcel, TParcel } from 'app/codecs/parcel'
import { TParcelNomenclature } from 'app/codecs/selling-container'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { PARCEL_COUNT_QUERY_KEY } from 'components/layout/app-count-branch-parcels'
import { SupportDialog } from 'components/support-dialog'
import { DualItemType } from 'components/ui/dual-items'
import { useBoolean } from 'hooks/use-boolean'
import { useNotificationContext } from 'hooks/use-notification'
import { array } from 'io-ts'
import { Handler } from 'lib/react-handy-types'
import { useQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import { useMutation } from 'lib/rest-query/rest-mutation'
import {
  getAuthenticationData,
  getAuthenticationType,
  getDocumentName,
  hasNomenclatureOption,
  MeestVerification,
} from 'meest-domain/utils'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import {
  ParcelActionsFormDialog,
  ParcelIdentificationFieldValues,
} from './parcel-actions-form-dialog'
import { FormMode } from './parcel-actions-form-dialog'
import {
  isParcelIssuanceMode,
  isParcelReceptionMode,
  isParcelReturnMode,
  isUnpackOption,
} from './utils'

type Props = {
  mode: FormMode
  isOpen: boolean
  onClose: Handler
  onOpen?: Handler
  onSuccess?: Handler
  onRefuseSuccess?: (parcelId: string) => void
  parcelIds: Array<string>
  renderParcelDetails: (parcel: Parcel) => Array<DualItemType>
  branchId: string
  identificationValues?: MeestVerification | null
  clientPhone?: string | null
  setClientPhone?: Dispatch<SetStateAction<string | null>>
  searchFieldValue?: string | null
  isAlternative?: boolean
}

export const PARCEL_ARREARS_QUERY_KEY = 'PARCEL_ARREARS_QUERY_KEY'

export const ParcelActions = ({
  mode,
  parcelIds,
  clientPhone,
  setClientPhone,
  isOpen,
  onOpen,
  onRefuseSuccess,
  branchId,
  identificationValues,
  onClose,
  renderParcelDetails,
  onSuccess,
  isAlternative,
}: Props) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const [formSubmitValues, setFormSubmitValues] =
    useState<ParcelIdentificationFieldValues | null>(null)

  const $parcels = useQuery(
    'PUT',
    concatQueryParams(
      '/api/pudo/parcels/arrears',
      new URLSearchParams({
        branchId,
      }),
    ),
    array(TParcel),
    {
      rawBody: {
        parcelIds,
      },
      customQueryKey: [PARCEL_ARREARS_QUERY_KEY, ...parcelIds.sort()],
      options: {
        enabled: isOpen && parcelIds.length <= 10,
      },
    },
  )
  const parcels =
    isAlternative !== undefined && isAlternative && $parcels.data
      ? $parcels.data.filter(parcel => parcel.alternateReceiver)
      : $parcels.data

  const $getParcelNomenclatures = useMutation(
    'GET',
    '/api/pudo/product/:parcelId',
    array(TParcelNomenclature),
  )

  const $saleProduct = useMutation('POST', '/api/pudo/product/sale')

  const isReturnedParcel = useMemo(
    () =>
      !isParcelReturnMode(mode) &&
      parcels?.some(parcel => parcel.status === 'FOR_RETURNING'),
    [mode, parcels],
  )

  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext()

  const $verifyClient = useMutation('POST', '/api/pudo/customers/verification')

  const branchIdParams = useMemo(
    () =>
      new URLSearchParams({
        branchId,
      }),
    [branchId],
  )

  const $acceptParcel = useMutation('PUT', '/api/pudo/parcels/accept')

  const $returnParcel = useMutation('POST', '/api/pudo/parcels/return')

  const $giveToClient = useMutation('PUT', '/api/pudo/parcels/give')

  const verifyClientMutate = $verifyClient.mutate

  const acceptParcelMutate = $acceptParcel.mutate

  const returnParcelMutate = $returnParcel.mutate

  const giveToClientMutate = $giveToClient.mutate

  const onVerifyClient = useCallback(
    ({
      values,
      onSuccess,
    }: {
      values: ParcelIdentificationFieldValues
      onSuccess: Handler
    }) => {
      const type = clientPhone ? 'QR' : getAuthenticationType(values)
      const authenticationData = clientPhone ?? getAuthenticationData(values)
      const documentName = getDocumentName(values)

      verifyClientMutate(
        {
          body: {
            type,
            authenticationData,
            documentName,
            sender: isParcelReceptionMode(mode) || isParcelReturnMode(mode),
            parcelIds: parcels?.map(parcel => parcel.parcelId),
          },
        },
        { onSuccess },
      )
    },
    [mode, parcels, clientPhone, verifyClientMutate],
  )

  const confirmationDialog = useBoolean(false)

  const onSubmit = useCallback(
    async (values: ParcelIdentificationFieldValues | null) => {
      if (values === null) {
        return null
      }
      if (identificationValues != null) {
        values.document = identificationValues?.document
        values.identificationType = identificationValues?.identificationType
        values.smsCode = identificationValues?.smsCode || ''
      }
      if (isParcelReceptionMode(mode)) {
        return acceptParcelMutate(
          {
            body: parcels?.map(parcel => ({
              parcelId: parcel.parcelId,
              stickersData: values.stickers[parcel.parcelId].map(sticker => ({
                miniSticker: sticker.main || sticker.confirm,
              })),
            })),
            search: branchIdParams,
          },
          {
            onSuccess: () => {
              showSuccessNotification(
                t('notifications.parcel_reception_success'),
              )
              onClose()

              if (onSuccess) {
                onSuccess()
              }
              queryClient.invalidateQueries([PARCEL_COUNT_QUERY_KEY, branchId])
            },
          },
        )
      }

      onVerifyClient({
        values,
        onSuccess: () => {
          if (isParcelReturnMode(mode)) {
            return returnParcelMutate(
              {
                body: {
                  parcelIds: parcels?.map(parcel => parcel?.parcelId),
                },
                search: branchIdParams,
              },
              {
                onSuccess: () => {
                  showSuccessNotification(
                    t('notifications.parcel_return_success'),
                  )
                  onClose()

                  if (onSuccess) {
                    onSuccess()
                  }
                  queryClient.invalidateQueries([
                    PARCEL_COUNT_QUERY_KEY,
                    branchId,
                  ])
                },
              },
            )
          }
          giveToClientMutate(
            {
              body: parcels?.map(parcel => ({
                parcelId: parcel.parcelId,
                unpacking:
                  values.unpackChoices !== undefined
                    ? values.unpackChoices?.[parcel.parcelId] === 'true'
                    : undefined,
                phoneReceiver:
                  isAlternative === true
                    ? parcel.alternateReceiver?.phone
                    : parcel.receiverPhone,
              })),
              search: new URLSearchParams({
                branchId: branchId,
                isAlternative:
                  isAlternative !== undefined
                    ? isAlternative.toString()
                    : 'false',
              }),
            },
            {
              onSuccess: async () => {
                if (parcels?.every(hasNomenclatureOption)) {
                  for (const parcel of parcels) {
                    await $getParcelNomenclatures.mutateAsync(
                      {
                        params: {
                          parcelId: parcel.parcelId,
                        },
                      },
                      {
                        onSuccess: parcelNomenclatures => {
                          const parcelId = parcelNomenclatures[0].parcelId

                          const productItems = parcelNomenclatures.map(
                            nomenclature => ({
                              nomenclatureId: nomenclature.nomenclatureId,
                              amount: nomenclature.amount,
                              price: nomenclature.price,
                            }),
                          )

                          const newProductSale = {
                            parcelId,
                            productItems,
                          }
                          $saleProduct.mutate({
                            body: newProductSale,
                            search: new URLSearchParams({ branchId }),
                          })
                        },
                      },
                    )
                  }
                }
                showSuccessNotification(
                  t('notifications.parcel_issuance_success'),
                )
                onClose()

                if (onSuccess) {
                  onSuccess()
                }
                queryClient.invalidateQueries([
                  PARCEL_COUNT_QUERY_KEY,
                  branchId,
                  isAlternative,
                ])
              },
            },
          )
        },
      })
    },
    [
      identificationValues,
      mode,
      parcels,
      onVerifyClient,
      acceptParcelMutate,
      branchIdParams,
      showSuccessNotification,
      t,
      onClose,
      onSuccess,
      queryClient,
      branchId,
      $getParcelNomenclatures,
      $saleProduct,
      giveToClientMutate,
      isAlternative,
      returnParcelMutate,
    ],
  )

  const onFormSubmit = useCallback(
    (values: ParcelIdentificationFieldValues) => {
      if (identificationValues != null) {
        values.document = identificationValues?.document
        values.identificationType = identificationValues?.identificationType
        values.smsCode = identificationValues?.smsCode || ''
      }
      setFormSubmitValues({ ...values })
      confirmationDialog.setTrue()
    },
    [confirmationDialog, identificationValues],
  )

  const confirmationDialogSubmitHandler = useCallback(() => {
    confirmationDialog.setFalse()
    onSubmit(formSubmitValues)
    setFormSubmitValues(null)
  }, [confirmationDialog, formSubmitValues, onSubmit])

  /* eslint-disable */
  useEffect(() => {
    if (parcels?.some(parcel => parcel.stop === true)) {
      showErrorNotification(t('notifications.stop'))
      onClose()
    }
  }, [parcels])
  /* eslint-enable */

  if (isReturnedParcel) {
    return (
      <SupportDialog
        open={isOpen}
        onClose={onClose}
        title={t('dialog_support.attention_title')}
        text={t('dialog_support.parcel_return_text')}
        phoneNumbers={['+380674338830', '+380504338830']}
      />
    )
  }

  if (!parcels || $parcels.isLoading) {
    return null
  }

  // @ts-ignore
  return (
    <>
      <ConfirmationDialog
        title={t('dialog_confirm.title_confirmation_parcel_issuance')}
        text={
          isParcelIssuanceMode(mode) &&
          isUnpackOption(parcels) &&
          !parcels.some(parcel => parcel.international === true)
            ? t('dialog_confirm.issue_parcel_title')
            : ''
        }
        hint={
          isParcelIssuanceMode(mode) &&
          isUnpackOption(parcels) &&
          !parcels.some(parcel => parcel.international === true)
            ? t('dialog_confirm.issue_parcel_hint')
            : ''
        }
        confirm={
          isParcelIssuanceMode(mode) &&
          isUnpackOption(parcels) &&
          !parcels.some(parcel => parcel.international === true)
            ? t('dialog_confirm.confirm_title')
            : ''
        }
        attention={t('dialog_confirm.attention')}
        isOpen={confirmationDialog.isTrue}
        onClose={confirmationDialog.setFalse}
        onConfirm={confirmationDialogSubmitHandler}
      />
      <ParcelActionsFormDialog
        refreshArrears={() => $parcels.refetch()}
        mode={mode}
        clientPhone={clientPhone}
        setClientPhone={setClientPhone}
        isOpen={isOpen}
        parcels={parcels}
        onOpen={onOpen}
        onClose={onClose}
        renderParcelDetails={renderParcelDetails}
        onSubmit={isParcelIssuanceMode(mode) ? onFormSubmit : onSubmit}
        isParcelsLoading={$parcels.isLoading}
        isSubmitting={
          $acceptParcel.isLoading ||
          $giveToClient.isLoading ||
          $returnParcel.isLoading
        }
        branchId={branchId}
        identificationValues={identificationValues}
        closeDialogTitle={
          isParcelReceptionMode(mode)
            ? 'dialog_confirm.title_client_parcel_reception'
            : isParcelIssuanceMode(mode)
            ? 'dialog_confirm.title_client_parcel_issuance'
            : 'dialog_confirm.title_client_parcel_return'
        }
        onRefuseSuccess={onRefuseSuccess}
      />
    </>
  )
}
