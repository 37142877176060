import { IconProps, SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconClientParcelIssuance = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.94737 0.647073C7.14846 0.534702 7.36014 0.478516 7.5824 0.478516C7.80466 0.478516 8.01404 0.534702 8.21053 0.647073L14.5263 4.29213C14.7263 4.41049 14.8816 4.56649 14.9921 4.76015C15.1026 4.95381 15.1579 5.16301 15.1579 5.38775V8.69569C14.9662 8.55304 14.7639 8.42958 14.551 8.32533C14.338 8.22108 14.1193 8.12681 13.8947 8.04254V6.01985L10.7579 7.85291C9.92982 8.04956 9.18246 8.40774 8.51579 8.92746C7.84912 9.44718 7.32632 10.0793 6.94737 10.8237V9.32779L1.26316 6.04091V12.5514L6.84211 15.7751C6.98246 16.0701 7.14737 16.3545 7.33684 16.6284C7.52632 16.9023 7.74035 17.1587 7.97895 17.3975C7.81053 17.4677 7.63509 17.4923 7.45263 17.4712C7.27018 17.4502 7.10175 17.3905 6.94737 17.2921L0.631579 13.6471C0.431579 13.5287 0.276316 13.3727 0.165789 13.1791C0.0552632 12.9854 0 12.7762 0 12.5514V5.38775C0 5.16301 0.0552632 4.95381 0.165789 4.76015C0.276316 4.56649 0.431579 4.41049 0.631579 4.29213L6.94737 0.647073ZM7.57895 1.7427L1.95789 4.98743L7.57895 8.25323L13.1789 4.98743L7.57895 1.7427Z"
      fill={(props.sx as IconProps).color}
    />
    <path
      d="M11.7676 11.0812L9.41075 13.484L9.85692 13.9223L11.4629 12.2851L11.4962 15.8878L12.1278 15.882L12.0945 12.2792L13.7304 13.8866L14.1684 13.44L11.7676 11.0812ZM11.9633 9.47807C13.058 9.46796 14.0019 9.84833 14.7951 10.6192C15.5882 11.3901 15.9898 12.3275 16 13.4315C16.0102 14.5356 15.626 15.4845 14.8473 16.2783C14.0686 17.0721 13.132 17.4741 12.0373 17.4842C10.9426 17.4943 9.99866 17.1107 9.20548 16.3332C8.4123 15.5558 8.01057 14.6104 8.00029 13.497C7.99011 12.3953 8.3744 11.4526 9.15317 10.6689C9.93193 9.88511 10.8687 9.48817 11.9633 9.47807Z"
      fill={(props.sx as IconProps).color}
    />
  </SvgIcon>
)
