import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconInfo = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M6.5 12L10.5 5.5L16.5 9C16.6667 10.5 17 13.6 17 14C17 14.4 16 16.8333 15.5 18L11 19L6.5 12Z"
      fill="#7B8085"
    />
    <path
      d="M12 4C7.58166 4 4 7.58178 4 12C4 16.4185 7.58166 20 12 20C16.4183 20 20 16.4185 20 12C20 7.58178 16.4183 4 12 4ZM12 8C12.5523 8 13 8.44775 13 9C13 9.5525 12.5523 10 12 10C11.4477 10 11 9.5525 11 9C11 8.44775 11.4477 8 12 8ZM13.5 16H10.5C10.2239 16 10 15.7764 10 15.5C10 15.2239 10.2239 15 10.5 15H11V12H10.5C10.2239 12 10 11.7764 10 11.5C10 11.2239 10.2239 11 10.5 11H12.5C12.7761 11 13 11.2239 13 11.5V15H13.5C13.7761 15 14 15.2239 14 15.5C14 15.7764 13.7761 16 13.5 16Z"
      fill="#E6EDF8"
    />
  </SvgIcon>
)
