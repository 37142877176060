export const IconErrorAlert = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#FE6975" />
    <path
      d="M7.86852 2.88574C8.40657 2.88574 8.85003 3.2763 8.91064 3.77947L8.9177 3.89745V8.956C8.9177 9.51475 8.44796 9.96771 7.86852 9.96771C7.33046 9.96771 6.887 9.57715 6.82639 9.07399L6.81934 8.956V3.89745C6.81934 3.3387 7.28907 2.88574 7.86852 2.88574Z"
      fill="white"
    />
    <path
      d="M7.87374 10.4912C8.4503 10.4912 8.9177 10.9609 8.9177 11.5404C8.9177 12.0784 8.51469 12.5219 7.99548 12.5825L7.8633 12.5896C7.28673 12.5896 6.81934 12.1198 6.81934 11.5404C6.81934 11.0023 7.22235 10.5589 7.74155 10.4983L7.87374 10.4912Z"
      fill="white"
    />
  </svg>
)
