import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconBranches = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8108 16.9317H15.1432V4.44923C15.1432 4.15424 14.904 3.91511 14.6091 3.91511H13.9948H4.5396V1.06826H13.4606V2.17185C13.4606 2.46684 13.6998 2.70598 13.9948 2.70598C14.2897 2.70598 14.5289 2.46684 14.5289 2.17185V0.534129C14.5289 0.239133 14.2897 0 13.9948 0H4.00547C3.71047 0 3.47134 0.239133 3.47134 0.534129V3.91511H3.39122C3.09622 3.91511 2.85709 4.15424 2.85709 4.44923V13.5135C2.85709 13.8085 3.09622 14.0477 3.39122 14.0477C3.68621 14.0477 3.92535 13.8085 3.92535 13.5135V4.98336H4.00547H13.9948H14.0749V16.9317H3.39122H2.1894C1.89441 16.9317 1.65527 17.1709 1.65527 17.4659C1.65527 17.7609 1.89441 18 2.1894 18H3.39122H14.6091H15.8109C16.1058 18 16.345 17.7609 16.345 17.4659C16.345 17.1709 16.1058 16.9317 15.8108 16.9317Z"
      fill="#2C363F"
    />
    <path
      d="M5.20845 10.4877H7.96849C8.26349 10.4877 8.50262 10.2486 8.50262 9.95361V7.06928C8.50262 6.77429 8.26349 6.53516 7.96849 6.53516H5.20845C4.91345 6.53516 4.67432 6.77429 4.67432 7.06928V9.95361C4.67432 10.2486 4.91345 10.4877 5.20845 10.4877ZM5.74257 7.60341H7.43433V9.41948H5.74257V7.60341Z"
      fill="#2C363F"
    />
    <path
      d="M4.67432 14.9556C4.67432 15.2506 4.91345 15.4897 5.20845 15.4897H7.96849C8.26349 15.4897 8.50262 15.2506 8.50262 14.9556V12.0712C8.50262 11.7762 8.26349 11.5371 7.96849 11.5371H5.20845C4.91345 11.5371 4.67432 11.7762 4.67432 12.0712V14.9556ZM5.74257 12.6054H7.43433V14.4214H5.74257V12.6054Z"
      fill="#2C363F"
    />
    <path
      d="M10.0317 10.4877H12.7917C13.0867 10.4877 13.3259 10.2486 13.3259 9.95361V7.06928C13.3259 6.77429 13.0867 6.53516 12.7917 6.53516H10.0317C9.73673 6.53516 9.49756 6.77429 9.49756 7.06928V9.95361C9.49756 10.2486 9.73673 10.4877 10.0317 10.4877ZM10.5658 7.60341H12.2576V9.41948H10.5658V7.60341Z"
      fill="#2C363F"
    />
    <path
      d="M9.49756 14.9556C9.49756 15.2506 9.73673 15.4897 10.0317 15.4897H12.7917C13.0867 15.4897 13.3259 15.2506 13.3259 14.9556V12.0712C13.3259 11.7762 13.0867 11.5371 12.7917 11.5371H10.0317C9.73673 11.5371 9.49756 11.7762 9.49756 12.0712V14.9556ZM10.5658 12.6054H12.2576V14.4214H10.5658V12.6054Z"
      fill="#2C363F"
    />
  </SvgIcon>
)
