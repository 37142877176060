import { Parcel } from 'app/codecs/parcel'

import { FormMode } from './parcel-actions-form-dialog'

export const isParcelReceptionMode = (mode: FormMode) =>
  mode === 'parcel-reception'

export const isParcelIssuanceMode = (mode: FormMode) =>
  mode === 'parcel-issuance'

export const isParcelReturnMode = (mode: FormMode) => mode === 'parcel-return'

export const isUnpackOption = (parcels: Array<Parcel>) =>
  parcels.some(parcel =>
    parcel.additionalOptions?.some(
      option => option.optionId === '9c6c4d1e-b2cc-11e0-a658-003048d2b473',
    ),
  )
