import { createSvgIcon } from '@material-ui/core'

export const IconCheck = createSvgIcon(
  <svg fill="none" viewBox="0 0 24 24">
    <defs />
    <rect width="24" height="24" fill="#0061AF" rx="5" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 13l3 3 7-7"
    />
    <path
      stroke="url(#paint0_linear)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 13l3 3 7-7"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="12"
        x2="12"
        y1="9"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>,
  'IconCheck',
)
