import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

const TDocumentCreator = t.type({
  phone: t.union([t.string, t.undefined]),
})

const TDocumentType = t.union([
  t.literal('ENTERED'),
  t.literal('WAREHOUSE_DELIVERY'),
  t.literal('TRANSFER'),
  t.literal('RETURN_TO_SENDER'),
  t.literal('CHANGING_DIRECTION'),
])

export type DocumentType = t.TypeOf<typeof TDocumentType>

export const TParcel = t.type({
  parcelNumber: t.string,
  receiverName: t.string,
  receiverPhone: t.string,
  senderName: t.string,
  senderPhone: t.string,
})

export const TDocument = t.type({
  contactType: t.string,
  creator: TDocumentCreator,
  documentId: t.string,
  documentNumber: t.string,
  documentType: TDocumentType,
  createdAt: DateFromISOString,
  count: t.number,
  place: t.number,
  parcels: t.array(TParcel),
})

export const TDocumentAction = t.type({
  createdAtTime: t.string,
  createdAtDate: t.string,
  action: t.string,
  branch: t.union([t.string, t.undefined]),
})

export const TAction = t.type({
  action: t.array(TDocumentAction),
})
export type Document = t.TypeOf<typeof TDocument>

export type Parcel = t.TypeOf<typeof TParcel>
