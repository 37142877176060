import { Typography } from '@material-ui/core'
import { Checkbox } from 'components/checkbox'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  headLabel?: string
  disabled?: boolean
  required?: boolean
}

export const ControlledCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()
  const {
    field: { ...checkboxField },
  } = useController({
    control: props.control,
    name: props.name,
    rules: { required: props.required },
  })

  return (
    <>
      {props.headLabel && (
        <Typography variant="body2" color="textSecondary">
          {t(props.headLabel)}
        </Typography>
      )}
      <Checkbox
        {...checkboxField}
        name={props.name}
        required={props.required}
        disabled={props.disabled}
        label={t(props.label)}
        checked={checkboxField?.value}
      />
    </>
  )
}

export default ControlledCheckbox
