import { createSvgIcon } from '@material-ui/core'

export const IconAlert = createSvgIcon(
  <svg fill="none" viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="8" fill="#FE6975" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11 6a1 1 0 00-2 0v5a1 1 0 002 0V6zm0 8l-1-1a1 1 0 000 2l1-1z"
      clipRule="evenodd"
    />
    <path
      fill="url(#paint0_linear)"
      fillRule="evenodd"
      d="M11 6a1 1 0 00-2 0v5a1 1 0 002 0V6zm0 8l-1-1a1 1 0 000 2l1-1z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="10"
        x2="10"
        y1="5"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>,
  'IconAlert',
)
