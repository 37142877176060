export const IconBackAdminBig = () => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99994 1.00048L1.00046 5.98631L5.93803 11"
      stroke="#89A6CC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
