import { InputBaseProps, Theme } from '@material-ui/core'
import { useUuid } from 'hooks/use-uuid'
import { forwardRef } from 'react'

import { FormControl, FormControlProps } from '../form-control'
import { InputBase } from '../input-base'

export type TextFieldProps = Omit<FormControlProps, 'children' | 'htmlFor'> &
  InputBaseProps & {
    maxLength?: number
    minLength?: number
    placeholderVariant?: 'small' | 'inherit'
    value?: unknown
  }

// TODO [dev]: Clicking the label doesn't focus the input and I don't understand why.
//             See for inspiration: https://next.material-ui.com/components/text-fields/#customized-inputs
export const TextField = forwardRef((props: TextFieldProps, ref) => {
  const id = useUuid()

  return (
    <FormControl
      htmlFor={id}
      label={props.label}
      error={props.error}
      helperText={props.helperText}
      required={props.required}
      ref={ref}
    >
      <InputBase
        title={props.placeholder}
        sx={{
          height: props.multiline ? 'inherit' : 44,
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: (theme: Theme) => theme.palette.grey[500],
          },
        }}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        id={id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        error={props.error}
        startAdornment={props.startAdornment}
        endAdornment={props.endAdornment}
        multiline={props.multiline}
        rows={props.rows}
        maxRows={props.maxRows}
        minRows={props.minRows}
        autoComplete={props.autoComplete ?? 'new-password'}
        required={props.required}
        onBlur={props.onBlur}
        inputComponent={props.inputComponent}
        inputProps={{
          ...props.inputProps,
          maxLength: props.maxLength,
          minLength: props.minLength,
          sx: {
            pl: 0,
            ...(props.placeholderVariant === 'small' && {
              '&::placeholder': {
                fontSize: (theme: Theme) => theme.typography.caption1.fontSize,
              },
            }),
          },
        }}
      />
    </FormControl>
  )
})

TextField.displayName = 'TextField'
