import { Box, Divider, Typography } from '@material-ui/core'
import {
  Control,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<T> = {
  title: string
  Component: (props: {
    control: Control<T>
    watch: UseFormWatch<T>
    clearErrors: UseFormClearErrors<T>
    setValue: UseFormSetValue<T>
  }) => JSX.Element | null
  control: Control<T>
  watch: UseFormWatch<T>
  setValue: UseFormSetValue<T>
  clearErrors: UseFormClearErrors<T>
}
export const FormStep = <T,>({
  title,
  Component,
  control,
  watch,
  clearErrors,
  setValue,
}: Props<T>) => {
  const { t } = useTranslation()

  return (
    <Box mt={1.5} width="100%">
      <Typography variant="body2" textAlign="left" width={1}>
        {t(title)}
      </Typography>
      <Divider sx={{ width: '100%', mt: 1 }} />
      <Box mt={3}>
        <Component {...{ control, watch, clearErrors, setValue }} />
      </Box>
    </Box>
  )
}
