import { IconProps } from '@material-ui/core'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconCreateNotification = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="19"
    height="19"
    viewBox="-1 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4202 16.0449H2.91016V7.15489C2.91016 5.60489 3.62016 4.23489 4.75016 3.34489C5.38016 2.81489 6.15016 2.47489 7.00016 2.32489C7.26016 2.28489 7.52016 2.26489 7.80016 2.26489C8.11016 2.26489 8.40016 2.28489 8.69016 2.33489C9.52016 2.48489 10.2802 2.84489 10.9102 3.34489C11.0702 3.45489 11.2202 3.58489 11.3602 3.72489C12.2702 4.64489 12.8302 5.89489 12.8302 7.28489V9.15489"
      stroke={(props.sx as IconProps).color}
      strokeMiterlimit="10"
      strokeWidth="1.3"
    />
    <path
      d="M13.4902 3.34497C14.2502 4.45497 14.6802 5.79497 14.6802 7.22497V9.15497"
      stroke={(props.sx as IconProps).color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeWidth="1.3"
    />
    <path
      d="M1 10.515V7.10497C1 5.71497 1.42 4.43497 2.14 3.34497"
      stroke={(props.sx as IconProps).color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeWidth="1.3"
    />
    <path
      d="M7.37039 1.99494H7.36039L7.35039 1.97494C7.23039 1.85494 7.15039 1.68494 7.15039 1.50494C7.15039 1.13494 7.44039 0.834941 7.80039 0.814941H7.88039C8.24039 0.834941 8.53039 1.12494 8.53039 1.50494C8.53039 1.69494 8.46039 1.85494 8.33039 1.99494C8.19039 2.12494 8.02039 2.19494 7.84039 2.19494C7.66039 2.19494 7.47039 2.10494 7.37039 1.99494Z"
      fill="#2C363F"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.3"
    />
    <path
      d="M8.76992 17.635C8.58992 17.965 8.23992 18.185 7.83992 18.185C7.43992 18.185 7.08992 17.965 6.91992 17.635H8.77992H8.76992Z"
      fill="black"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.3"
    />
    <path
      d="M10.4203 16.215H1.57031V15.885H2.91031H10.4203"
      fill="black"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.3"
    />
    <path
      d="M10.4203 16.215H1.57031V15.885H2.91031H10.4203"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.3"
    />
    <path
      d="M12.2397 10.385H12.9297V16.215H12.2397V10.385Z"
      fill="#2C363F"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.3"
    />
    <path
      d="M15.5 12.965V13.655H9.67V12.965H15.5Z"
      fill="#2C363F"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.3"
    />
  </SvgIcon>
)
