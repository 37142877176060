import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconFilter = (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      d="M3.11111 2H20.8889C21.1836 2 21.4662 2.11892 21.6746 2.3306C21.8829 2.54228 22 2.82937 22 3.12873V4.91891C21.9999 5.21824 21.8828 5.50529 21.6744 5.71692L14.5467 12.9566C14.3387 13.1684 14.222 13.4555 14.2222 13.7546V20.8713C14.2222 21.0429 14.1837 21.2122 14.1096 21.3664C14.0355 21.5206 13.9278 21.6556 13.7947 21.7611C13.6615 21.8667 13.5064 21.94 13.3412 21.9755C13.176 22.0111 13.0049 22.0079 12.8411 21.9662L10.6189 21.4018C10.3786 21.3407 10.1653 21.1997 10.013 21.0013C9.86056 20.803 9.77779 20.5586 9.77778 20.3069V13.7546C9.77772 13.4553 9.66061 13.1682 9.45222 12.9566L2.32444 5.71692C2.11646 5.50511 1.99977 5.21808 2 4.91891V3.12873C2 2.82937 2.11706 2.54228 2.32544 2.3306C2.53381 2.11892 2.81643 2 3.11111 2V2Z"
      stroke="#0061AF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
