import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconXsParcel = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ width: '44px', height: '44px' }}
    fill="none"
    viewBox="0 0 93 60"
  >
    <path
      d="m86.8 37.2-39.2 22.6-8.9-2.5-38.2-22.2 41.5-23.9 44.8 26z"
      fill="#2C363F"
      opacity=".2"
    />
    <path
      d="m44.5 47.4 4.6 7.2-4.6 1.9s-10.1-4.6-16.5-8.4c-7.3-4.4-13.9-9.1-13.9-9.1l-4.6-7.2 4.6-1.9 17.4 3.9 13 13.6z"
      fill="#D9B996"
    />
    <path
      d="m9.3999 31.8 5.8 2 30.5 17.5 3.4 3.4-2.1-10.7-26.3-14.6-7.5 0.2-3.8 2.2z"
      fill="#B39372"
    />
    <path d="m92.2 31.6-41.5 24-1.6-0.9 41.5-24 1.6 0.9z" fill="#D9B996" />
    <path d="m49.3 6.9-41.5 23.9 1.6 1 41.5-24-1.6-0.9z" fill="#D9B996" />
    <path
      d="m85.9 23.5-41.4 23.9s-8.6-6.1-15.1-9.9c-6-3.5-15.3-7.6-15.3-7.6l41.4-24s7 2.4 14.8 6.8c7.2 4.2 15.6 10.8 15.6 10.8z"
      fill="#D9B996"
    />
    <path d="m90.6 30.7-41.5 24-4.6-7.3 41.4-23.9 4.7 7.2z" fill="#CCAC89" />
    <path d="m50.9 7.8-41.5 24 4.7-1.9 41.4-24-4.6 1.9z" fill="#CCAC89" />
    <path d="m9.6001 31.8 4.5 7.2 1.2-5.2-5.7-2z" fill="#CCAC89" />
    <path d="m45.7 51.3-1.2 5.1 4.6-1.7-3.4-3.4z" fill="#CCAC89" />
    <path d="m83 24.6-8.2 4.7-4.1-2.4 8.2-4.7 4.1 2.4z" fill="#fff" />
    <path d="m83 24.6-8.2 4.7-4.1-2.4 8.2-4.7 4.1 2.4z" fill="url(#a)" />
    <path d="m75 28.5 0.1-0.1-2.9-1.7-0.1 0.1 2.9 1.7z" fill="#2C363F" />
    <path d="m75.3 28.3 0.1-0.1-2.9-1.7-0.1 0.2 2.9 1.6z" fill="#2C363F" />
    <path d="m79.3 26 0.2-0.1-2.9-1.7-0.2 0.1 2.9 1.7z" fill="#2C363F" />
    <path d="m76 27.9 0.2-0.1-2.9-1.7-0.2 0.1 2.9 1.7z" fill="#2C363F" />
    <path d="m75.6 28.1h0.1l-2.9-1.7h-0.1l2.9 1.7z" fill="#2C363F" />
    <path d="m80.4 25.3 0.2-0.1-2.9-1.6-0.2 0.1 2.9 1.6z" fill="#2C363F" />
    <path d="m80.8 25.2 0.1-0.1-2.9-1.7-0.1 0.1 2.9 1.7z" fill="#2C363F" />
    <path d="m78.6 23.1 2.9 1.7 0.1-0.2-2.9-1.6-0.1 0.1z" fill="#2C363F" />
    <path d="m81.1 25 0.1-0.1-2.9-1.7-0.1 0.1 2.9 1.7z" fill="#2C363F" />
    <path d="m77 27.3 0.2-0.1-2.9-1.7-0.2 0.1 2.9 1.7z" fill="#2C363F" />
    <path d="m77.8 26.9 0.1-0.1-2.9-1.7-0.2 0.1 3 1.7z" fill="#2C363F" />
    <path d="m77.4 27.1h0.1l-2.9-1.7h-0.1l2.9 1.7z" fill="#2C363F" />
    <path d="m76.5 27.6-2.9-1.7v0.1l2.9 1.6z" fill="#2C363F" />
    <path d="m76.7 27.5h0.1l-2.9-1.7h-0.1l2.9 1.7z" fill="#2C363F" />
    <path d="m78.2 26.7v-0.1l-2.9-1.7v0.1l2.9 1.7z" fill="#2C363F" />
    <path d="m78.5 26.5v-0.1l-2.9-1.6 2.9 1.7z" fill="#2C363F" />
    <path d="m78.8 26.3 0.1-0.1-2.9-1.6h-0.1l2.9 1.7z" fill="#2C363F" />
    <path d="m79.8 25.7h0.1l-2.9-1.7-0.1 0.1 2.9 1.6z" fill="#2C363F" />
    <path d="m80.1 25.6 0.1-0.1-2.9-1.7-0.1 0.1 2.9 1.7z" fill="#2C363F" />
    <path
      d="m76.2 21.6-23.9-14.4-29.8 10.5v5.9l1.3 1.9 23.2 12.9 29.2-16.8z"
      fill="#0061AF"
    />
    <path
      d="m47 30.3v7.2l-22.1-12.8c-0.7-0.4-1.2-1.3-1.2-2.1v-4.3c0-0.8 0.5-1.1 1.2-0.7l22.1 12.7z"
      fill="#F4F5F5"
    />
    <path
      d="m53.1 0.3c-0.5-0.3-1-0.3-1.4-0.2l-29.2 16.9 24.5 14.3 29.1-16.8v-0.9l-23-13.3z"
      fill="#0061AF"
    />
    <path d="M47 38.4L76.2 21.6V22.5L47 39.3V38.4Z" fill="#1E407F" />
    <path d="M47 30.4L76.2 13.6V14.5L47 31.3V30.4Z" fill="#1E407F" />
    <path d="M47 31.3V37.5L74.1 21.8V15.6L47 31.3Z" fill="#E0E3E7" />
    <path
      d="m47 38.4-23-13.2c-0.7-0.4-1.2-1.3-1.2-2.1v-4.3c0-0.8 0.5-1.1 1.2-0.7l23 13.3v-0.9l-23-13.4c-1.1-0.6-2-0.1-2 1.2v4.3c0 1.3 0.9 2.8 2 3.5l23 13.3v-1z"
      fill="#1E407F"
    />
    <path d="m47 37.5h1.7l27.5-15.9-2.1-1.2v1.4l-27.1 15.7z" fill="#0061AF" />
    <path d="m47 36.3-21.9-12.5 21.9 12.8 26.6-15.3-26.6 15z" fill="#C1C5C7" />
    <path d="m47 35-19.7-11.3 19.7 11.6 24.2-14-24.2 13.7z" fill="#C1C5C7" />
    <path d="M47 32.4L25.5 20.5L47 32.7L73.2 17.7L47 32.4Z" fill="#C1C5C7" />
    <path d="m47 33.7-17.4-9.9 17.4 10.2 19.1-11-19.1 10.7z" fill="#C1C5C7" />
    <path
      d="m72.6 36.3c-0.1-0.4 0.6-0.6 0.8-0.6l-0.1-0.8c-0.7 0.1-1.6 0.8-1.4 1.9s1.3 0.3 1.4 0.8-0.9 0.8-1.1 0.7v0.8c0.8 0 2-0.7 1.8-1.9s-1.3-0.4-1.4-0.9zm-1.5 1.2c-0.2-1-1.1-0.5-1 0.5l1-0.5zm-0.8 1.3c0.2 0.8 1.1 0.2 1.5-0.2l0.2 0.7c-0.9 1-2.3 1.4-2.6-0.3-0.4-2.3 2-3.9 2.4-1.4v0.4l-1.5 0.8zm3.2-3.2 0.4-0.2 0.2 1.5c0.1 0.8 0.4 1.3 1 0.9 0.2-0.1 0.4-0.3 0.5-0.4l-0.1-0.8c-0.4 0.3-0.5 0.4-0.6-0.3l-0.5-3-0.7 0.4 0.2 1-0.3 0.2-0.1 0.7zm1.2-0.6 0.4-0.2 0.1-0.4-0.2-0.4-0.4 0.2 0.1 0.8zm-9.1 7.8 0.1-3.8 0.7 3.4 0.7-0.4-0.9-4.4-1.1 0.6-0.1 3.3-1-2.7-1.1 0.6 0.5 4.6 0.7-0.4-0.4-3.6 1.2 3.1 0.7-0.3z"
      fill="#0061AF"
    />
    <path
      d="m68.5 38.9c-0.2-1-1.1-0.5-1 0.5l1-0.5zm-0.8 1.2c0.2 0.8 1.1 0.2 1.5-0.2l0.2 0.7c-0.9 1-2.3 1.4-2.6-0.3-0.4-2.3 2-3.9 2.4-1.4v0.4l-1.5 0.8z"
      fill="#E60033"
    />
    <path
      d="m75.3 32.8 0.7 1.1-0.3 1.6-0.5 0.3 0.3-1.6-0.8-1.1 0.6-0.3z"
      fill="#E60033"
    />
    <defs>
      <linearGradient
        id="a"
        x1="76.85"
        x2="76.85"
        y1="22.2"
        y2="29.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" offset="0" />
        <stop stopColor="#fff" stopOpacity="0" offset="1" />
      </linearGradient>
    </defs>
  </SvgIcon>
)
