import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconDocsParcel = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ width: '44px', height: '44px' }}
    fill="none"
    viewBox="0 0 50 20"
  >
    <g opacity="0.2">
      <path
        d="M49.6665 13.0295L27.1247 26.0459L21.9696 24.5865L0 11.8352L24.8333 0L49.6665 13.0295Z"
        fill="#1B1C20"
      />
    </g>
    <path
      d="M25.4816 22.1145L27.458 22.9072L26.3766 24.2433C26.3766 24.2433 25.5078 23.5372 19.7962 20.936C15.2238 18.8545 7.17618 13.7021 7.17618 13.7021L3.57495 12.2558L6.28112 11.5733L17.5074 14.8465L25.4816 22.1145Z"
      fill="#F2F2F2"
    />
    <path
      d="M49.8975 11.3814L27.4265 24.0802L26.2874 23.8492L48.7557 11.1504L49.8975 11.3814Z"
      fill="#F2F2F2"
    />
    <path
      d="M24.9407 1.79879L2.47241 12.1143L3.61158 12.3453L26.0799 2.02977L24.9407 1.79879Z"
      fill="#F2F2F2"
    />
    <path
      d="M48.682 11.1588L26.2137 23.8576C26.2137 23.8576 21.4812 17.482 16.8694 16.5265C12.6172 15.6472 6.23633 11.4659 6.23633 11.4659L28.7046 1.15043C28.7046 1.15043 31.6024 0.914196 36.6657 3.25552C41.9048 5.67559 48.682 11.1588 48.682 11.1588Z"
      fill="#F2F2F2"
    />
    <path
      d="M26.0801 2.02883L3.61182 12.3443L6.23662 11.4624L28.7049 1.14952L26.0801 2.02883Z"
      fill="#E6E6E6"
    />
    <path
      d="M46.5534 11.0912L42.1201 13.1254L39.2197 12.5375L43.6504 10.5032L46.5534 11.0912Z"
      fill="white"
    />
    <path
      d="M42.1017 12.8534L42.1988 12.8088L40.1463 12.394L40.0518 12.4387L42.1017 12.8534Z"
      fill="#1B1C20"
    />
    <path
      d="M42.267 12.776L42.3641 12.7314L40.3142 12.3167L40.217 12.3613L42.267 12.776Z"
      fill="#1B1C20"
    />
    <path
      d="M44.4718 11.7631L44.569 11.7211L42.5164 11.3038L42.4219 11.3484L44.4718 11.7631Z"
      fill="#1B1C20"
    />
    <path
      d="M42.653 12.6012L42.7501 12.5566L40.6975 12.1418L40.603 12.1838L42.653 12.6012Z"
      fill="#1B1C20"
    />
    <path
      d="M42.4613 12.687L42.5085 12.666L40.4586 12.2513L40.4087 12.2723L42.4613 12.687Z"
      fill="#1B1C20"
    />
    <path
      d="M45.0726 11.4908L45.1697 11.4462L43.1171 11.0288L43.02 11.0735L45.0726 11.4908Z"
      fill="#1B1C20"
    />
    <path
      d="M45.2382 11.412L45.3353 11.37L43.2854 10.9526L43.1882 10.9973L45.2382 11.412Z"
      fill="#1B1C20"
    />
    <path
      d="M43.5713 10.8212L45.6239 11.236L45.721 11.1913L43.6684 10.7766L43.5713 10.8212Z"
      fill="#1B1C20"
    />
    <path
      d="M45.4299 11.3253L45.4797 11.3017L43.4298 10.887L43.3799 10.908L45.4299 11.3253Z"
      fill="#1B1C20"
    />
    <path
      d="M43.225 12.3382L43.3221 12.2936L41.2722 11.8763L41.175 11.9209L43.225 12.3382Z"
      fill="#1B1C20"
    />
    <path
      d="M43.6108 12.1598L43.7079 12.1152L41.6553 11.7005L41.5608 11.7451L43.6108 12.1598Z"
      fill="#1B1C20"
    />
    <path
      d="M43.4193 12.25L43.4665 12.2264L41.4166 11.8117L41.3667 11.8353L43.4193 12.25Z"
      fill="#1B1C20"
    />
    <path
      d="M42.91 12.4826L42.9573 12.4589L40.9073 12.0442L40.8574 12.0679L42.91 12.4826Z"
      fill="#1B1C20"
    />
    <path
      d="M43.0414 12.423L43.0887 12.3994L41.0387 11.9847L40.9915 12.0057L43.0414 12.423Z"
      fill="#1B1C20"
    />
    <path
      d="M43.8288 12.0597L43.8787 12.0361L41.8261 11.6214L41.7788 11.645L43.8288 12.0597Z"
      fill="#1B1C20"
    />
    <path
      d="M43.994 11.9835L44.0439 11.9625L41.9913 11.5452L41.9414 11.5688L43.994 11.9835Z"
      fill="#1B1C20"
    />
    <path
      d="M44.1752 11.8998L44.2225 11.8788L42.1725 11.4641L42.1252 11.485L44.1752 11.8998Z"
      fill="#1B1C20"
    />
    <path
      d="M44.7054 11.6593L44.7527 11.6357L42.7027 11.221L42.6528 11.2446L44.7054 11.6593Z"
      fill="#1B1C20"
    />
    <path
      d="M44.8735 11.5793L44.9233 11.5583L42.8707 11.1409L42.8235 11.1645L44.8735 11.5793Z"
      fill="#1B1C20"
    />
    <path
      d="M30.2298 9.26189C30.0434 9.17003 30.6445 8.96792 30.8519 8.92067L30.5946 8.74743C29.9384 8.92854 29.1694 9.33014 29.6287 9.5585C30.0697 9.78948 31.0566 9.31177 31.2718 9.41938C31.5002 9.53225 30.5605 9.8236 30.3138 9.8656L30.529 10.0572C31.3112 9.88135 32.432 9.36951 31.886 9.12803C31.3768 8.88392 30.4345 9.36689 30.2298 9.26452M28.9961 9.93909C28.5945 9.73961 27.7362 10.0992 28.1509 10.2987L28.9961 9.93909ZM28.4554 10.4562C28.857 10.6137 29.6655 10.2121 29.9673 10.0126L30.3689 10.1255C29.7022 10.6084 28.3872 11.0389 27.6575 10.6767C26.6863 10.1937 28.7651 9.2094 29.7914 9.72124C29.8702 9.76061 29.9227 9.79998 29.9463 9.82098L28.4554 10.4562ZM30.9647 8.87605L31.3007 8.73168L31.9123 9.03616C32.2325 9.19627 32.6 9.26189 33.1591 9.02304C33.3612 8.93642 33.495 8.8498 33.5659 8.7973L33.2221 8.62669C32.9281 8.78943 32.7785 8.86555 32.4976 8.72381L31.2377 8.09911L30.5868 8.37734L30.9936 8.57945L30.7075 8.70019L30.9647 8.87605ZM32.0645 8.40883L32.4477 8.2461L32.4267 8.11223L32.1433 8.09386L31.7574 8.25922L32.0645 8.41146V8.40883ZM24.6783 12.6111L23.7964 11.6478L25.429 12.3198L26.0668 12.0468L23.9854 11.1911L22.988 11.6163L23.7675 12.4563L22.0089 12.0337L21.0115 12.4589L22.7045 13.48L23.3423 13.207L22.0141 12.4064L24.0326 12.8841L24.6731 12.6111H24.6783Z"
      fill="#025195"
    />
    <path
      d="M26.6967 10.9133C26.2951 10.7138 25.4368 11.0734 25.8515 11.2729L26.6967 10.9133ZM26.156 11.4304C26.5576 11.5879 27.3661 11.1863 27.6679 10.9868L28.0695 11.0997C27.4028 11.5826 26.0878 12.0131 25.3581 11.6509C24.3869 11.1679 26.4631 10.1836 27.492 10.6954C27.5708 10.7348 27.6233 10.7742 27.6469 10.7952L26.156 11.4304Z"
      fill="#DD263B"
    />
    <path
      d="M32.0777 7.73741L33.125 7.80828L33.2011 8.29387L32.7181 8.50123L32.6394 8.01564L31.5947 7.94215L32.0777 7.73741Z"
      fill="#DD263B"
    />
  </SvgIcon>
)
