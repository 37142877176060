import { Stack } from '@material-ui/core'
import { TCity, TStreet } from 'app/codecs/address'
import { Branch, TBranch } from 'app/codecs/branch'
import { selectOption } from 'app/options'
import { ControlledAutocomplete } from 'components/form/controlled-autocomplete'
import ControlledCheckbox from 'components/form/controlled-checkbox'
import { ControlledPhoneTextField } from 'components/form/controlled-phone-text-field'
import { ControlledSelect } from 'components/form/controlled-select'
import { ControlledTextField } from 'components/form/controlled-text-field'
import { FormField } from 'components/form/form-field'
import {
  building,
  flat,
  nameUaWithOptionalSymbol,
  notes,
} from 'components/form/validations'
import { ParcelStepProps } from 'components/forms/create-parcel/steps/parcel-info-step'
import { array } from 'io-ts'
import { useMutation } from 'lib/rest-query/rest-mutation'
import {
  formatBranch,
  formatBranchShort,
  formatCity,
  formatStreet,
} from 'meest-domain/format'
import { countPlacesSum, isBranchAvailableForPlaces } from 'meest-domain/utils'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type TypeCity = {
  cityId: string
  nameRU: string
  typeUA: string
  nameUA: string
  region: string
  district: string
}

export const ReceiverInfoStep = ({
  control,
  watch,
  clearErrors,
  setValue,
}: ParcelStepProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const service = watch('receiver.service')
  const city = watch('receiver.addressRequest.city')
  const branchCity = watch('receiver.branchCity')
  const deliveryPlaceId = watch('receiver.deliveryPlaceId')
  const places = watch('places')
  const receiverPhone = watch('receiver.number')
  const receiverStreet = watch('receiver.addressRequest.street')

  const [curCity, setCurCity] = useState<string | undefined>(
    branchCity?.label || '',
  )

  const serviceOptions = useMemo(() => {
    const options = [
      selectOption(t('user.branch'), 'BRANCH'),
      selectOption(t('service_options.courier'), 'COURIER'),
      selectOption(t('service_options.parcel_machine'), 'PARCEL_MACHINE'),
    ]

    return options
  }, [t])

  const actualWeight = countPlacesSum(places)
  const totalInsurance = places.reduce((sum, place) => {
    return sum + Number.parseFloat(place.insurance)
  }, 0)

  clearErrors()

  useEffect(() => {
    if (service === 'COURIER') {
      setValue('branchAdditionalOption', false)
    }
    if (service === 'BRANCH' || service === 'PARCEL_MACHINE') {
      setValue('courierAdditionalOptions', [])
      setValue('courierAdditionalOptions', [])
    }
  }, [service, setValue])

  const $favouriteBranch = useMutation(
    'GET',
    `/api/pudo/branches/favouriteBranch/:clientPhone`,
    TBranch,
  )

  const [favoutiteCity, setFavoutiteCity] = useState<TypeCity | null>(null)
  const [favouriteBranchData, setFavouriteBranchData] = useState<Branch | null>(
    null,
  )
  const [isFavouriteBranchAvailable, setIsFavouriteBranchAvailable] =
    useState<boolean>(true)

  /* eslint-disable */
  useEffect(() => {
    if (
      service === 'BRANCH' &&
      receiverPhone.length === 9 &&
      !branchCity &&
      !deliveryPlaceId
    ) {
      $favouriteBranch.mutate(
        {
          params: {
            clientPhone: `380${receiverPhone}`,
          },
        },
        {
          onSuccess: data => {
            setIsFavouriteBranchAvailable(
              isBranchAvailableForPlaces(
                data.branchType.weightLimit!,
                data.insuranceMax!,
                places,
              ),
            )
            setFavouriteBranchData(data)
          },
          onError: () => {
            setFavouriteBranchData(null)
            setValue('receiver.branchCity', null)
            setValue('receiver.deliveryPlaceId', null)
          },
        },
      )
    } else {
      setFavouriteBranchData(null)
    }
    if (service !== 'BRANCH') {
      setValue('receiver.branchCity', null)
      setValue('receiver.deliveryPlaceId', null)
    }
  }, [service, receiverPhone])

  useEffect(() => {
    if (favouriteBranchData !== null) {
      setFavoutiteCity({
        cityId: favouriteBranchData.city.addressDetailsId,
        nameRU: favouriteBranchData.city.descriptionRU,
        typeUA: favouriteBranchData.city.addressType.descriptionUA,
        nameUA: favouriteBranchData.city.descriptionUA,
        region: favouriteBranchData.region.descriptionUA,
        district: favouriteBranchData.district.descriptionUA,
      })
    }
  }, [favouriteBranchData])

  useEffect(() => {
    if (favoutiteCity !== null) {
      setValue('receiver.branchCity', {
        label: formatCity(language)(favoutiteCity),
        value: {
          cityId: favoutiteCity.cityId,
          cityNameUA: favoutiteCity.nameUA,
          cityNameRU: favoutiteCity.nameRU,
          cityType: favoutiteCity.typeUA,
        },
      })
      setCurCity(branchCity?.label)
    }
  }, [favoutiteCity])

  useEffect(() => {
    if (favouriteBranchData !== null && isFavouriteBranchAvailable) {
      setValue('receiver.deliveryPlaceId', {
        label: formatBranchShort(language)(favouriteBranchData),
        value: favouriteBranchData.branchId,
      })
    }
    if (curCity !== branchCity?.label && deliveryPlaceId !== null) {
      setValue('receiver.deliveryPlaceId', null)
    }
  }, [branchCity])

  useEffect(() => {
    setValue('receiver.addressRequest.street', null)
    setValue('receiver.addressRequest.building', '')
    setValue('receiver.addressRequest.corps', '')
    setValue('receiver.addressRequest.flat', '')
  }, [city])

  useEffect(() => {
    setValue('receiver.addressRequest.building', '')
    setValue('receiver.addressRequest.corps', '')
    setValue('receiver.addressRequest.flat', '')
  }, [receiverStreet])
  /* eslint-enable */

  return (
    <>
      <FormField>
        <ControlledTextField
          name="receiver.lastname"
          control={control}
          label={'form_fields.last_name'}
          type="text"
          required
          pattern={{
            value: nameUaWithOptionalSymbol.pattern.value,
            message: t(nameUaWithOptionalSymbol.pattern.message),
          }}
        />
      </FormField>
      <FormField>
        <ControlledTextField
          name="receiver.firstname"
          control={control}
          label={'form_fields.first_name'}
          type="text"
          required
          pattern={{
            value: nameUaWithOptionalSymbol.pattern.value,
            message: t(nameUaWithOptionalSymbol.pattern.message),
          }}
        />
      </FormField>
      <FormField>
        <ControlledTextField
          name="receiver.middleName"
          control={control}
          label={'form_fields.middle_name'}
          type="text"
          required
          pattern={{
            value: nameUaWithOptionalSymbol.pattern.value,
            message: t(nameUaWithOptionalSymbol.pattern.message),
          }}
        />
      </FormField>

      <FormField>
        <ControlledPhoneTextField
          control={control}
          required
          name="receiver.number"
          label={'form_fields.receiver_phone'}
        />
      </FormField>

      <FormField>
        <ControlledSelect
          control={control}
          label={'form_fields.service'}
          name="receiver.service"
          required
          options={serviceOptions}
        />
      </FormField>

      {service === 'BRANCH' && (
        <>
          <FormField>
            <ControlledAutocomplete
              required
              name="receiver.branchCity"
              label={'form_fields.city'}
              control={control}
              optionsUrl={'/api/pudo/cities/'}
              optionsCodec={array(TCity)}
              queryParam="cityName"
              formatOptions={cities => {
                setCurCity(branchCity?.label)
                return cities.map(city => ({
                  label: formatCity(language)(city),
                  value: {
                    cityNameUA: city.nameUA,
                    cityNameRU: city.nameRU,
                    cityType: city.typeUA,
                    cityId: city.cityId,
                  },
                }))
              }}
            />
          </FormField>
          {branchCity !== null && (
            <FormField>
              <ControlledAutocomplete
                required
                name="receiver.deliveryPlaceId"
                label={'user.branch'}
                control={control}
                optionsUrl={'/api/pudo/branches/search'}
                searchBodyParam="searchCriteria"
                optionsCodec={array(TBranch)}
                formatOptions={branches =>
                  branches.map(branch => ({
                    label: formatBranchShort(language)(branch),
                    value: branch.branchId,
                    option: branch.closed ? 'closedBranch' : null,
                  }))
                }
                rawBody={{
                  types: [
                    'MINI_BRANCH_TEN',
                    'MINI_BRANCH_THIRTY',
                    'BRANCH_MPPB',
                    'BRANCH_OB',
                    'BRANCH_PPB',
                    'UNKNOWN',
                  ],
                  cityId: branchCity.value.cityId,
                  closed: [false],
                  actualWeight: actualWeight,
                  insurance: totalInsurance,
                }}
                showTip={true}
              />
            </FormField>
          )}
        </>
      )}

      {service === 'COURIER' && (
        <>
          <FormField>
            <ControlledAutocomplete
              required
              name="receiver.addressRequest.city"
              label={'form_fields.city'}
              control={control}
              optionsUrl={'/api/pudo/cities/'}
              optionsCodec={array(TCity)}
              queryParam="cityName"
              formatOptions={cities =>
                cities.map(city => ({
                  label: formatCity(language)(city),
                  value: {
                    cityNameUA: city.nameUA,
                    cityNameRU: city.nameRU,
                    cityType: city.typeUA,
                    cityId: city.cityId,
                  },
                }))
              }
            />
          </FormField>
          {city?.value.cityId !== '' && (
            <>
              <FormField>
                <ControlledAutocomplete
                  required
                  name="receiver.addressRequest.street"
                  control={control}
                  label={t('form_fields.street')}
                  optionsUrl={`/api/pudo/cities/${city?.value.cityId}/streets/`}
                  optionsCodec={array(TStreet)}
                  serverSearch
                  formatOptions={streets =>
                    streets.map(street => ({
                      label: formatStreet(language)(street),
                      value: {
                        streetNameUA: street.nameUA,
                        streetNameRU: street.nameRU,
                        streetType: street.typeRU,
                        streetId: street.streetId,
                      },
                    }))
                  }
                />
              </FormField>

              <FormField>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <ControlledTextField
                    name="receiver.addressRequest.building"
                    control={control}
                    label={t('form_fields.building')}
                    type="text"
                    required
                    pattern={{
                      value: building.pattern.value,
                      message: t(building.pattern.message),
                    }}
                  />

                  <ControlledTextField
                    name="receiver.addressRequest.corps"
                    control={control}
                    label={t('form_fields.corps')}
                    type="number"
                    maxLength={3}
                  />

                  <ControlledTextField
                    name="receiver.addressRequest.flat"
                    control={control}
                    label={t('form_fields.flat')}
                    maxLength={4}
                    type="text"
                    pattern={{
                      value: flat.pattern.value,
                      message: t(flat.pattern.message),
                    }}
                  />
                </Stack>
              </FormField>
            </>
          )}
        </>
      )}

      {service === 'PARCEL_MACHINE' && (
        <>
          <FormField>
            <ControlledAutocomplete
              required
              name="receiver.branchCity"
              label={'form_fields.city'}
              control={control}
              optionsUrl={'/api/pudo/cities/'}
              optionsCodec={array(TCity)}
              queryParam="cityName"
              formatOptions={cities =>
                cities.map(city => ({
                  label: formatCity(language)(city),
                  value: {
                    cityNameUA: city.nameUA,
                    cityNameRU: city.nameRU,
                    cityType: city.typeUA,
                    cityId: city.cityId,
                  },
                }))
              }
            />
          </FormField>
          {branchCity && (
            <FormField>
              <ControlledAutocomplete
                required
                name="receiver.deliveryPlaceId"
                label={'service_options.parcel_machine'}
                control={control}
                optionsUrl={'/api/pudo/branches/search'}
                searchBodyParam="searchCriteria"
                optionsCodec={array(TBranch)}
                formatOptions={branches =>
                  branches.map(branch => ({
                    label: formatBranch(language)(branch),
                    value: branch.branchId,
                    option: branch.closed ? 'closedBranch' : null,
                  }))
                }
                rawBody={{
                  cityId: branchCity.value.cityId,
                  types: ['APT'],
                  closed: [false],
                }}
              />
            </FormField>
          )}
        </>
      )}

      {service === 'BRANCH' && (
        <FormField>
          <ControlledCheckbox
            control={control}
            headLabel={'form_fields.add_option'}
            label={'form_fields.UNPACKING_AT_BRANCH'}
            name="branchAdditionalOption"
          />
        </FormField>
      )}

      {service === 'COURIER' && (
        <FormField>
          <ControlledSelect
            control={control}
            label={'form_fields.add_option'}
            name="courierAdditionalOptions"
            multiple
            options={[
              selectOption(
                t('form_fields.UNPACKING_AT_BRANCH'),
                'UNPACKING_AT_BRANCH',
              ),
              selectOption(
                t('form_fields.RETURN_DOCUMENTS'),
                'RETURN_DOCUMENTS',
              ),
              selectOption(t('form_fields.LIFT_TO_FLOOR'), 'LIFT_TO_FLOOR'),
            ]}
          />
        </FormField>
      )}

      <FormField>
        <ControlledTextField
          name="notes"
          control={control}
          label={t('form_fields.notes')}
          pattern={{
            value: notes.pattern.value,
            message: t(notes.pattern.message),
          }}
          type="text"
          multiline
          minRows={3}
        />
      </FormField>
    </>
  )
}
