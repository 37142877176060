import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconLParcel = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ width: '44px', height: '44px' }}
    fill="none"
    viewBox="0 0 120 120"
  >
    <path
      d="m83.5 57.7-12.4-1.4-71.1 40.6 36.4 21 12.5 1.4 71.1-41-36.5-20.6z"
      fill="#2C363F"
      opacity=".2"
    />
    <path d="m14.2 59.3 34.7 20.1v38.9l-34.7-20v-39z" fill="#B39372" />
    <path d="m118.3 39.3v39l-69.4 40v-38.9l69.4-40.1z" fill="#CCAC89" />
    <path d="m48.9 79.1-34.6-20 69.2-39.9 34.7 19.9-69.3 40z" fill="#D9B996" />
    <path d="m14.3 59.1 34.6 20v0.3l-34.7-20.1 0.1-0.2z" fill="#C1A180" />
    <path d="m118.2 39.1 0.1 0.2-69.4 40.1v-0.3l69.3-40z" fill="#E7C7A3" />
    <path d="m33.6 70.4 69.3-40.1-3.1-1.7-69.3 40 3.1 1.8z" fill="#E6CFB5" />
    <path d="m33.6 76.2v-5.8l-3.1-1.8v5.8l3.1 1.8z" fill="#E6CFB5" />
    <path
      d="m94.8 70.6c0-1.2 1.6-1.8 2.1-1.8l0.2-2c-1.6 0.3-4.2 2.3-4.2 5.2s3.1 0.6 3.1 2-2.5 2.2-3 2l-0.3 2.1c1.8 0 5.3-2.3 5.2-5.5 0-3.1-3.1-0.7-3.1-2zm-4.2 3.5c0-2.5-2.6-1.1-2.5 1.5l2.5-1.5zm-2.6 3.5c0.1 2.2 2.7 0.2 3.9-0.9l0.3 1.8c-2.6 2.9-6.2 4.1-6.2-0.5 0-6.2 6.5-10.7 6.5-4.2 0 0.5 0 1-0.1 1.2l-4.4 2.6zm9.2-8.9 1-0.6v3.9c0 2 0.4 3.3 2.1 2.3 0.6-0.4 1.1-0.9 1.4-1.2v-2.2c-1 0.8-1.5 1.2-1.5-0.6v-8l-2 1.2v2.6l-0.9 0.5-0.1 2.1zm3.3-2 1.2-0.7 0.4-1.2-0.4-0.7-1.2 0.7v1.9zm-25.6 22.3 1.7-10.2 0.5 9.1 1.9-1.1-0.6-11.6-3 1.8-1.4 8.9-1.5-7.2-3 1.8-0.5 12.3 1.9-1.1 0.5-9.6 1.7 8.2 1.8-1.3z"
      fill="#0061AF"
    />
    <path
      d="m83.7 78.2c0-2.5-2.6-1.1-2.5 1.5l2.5-1.5zm-2.5 3.4c0.1 2.2 2.7 0.2 3.9-0.9l0.3 1.8c-2.6 2.9-6.2 4.1-6.2-0.5 0-6.2 6.5-10.7 6.5-4.2 0 0.5 0 1-0.1 1.2l-4.4 2.6z"
      fill="#E60033"
    />
    <path
      d="m102.7 60.9 1.5 2.7-1.5 4.4-1.4 0.9 1.4-4.5-1.4-2.7 1.4-0.8z"
      fill="#E60033"
    />
    <path
      d="m52.6 109.5v-0.2l0.1-0.1v0.2c0.6-0.3 1.1-0.2 1.4 0.1h-0.2c-0.1 0.1-0.3 0.3-0.3 0.4 0-0.1-0.2-0.2-0.3-0.1s-0.3 0.3-0.3 0.4c0-0.1-0.2-0.2-0.3-0.1s-0.3 0.3-0.3 0.4c0-0.1-0.2-0.2-0.3-0.1s-0.3 0.3-0.3 0.4c0-0.1-0.2-0.2-0.3-0.1-0.1 0-0.2 0.1-0.2 0.2 0.2-0.4 0.7-1.1 1.3-1.4z"
      fill="#2C363F"
    />
    <path d="m52.7 113.4-0.1 0.1v-3l0.1-0.1v3z" fill="#2C363F" />
    <path
      d="m53 113.7c0.2-0.1 0.3-0.4 0.3-0.6l0.1-0.1c0 0.3-0.2 0.7-0.5 0.8-0.3 0.2-0.5 0-0.5-0.3l0.1-0.1c0.2 0.3 0.4 0.4 0.5 0.3z"
      fill="#2C363F"
    />
    <path
      d="m58.5 105.9v1.7c0 0.7-0.5 1.4-1 1.8-0.6 0.3-1 0.1-1-0.6v-1.8l2-1.1z"
      fill="#2C363F"
    />
    <path
      d="m57.4 111.1v-1.5l0.2-0.2v1.5l0.2-0.1 0.7-0.2v0.1l-2 1.2v-0.1l0.6-0.6 0.3-0.1z"
      fill="#2C363F"
    />
    <path d="m63.7 107.3v0.4l-3 1.8v-0.5l3-1.7z" fill="#2C363F" />
    <path
      d="m61.7 108.3-0.4 0.2v-3l-0.2 0.1 0.4-1.5 0.5 1-0.3 0.2v3z"
      fill="#2C363F"
    />
    <path
      d="m63.1 107.5-0.4 0.2v-3l-0.3 0.1 0.5-1.5 0.4 1-0.2 0.2v3z"
      fill="#2C363F"
    />
    <path
      d="m50.6 109.8 4.1-2.3v5.9l-4.1 2.3v-5.9zm4-2.1-3.9 2.2v5.6l3.9-2.2v-5.6z"
      fill="#2C363F"
    />
    <path
      d="m55.5 107 4.1-2.3v5.9l-4.1 2.3v-5.9zm3.9-2.1-3.9 2.2v5.5l3.9-2.2v-5.5z"
      fill="#2C363F"
    />
    <path
      d="m60.2 104.3 4.1-2.3v5.9l-4.1 2.3v-5.9zm3.9-2.2-3.9 2.2v5.6l3.9-2.2v-5.6z"
      fill="#2C363F"
    />
    <path d="m15.6 66.4 8.1 4.7v-4.7l-8.1-4.7v4.7z" fill="#F4F5F5" />
    <path d="m23.1 70.1-0.2-0.1v-3.5l0.2 0.1v3.5z" fill="#2C363F" />
    <path d="m22.8 69.9-0.2-0.1v-3.5l0.2 0.1v3.5z" fill="#2C363F" />
    <path d="m18.5 67.5-0.1-0.1v-3.5l0.1 0.1v3.5z" fill="#2C363F" />
    <path d="m22.1 69.5-0.2-0.1v-3.5l0.2 0.1v3.5z" fill="#2C363F" />
    <path d="m22.4 69.7h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m17.4 66.8-0.2-0.1v-3.5l0.2 0.1v3.5z" fill="#2C363F" />
    <path d="m17.1 66.6-0.2-0.1v-3.5l0.2 0.1v3.5z" fill="#2C363F" />
    <path d="m16.3 62.7v3.5l-0.2-0.1v-3.5l0.2 0.1z" fill="#2C363F" />
    <path d="m16.7 66.4h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m21 68.9-0.2-0.1v-3.5l0.2 0.1v3.5z" fill="#2C363F" />
    <path d="m20.2 68.5-0.2-0.2v-3.5l0.2 0.1v3.6z" fill="#2C363F" />
    <path d="m20.6 68.7-0.1-0.1v-3.5l0.1 0.1v3.5z" fill="#2C363F" />
    <path d="m21.6 69.2h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m21.3 69.1-0.1-0.1v-3.5l0.1 0.1v3.5z" fill="#2C363F" />
    <path d="m19.8 68.2h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m19.5 68h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m19.1 67.8h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m18.1 67.2h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m17.8 67h-0.1v-3.5h0.1v3.5z" fill="#2C363F" />
    <path d="m72.6 0-44.3 25.6 27.9 16.1 44.3-25.6-27.9-16.1z" fill="#E6EDF8" />
    <path d="m100.5 47.1-44.3 25.5v-30.9l44.3-25.6v31z" fill="#EBF1FB" />
    <path d="m28.3 25.6v30.9l27.9 16.1v-30.9l-27.9-16.1z" fill="#E0E3E7" />
    <path d="m53.6 71.1 0.4 0.2v-30.9l-0.4-0.2v30.9z" fill="#E0E3E7" />
    <path d="m91.1 52.5 0.4-0.2v-30.9l-0.4 0.1v31z" fill="#E6EDF8" />
    <path d="m88.8 26.9-29.1 16.8v22.9l29.1-16.8v-22.9z" fill="#0061AF" />
    <path
      d="m98.3 14.8-0.4-0.2-44.3 25.6 0.4 0.2 34.9-20.2 2.2 1.3 0.4-0.2-2.3-1.3 9.1-5.2z"
      fill="#E6EDF8"
    />
    <path d="m98.7 22.2-5.3 3v3.9l5.3-3.1v-3.8z" fill="#2C363F" />
    <path
      d="m97.9 32.3-1.1-0.7c-0.2-0.1-0.6-0.1-0.9 0.1-0.7 0.4-1.3 1.4-1.3 2.3 0 0.4 0.1 0.7 0.4 0.9l1.1 0.7c0.2 0.1 0.6 0.1 0.9-0.1 0.7-0.4 1.3-1.4 1.3-2.3 0-0.5-0.2-0.8-0.4-0.9z"
      fill="#E0E3E7"
    />
    <path
      d="m98.3 33.1c0 0.8-0.6 1.9-1.3 2.3-0.4 0.2-0.7 0.2-0.9 0.1s-0.4-0.4-0.4-0.9c0-0.8 0.6-1.9 1.3-2.3 0.4-0.2 0.7-0.2 0.9-0.1 0.2 0.2 0.4 0.5 0.4 0.9z"
      fill="#E6EDF8"
    />
    <path
      d="m97.9 38.4-1.1-0.7c-0.2-0.1-0.6-0.1-0.9 0.1-0.7 0.4-1.3 1.4-1.3 2.3 0 0.4 0.1 0.7 0.4 0.9l1.1 0.7c0.2 0.1 0.6 0.1 0.9-0.1 0.7-0.4 1.3-1.4 1.3-2.3 0-0.5-0.2-0.8-0.4-0.9z"
      fill="#E0E3E7"
    />
    <path
      d="m98.3 39.3c0 0.8-0.6 1.9-1.3 2.3-0.4 0.2-0.7 0.2-0.9 0.1s-0.4-0.4-0.4-0.9c0-0.8 0.6-1.9 1.3-2.3 0.4-0.2 0.7-0.2 0.9-0.1s0.4 0.4 0.4 0.9z"
      fill="#E6EDF8"
    />
    <g opacity=".1">
      <path
        d="m83.8 52.7 5-2.9v-22.9l-14 8.1c2.6 4 5.8 9.9 9 17.7z"
        fill="#fff"
      />
      <path
        d="m83.8 52.7 5-2.9v-22.9l-14 8.1c2.6 4 5.8 9.9 9 17.7z"
        fill="url(#a)"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1="81.8"
        x2="81.8"
        y1="26.9"
        y2="52.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" offset="0" />
        <stop stopColor="#fff" stopOpacity="0" offset="1" />
      </linearGradient>
    </defs>
  </SvgIcon>
)
