import { constant, pipe } from 'fp-ts/function'
import { fold, getOrElseW, Option } from 'fp-ts/lib/Option'

export const isEmpty = <A extends any>(value: A | null | undefined) =>
  value === undefined || value === null || value === '' || Number.isNaN(value)

export const nullable = <A>(value: A): A | undefined => {
  if (isEmpty(value)) {
    return undefined
  }
  return value
}

export const getOrDefault = <A, B>(ma: Option<A>, defaultValue: B) => {
  return pipe(ma, getOrElseW(constant(defaultValue)))
}

export const getOrHyphen = (value: string | number | null | undefined) => {
  if (value === null || value === undefined) {
    return '—'
  }

  return value
}

export const valueOrHyphen = <T>(
  value: Option<T>,
  handler: (value: T) => string,
  defaultValue?: string | null,
) => pipe(value, fold(constant(defaultValue ? defaultValue : '—'), handler))
