import { FormControlLabelProps, RadioGroupProps } from '@material-ui/core'
import { SelectOption } from 'app/options'
import { RadioGroup } from 'components/ui/radio-group'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  disabled?: boolean
  required?: boolean
  options: ReadonlyArray<SelectOption>
  fullWidth?: boolean
  isLoading?: boolean
  sxRadioGroup?: RadioGroupProps['sx']
  sxFormControlLabel?: FormControlLabelProps['sx']
}

export const ControlledRadioGroup = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()

  const {
    field: { ...radioGroupField },
    formState: { errors },
  } = useController({
    control: props.control,
    name: props.name,
    rules: { required: props.required },
  })

  return (
    <RadioGroup
      {...radioGroupField}
      label={t(props.label)}
      required={props.required}
      name={props.name}
      error={Boolean(errors[props.name])}
      disabled={props.disabled}
      isLoading={props.isLoading}
      options={props.options}
      sx={props.sxRadioGroup}
      sxFormControlLabel={props.sxFormControlLabel}
    />
  )
}
