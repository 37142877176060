import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconDelete = (props: SvgIconProps) => (
  <SvgIcon {...props} fill="none">
    <path
      d="M3 6H5H21"
      stroke={props.htmlColor ?? '#2C363F'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9971 6.0767C20.0394 5.52604 19.6274 5.0453 19.0767 5.00295C18.526 4.96059 18.0453 5.37265 18.0029 5.9233L19.9971 6.0767ZM18 19L17.0029 18.9233C17.001 18.9488 17 18.9744 17 19H18ZM6 19H7C7 18.9744 6.99902 18.9488 6.99705 18.9233L6 19ZM5.99705 5.9233C5.9547 5.37265 5.47396 4.96059 4.9233 5.00295C4.37265 5.0453 3.96059 5.52604 4.00295 6.0767L5.99705 5.9233ZM7 6C7 6.55228 7.44772 7 8 7C8.55228 7 9 6.55228 9 6H7ZM15 6C15 6.55228 15.4477 7 16 7C16.5523 7 17 6.55228 17 6H15ZM18.0029 5.9233L17.0029 18.9233L18.9971 19.0767L19.9971 6.0767L18.0029 5.9233ZM17 19C17 19.5523 16.5523 20 16 20V22C17.6569 22 19 20.6569 19 19H17ZM16 20H8V22H16V20ZM8 20C7.44772 20 7 19.5523 7 19H5C5 20.6569 6.34315 22 8 22V20ZM6.99705 18.9233L5.99705 5.9233L4.00295 6.0767L5.00295 19.0767L6.99705 18.9233ZM9 6V5H7V6H9ZM9 5C9 4.44772 9.44772 4 10 4V2C8.34315 2 7 3.34315 7 5H9ZM10 4H14V2H10V4ZM14 4C14.5523 4 15 4.44772 15 5H17C17 3.34315 15.6569 2 14 2V4ZM15 5V6H17V5H15Z"
      fill={props.htmlColor ?? '#2C363F'}
    />
  </SvgIcon>
)
