import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconSParcel = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ width: '44px', height: '44px' }}
    fill="none"
    viewBox="0 0 120 60"
  >
    <g clipPath="url(#clip0_160_561)">
      <path
        opacity="0.2"
        d="M83.5 18.4L71.1 17L0 57.6L36.4 78.7L48.9 80.1L120 39L83.5 18.4Z"
        fill="#2C363F"
      />
      <path
        d="M14.2002 40.2002L48.9002 60.2002V79.1002L14.2002 59.0002V40.2002Z"
        fill="#B39372"
      />
      <path
        d="M118.3 20.2002V39.0002L48.8999 79.1002V60.2002L118.3 20.2002Z"
        fill="#CCAC89"
      />
      <path
        d="M48.8998 60L14.2998 40L83.4998 0L118.2 20L48.8998 60Z"
        fill="#D9B996"
      />
      <path
        d="M14.3002 40L48.9002 60V60.2L14.2002 40.2L14.3002 40Z"
        fill="#C1A180"
      />
      <path d="M118.2 20L118.3 20.2L48.8999 60.2V60L118.2 20Z" fill="#E7C7A3" />
      <path
        d="M33.6 51.1999L102.9 11.1999L99.8 9.3999L30.5 49.4999L33.6 51.1999Z"
        fill="#E6CFB5"
      />
      <path d="M33.6 57V51.2L30.5 49.5V55.2L33.6 57Z" fill="#E6CFB5" />
      <path
        d="M91.6 44.9004C91.6 44.0004 92.9 43.5004 93.3 43.5004L93.4 41.9004C92.1 42.1004 90.1 43.7004 90.1 46.0004C90.1 48.3004 92.6 46.5004 92.6 47.6004C92.6 48.7004 90.7 49.3004 90.2 49.2004L90 50.8004C91.4 50.8004 94.2 49.0004 94.1 46.5004C94.1 44.0004 91.6 45.9004 91.6 44.9004ZM88.3 47.6004C88.3 45.6004 86.3 46.8004 86.3 48.8004L88.3 47.6004ZM86.3 50.4004C86.4 52.1004 88.5 50.6004 89.4 49.7004L89.6 51.1004C87.5 53.4004 84.7 54.3004 84.7 50.7004C84.7 45.8004 89.9 42.3004 89.9 47.4004C89.9 47.8004 89.9 48.2004 89.8 48.3004L86.3 50.4004ZM93.6 43.3004L94.4 42.8004V45.9004C94.4 47.5004 94.7 48.5004 96 47.8004C96.5 47.5004 96.9 47.1004 97.1 46.9004V45.2004C96.3 45.9004 95.9 46.2004 95.9 44.7004V38.4004L94.4 39.3004V41.3004L93.7 41.7004L93.6 43.3004ZM96.2 41.8004L97.1 41.3004L97.4 40.3004L97.1 39.7004L96.2 40.2004V41.8004ZM76 59.4004L77.3 51.3004L77.7 58.5004L79.2 57.6004L78.7 48.4004L76.3 49.8004L75.1 56.8004L73.9 51.1004L71.5 52.5004L71 62.2004L72.5 61.3004L72.9 53.7004L74.2 60.2004L76 59.4004Z"
        fill="#0061AF"
      />
      <path
        d="M82.8998 50.8001C82.8998 48.8001 80.8998 50.0001 80.8998 52.0001L82.8998 50.8001ZM80.8998 53.6001C80.9998 55.3001 83.0998 53.8001 83.9998 52.9001L84.1998 54.3001C82.0998 56.6001 79.2998 57.5001 79.2998 53.9001C79.2998 49.0001 84.4998 45.5001 84.4998 50.6001C84.4998 51.0001 84.4998 51.4001 84.3998 51.5001L80.8998 53.6001Z"
        fill="#E60033"
      />
      <path
        d="M97.8998 37.2002L99.0998 39.3002L97.8998 42.8002L96.7998 43.5002L97.8998 40.0002L96.7998 37.8002L97.8998 37.2002Z"
        fill="#E60033"
      />
      <path
        d="M52.4001 70.5002V70.3002L52.5001 70.2002V70.4002C53.1001 70.1002 53.6001 70.2002 53.9001 70.5002C53.8001 70.5002 53.8001 70.5002 53.7001 70.5002C53.6001 70.6002 53.4001 70.8002 53.4001 70.9002C53.4001 70.8002 53.2001 70.7002 53.1001 70.8002C53.0001 70.9002 52.8001 71.1002 52.8001 71.2002C52.8001 71.1002 52.6001 71.0002 52.5001 71.1002C52.4001 71.2002 52.2001 71.4002 52.2001 71.5002C52.2001 71.4002 52.0001 71.3002 51.9001 71.4002C51.8001 71.5002 51.6001 71.7002 51.6001 71.8002C51.6001 71.7002 51.4001 71.6002 51.3001 71.7002C51.2001 71.7002 51.1001 71.8002 51.1001 71.9002C51.3001 71.5002 51.8001 70.8002 52.4001 70.5002Z"
        fill="#2C363F"
      />
      <path
        d="M52.5999 74.4004L52.3999 74.5004V71.5004L52.5999 71.4004V74.4004Z"
        fill="#2C363F"
      />
      <path
        d="M52.9003 74.7C53.1003 74.6 53.2003 74.3 53.2003 74.1L53.3003 74C53.3003 74.3 53.1003 74.7 52.8003 74.8C52.5003 75 52.3003 74.8 52.3003 74.5L52.4003 74.4C52.6003 74.7 52.7003 74.8 52.9003 74.7Z"
        fill="#2C363F"
      />
      <path
        d="M58.3999 66.8999V68.5999C58.3999 69.2999 57.8999 69.9999 57.3999 70.3999C56.7999 70.6999 56.3999 70.4999 56.3999 69.7999V68.0999L58.3999 66.8999Z"
        fill="#2C363F"
      />
      <path
        d="M57.1998 72.1V70.6L57.4998 70.5V71.9L57.6998 71.8L58.3998 71.6V71.7L56.2998 72.9V72.8L56.9998 72.2L57.1998 72.1Z"
        fill="#2C363F"
      />
      <path
        d="M63.6001 68.2998V68.6998L60.6001 70.4998V69.9998L63.6001 68.2998Z"
        fill="#2C363F"
      />
      <path
        d="M61.5999 69.2996L61.1999 69.4996V66.4996L60.8999 66.5996L61.3999 65.0996L61.7999 66.0996L61.5999 66.2996V69.2996Z"
        fill="#2C363F"
      />
      <path
        d="M63.0003 68.4998L62.6003 68.6998V65.6998L62.3003 65.7998L62.8003 64.2998L63.2003 65.2998L63.0003 65.4998V68.4998Z"
        fill="#2C363F"
      />
      <path
        d="M50.5 70.8L54.6 68.5V74.4L50.5 76.7V70.8ZM54.4 68.7L50.5 70.9V76.5L54.4 74.3V68.7Z"
        fill="#2C363F"
      />
      <path
        d="M55.2998 68.0002L59.3998 65.7002V71.6002L55.2998 73.9002V68.0002ZM59.2998 65.9002L55.3998 68.1002V73.6002L59.2998 71.4002V65.9002Z"
        fill="#2C363F"
      />
      <path
        d="M60 65.3L64.1 63V68.9L60 71.1V65.3ZM64 63.2L60.1 65.4V71L64 68.8V63.2Z"
        fill="#2C363F"
      />
      <path
        d="M37.2003 25.9002C36.9003 26.1002 36.7003 26.3002 36.4003 26.5002C35.2003 27.3002 31.3003 30.1002 31.3003 35.8002C31.3003 42.6002 43.2003 41.5002 47.7003 39.0002L66.9003 27.9002C68.6003 26.9002 69.7003 25.2002 69.8003 23.2002L70.3003 15.8002C70.4003 13.9002 70.0003 9.40022 68.4003 8.50022L66.4003 7.30022C63.6003 5.70022 59.7003 8.80022 56.8003 9.90022L54.8003 10.8002C54.4003 11.0002 54.0003 11.3002 53.6003 11.6002L37.2003 25.9002Z"
        fill="#0061AF"
      />
      <path
        opacity="0.1"
        d="M31.2999 34.9001C31.2999 34.9001 30.6999 29.2001 36.7999 26.3001C43.0999 23.3001 47.8999 37.0001 47.8999 37.0001C47.8999 37.0001 34.7999 44.1001 31.2999 34.9001Z"
        fill="white"
      />
      <path
        d="M31.2001 34.4004C31.2001 34.4004 31.6001 38.6004 37.1001 38.9004C42.6001 39.2004 47.2001 37.4004 50.8001 35.5004C54.4001 33.6004 69.7001 24.4004 69.7001 24.4004L69.5001 26.5004L50.2001 37.7004C50.2001 37.7004 44.7001 41.7004 38.5001 41.2004C37.0001 41.1004 36.0001 41.0004 34.6001 40.5004C34.5001 40.5004 30.3001 39.3004 31.2001 34.4004Z"
        fill="#F4F7FC"
      />
      <path
        d="M48.8999 24.6002C48.8999 24.6002 56.1999 27.0002 57.5999 31.5002L59.9999 30.1002C59.9999 30.1002 55.3999 24.5002 50.4999 23.2002L48.8999 24.6002Z"
        fill="white"
      />
      <path
        d="M52 21.9004L54 19.9004C54 19.9004 61.7 22.7004 63.9 27.7004L61.9 28.9004C61.9 28.8004 59.7 24.1004 52 21.9004Z"
        fill="white"
      />
      <g opacity="0.1">
        <path
          d="M64.0003 7.60035C60.7003 7.90035 56.6003 10.3004 56.1003 11.2004C56.0003 11.3004 55.6003 11.8004 54.8003 12.5004C56.4003 12.9004 57.7003 15.0004 57.7003 15.0004C57.7003 15.0004 59.9003 14.1004 61.6003 15.0004C63.4003 16.0004 66.7003 16.7004 68.5003 14.1004C70.3003 11.6004 67.4003 7.30035 64.0003 7.60035Z"
          fill="white"
        />
        <path
          d="M54.8003 13.3003C51.1003 17.0003 40.8003 26.5003 40.8003 26.5003C40.8003 26.5003 41.7003 27.0003 42.9003 28.2003L56.5003 15.6003C56.6003 15.7003 56.5003 13.7003 54.8003 13.3003Z"
          fill="white"
        />
      </g>
      <path
        d="M53.6001 14.5001L55.7001 9.80008C56.0001 9.20008 56.6001 8.80008 57.2001 8.90008C58.4001 9.00008 60.2001 9.50008 60.5001 12.0001C60.7001 13.9001 56.2001 16.0001 56.2001 16.0001L53.6001 14.5001Z"
        fill="#0061AF"
      />
      <path
        d="M52.2002 14.9004L53.2002 19.2004C53.3002 19.5004 53.6002 19.6004 53.8002 19.4004C53.9002 19.3004 53.9002 19.2004 53.9002 19.1004L53.0002 14.6004C52.9002 14.3004 52.6002 14.2004 52.4002 14.4004L52.3002 14.5004C52.2002 14.6004 52.2002 14.7004 52.2002 14.9004Z"
        fill="white"
      />
      <path
        d="M49.3003 17.4L50.3003 21.7C50.4003 22 50.7003 22.1 50.9003 21.9C51.0003 21.8 51.0003 21.7 51.0003 21.6L50.1003 17.2C50.0003 16.9 49.7003 16.8 49.5003 17L49.4003 17.1C49.3003 17.2 49.3003 17.3 49.3003 17.4Z"
        fill="white"
      />
      <path
        d="M46.2002 20.6002L47.2002 24.9002C47.3002 25.2002 47.6002 25.3002 47.8002 25.1002C47.9002 25.0002 47.9002 24.9002 47.9002 24.8002L47.0002 20.4002C46.9002 20.1002 46.6002 20.0002 46.4002 20.2002L46.3002 20.3002C46.2002 20.4002 46.2002 20.5002 46.2002 20.6002Z"
        fill="white"
      />
      <path
        d="M42.7002 23.7003L43.7002 28.0003C43.8002 28.3003 44.1002 28.4003 44.3002 28.2003C44.4002 28.1003 44.4002 28.0003 44.4002 27.9003L43.5002 23.5003C43.4002 23.2003 43.1002 23.1003 42.9002 23.3003L42.8002 23.4003C42.7002 23.4003 42.7002 23.6003 42.7002 23.7003Z"
        fill="white"
      />
      <path
        d="M40.5998 25.3C40.4998 25.5 40.6998 25.7 40.8998 25.7L46.0998 26.6C46.2998 26.6 46.3998 26.5 46.4998 26.4C46.5998 26.2 46.4998 25.9 46.1998 25.9L41.0998 25C40.8998 25 40.6998 25.1 40.5998 25.3Z"
        fill="white"
      />
      <path
        d="M44.0998 22.1003C43.9998 22.3003 44.1998 22.5003 44.3998 22.5003L49.5998 23.4003C49.7998 23.4003 49.8998 23.3003 49.9998 23.2003C50.0998 23.0003 49.9998 22.7003 49.6998 22.7003L44.4998 21.8003C44.2998 21.8003 44.1998 21.9003 44.0998 22.1003Z"
        fill="white"
      />
      <path
        d="M47.1999 18.9001C47.0999 19.1001 47.2999 19.3001 47.4999 19.3001L52.6999 20.2001C52.8999 20.2001 52.9999 20.1001 53.0999 20.0001C53.1999 19.8001 53.0999 19.5001 52.7999 19.5001L47.5999 18.6001C47.3999 18.6001 47.2999 18.7001 47.1999 18.9001Z"
        fill="white"
      />
      <path
        d="M50.4001 16.3C50.3001 16.5 50.5001 16.7 50.7001 16.7L55.9001 17.6C56.1001 17.6 56.2001 17.5 56.3001 17.4C56.4001 17.2 56.3001 16.9 56.0001 16.9L50.8001 16C50.6001 16 50.4001 16.2 50.4001 16.3Z"
        fill="white"
      />
      <path
        d="M55.0001 15.1C55.0001 15.1 49.9001 10.8 52.8001 10.2C54.8001 9.79997 55.6001 14.3 55.6001 14.3C55.6001 14.3 60.5001 14.3 61.0001 15.9C61.5001 17.5 57.8001 17.2 55.1001 15.2L55.3001 14.9C55.3001 14.9 59.5001 17 59.7001 16C60.0001 15 55.3001 14.8 55.3001 14.8C55.3001 14.8 53.9001 11.1 53.4001 11C52.8001 11 52.7001 11.4 53.0001 12C53.3001 12.6 55.1001 14.9 55.1001 14.9L55.0001 15.1Z"
        fill="white"
      />
      <path
        d="M55 15.3C55 15.3 57.1 17.2 58 19.2L58.5 18.9C58.5 18.9 56.8 15.7 55.1 15L55 15.3Z"
        fill="white"
      />
      <path
        d="M50.6001 14.2002C50.6001 14.2002 52.6001 14.0002 54.9001 15.1002L55.1001 14.8002C55.1001 14.8002 53.6001 13.5002 50.8001 13.6002L50.6001 14.2002Z"
        fill="white"
      />
      <path
        d="M54.6 15.3002C54.5 15.3002 54.5 15.1002 54.5 15.1002C54.7 14.7002 55.4 13.6002 55.6 13.7002C55.8 13.8002 55 14.9002 54.7 15.3002C54.8 15.3002 54.7 15.3002 54.6 15.3002Z"
        fill="white"
      />
      <path
        d="M55.0003 15.3003L55.8003 14.0003C55.8003 13.9003 55.9003 13.9003 56.0003 14.0003C56.1003 14.0003 56.1003 14.1003 56.0003 14.2003L55.2003 15.5003C55.2003 15.6003 55.1003 15.6003 55.0003 15.6003C54.9003 15.5003 54.9003 15.4003 55.0003 15.3003Z"
        fill="white"
      />
      <path
        d="M70.2999 26.5002C69.9999 26.7002 69.7999 26.9002 69.4999 27.1002C68.2999 27.9002 64.3999 30.7002 64.3999 36.4002C64.3999 43.2002 76.2999 42.1002 80.7999 39.6002L99.9999 28.4002C101.7 27.4002 102.8 25.7002 102.9 23.7002L103.4 16.3002C103.5 14.4002 103.1 9.90022 101.5 9.00022L99.4999 7.80022C96.6999 6.20022 92.7999 9.30022 89.8999 10.4002L87.8999 11.3002C87.4999 11.5002 87.0999 11.8002 86.6999 12.1002L70.2999 26.5002Z"
        fill="#0061AF"
      />
      <path
        opacity="0.1"
        d="M64.4 35.5002C64.4 35.5002 63.8 29.8002 69.9 26.9002C76.2 23.9002 81 37.6002 81 37.6002C81 37.6002 67.8 44.6002 64.4 35.5002Z"
        fill="white"
      />
      <path
        d="M64.2998 35C64.2998 35 64.6998 39.2 70.1998 39.5C75.6998 39.8 80.2998 38 83.8998 36.1C87.4998 34.2 102.8 25 102.8 25L102.6 27.1L83.1998 38.3C83.1998 38.3 77.6998 42.3 71.4998 41.8C69.9998 41.6 68.9998 41.5 67.5998 41C67.5998 41 63.3998 39.9 64.2998 35Z"
        fill="#F4F7FC"
      />
      <path
        d="M82 25.1002C82 25.1002 89.3 27.5002 90.7 32.0002L93.1 30.6002C93.1 30.6002 88.5 25.0002 83.6 23.7002L82 25.1002Z"
        fill="white"
      />
      <path
        d="M85.0996 22.4004L87.0996 20.4004C87.0996 20.4004 94.7996 23.2004 96.9996 28.2004L94.9996 29.4004C94.9996 29.4004 92.7996 24.6004 85.0996 22.4004Z"
        fill="white"
      />
      <g opacity="0.1">
        <path
          d="M97.0999 8.10015C93.7999 8.40015 89.6999 10.8002 89.1999 11.7002C89.0999 11.8002 88.6999 12.3002 87.8999 13.0002C89.4999 13.4002 90.7999 15.5002 90.7999 15.5002C90.7999 15.5002 92.9999 14.6002 94.6999 15.5002C96.4999 16.5002 99.7999 17.2002 101.6 14.6002C103.4 12.1002 100.4 7.90015 97.0999 8.10015Z"
          fill="white"
        />
        <path
          d="M87.8999 13.9004C84.1999 17.6004 73.8999 27.1004 73.8999 27.1004C73.8999 27.1004 74.7999 27.6004 75.9999 28.8004L89.5999 16.2004C89.5999 16.2004 89.4999 14.2004 87.8999 13.9004Z"
          fill="white"
        />
      </g>
      <path
        d="M86.6997 15.0001L88.7997 10.3001C89.0997 9.70008 89.6997 9.30008 90.2997 9.40008C91.4997 9.50008 93.2997 10.0001 93.5997 12.5001C93.7997 14.4001 89.2997 16.5001 89.2997 16.5001L86.6997 15.0001Z"
        fill="#0061AF"
      />
      <path
        d="M85.2998 15.4L86.2998 19.7C86.3998 20 86.6998 20.1 86.8998 19.9C86.9998 19.8 86.9998 19.7 86.9998 19.6L86.0998 15.2C85.9998 14.9 85.6998 14.8 85.4998 15L85.3998 15.1C85.2998 15.1 85.2998 15.3 85.2998 15.4Z"
        fill="white"
      />
      <path
        d="M82.3999 18.0001L83.3999 22.3001C83.4999 22.6001 83.7999 22.7001 83.9999 22.5001C84.0999 22.4001 84.0999 22.3001 84.0999 22.2001L83.1999 17.8001C83.0999 17.5001 82.7999 17.4001 82.5999 17.6001L82.4999 17.7001C82.3999 17.7001 82.3999 17.8001 82.3999 18.0001Z"
        fill="white"
      />
      <path
        d="M79.2998 21.2003L80.2998 25.5003C80.3998 25.8003 80.6998 25.9003 80.8998 25.7003C80.9998 25.6003 80.9998 25.5003 80.9998 25.4003L80.0998 21.0003C79.9998 20.7003 79.6998 20.6003 79.4998 20.8003L79.3998 20.9003C79.2998 20.9003 79.2998 21.1003 79.2998 21.2003Z"
        fill="white"
      />
      <path
        d="M75.7998 24.2003L76.7998 28.5003C76.8998 28.8003 77.1998 28.9003 77.3998 28.7003C77.4998 28.6003 77.4998 28.5003 77.4998 28.4003L76.5998 24.0003C76.4998 23.7003 76.1998 23.6003 75.9998 23.8003L75.8998 23.9003C75.7998 24.0003 75.7998 24.1003 75.7998 24.2003Z"
        fill="white"
      />
      <path
        d="M73.6999 25.8C73.5999 26 73.7999 26.2 73.9999 26.2L79.1999 27.1C79.3999 27.1 79.4999 27 79.5999 26.9C79.6999 26.7 79.5999 26.4 79.2999 26.4L74.0999 25.5C73.8999 25.5 73.7999 25.6 73.6999 25.8Z"
        fill="white"
      />
      <path
        d="M77.1999 22.7004C77.0999 22.9004 77.2999 23.1004 77.4999 23.1004L82.6999 24.0004C82.8999 24.0004 82.9999 23.9004 83.0999 23.8004C83.1999 23.6004 83.0999 23.3004 82.7999 23.3004L77.5999 22.4004C77.3999 22.4004 77.1999 22.5004 77.1999 22.7004Z"
        fill="white"
      />
      <path
        d="M80.2995 19.4001C80.1995 19.6001 80.3995 19.8001 80.5995 19.8001L85.7995 20.7001C85.9995 20.7001 86.0995 20.6001 86.1995 20.5001C86.2995 20.3001 86.1995 20.0001 85.8995 20.0001L80.6995 19.1001C80.4995 19.1001 80.2995 19.3001 80.2995 19.4001Z"
        fill="white"
      />
      <path
        d="M83.3996 16.9001C83.2996 17.1001 83.4996 17.3001 83.6996 17.3001L88.8996 18.2001C89.0996 18.2001 89.1996 18.1001 89.2996 18.0001C89.3996 17.8001 89.2996 17.5001 88.9996 17.5001L83.7996 16.6001C83.6996 16.6001 83.4996 16.7001 83.3996 16.9001Z"
        fill="white"
      />
      <path
        d="M88.0997 15.7001C88.0997 15.7001 82.9997 11.4001 85.8997 10.8001C87.8997 10.4001 88.6997 14.9001 88.6997 14.9001C88.6997 14.9001 93.5997 14.9001 94.0997 16.5001C94.5997 18.1001 90.8997 17.8001 88.1997 15.8001L88.3997 15.5001C88.3997 15.5001 92.5997 17.6001 92.7997 16.6001C93.0997 15.6001 88.3997 15.4001 88.3997 15.4001C88.3997 15.4001 86.9997 11.7001 86.4997 11.6001C85.8997 11.5001 85.8997 11.9001 86.1997 12.5001C86.4997 13.1001 88.2997 15.4001 88.2997 15.4001L88.0997 15.7001Z"
        fill="white"
      />
      <path
        d="M88 15.9001C88 15.9001 90.1 17.8001 91 19.8001L91.5 19.5001C91.5 19.5001 89.8 16.3001 88.1 15.6001L88 15.9001Z"
        fill="white"
      />
      <path
        d="M83.6997 14.8003C83.6997 14.8003 85.6997 14.6003 87.9997 15.7003L88.1997 15.4003C88.1997 15.4003 86.6997 14.1003 83.8997 14.2003L83.6997 14.8003Z"
        fill="white"
      />
      <path
        d="M87.6996 15.8002C87.5996 15.8002 87.5996 15.6002 87.5996 15.6002C87.7996 15.2002 88.4996 14.1002 88.6996 14.2002C88.8996 14.3002 88.0996 15.4002 87.7996 15.8002H87.6996Z"
        fill="white"
      />
      <path
        d="M88 15.9004L88.8 14.6004C88.8 14.5004 88.9 14.5004 89 14.6004C89.1 14.6004 89.1 14.7004 89 14.8004L88.3 16.0004C88.3 16.1004 88.2 16.1004 88.1 16.1004C88 16.0004 88 15.9004 88 15.9004Z"
        fill="white"
      />
      <path
        d="M15.5 46.9002L23.7 51.5002V46.9002L15.5 42.2002V46.9002Z"
        fill="#F4F5F5"
      />
      <path
        d="M23.0999 50.6L22.8999 50.5V47L23.0999 47.1V50.6Z"
        fill="#2C363F"
      />
      <path
        d="M22.8001 50.4003L22.6001 50.3003V46.8003L22.8001 46.9003V50.4003Z"
        fill="#2C363F"
      />
      <path
        d="M18.5003 47.9999L18.3003 47.8999V44.3999L18.5003 44.4999V47.9999Z"
        fill="#2C363F"
      />
      <path
        d="M22.0003 49.9999L21.8003 49.8999V46.3999L22.0003 46.4999V49.9999Z"
        fill="#2C363F"
      />
      <path
        d="M22.4003 50.2002H22.3003V46.7002H22.4003V50.2002Z"
        fill="#2C363F"
      />
      <path
        d="M17.3002 47.3002L17.2002 47.2002V43.7002L17.3002 43.8002V47.3002Z"
        fill="#2C363F"
      />
      <path
        d="M17.0003 47.1L16.8003 47V43.5L17.0003 43.6V47.1Z"
        fill="#2C363F"
      />
      <path
        d="M16.3001 43.2001V46.7001L16.1001 46.6001V43.1001L16.3001 43.2001Z"
        fill="#2C363F"
      />
      <path
        d="M16.7001 46.8999H16.6001V43.3999H16.7001V46.8999Z"
        fill="#2C363F"
      />
      <path
        d="M20.9002 49.4003L20.7002 49.3003V45.8003L20.9002 45.9003V49.4003Z"
        fill="#2C363F"
      />
      <path
        d="M20.2 48.9003L20 48.8003V45.3003L20.2 45.4003V48.9003Z"
        fill="#2C363F"
      />
      <path
        d="M20.4999 49.2001L20.3999 49.1001V45.6001H20.4999V49.2001Z"
        fill="#2C363F"
      />
      <path
        d="M21.4999 49.7002H21.3999V46.2002H21.4999V49.7002Z"
        fill="#2C363F"
      />
      <path
        d="M21.3002 49.6L21.2002 49.5V46L21.3002 46.1V49.6Z"
        fill="#2C363F"
      />
      <path d="M19.4003 48.5H19.3003V45H19.4003V48.5Z" fill="#2C363F" />
      <path d="M19.1 48.3003H19V44.8003H19.1V48.3003Z" fill="#2C363F" />
      <path d="M18.1 47.7002H18V44.2002H18.1V47.7002Z" fill="#2C363F" />
      <path d="M17.7001 47.5H17.6001V44H17.7001V47.5Z" fill="#2C363F" />
    </g>
    <defs>
      <clipPath id="clip0_160_561">
        <rect width="120" height="81" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
