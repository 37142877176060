import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const HelperText = ({ msg }: { msg: string }) => {
  const { t } = useTranslation()
  return <>{t(msg)}</>
}

export const textFieldError = (error?: FieldError) => {
  return error !== undefined
    ? {
        error: true,
        helperText: error.message ? <HelperText msg={error.message} /> : '',
      }
    : {}
}
