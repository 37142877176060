import { Button, Typography, useTheme } from '@material-ui/core'
import { IconScanner } from 'assets/icons'
import { ControlledTextField } from 'components/form/controlled-text-field'
import { FormField } from 'components/form/form-field'
import { miniSticker } from 'components/form/validations'
import { ScannerDialog } from 'components/scanner-dialog'
import { useBoolean } from 'hooks/use-boolean'
import { Fragment } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ParcelIdentificationFieldValues } from './parcel-actions-form-dialog'

type Props = {
  id: string
  index: number
  parcelId: string
  control: Control<ParcelIdentificationFieldValues>
  setValue: UseFormSetValue<ParcelIdentificationFieldValues>
}

export const StickerFields = ({
  id,
  index,
  control,
  parcelId,
  setValue,
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const scanner = useBoolean()

  return (
    <Fragment key={id}>
      <FormField>
        <Button variant="text" fullWidth onClick={scanner.setTrue}>
          <IconScanner strokecolor={theme.palette.primary.main} />
          <Typography ml={1}>{t('buttons.sticker_scan')}</Typography>
        </Button>
      </FormField>
      <FormField>
        <ControlledTextField
          required
          control={control}
          name={`stickers.${parcelId}.${index}.main` as const}
          label={t('identification.sticker_main')}
          min={7}
          maxLength={7}
          pattern={{
            value: miniSticker.pattern.value,
            message: t(''),
          }}
        />
      </FormField>

      <ScannerDialog
        isBarcode
        isMiniSticker
        open={scanner.isTrue}
        onClose={scanner.setFalse}
        onUpdate={value => {
          // TODO research how to avoid use type assertion
          setValue(
            `stickers.${parcelId}.${index}.main` as const,
            value as never,
          )
          setValue(
            `stickers.${parcelId}.${index}.confirm` as const,
            value as never,
          )
        }}
      />
    </Fragment>
  )
}
