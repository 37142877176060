import { TextFieldProps } from '@material-ui/core'
import { IconAlert } from 'assets/icons'
import { textFieldError } from 'components/form/utils'
import { maxLength, minLength } from 'components/form/validations'
import { TextField } from 'components/ui/text-field'
import { useRef } from 'react'
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseControllerProps,
  Validate,
  ValidationRule,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CustomNumberFormat } from '../custom-number-format'

type TFCustomRules<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Partial<{
  required: boolean
  min: number
  max: number
  label: string
  maxLength: number
  minLength: number
  placeholderVariant?: 'small' | 'inherit'
  pattern: ValidationRule<RegExp>
  value?: unknown
  isOnlyNumber?: boolean
  disabled?: boolean
  validate:
    | Validate<FieldPathValue<TFieldValues, TFieldName>>
    | Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>>>
}>
type TFProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  UseControllerProps<TFieldValues>,
  'rules'
> &
  TFCustomRules &
  Omit<
    TextFieldProps,
    | 'value'
    | 'onChange'
    | 'required'
    | 'maxLength'
    | 'minLength'
    | 'max'
    | 'min'
    | 'label'
  >
export const ControlledTextField = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: TFProps<TFieldValues>,
) => {
  const { name, shouldUnregister, defaultValue, pattern, ...innerProps } = props
  const maxLengthValue = innerProps.maxLength ?? 1000
  const minLengthValue = innerProps.minLength ?? 0

  const { t } = useTranslation()
  const ref = useRef()

  const { field, fieldState } = useController({
    control: props.control,
    name,
    shouldUnregister,
    defaultValue,
    rules: {
      required: innerProps.required,
      max: innerProps.max,
      min: innerProps.min,
      maxLength: maxLength(maxLengthValue),
      minLength: minLength(minLengthValue),
      pattern,
    },
  })

  return (
    <TextField
      {...textFieldError(fieldState.error)}
      {...field}
      label={t(props.label as string)}
      ref={ref}
      maxLength={maxLengthValue}
      minLength={minLengthValue}
      required={innerProps.required !== undefined}
      helperText={
        fieldState.error?.type === 'required'
          ? t('validations.required')
          : fieldState.error?.message || props.helperText
      }
      placeholder={props.placeholder ? t(props.placeholder) : undefined}
      type={props.type}
      minRows={props.minRows}
      multiline={props.multiline}
      placeholderVariant={props.placeholderVariant}
      inputComponent={props.isOnlyNumber ? CustomNumberFormat : undefined}
      disabled={props.disabled}
      endAdornment={fieldState.error && <IconAlert />}
    />
  )
}
