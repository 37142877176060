import { forwardRef } from 'react'
import NumberFormat from 'react-number-format'

type NumberFormatCustomProps = {
  inputRef?: (instance: NumberFormat | null) => void
  onChange?: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  mask?: string
  format?: string
  decimalScale?: number
  isNumericString?: boolean
}

export const CustomNumberFormat = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: NumberFormatCustomProps, _ref) => {
    const {
      inputRef,
      onChange,
      decimalScale = 0,
      isNumericString = false,
      ...other
    } = props

    return (
      <NumberFormat
        {...other}
        format={props.format}
        getInputRef={inputRef}
        onChange={onChange}
        decimalScale={decimalScale}
        allowNegative={false}
        isNumericString={isNumericString}
      />
    )
  },
)
