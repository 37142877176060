import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconAddParcelPlace = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <circle cx="10" cy="10" r="10" fill="white" />
    <rect
      x="9.3335"
      y="4.66669"
      width="1.33333"
      height="10.6667"
      rx="0.666667"
      fill="#0065AF"
    />
    <rect
      x="15.3569"
      y="9.39648"
      width="1.33333"
      height="10.6825"
      rx="0.666667"
      transform="rotate(90.3384 15.3569 9.39648)"
      fill="#0065AF"
    />
  </SvgIcon>
)
