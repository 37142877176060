import {
  MenuItem as MuiMenuItem,
  MenuItemProps,
  styled,
} from '@material-ui/core'

export const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(2),
  minHeight: '44px',
  backgroundColor: theme.palette.background.default,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  borderBottom: `1px solid ${theme.palette.primary.light}`,

  '&.MuiAutocomplete-listbox .MuiAutocomplete-option': {
    minHeight: '44px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
}))
