import { ComponentType } from 'react'
import { Route } from 'react-router-dom'

import { LazyRoute } from './lazy-route'

export const createRoutes = (
  routes: Array<LazyRoute> | null,
  TitleComponent: ComponentType<{ title: string }>,
) => {
  if (routes === null) {
    return null
  }

  return routes.map(route => (
    <Route
      key={route.path.join(',')}
      exact={route.exact}
      path={route.path}
      render={props => (
        <>
          <TitleComponent title={route.title} />
          <route.component {...props.match.params} />
        </>
      )}
    />
  ))
}
