import {
  IconButton,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  styled,
  useTheme,
} from '@material-ui/core'
import { outlinedInputClasses } from '@material-ui/core/OutlinedInput'
import { ListItemIcon } from '@mui/material'
import { IconDropdown } from 'assets/icons'
import { Checkbox } from 'components/checkbox'
import { useBoolean } from 'hooks/use-boolean'
import { useUuid } from 'hooks/use-uuid'

import { FormControl, FormControlProps } from '../form-control'
import { MenuItem } from '../menu-item'

export type Option = {
  label: string
  value: string | number
}

export type SelectProps = Omit<FormControlProps, 'children' | 'htmlFor'> &
  Omit<MuiSelectProps, 'onChange, value'> & {
    options: ReadonlyArray<Option>
    initialOption?: Option
    isLoading?: boolean
    disabled?: boolean
    onChange: (value: unknown) => void
    value: unknown
    onFocus?: () => void
    defaultValue?: any
  }

export const SelectBase = styled(MuiSelect)<MuiSelectProps>(
  ({ theme }) => `
  & .${outlinedInputClasses.notchedOutline} {
    border-color: #D5D5D5;
  }
  &.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline} {
    border-color: ${theme.palette.error.main} !important;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: #D5D5D5;
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border: 1px solid ${theme.palette.primary.main} !important;
  }
  `,
)

export const Select = (props: SelectProps) => {
  const id = useUuid()
  const theme = useTheme()
  const isSelectMenuOpen = useBoolean(false)

  const isAllSelected =
    Array.isArray(props.value) &&
    props.options.length > 0 &&
    props.value.length === props.options.length

  return (
    <FormControl
      htmlFor={id}
      label={props.label}
      error={props.error}
      helperText={props.helperText}
      required={props.required}
    >
      <SelectBase
        defaultValue={props.defaultValue}
        id={id}
        name={props.name}
        onChange={event => {
          const { value } = event.target
          if (
            Array.isArray(value) &&
            Array.isArray(props.value) &&
            value.includes('select-all')
          ) {
            return props.onChange(
              props.value.length === props.options.length
                ? []
                : props.options.map(option => option.value),
            )
          }
          props.onChange(value)
        }}
        multiple={props.multiple}
        error={props.error}
        onBlur={props.onBlur}
        value={props.value}
        open={isSelectMenuOpen.isTrue}
        onClose={isSelectMenuOpen.setFalse}
        onOpen={isSelectMenuOpen.setTrue}
        onFocus={props.onFocus}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
        }}
        required={props.required}
        disabled={props.disabled}
        renderValue={options =>
          Array.isArray(options)
            ? options
                .map(
                  option =>
                    props.options.find(item => item.value === option)?.label,
                )
                .join(', ')
            : props.options.find(item => item.value === options)?.label
        }
        IconComponent={() => (
          <IconButton
            onClick={() => {
              isSelectMenuOpen.setTrue()
              props.onFocus?.()
            }}
            sx={{
              position: 'absolute',
              right: theme.spacing(2),
              padding: theme.spacing(1),
            }}
          >
            <IconDropdown
              sx={{
                transform: isSelectMenuOpen.isTrue
                  ? 'rotate(180deg)'
                  : undefined,
              }}
              strokecolor={theme.palette.secondary.main}
            />
          </IconButton>
        )}
      >
        {props.initialOption && (
          <MenuItem value={props.initialOption.value}>
            {props.multiple && (
              <ListItemIcon>
                <Checkbox checked={isAllSelected} sx={{ p: 0, pl: 1 }} />
              </ListItemIcon>
            )}
            {props.initialOption.label}
          </MenuItem>
        )}
        {props.options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple && (
              <ListItemIcon>
                <Checkbox
                  checked={
                    Array.isArray(props.value) &&
                    props.value.includes(option.value)
                  }
                  sx={{ p: 0, pl: 1 }}
                />
              </ListItemIcon>
            )}
            {option.label}
          </MenuItem>
        ))}
      </SelectBase>
    </FormControl>
  )
}

Select.displayName = 'Select'
