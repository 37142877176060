import {
  InputLabel as MuiInputLabel,
  InputLabelProps,
  styled,
} from '@material-ui/core'

export const InputLabel = styled(MuiInputLabel)<InputLabelProps>(
  ({ theme }) => ({
    position: 'static',
    transform: 'none',
    marginBottom: theme.spacing(1),
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
    ...theme.typography.body2,
  }),
)
