import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { IconNewNotifications } from 'assets/icons'
import { LinkPrefetch } from 'components/InternalLink/InternalLink'
import { useBoolean } from 'hooks/use-boolean'
import { useBooleanContext } from 'hooks/use-boolean-context'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

type IconProps = SvgIconProps

type MenuItem = {
  level?: number
  title: string
  path?: string
  Icon: (props: IconProps) => JSX.Element
  iconProps?: IconProps
  variant?: 'normal' | 'danger'
  closedBranch?: boolean
  counterValue?: number
}

type ExpandedMenuItem = MenuItem & {
  items?: ReadonlyArray<MenuItem>
}

export const AppMenuItem = ({
  level = 0,
  title,
  path,
  Icon,
  iconProps,
  items,
  variant = 'normal',
  closedBranch,
  counterValue,
}: ExpandedMenuItem) => {
  const { t } = useTranslation()
  const location = useLocation()
  const expandedItem = useBoolean(false)
  const drawer = useBooleanContext()

  const pathnameStart = `/${location.pathname.split('/')[1]}`
  const isExpanded = items && items.length > 0

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const listItemProps = useMemo(() => {
    if (path && !isExpanded) {
      return {
        component: LinkPrefetch,
        to: path,
        selected: pathnameStart === path,
      }
    }
  }, [isExpanded, pathnameStart, path])

  const itemColor =
    variant === 'danger'
      ? theme.palette.error.main
      : path === pathnameStart
      ? theme.palette.primary.main
      : theme.palette.text.primary

  const onClose = useCallback(() => {
    if (mdDown) {
      drawer.setFalse()
    }
  }, [drawer, mdDown])

  const onClick = useCallback(() => {
    if (isExpanded) {
      return expandedItem.toggle()
    }

    return onClose()
  }, [expandedItem, isExpanded, onClose])

  const MenuItemRoot = (
    <ListItemButton
      onClick={onClick}
      disabled={closedBranch}
      {...listItemProps}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: 2,
          paddingLeft: `calc(48px * ${level})`,
        }}
      >
        <Icon
          sx={{
            ...(iconProps ? iconProps.sx : undefined),
            color: itemColor,
          }}
        />
      </ListItemIcon>
      <ListItemText primary={t(title)} sx={{ color: itemColor }} />
      {counterValue !== undefined && (
        <IconNewNotifications value={counterValue?.toString()} />
      )}
    </ListItemButton>
  )

  const MenuItemChildren = items ? (
    <Collapse in={expandedItem.isTrue} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map(item => (
          <AppMenuItem {...item} key={item.title} level={level + 1} />
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}
