import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconDropdown = (props: SvgIconProps & { strokecolor: string }) => (
  <SvgIcon {...props} sx={{ fill: 'none', ...props.sx }}>
    <circle cx="12" cy="12" r="12" />
    <path
      d="M8 11L12 15L16 11"
      stroke={props.strokecolor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
