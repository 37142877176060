import { Button } from '@material-ui/core'
import { useAuthContext } from 'app/auth'
import { TProfile } from 'app/codecs/user'
import { ControlledPasswordTextField } from 'components/form/controlled-password-text-field'
import { ControlledTextField } from 'components/form/controlled-text-field'
import { FormField } from 'components/form/form-field'
import { DualItems } from 'components/ui/dual-items'
import { FormDialog } from 'components/ui/form-dialog/form-dialog'
import { useBoolean } from 'hooks/use-boolean'
import { Output } from 'hooks/use-boolean/use-boolean'
import { useNotificationContext } from 'hooks/use-notification'
import { useQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { formatBranch } from 'meest-domain/format'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type ProfileFieldValues = {
  fullName: string
  email?: string
  password?: {
    current: string
    main: string
    confirm: string
  }
}

type Props = {
  dialogState: Output
  isPasswordChanging?: boolean
}

export const Profile = ({ dialogState, isPasswordChanging }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { auth, refresh } = useAuthContext()
  const password = useBoolean(isPasswordChanging || false)
  const { showSuccessNotification } = useNotificationContext()

  const form = useForm<ProfileFieldValues>({
    defaultValues: {
      fullName: '',
      email: '',
      password: {
        current: '',
        main: '',
        confirm: '',
      },
    },
  })

  const userId = auth.type === 'authenticated' ? auth.userId : ''

  const $user = useQuery('GET', '/api/pudo/users/me', TProfile)
  const $updateUser = useMutation('PUT', `/api/pudo/users/${userId}`)
  const user = $user.data
  const { control, reset } = form

  const passwordField = useWatch({
    control,
    name: 'password.main',
  })

  useEffect(() => {
    if (user) {
      reset({
        fullName: user.fullName,
        email: user.email,
      })
    }
  }, [reset, user])

  if (!user) {
    return null
  }

  return (
    <FormDialog
      title="dialog_titles.edit_profile"
      open={dialogState.isTrue}
      onClose={() => {
        password.setFalse()
        dialogState.setFalse()
      }}
      closeDialogTitle="dialog_confirm.title_profile_edit"
      form={form}
      onSubmit={values => {
        const body = {
          fullName: values.fullName,
          email: values.email,
          password: values.password?.confirm,
          currentPassword: values.password?.current,
        }

        $updateUser.mutate(
          {
            body,
          },
          {
            onSuccess: () => {
              refresh()
              $user.refetch()
              reset()
              showSuccessNotification(t('notifications.user_edit_success'))
            },
          },
        )
        dialogState.setFalse()
        reset()
      }}
      actions={
        <>
          {password.isFalse && (
            <Button fullWidth variant="text" onClick={password.setTrue}>
              {t('buttons.change_password')}
            </Button>
          )}
          <Button type="submit" fullWidth sx={{ ml: '0px !important', mt: 1 }}>
            {t('buttons.save')}
          </Button>
        </>
      }
    >
      <FormField>
        <ControlledTextField
          required
          control={control}
          name="fullName"
          label="form_fields.name"
        />
      </FormField>
      <FormField>
        <ControlledTextField
          control={control}
          name="email"
          label="user.email"
        />
      </FormField>
      <FormField>
        <DualItems
          view="row"
          items={[
            {
              label: 'user.phone',
              value: user.phone,
              labelColor: 'textSecondary',
              valueVariant: 'body1',
            },
            {
              label: 'user.branch',
              value: user.branchResponse
                ? formatBranch(language)(user.branchResponse)
                : '—',
              labelColor: 'textSecondary',
              valueVariant: 'body1',
            },
          ]}
        />
      </FormField>
      {password.isTrue && (
        <>
          <FormField>
            <ControlledPasswordTextField
              control={control}
              required
              fullWidth
              label={'form_fields.current_password'}
              name="password.current"
              isOldPasswordCheck
              doNotCheckPattern
            />
          </FormField>
          <FormField>
            <ControlledPasswordTextField
              control={control}
              required
              fullWidth
              label={'form_fields.password_new'}
              isHelperText={true}
              name="password.main"
            />
          </FormField>
          <FormField>
            <ControlledPasswordTextField
              control={control}
              required
              fullWidth
              label={'form_fields.password_confirm'}
              name="password.confirm"
              pattern={{
                // eslint-disable-next-line security/detect-non-literal-regexp
                value: new RegExp(`^${passwordField}$`),
                message: t('validations.password_confirm'),
              }}
            />
          </FormField>
        </>
      )}
    </FormDialog>
  )
}
