import { DialogForm } from 'components/form/dialog-form'
import { DialogFormWrapper } from 'components/form/dialog-form-wrapper'
import { identity } from 'fp-ts/lib/function'
import { Mixed, TypeOf } from 'io-ts'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { scrollToView } from 'lib/scroll-to-view'
import { useState } from 'react'
import {
  DefaultValues,
  Path,
  UnpackNestedValue,
  useForm,
  UseFormReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type ErrorFieldMapping<TFieldValues> = {
  [err: string]: {
    field: Path<TFieldValues>
    text: string
  }
}

type Props<TFieldValues, K extends Mixed> = {
  url: string
  action: 'create' | 'update'
  title: string
  isOpen: boolean
  onClose: () => void
  defaultValues: DefaultValues<TFieldValues>
  refetch: () => void
  renderForm: (
    form: UseFormReturn<TFieldValues>,
    activeStep: number,
    steps?: number,
  ) => React.ReactNode
  submitButtonLabel: string
  closeDialogTitle: string
  mapFieldValuesToPayload?: (values: UnpackNestedValue<TFieldValues>) => any
  errorFieldMapping?: ErrorFieldMapping<TFieldValues>
  steps?: number
  codec?: K
  onSuccess?: (data: TypeOf<K>) => void
}

export const EntityDialog = <TFieldValues, K extends Mixed>(
  props: Props<TFieldValues, K>,
) => {
  const method = props.action === 'create' ? 'POST' : 'PUT'

  const $mutation = useMutation(method, props.url, props.codec)

  const [activeStep, setActiveStep] = useState(0)

  const form = useForm<TFieldValues>({
    defaultValues: props.defaultValues,
  })

  const handleNextStep = () => {
    setActiveStep(prevActiveStep => {
      form.clearErrors()
      return prevActiveStep + 1
    })
  }

  const handlePrevStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const { t } = useTranslation()

  const mapFieldValuesToPayload = props.mapFieldValuesToPayload ?? identity

  const handleClose = () => {
    form.reset()
    setActiveStep(0)
    props.onClose()
  }

  const onSubmit = (values: UnpackNestedValue<TFieldValues>) => {
    $mutation.mutate(
      {
        body: mapFieldValuesToPayload(values),
        params: {},
      },
      {
        onSuccess: data => {
          handleClose()
          props.refetch()
          props.onSuccess?.(data)
        },
        //TODO: change type
        onError: (e: any) => {
          const error = props.errorFieldMapping?.[e.code]
          if (error) {
            form.setError(error.field, {
              type: 'manual',
              message: t(error.text),
            })
          }
        },
      },
    )
  }

  const handleSubmit = () => {
    //if statement prevents from infinite re-render on create parcel. TODO: fix it
    if (!props.steps) {
      scrollToView(form.formState.errors)
    }

    return form.handleSubmit(onSubmit)
  }

  return (
    <DialogForm
      title={props.title}
      isOpen={props.isOpen}
      onClose={handleClose}
      closeDialogTitle={props.closeDialogTitle}
      form={form}
    >
      <DialogFormWrapper
        isZeroStep={activeStep === 0}
        isLastStep={props.steps ? activeStep === props.steps - 1 : true}
        form={form}
        handleNextStep={props.steps ? handleNextStep : undefined}
        handlePrevStep={props.steps ? handlePrevStep : undefined}
        submitButtonLabel={props.submitButtonLabel}
        isLoading={$mutation.isLoading}
        onSubmit={handleSubmit()}
      >
        {props.renderForm(form, activeStep, props.steps)}
      </DialogFormWrapper>
    </DialogForm>
  )
}
