import {
  Box,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { myNotificationsPath } from 'app/app-menu'
import { NotificationsViewed } from 'app/codecs/notifications'
import { IconCross } from 'assets/icons/cross'
import { IconNewNotificationSnackbar } from 'assets/icons/new-notification-snackbar'
import { useBooleanContext } from 'hooks/use-boolean-context'
import { getShortTitleFromText } from 'meest-domain/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type Props = {
  isOpen: boolean
  onClose: () => void
  newestNotification: NotificationsViewed
}

export const NewNotificationSnackbar = ({
  isOpen,
  onClose,
  newestNotification,
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const drawer = useBooleanContext()

  return (
    <Snackbar
      sx={{
        marginTop: '80px',
        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
        width: { xs: '90%', sm: '400px' },
        height: '80px',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: 'white',
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={30_000}
    >
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          gap: '10px',
        }}
        onClick={() => {
          {
            if (mdDown) drawer.setFalse()
            history.push(myNotificationsPath)
          }
          history.push(myNotificationsPath)
          onClose()
        }}
      >
        <Box sx={{ width: '80px' }}>
          <IconNewNotificationSnackbar />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">
              {t('notification_page.new_notification')}
            </Typography>
            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation()
                onClose()
              }}
              sx={{
                maxWidth: '1.6rem',
                maxHeight: '1.6rem',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <IconCross strokecolor={'black'} size={15} />
            </IconButton>
          </Stack>
          <Typography variant="body2">
            {getShortTitleFromText(newestNotification.title, 50)}
          </Typography>
        </Box>
      </Stack>
    </Snackbar>
  )
}
