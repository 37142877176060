import { SvgIcon, SvgIconProps, Typography } from '@material-ui/core'

export const IconNewNotifications = (
  props: SvgIconProps & {
    value: string
  },
) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '30px',
        height: '30px',
        marginTop: '10px',
        cursor: 'pointer',
      }}
    >
      <SvgIcon
        {...props}
        sx={{ width: '33px', height: '33px', position: 'relative' }}
      >
        <path
          d="M0 5C0 2.23858 2.23858 0 5 0H18.2585C21.0199 0 23.2585 2.23858 23.2585 5V11C23.2585 13.7614 21.0199 16 18.2585 16H0V5Z"
          fill="#E60338"
        />
      </SvgIcon>
      <Typography
        variant="body1"
        sx={{
          position: 'absolute',
          top: '38%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
        }}
      >
        {props.value}
      </Typography>
    </div>
  )
}
