import { TCity } from 'app/codecs/address'
import { Branch, TBranch } from 'app/codecs/branch'
import { ControlledAutocomplete } from 'components/form/controlled-autocomplete'
import { DialogFormWrapper } from 'components/form/dialog-form-wrapper'
import { FormField } from 'components/form/form-field'
import { PARCEL_COUNT_QUERY_KEY } from 'components/layout/app-count-branch-parcels'
import { array } from 'io-ts'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { formatBranch, formatCity } from 'meest-domain/format'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { Dialog } from '../ui/dialog'

type FormValues = {
  branchCity: {
    label: string
    value: {
      cityNameUA: string
      cityNameRU: string
      cityType: string
      cityId: string
    }
  } | null
  branch: { label: string; value: string } | null
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (branch: Branch) => void
  branch?: { label: string; value: string }
}

export const ChooseBranchDialog = ({
  isOpen,
  onClose,
  onSubmit,
  branch,
}: Props) => {
  const form = useForm<FormValues>({
    defaultValues: { branch },
  })

  const $branch = useMutation('GET', '/api/pudo/branches/:branchId', TBranch)

  const queryClient = useQueryClient()

  const { mutate } = $branch

  const onChooseBranch = useCallback(
    (formValues: FormValues) => {
      if (formValues.branch !== null)
        mutate(
          { params: { branchId: formValues.branch.value } },
          {
            onSuccess: branch => {
              queryClient.invalidateQueries([
                PARCEL_COUNT_QUERY_KEY,
                branch.branchId,
              ])
              onSubmit(branch)
            },
          },
        )
    },
    [mutate, onSubmit, queryClient],
  )

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { control, watch, setValue } = form

  const branchCity = watch('branchCity')

  const [curCity, setCurCity] = useState<string | undefined>('')

  /* eslint-disable */
  useEffect(() => {
    if (curCity !== branchCity?.label && branch !== null) {
      setValue('branch', null)
    }
  }, [branchCity])
  /* eslint-enable */

  return (
    <Dialog
      title={t('dialog_titles.change_branch')}
      open={isOpen}
      onClose={onClose}
    >
      <DialogFormWrapper
        form={form}
        isLastStep={true}
        isLoading={false}
        isZeroStep={false}
        onSubmit={form.handleSubmit(onChooseBranch)}
        submitButtonLabel={t('buttons.go_to_the_branch')}
      >
        <FormField>
          <ControlledAutocomplete
            required
            name="branchCity"
            label={'form_fields.city_or_village'}
            control={control}
            optionsUrl={'/api/pudo/cities/'}
            optionsCodec={array(TCity)}
            queryParam="cityName"
            formatOptions={cities => {
              setCurCity(branchCity?.label)
              return cities.map(city => ({
                label: formatCity(language)(city),
                value: {
                  cityNameUA: city.nameUA,
                  cityNameRU: city.nameRU,
                  cityType: city.typeUA,
                  cityId: city.cityId,
                },
              }))
            }}
          />
        </FormField>
        {branchCity !== null && branchCity !== undefined && (
          <FormField>
            <ControlledAutocomplete
              required
              name="branch"
              label={'form_fields.branch_number_or_name'}
              control={control}
              optionsUrl={'/api/pudo/branches/search'}
              searchBodyParam="searchCriteria"
              optionsCodec={array(TBranch)}
              /*            rawBody={{
                types: ['MINI_BRANCH'],
              }}*/
              rawBody={{
                cityId: branchCity.value.cityId,
              }}
              formatOptions={branches =>
                branches.map(branch => ({
                  label: formatBranch(language)(branch),
                  value: branch.branchId,
                  option: branch.closed ? 'closedBranch' : null,
                }))
              }
              showTip={true}
            />
          </FormField>
        )}
      </DialogFormWrapper>
    </Dialog>
  )
}
