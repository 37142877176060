import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconMenuOpen = (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      d="M8 11L12 15L16 11"
      stroke="#2C363F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
