import { useAuthContext } from 'app/auth'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useEffect } from 'react'

export const Logout = () => {
  const { mutate } = useMutation('DELETE', '/api/pudo/tokens/me')

  const { removeToken } = useAuthContext()

  useEffect(() => {
    mutate(
      {},
      {
        onSuccess: () => {
          removeToken()
        },
      },
    )
  }, [mutate, removeToken])

  return null
}
