import { Box, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

type Props = {
  steps: number
  activeStep: number
}

const INITIAL_STEP = 1

export const Stepper = (props: Props) => {
  // eslint-disable-next-line unicorn/no-new-array
  const steps = Array.apply(null, new Array(props.steps)).map(() => {})
  const { t } = useTranslation()

  const step = props.activeStep ? props.activeStep + INITIAL_STEP : INITIAL_STEP

  return (
    <Box width={1} display="flex" flexWrap="wrap">
      <Box width={1} mb={1}>
        <Typography color="textSecondary" variant="body2">
          {t('stepper.step', { step })}
        </Typography>
      </Box>
      <Box width={1} display="flex" alignItems="center">
        {steps.map((_e, index) => {
          const isPassedStep = props.activeStep >= index

          const styles = isPassedStep
            ? {
                borderRadius: '2px',
                bgcolor: (theme: Theme) => theme.palette.primary.main,
                height: '4px',
              }
            : {
                borderRadius: '1px',
                bgcolor: (theme: Theme) => theme.palette.info.light,
                height: '2px',
              }

          return (
            <Box
              width={1}
              key={uuidV4()}
              {...styles}
              sx={{
                margin: '0px 2px',
                ':first-of-type': { marginRight: '2px', marginLeft: 0 },
                ':last-child': { marginRight: 0, marginLeft: '2px' },
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
}
