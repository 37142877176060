export const IconProfileCircle = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#89A6CC" />
    <circle cx="15.9998" cy="15.9998" r="14.0408" fill="white" />
  </svg>
)
