import { Box, Button } from '@material-ui/core'
import { DialogActions, DialogContent } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { Handler } from 'lib/react-handy-types'
import { ReactNode, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<T> = {
  submitButtonLabel: string
  children: ReactNode
  onSubmit: Handler
  isZeroStep: boolean
  isLastStep: boolean
  handleNextStep?: () => void
  handlePrevStep?: () => void
  isLoading: boolean
  form: UseFormReturn<T>
}

export const DialogFormWrapper = <T,>(props: Props<T>) => {
  const { t } = useTranslation()

  const { trigger } = props.form

  const [isError, setIsError] = useState(false)

  /* eslint-disable */
  useEffect(() => {
    const formValues = props.form.getValues() as { isCreateError?: boolean }

    if (
      props.isLastStep &&
      'isCreateError' in formValues &&
      (formValues !== null || formValues !== undefined) &&
      formValues.isCreateError
    ) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }, [props.form])
  /* eslint-enable */

  return (
    <form
      noValidate
      onSubmit={props.onSubmit}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'overlay',
      }}
    >
      <DialogContent
        sx={{
          px: { sm: 3, md: 8 },
          py: 0,
          overflowY: 'overlay' as any,
        }}
      >
        {props.children}
      </DialogContent>
      <DialogActions
        sx={{
          px: { sm: 3, md: 8 },
          py: 4,
        }}
      >
        {props.handleNextStep && props.handlePrevStep && (
          <Box
            display="flex"
            justifyContent={!props.isZeroStep ? 'space-between' : 'flex-end'}
            width={1}
          >
            {!props.isZeroStep && (
              <Button variant="text" onClick={props.handlePrevStep}>
                {t('dialog_actions.back')}
              </Button>
            )}
            {!props.isLastStep && (
              <Button
                color="primary"
                autoFocus
                onClick={async () => {
                  const valid = await trigger()

                  if (valid && props.handleNextStep) {
                    return props.handleNextStep()
                  }
                }}
              >
                {t('dialog_actions.next')}
              </Button>
            )}
          </Box>
        )}

        {props.isLastStep && (
          <Box
            width={1}
            display={'flex'}
            justifyContent={'space-between'}
            gap={1}
          >
            <LoadingButton
              disabled={isError}
              type="submit"
              fullWidth
              loading={props.isLoading}
            >
              {t(props.submitButtonLabel)}
            </LoadingButton>
          </Box>
        )}
      </DialogActions>
    </form>
  )
}
