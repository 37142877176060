import {
  Autocomplete,
  ListItemText,
  TextFieldProps,
  useTheme,
} from '@material-ui/core'
import { AutocompleteOption } from 'app/options'
import { Checkbox } from 'components/checkbox'
import { ListboxComponent } from 'components/ui/autocomplete-single/list-box'
import { useUuid } from 'hooks/use-uuid'
import { Mixed, TypeOf } from 'io-ts'
import { useQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import debounce from 'lodash.debounce'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '../form-control'
import { InputBase } from '../input-base'
import { MenuItem } from '../menu-item'

export type RawBody = {
  [k: string]: unknown
}

type Props<C extends Mixed> = {
  label?: string
  selectedOptions: Array<AutocompleteOption>
  name: string
  disabled?: boolean
  size?: TextFieldProps['size']
  required?: boolean
  optionsUrl: string
  optionsCodec: C
  formatOptions: (options: TypeOf<C>) => Array<AutocompleteOption>
  onChange: (values: Array<AutocompleteOption> | null) => void
  onBlur?: () => void
  error?: boolean
  helperText?: string
  loading?: boolean
  queryParam?: string
  serverSearch?: boolean
  searchBodyParam?: string
  rawBody?: RawBody
}

export const AutocompleteMultiple = <C extends Mixed>(props: Props<C>) => {
  const [inputValue, setInputValue] = useState('')
  const theme = useTheme()
  const id = useUuid()

  const params = new URLSearchParams()
  const { t } = useTranslation()

  if (props.queryParam) {
    params.set(props.queryParam, inputValue)
  }

  const rawBody = {
    ...(props.rawBody ? props.rawBody : undefined),
    ...(props.searchBodyParam
      ? { [props.searchBodyParam]: inputValue }
      : undefined),
  }

  const $data = useQuery(
    props.searchBodyParam ? 'POST' : 'GET',
    props.serverSearch
      ? props.optionsUrl + inputValue
      : concatQueryParams(props.optionsUrl, params),
    props.optionsCodec,
    {
      rawBody: props.searchBodyParam || props.rawBody ? rawBody : undefined,
    },
  )

  const { data, isLoading } = $data

  const options = useMemo(
    () => (data ? props.formatOptions(data) : []),
    [data, props],
  )

  const selectAllOption = {
    label: t('ui.select_all'),
    value: 'select-all',
  }

  const isAllSelected = props.selectedOptions?.some(
    option => option.value === selectAllOption.value,
  )

  return (
    <FormControl
      label={props.label}
      required={props.required}
      helperText={props.helperText}
      error={props.error}
      htmlFor={id}
    >
      <Autocomplete
        id={id}
        fullWidth
        options={options}
        filterOptions={(
          options: Array<AutocompleteOption>,
        ): Array<AutocompleteOption> => {
          return isAllSelected
            ? [selectAllOption]
            : [selectAllOption, ...options]
        }}
        multiple
        disabled={props.disabled}
        loading={isLoading}
        value={props.selectedOptions || undefined}
        ListboxComponent={props => (
          <ListboxComponent
            height={isAllSelected ? 50 : undefined}
            {...props}
          />
        )}
        onChange={(_event, option) => props.onChange(option)}
        onInputChange={debounce((_event, newInputValue) => {
          setInputValue(newInputValue)
        }, 500)}
        ListboxProps={{
          style: {
            padding: 0,
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 5px 12px rgba(128, 134, 140, 0.2)',
            borderRadius: '10px',
          },
        }}
        disableClearable
        disableCloseOnSelect
        onBlur={props.onBlur}
        forcePopupIcon={false}
        isOptionEqualToValue={(option, value) =>
          option.label === value.label || option.value === value.value
        }
        limitTags={3}
        renderOption={(optionProps, option, { selected }) => {
          return (
            <MenuItem {...optionProps}>
              <>
                <Checkbox checked={selected} />
                <ListItemText
                  disableTypography
                  sx={{
                    paddingY: theme.spacing(0.5),
                    textOverflow: 'ellipsis',
                    fontSize: theme.typography.body2.fontSize,
                    overflow: 'hidden',
                    fontWeight: 400,
                  }}
                >
                  {option.label}
                </ListItemText>
              </>
            </MenuItem>
          )
        }}
        renderInput={params => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { InputLabelProps, InputProps, ...rest } = params
          return (
            <InputBase
              {...rest}
              ref={InputProps.ref}
              className={InputProps.className}
              name={props.name}
              endAdornment={InputProps.endAdornment}
              startAdornment={InputProps.startAdornment}
              error={props.error}
              maxRows={5}
              sx={{ maxHeight: '164.19px', overflowY: 'auto' }}
            />
          )
        }}
      />
    </FormControl>
  )
}
