import { format, utcToZonedTime } from 'date-fns-tz'

const padTo2Digits = (num: Number) => {
  return String(num).padStart(2, '0')
}

export const formatDate = (date: Date) => {
  // Date From String type from io-ts-types creates date based on client timezone, but the server returns the date in UTC format
  // We should create by hand correctly UTC dates.
  // TODO Create a custom DateFromString type to prevent this behavior
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  )

  return format(utcToZonedTime(utcDate, 'Europe/Kiev'), 'dd.MM.yyyy')
}

export const formatTime = (date: Date) => {
  const createdDate = new Date(date)
  const hoursAndMinutes = `${padTo2Digits(
    createdDate.getHours(),
  )}:${padTo2Digits(createdDate.getMinutes())}`
  return hoursAndMinutes
}

export const formatDateTime = (date: Date) => {
  const hoursAndMinutes = `${padTo2Digits(date.getHours())}:${padTo2Digits(
    date.getMinutes(),
  )}`

  const formatedDate = format(utcToZonedTime(date, 'Europe/Kiev'), 'dd.MM.yyyy')

  return `${formatedDate} ${hoursAndMinutes}`
}
