import { cloneElement, forwardRef, isValidElement } from 'react'
import { FixedSizeList as List } from 'react-window'

type Props = {
  height?: number
} & React.HTMLAttributes<HTMLElement>

export const ListboxComponent = forwardRef((props: Props, ref: any) => {
  const { children, ...other } = props
  const itemCount = Array.isArray(children) ? children.length : 0
  const itemSize = 48
  const countedHeight = itemCount * itemSize

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={props.height || (countedHeight <= 250 ? countedHeight : 250)}
          width="100%"
          itemSize={itemSize}
          itemCount={itemCount}
        >
          {({ index, style }) => {
            if (Array.isArray(children)) {
              const element = children[index]

              if (isValidElement(element)) {
                return cloneElement(element, {
                  key: index,
                  style: style,
                })
              }
            }

            return null
          }}
        </List>
      </div>
    </div>
  )
})
