import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconDropdownClose = (
  props: SvgIconProps & { strokecolor: string },
) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <circle cx="12" cy="12" r="12" />
    <path
      d="M8 14L12 10L16 14"
      stroke={props.strokecolor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
