import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconMapBranch = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21 9C21 13.9706 15 20.25 11.625 24C8.25 20.25 3 13.9706 3 9C3 4.02944 7.02944 0 12 0C16.9706 0 21 4.02944 21 9Z"
      fill="#0061AF"
    />
    <circle cx="12" cy="9" r="7" fill="white" />
    <g clipPath="url(#clip0_3585:23570)">
      <path
        d="M8.625 8.875C8.625 8.32272 9.07272 7.875 9.625 7.875H14.375C14.9273 7.875 15.375 8.32272 15.375 8.875V13.5H8.625V8.875Z"
        fill="white"
      />
      <path
        d="M16.3785 7.45575L12.1597 4.5495C12.0637 4.4835 11.937 4.4835 11.8406 4.5495L7.62187 7.45575C7.54537 7.50825 7.5 7.59488 7.5 7.6875V13.125C7.5 13.332 7.668 13.5 7.875 13.5H8.625V9C8.625 8.793 8.793 8.625 9 8.625H15C15.207 8.625 15.375 8.793 15.375 9V13.5H16.125C16.332 13.5 16.5 13.332 16.5 13.125V7.6875C16.5 7.59488 16.4546 7.50825 16.3785 7.45575Z"
        fill="#0061AF"
      />
      <path
        d="M10.875 11.125C10.875 10.5727 11.3227 10.125 11.875 10.125H12.125C12.6773 10.125 13.125 10.5727 13.125 11.125V13.5H10.875V11.125Z"
        fill="#0061AF"
      />
    </g>
    <defs>
      <clipPath id="clip0_3585:23570">
        <rect
          width="9"
          height="9"
          fill="white"
          transform="translate(7.5 4.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)
