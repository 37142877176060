import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconCross = (
  props: SvgIconProps & {
    strokecolor: string
    size?: number
    strokeWidth?: number
  },
) => {
  const size = props.size !== undefined ? props.size : 20
  const strokeWidth = props.strokeWidth !== undefined ? props.size : 2
  return (
    <SvgIcon {...props} sx={{ fill: 'none' }}>
      <path
        d={`M4 4L${size} ${size}`}
        stroke={props.strokecolor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d={`M4 ${size}L${size} 4`}
        stroke={props.strokecolor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </SvgIcon>
  )
}
