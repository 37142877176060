import {
  FormControl as MuiFormControl,
  FormHelperText,
} from '@material-ui/core'
import { forwardRef } from 'react'

import { InputLabel } from '../input-label'

export type FormControlProps = {
  children: React.ReactNode
  htmlFor?: string
  label?: string
  helperText?: React.ReactNode
  error?: boolean
  required?: boolean
}

export const FormControl = forwardRef((props: FormControlProps, ref) => {
  return (
    // TODO [dev]: Find out why `ref={ref}` causes TS error.
    // @ts-expect-error
    <MuiFormControl fullWidth required={props.required} ref={ref} margin="none">
      {props.label && (
        <InputLabel
          error={props.error}
          disableAnimation
          shrink
          htmlFor={props.htmlFor}
          required={props.required}
        >
          {props.label}
        </InputLabel>
      )}

      {props.children}

      {props.helperText && (
        <FormHelperText
          error={props.error}
          sx={{ ml: 0, mt: 1, fontSize: '0.875rem' }}
        >
          {props.helperText}
        </FormHelperText>
      )}
    </MuiFormControl>
  )
})
