import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export const IconSort = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M12.0113 6.21995C11.8707 6.0795 11.68 6.00061 11.4813 6.00061C11.2825 6.00061 11.0919 6.0795 10.9513 6.21995L8.20129 8.96995C8.06881 9.11213 7.99669 9.30017 8.00012 9.49447C8.00355 9.68877 8.08226 9.87416 8.21967 10.0116C8.35708 10.149 8.54247 10.2277 8.73677 10.2311C8.93107 10.2346 9.11912 10.1624 9.26129 10.03L10.7313 8.55995V17.25C10.7313 17.4489 10.8103 17.6396 10.951 17.7803C11.0916 17.9209 11.2824 18 11.4813 18C11.6802 18 11.871 17.9209 12.0116 17.7803C12.1523 17.6396 12.2313 17.4489 12.2313 17.25V8.55995L13.7013 10.03C13.8435 10.1624 14.0315 10.2346 14.2258 10.2311C14.4201 10.2277 14.6055 10.149 14.7429 10.0116C14.8803 9.87416 14.959 9.68877 14.9625 9.49447C14.9659 9.30017 14.8938 9.11213 14.7613 8.96995L12.0113 6.21995Z"
      fill="#2C363F"
    />
  </SvgIcon>
)
