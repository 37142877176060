import { TPaymentType } from 'app/codecs/cash-to-pay'
import { TParcel, TParcelCashExpired } from 'app/codecs/parcel'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TPayment = t.type({
  paymentId: t.string,
  parcelResponse: TParcel,
  amount: t.number,
  enrollmentMethodType: t.string,
  paymentType: TPaymentType,
  account: t.union([t.string, t.undefined]),
  payerType: t.string,
  collected: t.boolean,
  createdAt: DateFromISOString,
})

export const TPaymentCashExpired = t.type({
  paymentId: t.string,
  parcelResponse: TParcelCashExpired,
  amount: t.number,
  enrollmentMethodType: t.string,
  paymentType: TPaymentType,
  account: t.union([t.string, t.undefined]),
  payerType: t.string,
  collected: t.boolean,
  createdAt: DateFromISOString,
})

export type Payment = t.TypeOf<typeof TPayment>
