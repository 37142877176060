import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import {
  menuItemsAdmin,
  menuItemsOperator as originalMenuItemsOperator,
} from 'app/app-menu'
import { useAuthContext } from 'app/auth'
import { TPaymentCashExpired } from 'app/codecs/cashbox'
import { TCashboxAvailability } from 'app/codecs/cashbox-availability'
import { isAdminRole, isSuperAdminRole, TProfile } from 'app/codecs/user'
import { routes } from 'app/routes'
import {
  IconAdd,
  IconBackAdmin,
  IconBackAdminBig,
  IconLogout,
} from 'assets/icons'
import { ParcelsToSendBanner } from 'components/banners/parcels-to-send-banner'
import { ChangePasswordDialog } from 'components/change-password-dialog'
import { CheckCashboxDialog } from 'components/check-cashbox-dialog'
import { Profile } from 'components/forms/profile'
import { drawerWidth, headerHeight } from 'components/layout'
import { AppMenuItem } from 'components/layout/app-menu-item'
import { ParcelActionsDialog } from 'components/parcel-actions-dialog'
import { SupportDialog } from 'components/support-dialog'
import { useBoolean } from 'hooks/use-boolean'
import { useBooleanContext } from 'hooks/use-boolean-context'
import { array } from 'io-ts'
import { useQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { formatBranch } from 'meest-domain/format'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AppCountBranchParcels } from './app-count-branch-parcels'

type Props = {
  onChooseBranchDialog: () => void
  onCreateParcelDialog: () => void
}

export const CASH_EXPIRED_CRON_VALUE = '0 16 * * *'
export const MONDAY_PARCELS_TO_SEND_BANNER_CRON_VALUE = '0 15 * * 1'
export const WEDNESDAY_PARCELS_TO_SEND_BANNER_CRON_VALUE = '0 15 * * 3'
export const FRIDAY_PARCELS_TO_SEND_BANNER_CRON_VALUE = '0 15 * * 5'

export const AppMenu = (props: Props) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { auth, setBranch } = useAuthContext()

  const queryParams = new URLSearchParams(location.search)
  const isLoginChecker = queryParams.get('isLoginChecker') ? true : false

  const schedule = require('node-schedule')

  const helpDialog = useBoolean()
  const passwordChangeNeededDialog = useBoolean()
  const isPasswordChanging = useBoolean(false)
  const profileDialog = useBoolean()

  const [isCheckCashboxOpen, setIsCheckCashboxOpen] = useState(false)
  const [isParcelToSendBannerOpen, setIsParcelToSendBannerOpen] =
    useState(false)

  const cashBoxDialogShown = sessionStorage.getItem('cashBoxDialogShown')

  useEffect(() => {
    if (cashBoxDialogShown === 'true') {
      setIsCheckCashboxOpen(true)
      sessionStorage.setItem('cashBoxDialogShown', 'false')
    }
  }, [cashBoxDialogShown])

  const changingPasswordNeeded = sessionStorage.getItem(
    'changingPasswordNeeded',
  )

  useEffect(() => {
    if (changingPasswordNeeded === 'true') {
      passwordChangeNeededDialog.setTrue()
      isPasswordChanging.setTrue()
      sessionStorage.setItem('changingPasswordNeeded', 'false')
    }
  }, [changingPasswordNeeded, passwordChangeNeededDialog, isPasswordChanging])

  const history = useHistory()

  const isAdmin = isAdminRole(auth.role)

  const isSuperAdmin = isSuperAdminRole(auth.role)

  const firstAdminMenuItem = menuItemsAdmin[0].path

  const $getBranchList = useMutation('POST', '/api/pudo/data/branches')
  const $user = useQuery('GET', '/api/pudo/users/me', TProfile)
  const userData = $user.data

  const formattedBranch = useMemo(
    () =>
      auth.branch
        ? `${formatBranch(language)(auth.branch)}, ${
            auth.branch.branchType.weightLimit
          }`
        : null,
    [auth.branch, language],
  )

  const $cashBoxAvailability = useQuery(
    'PUT',
    `api/pudo/branches/${auth.branch?.branchId}/cash-box`,
    TCashboxAvailability,
    {
      options: {
        enabled: auth.branch?.branchId !== undefined,
      },
    },
  )

  const cashboxIndex = originalMenuItemsOperator.findIndex(
    el => el.title === 'menu_items.cashbox',
  )

  const cashExpiredParams = auth.branch
    ? new URLSearchParams({ branchId: auth.branch.branchId })
    : undefined

  const $getCashExpired = useQuery(
    'POST',
    concatQueryParams('/api/pudo/parcels/cash/expired', cashExpiredParams),
    array(TPaymentCashExpired),
  )

  const cashExpiredData = $getCashExpired.data

  let menuItemsOperator = [...originalMenuItemsOperator]

  if (cashExpiredData !== undefined && cashExpiredData.length > 0) {
    menuItemsOperator = menuItemsOperator.map((item, index) => {
      if (index === cashboxIndex) {
        return {
          ...item,
          counterValue: cashExpiredData.length,
        }
      }
      return item
    })
  }

  const menuItemsOperatorNoCashbox = [
    ...menuItemsOperator.slice(0, cashboxIndex),
    ...menuItemsOperator.slice(cashboxIndex + 1),
  ]

  const [isOpenParcelActionDialog, setIsOpenParcelActionDialog] =
    useState(false)

  let operatorItems = $cashBoxAvailability.data?.cashBox
    ? menuItemsOperator
    : menuItemsOperatorNoCashbox

  const operatorItemsToShow =
    userData?.sale !== undefined && userData.sale
      ? operatorItems
      : operatorItems.filter(
          item => item.title !== 'menu_items.container_selling',
        )

  const drawer = useBooleanContext()

  const $cashBoxAvailabilityCron = useMutation(
    'PUT',
    `api/pudo/branches/:branchId/cash-box`,
    TCashboxAvailability,
  )

  const $getCashExpiredCron = useMutation(
    'POST',
    concatQueryParams('/api/pudo/parcels/cash/expired', cashExpiredParams),
    array(TPaymentCashExpired),
  )

  /* eslint-disable */
  useEffect(() => {
    const cashJob = schedule.scheduleJob(CASH_EXPIRED_CRON_VALUE, function () {
      $cashBoxAvailabilityCron.mutate(
        {
          params: { branchId: auth.branch?.branchId || '' },
        },
        {
          onSuccess: cashBox => {
            if (cashBox.cashBox) {
              $getCashExpiredCron.mutate(
                {
                  params: {},
                },
                {
                  onSuccess: cashExpiredCronData => {
                    if (
                      !isAdminRole(auth.role) &&
                      cashExpiredCronData.length > 0
                    ) {
                      setIsCheckCashboxOpen(true)
                    }
                  },
                },
              )
            }
          },
        },
      )
    })

    const bannerJobMonday = schedule.scheduleJob(
      MONDAY_PARCELS_TO_SEND_BANNER_CRON_VALUE,
      function () {
        setIsParcelToSendBannerOpen(true)
      },
    )

    const bannerJobWednesday = schedule.scheduleJob(
      WEDNESDAY_PARCELS_TO_SEND_BANNER_CRON_VALUE,
      function () {
        setIsParcelToSendBannerOpen(true)
      },
    )

    const bannerJobFriday = schedule.scheduleJob(
      FRIDAY_PARCELS_TO_SEND_BANNER_CRON_VALUE,
      function () {
        setIsParcelToSendBannerOpen(true)
      },
    )

    return () => {
      cashJob.cancel()
      bannerJobMonday.cancel()
      bannerJobWednesday.cancel()
      bannerJobFriday.cancel()
    }
  }, [])
  /* eslint-enable */

  if (auth.type === 'authenticated') {
    return (
      <Stack width={drawerWidth} height="100%" overflow="overlay">
        {mdDown && (
          <Box
            onClick={drawer.toggle}
            sx={{
              '&:hover': { cursor: 'pointer' },
            }}
          >
            <Box
              pl={0.8}
              sx={{
                height: headerHeight,
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #D1DAE8',
              }}
            >
              <IconButton
                sx={{
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                <IconBackAdminBig />
                <Typography color="#89A6CC" variant="body1" ml={'0.6rem'}>
                  {t('buttons.close_app_menu')}
                </Typography>
              </IconButton>
            </Box>
          </Box>
        )}
        {isAdmin && auth.branch && (
          <Box>
            <Button
              disableRipple
              onClick={() => {
                setBranch()
                history.push(firstAdminMenuItem)
                localStorage.removeItem('branch')
              }}
              variant="text"
              sx={{
                fontSize: theme.typography.caption1.fontSize,
                pb: 2,
                pl: 0,
                pr: 3.5,
                px: 2,
                display: 'flex',
                gap: '0.6rem',
              }}
            >
              <IconBackAdmin />
              <Typography color="#89A6CC" variant="caption1">
                {t('buttons.return_to_the_administration_panel')}
              </Typography>
            </Button>
          </Box>
        )}
        <Box mx={2} mb={1}>
          {mdDown && formattedBranch && (
            <Typography my={1.5} color="textPrimary" variant="caption1">
              {formattedBranch}
            </Typography>
          )}
          {auth.branch && (
            <>
              <AppCountBranchParcels
                branchId={auth.branch.branchId}
                isLoginChecker={isLoginChecker}
              />
              <Box mt={1}>
                <Button
                  size="medium"
                  fullWidth
                  variant="contained"
                  disabled={auth.branch.closed || false}
                  onClick={props.onCreateParcelDialog}
                  startIcon={
                    <IconAdd
                      sx={{
                        color: theme.palette.background.default,
                      }}
                    />
                  }
                >
                  {t('parcel_options.create')}
                </Button>
              </Box>
              <Divider sx={{ mt: 1 }} />
            </>
          )}
          {mdDown && (
            <Box sx={{ marginTop: '10px' }}>
              <Button
                fullWidth
                onClick={() => setIsOpenParcelActionDialog(true)}
                disableRipple
              >
                {t('buttons.track_parcel')}
              </Button>
              <ParcelActionsDialog
                isOpen={isOpenParcelActionDialog}
                onClose={() => setIsOpenParcelActionDialog(false)}
              />
            </Box>
          )}

          {mdDown && isSuperAdmin && (
            <LoadingButton
              fullWidth
              onClick={() => $getBranchList.mutate({})}
              disableRipple
              sx={{ my: 1 }}
              loading={$getBranchList.isLoading}
            >
              {t('buttons.reload_branch')}
            </LoadingButton>
          )}

          {mdDown && isAdmin && (
            <Button
              fullWidth
              sx={{
                mt: '1',
              }}
              variant="contained"
              onClick={props.onChooseBranchDialog}
            >
              {t('buttons.change_branch')}
            </Button>
          )}
        </Box>
        <List
          component="nav"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            py: 0,
            flex: 1,
          }}
        >
          <Box>
            {auth.branch
              ? operatorItemsToShow.map(item => (
                  <AppMenuItem
                    key={item.title}
                    closedBranch={
                      item.title === 'menu_items.parcel_reception' &&
                      auth.branch !== undefined
                        ? auth.branch.closed
                        : false
                    }
                    {...item}
                  />
                ))
              : menuItemsAdmin.map(item => (
                  <AppMenuItem key={item.title} {...item} />
                ))}
          </Box>
          <Box>
            <AppMenuItem
              title={t('menu_items.logout')}
              path={routes.logout}
              Icon={IconLogout}
            />
          </Box>
        </List>
        <SupportDialog
          title={t('dialog_support.phone_title')}
          open={helpDialog.isTrue}
          onClose={helpDialog.setFalse}
          phoneNumbers={['+380674338830', '+380504338830']}
          isPhoneButton
        />
        <Profile
          dialogState={profileDialog}
          isPasswordChanging={isPasswordChanging.isTrue}
        />
        <ChangePasswordDialog
          open={passwordChangeNeededDialog.isTrue}
          onClose={passwordChangeNeededDialog.setFalse}
          setOpenProfileDialog={profileDialog.setTrue}
        />

        {$cashBoxAvailability.data?.cashBox &&
          !isAdminRole(auth.role) &&
          cashExpiredData !== undefined &&
          cashExpiredData.length > 0 && (
            <CheckCashboxDialog
              isOpen={isCheckCashboxOpen}
              onClose={() => setIsCheckCashboxOpen(false)}
            />
          )}
        {location.pathname === '/home' && (
          <ParcelsToSendBanner
            isOpen={isParcelToSendBannerOpen}
            onClose={() => setIsParcelToSendBannerOpen(false)}
          />
        )}
      </Stack>
    )
  }

  return null
}
