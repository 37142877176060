import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconCalculator = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="19"
    height="19"
    viewBox="3 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_1988_5397)">
      <path
        d="M6 9.66667H18M14.5714 16.6667V14.3333M9.42857 16.6667H9.43714M12 16.6667H12.0086M12 14.3333H12.0086M9.42857 14.3333H9.43714M14.5714 12H14.58M12 12H12.0086M9.42857 12H9.43714M8.74286 19H15.2571C16.2172 19 16.6973 19 17.064 18.8304C17.3865 18.6813 17.6488 18.4433 17.8131 18.1507C18 17.8179 18 17.3823 18 16.5111V7.48889C18 6.6177 18 6.1821 17.8131 5.84935C17.6488 5.55665 17.3865 5.31868 17.064 5.16955C16.6973 5 16.2172 5 15.2571 5H8.74286C7.78277 5 7.30272 5 6.93602 5.16955C6.61345 5.31868 6.3512 5.55665 6.18685 5.84935C6 6.1821 6 6.61769 6 7.48889V16.5111C6 17.3823 6 17.8179 6.18685 18.1507C6.3512 18.4433 6.61345 18.6813 6.93602 18.8304C7.30272 19 7.78276 19 8.74286 19Z"
        stroke="#2C363F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
)
