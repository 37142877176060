import { FormStep } from 'components/form/form-step'
import {
  Control,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

type Props<T> = {
  step: number
  control: Control<T>
  watch: UseFormWatch<T>
  clearErrors: UseFormClearErrors<T>
  setValue: UseFormSetValue<T>
  formStepsMapping: {
    [key: number]: {
      component: (props: {
        control: Control<T>
        watch: UseFormWatch<T>
        clearErrors: UseFormClearErrors<T>
        setValue: UseFormSetValue<T>
      }) => JSX.Element | null
      title: string
    }
  }
}

export const CurrentStepFields = <T,>({
  step,
  control,
  watch,
  clearErrors,
  formStepsMapping,
  setValue,
}: Props<T>) => {
  const formStep = formStepsMapping[step]

  return (
    <FormStep
      title={formStep.title}
      control={control}
      watch={watch}
      setValue={setValue}
      clearErrors={clearErrors}
      Component={formStep.component}
    />
  )
}
