import { Box, Button, DialogContent, Divider } from '@material-ui/core'
import { Dialog } from 'components/ui/dialog'
import { useTranslation } from 'react-i18next'

type Props = {
  isOpen: boolean
  onClose: () => void
  parcelNumbers: string[]
}

export const ECheckPaymentDialog = ({
  isOpen,
  onClose,
  parcelNumbers,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      title={t('dialog_titles.parcel_number')}
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        {parcelNumbers.map(parcelNumber => (
          <Box
            key={parcelNumber}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Divider sx={{ my: 1, width: '70%' }} />
            <Box display={'flex'} alignItems={'center'} gap={3}>
              <Box>{parcelNumber}</Box>
              <Button
                onClick={() =>
                  // eslint-disable-next-line security/detect-non-literal-fs-filename
                  window.open(
                    `echeckapp://sens?action=openOrder&orderNumber=${parcelNumber}`,
                    '_blank',
                  )
                }
              >
                {t('buttons.pay_echeck')}
              </Button>
            </Box>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  )
}
