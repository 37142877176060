export const IconUnpack = () => (
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50108 7.38458C8.25647 7.38458 8.89805 7.12019 9.42583 6.59142C9.95361 6.06263 10.2175 5.42053 10.2175 4.66513C10.2175 3.90974 9.95311 3.26816 9.42433 2.74038C8.89554 2.21261 8.25345 1.94872 7.49805 1.94872C6.74266 1.94872 6.10108 2.21311 5.5733 2.74188C5.04552 3.27067 4.78163 3.91277 4.78163 4.66817C4.78163 5.42356 5.04602 6.06514 5.5748 6.59292C6.10359 7.12069 6.74568 7.38458 7.50108 7.38458ZM7.49957 6.46665C6.99957 6.46665 6.57457 6.29165 6.22457 5.94165C5.87457 5.59165 5.69957 5.16665 5.69957 4.66665C5.69957 4.16665 5.87457 3.74165 6.22457 3.39165C6.57457 3.04165 6.99957 2.86665 7.49957 2.86665C7.99957 2.86665 8.42457 3.04165 8.77457 3.39165C9.12457 3.74165 9.29957 4.16665 9.29957 4.66665C9.29957 5.16665 9.12457 5.59165 8.77457 5.94165C8.42457 6.29165 7.99957 6.46665 7.49957 6.46665ZM7.49957 9.3333C6.09615 9.3333 4.81261 8.95894 3.64895 8.21022C2.48527 7.46151 1.55643 6.47647 0.862415 5.2551C0.80686 5.15938 0.76626 5.06291 0.740615 4.96568C0.714971 4.86845 0.702148 4.76866 0.702148 4.66632C0.702148 4.56397 0.714971 4.4643 0.740615 4.3673C0.76626 4.27029 0.80686 4.17392 0.862415 4.0782C1.55643 2.85683 2.48527 1.87179 3.64895 1.12308C4.81261 0.374361 6.09615 0 7.49957 0C8.90298 0 10.1865 0.374361 11.3502 1.12308C12.5139 1.87179 13.4427 2.85683 14.1367 4.0782C14.1923 4.17392 14.2329 4.27039 14.2585 4.36762C14.2842 4.46485 14.297 4.56464 14.297 4.66698C14.297 4.76933 14.2842 4.869 14.2585 4.966C14.2329 5.06301 14.1923 5.15938 14.1367 5.2551C13.4427 6.47647 12.5139 7.46151 11.3502 8.21022C10.1865 8.95894 8.90298 9.3333 7.49957 9.3333ZM7.49957 8.33332C8.75512 8.33332 9.9079 8.00276 10.9579 7.34165C12.0079 6.68054 12.8107 5.78887 13.3662 4.66665C12.8107 3.54443 12.0079 2.65276 10.9579 1.99165C9.9079 1.33054 8.75512 0.999984 7.49957 0.999984C6.24401 0.999984 5.09123 1.33054 4.04123 1.99165C2.99123 2.65276 2.18845 3.54443 1.6329 4.66665C2.18845 5.78887 2.99123 6.68054 4.04123 7.34165C5.09123 8.00276 6.24401 8.33332 7.49957 8.33332Z"
      fill="#0061AF"
    />
  </svg>
)
