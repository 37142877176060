export const IconTerminalsWhite = () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9496 0H2.05041C0.919831 0 0 0.927293 0 2.06715V5.61782C0 6.75768 0.919831 7.68497 2.05041 7.68497H2.71733V13.8794C2.71733 15.0487 3.66091 16 4.82074 16H16.1793C17.3391 16 18.2827 15.0487 18.2827 13.8794V7.68497H18.9496C20.0802 7.68497 21 6.75768 21 5.61782V2.06715C21 0.927293 20.0803 0 18.9496 0ZM4.15622 13.8794V4.32608H10.8444V14.5494H4.82079C4.45436 14.5494 4.15622 14.2488 4.15622 13.8794ZM14.9179 4.32608V14.5494H11.8037V4.32608H14.9179ZM16.1794 14.5494H15.8771V4.32608H16.8439V13.8794C16.8439 14.2488 16.5457 14.5494 16.1794 14.5494ZM19.5612 5.61782C19.5612 5.95775 19.2868 6.23434 18.9496 6.23434H17.7904V4.32608H18.5578C18.8226 4.32608 19.0374 4.1096 19.0374 3.84253C19.0374 3.57547 18.8226 3.35899 18.5578 3.35899H17.3107H3.6893H2.44227C2.17737 3.35899 1.96264 3.57547 1.96264 3.84253C1.96264 4.1096 2.17737 4.32608 2.44227 4.32608H3.20967V6.23434H2.05041C1.71323 6.23434 1.43889 5.95775 1.43889 5.61782V2.06715C1.43889 1.72717 1.71323 1.45063 2.05041 1.45063H18.9496C19.2868 1.45063 19.5612 1.72717 19.5612 2.06715V5.61782Z"
      fill="white"
    />
  </svg>
)
