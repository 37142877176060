export const IconClientParcelIssuanceLarge = () => (
  <svg
    width="52"
    height="54"
    viewBox="0 0 52 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M26 53.4484C39.933 53.4484 51.2279 46.8789 51.2279 38.775C51.2279 30.6711 39.933 24.1016 26 24.1016C12.0669 24.1016 0.771973 30.6711 0.771973 38.775C0.771973 46.8789 12.0669 53.4484 26 53.4484Z"
      fill="#0061AF"
    />
    <circle
      cx="23.1131"
      cy="17.3769"
      r="16.3412"
      transform="rotate(179.88 23.1131 17.3769)"
      fill="white"
      stroke="#0065AF"
      strokeWidth="2"
    />
    <path
      d="M23.1274 24.207L23.0987 10.5443"
      stroke="#0065AF"
      strokeWidth="2"
    />
    <path
      d="M29.9419 15.9941L23.0971 9.63253L16.2791 16.0228"
      stroke="#0065AF"
      strokeWidth="2"
    />
    <path
      d="M37.5688 42.2185V31.8132C37.5688 31.3876 37.8254 31.0071 38.2227 30.8418L46.9697 27.2415C46.9848 27.2365 46.9998 27.2465 46.9998 27.2616V38.4931V38.4981L38.0568 42.534C37.8304 42.6391 37.5688 42.4689 37.5688 42.2185Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M36.6618 30.6008L27.8547 27.0159C27.8395 27.0109 27.8395 26.991 27.8547 26.9811L37.3349 23.1525C37.4615 23.1028 37.5981 23.1028 37.7196 23.1525L46.9317 26.9214C47.0228 26.9562 47.0228 27.0855 46.9317 27.1203L38.3979 30.6008C37.8411 30.8395 37.2135 30.8345 36.6618 30.6008Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0282 26.9551C27.9382 26.9194 27.8433 26.9857 27.8433 27.0826L28.0182 38.2333C28.0182 38.5241 28.193 38.7842 28.4579 38.8913L37.5527 42.564C37.5627 42.5691 37.5726 42.5589 37.5676 42.5538L37.5427 31.4439C37.5427 31.2042 37.4027 30.9951 37.1879 30.8981L28.0282 26.9551Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M41.9893 29.2398V33.7246L44.6417 32.5325V28.125L41.9893 29.2398Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M41.8722 29.3031L39.6318 28.1716L42.3821 26.9453L44.642 28.0874L41.8722 29.3031Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M32.7058 29.0096L42.2842 24.9628L37.8917 23.1946C37.6637 23.0905 37.4053 23.0905 37.1773 23.1846L28.1947 27.0184C28.1187 27.0531 28.1187 27.1621 28.1947 27.1918L32.7058 29.0096Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
  </svg>
)
