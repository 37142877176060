import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useAuthContext } from 'app/auth'
import { Parcel } from 'app/codecs/parcel'
import { TProfile } from 'app/codecs/user'
import { isAdminRole, isSuperAdminRole } from 'app/codecs/user'
import { IconNoUnpack } from 'assets/icons/no-unpack'
import { IconUnpack } from 'assets/icons/unpack'
import { DualItemType } from 'components/ui/dual-items'
import { useNotificationContext } from 'hooks/use-notification'
import { Array as ioArray, string, type } from 'io-ts'
import { getOrDefault } from 'lib/nullable'
import { useQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useEffect, useState } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormMode,
  ParcelIdentificationFieldValues,
  Sticker,
} from './parcel-actions-form-dialog'
import { StickerFields } from './sticker-fields'
import {
  isParcelIssuanceMode,
  isParcelReceptionMode,
  isParcelReturnMode,
  isUnpackOption,
} from './utils'

type Props = {
  mode: FormMode
  parcel: Parcel
  control: Control<ParcelIdentificationFieldValues>
  stickerFields: Array<Sticker>
  isSeveralParcels: boolean
  setValue: UseFormSetValue<ParcelIdentificationFieldValues>
  renderParcelDetails: (parcel: Parcel) => Array<DualItemType>
  onRefusalParcel: (parcelId: string) => void
  readyToReception?: (val: boolean) => void
  children?: React.ReactNode
}

export const ParcelDetails = ({
  mode,
  control,
  stickerFields,
  parcel,
  isSeveralParcels,
  setValue,
  onRefusalParcel,
  readyToReception,
  children,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { auth } = useAuthContext()
  const [printStickerClick, setPrintStickerClick] = useState(false)
  const [hasPrinter, setHasPrinter] = useState(false)

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { showSuccessNotification } = useNotificationContext()

  // eslint-disable-next-line
  const $getStickerFile = useMutation('POST', '/api/pudo/print/sticker100/')

  const $getPrinterOnBranch = useMutation(
    'GET',
    `/api/pudo/branches/:branchId/printer`,
    type({
      printer: ioArray,
    }),
  )

  /* eslint-disable */
  useEffect(() => {
    $getPrinterOnBranch.mutate(
      { params: { branchId: auth.branch ? auth.branch.branchId : '' } },
      {
        onSuccess: data => {
          setHasPrinter(data?.printer ? !!data.printer[0] : false)
        },
      },
    )
  }, [])
  /* eslint-enable */

  const handleStickerPrint = async () => {
    setPrintStickerClick(true)
    const token = auth?.type === 'authenticated' ? auth.accessToken : null

    const headers = new Headers({
      'Screen-Width': `${screen.width}`,
      'Screen-Height': `${screen.height}`,
    })

    headers.set('Content-Type', 'application/json')

    headers.set('Authorization', `Bearer ${token}`)

    try {
      const response = await window.fetch('/api/pudo/print/sticker100/', {
        method: 'POST',
        body: JSON.stringify({ parcelIds: [parcel.parcelId] }),
        headers,
      })

      if (response.ok) {
        try {
          if (response) {
            let reader = response.body?.getReader()
            // eslint-disable-next-line
            let buffer = new Array()
            reader
              ?.read()
              .then(function readFile({ value, done }): void | Promise<void> {
                if (done) {
                  if (buffer.length > 0) {
                    let blob = new Blob(buffer, {
                      type: 'application/pdf',
                    })
                    let blobURL = URL.createObjectURL(blob)
                    let iframe = document.createElement('iframe')
                    document.body.append(iframe)

                    iframe.style.display = 'none'
                    iframe.src = blobURL
                    iframe.addEventListener('load', function () {
                      setTimeout(function () {
                        iframe.focus()
                        iframe.contentWindow?.print()
                      }, 1)
                    })
                  }

                  return
                }

                buffer.push(value)

                return reader?.read().then(readFile)
              })
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const handleApproveSticker = () => {
    if (readyToReception) {
      readyToReception(false)
    }
  }

  const $user = useQuery('GET', '/api/pudo/users/me', TProfile)
  const user = $user.data

  const valueOfParcel =
    getOrDefault(parcel.debtCost, 0) + getOrDefault(parcel.debtCOD, 0)

  const sum = `${(
    getOrDefault(parcel.debtCost, 0) + getOrDefault(parcel.debtCOD, 0)
  ).toFixed(2)} грн`

  const $getPrice = useQuery(
    'GET',
    `/api/pudo/parcels/calculate/${auth.branch ? auth.branch.branchId : ''}/${
      parcel.parcelId
    }`,
    type({ costServices: string }),
  )

  const sumRecalc = $getPrice.data
    ? Number.parseFloat($getPrice.data.costServices)
    : 0

  return (
    <Box>
      <Box
        sx={{
          border: `solid 1px ${
            parcel.paid ? theme.palette.success.main : theme.palette.error.main
          }`,
          borderRadius: '5px',
        }}
      >
        <Box
          px={2}
          py={1}
          bgcolor={
            parcel.paid ? theme.palette.success.main : theme.palette.error.main
          }
          display={'flex'}
          flex={'row'}
          sx={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }}
        >
          <Box
            flex={1}
            onClick={() => {
              navigator.clipboard.writeText(parcel.parcelNumber.toString())
              showSuccessNotification(t('notifications.number_copied'))
            }}
          >
            <Typography
              variant="h3"
              sx={{
                flex: 1,
                textAlign: 'left',
                wordBreak: 'break-word',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              {parcel.parcelNumber}
            </Typography>
          </Box>
          <Typography
            variant={smDown ? 'body2' : 'body1'}
            fontWeight={500}
            sx={{ flex: 1, textAlign: 'center' }}
          >
            {parcel.paid ? (
              t(
                parcel.receiverPay && mode === 'parcel-reception'
                  ? 'identification.status_pay_receiver'
                  : 'identification.status_paid',
              )
            ) : (
              <Stack
                direction="row"
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                {t('identification.status_unpaid')}
                <Box ml={1}>
                  {parcel.status !== 'FOR_RETURNING' &&
                  sumRecalc &&
                  sumRecalc > valueOfParcel
                    ? sumRecalc.toFixed(2) + ' грн.'
                    : sum}
                </Box>
              </Stack>
            )}
          </Typography>
        </Box>
        <Box my={1} gap={1}>
          {parcel.status !== 'FOR_RETURNING' &&
          !parcel.paid &&
          sumRecalc &&
          sumRecalc > valueOfParcel ? (
            <Box
              borderRadius="5px"
              textAlign="center"
              lineHeight="16px"
              display="flex"
              justifyContent="center"
              bgcolor="#FEF0F1"
              color="#FE6975"
              py={1}
              px={2}
              mx={1}
              mb={1}
            >
              {t('identification.sum_changed')}
            </Box>
          ) : (
            ''
          )}
          <Box px={3}>
            <Typography variant="body1" fontWeight={400}>
              {parcel.receiverName}
            </Typography>
            <Typography variant="body1" fontWeight={400}>
              {parcel.receiverPhone}
            </Typography>
          </Box>
          <Divider
            sx={{
              mx: 2.5,
              my: 1,
              borderColor: parcel.paid
                ? theme.palette.success.main
                : theme.palette.error.main,
            }}
          />
          {parcel.alternateReceiver && (
            <>
              <Box px={3}>
                <Typography variant="body1" fontWeight={'bold'}>
                  {t('parcel.alternative_receiver')}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {parcel.alternateReceiver?.name}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {parcel.alternateReceiver?.phone}
                </Typography>
              </Box>
              <Divider
                sx={{
                  mx: 2.5,
                  my: 1,
                  borderColor: parcel.paid
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                }}
              />
            </>
          )}

          <Box px={3} display="flex" flexDirection="column" gap={1}>
            <Box display="flex" flexDirection="row" gap={3}>
              <Box
                flex={smDown ? 0.5 : 0.45}
                display="flex"
                gap={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2" fontWeight={400} color={'#8792A5'}>
                  {t('parcel.place_quantity_short')}
                </Typography>
                <Box py={1} px={2.5} bgcolor="#F5F6F9" borderRadius="5px">
                  <Typography variant="body2">{parcel.quantity}</Typography>
                </Box>
              </Box>
              <Box
                flex={smDown ? 0.5 : 0.35}
                display="flex"
                flexDirection="row"
                gap={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2" fontWeight={400} color={'#8792A5'}>
                  {t('parcel.weight_kg')}
                </Typography>
                <Box py={1} px={2.5} bgcolor="#F5F6F9" borderRadius="5px">
                  <Typography variant="body2">{parcel.weight}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap={3}
              justifyContent={isParcelReceptionMode(mode) ? 'center' : ''}
            >
              {!isParcelReceptionMode(mode) && (
                <Box
                  flex={smDown ? 0.5 : 0.45}
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    color={'#8792A5'}
                  >
                    {t('parcel.cell')}
                  </Typography>
                  <Box py={1} px={2.5} bgcolor="#F5F6F9" borderRadius="5px">
                    <Typography variant="body2">
                      {isParcelReceptionMode(mode)
                        ? parcel.senderPhone?.slice(-1)
                        : parcel.receiverPhone.slice(-1)}
                    </Typography>
                  </Box>
                </Box>
              )}
              {!isParcelReceptionMode(mode) && !isParcelReturnMode(mode) && (
                <Box
                  flex={smDown ? 0.5 : 0.35}
                  display="flex"
                  flexDirection="row"
                  gap={0.5}
                  justifyContent="center"
                  alignContent="center"
                  textAlign="center"
                  alignItems="center"
                  borderRadius="16px"
                  height="30px"
                  bgcolor={isUnpackOption([parcel]) ? '#EEF8FE' : '#F5F6F9'}
                >
                  {isUnpackOption([parcel]) ? <IconUnpack /> : <IconNoUnpack />}
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    color={
                      isUnpackOption([parcel])
                        ? theme.palette.primary.main
                        : '#455665'
                    }
                  >
                    {isUnpackOption([parcel])
                      ? t('parcel.unbox')
                      : t('parcel.no_unbox')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {isParcelReceptionMode(mode) &&
          !(
            user?.printer &&
            !(isAdminRole(auth.role) || isSuperAdminRole(auth.role))
          ) &&
          !(
            (isAdminRole(auth.role) || isSuperAdminRole(auth.role)) &&
            hasPrinter
          ) && (
            <Box mx={1} mb={1}>
              {stickerFields.map((field, index) => (
                <StickerFields
                  key={field.id}
                  id={field.id}
                  parcelId={parcel.parcelId}
                  setValue={setValue}
                  control={control}
                  index={index}
                />
              ))}
            </Box>
          )}
        {isParcelReceptionMode(mode) &&
          ((user?.printer &&
            !(isAdminRole(auth.role) || isSuperAdminRole(auth.role))) ||
            ((isAdminRole(auth.role) || isSuperAdminRole(auth.role)) &&
              hasPrinter)) && (
            <Box mx={1} mb={1}>
              <Button fullWidth size="small" onClick={handleStickerPrint}>
                {t('buttons.print_sticker')}
              </Button>
              <div
                style={{
                  marginTop: '0.5rem',
                  marginBottom: '0.5rem',
                  color: '#7C8084',
                  fontSize: '14px',
                  textAlign: 'center',
                }}
              >
                {t('ui.is_sticker_printed')}
              </div>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                width={1}
                spacing={2}
              >
                <Button
                  fullWidth
                  variant="text"
                  color="primary"
                  disabled={!printStickerClick}
                  onClick={handleApproveSticker}
                  style={{ border: '1px solid #0061AF' }}
                >
                  {t('dialog_confirm.yes')}
                </Button>
              </Stack>
            </Box>
          )}
        {isParcelIssuanceMode(mode) && (
          <Box mb={1} mx={1} display="flex" flexDirection="column" gap={1}>
            {children}
            {isSeveralParcels && (
              <Button
                variant="contained"
                fullWidth
                size="medium"
                onClick={() => onRefusalParcel(parcel.parcelId)}
              >
                {t('buttons.rejection')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
