import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconBack = (props: SvgIconProps & { strokecolor: string }) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      d="M14 18L8 12L14 6"
      stroke={props.strokecolor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
