import {
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { TAction } from 'app/codecs/document/document'
import { ControlledTextField } from 'components/form/controlled-text-field'
import { DialogFormWrapper } from 'components/form/dialog-form-wrapper'
import { FormField } from 'components/form/form-field'
import { parcelNumber } from 'components/form/validations'
import { Dialog } from 'components/ui/dialog'
import { useBoolean } from 'hooks/use-boolean'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

export type FormValues = {
  parcelId: string
}

export type TableValues = {
  action: {
    createdAtTime: string
    createdAtDate: string
    action: string
    branch?: string
  }[]
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const ParcelActionsDialog = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation()

  const form = useForm<FormValues>()

  const [title, setTitle] = useState<string>('')
  const [tableValues, setTableValues] = useState<TableValues | null>(null)
  const showInfo = useBoolean(false)

  const $findParcel = useMutation(
    'GET',
    `/api/pudo/parcels/action/:parcelNumber`,
    TAction,
  )

  const { handleSubmit, control, setValue } = useForm<FormValues>()

  const onSubmit = (data: FormValues) => {
    const parcelNumber = data.parcelId
    $findParcel.mutate(
      { params: { parcelNumber } },
      {
        onSuccess: action => {
          showInfo.setTrue()
          setTitle(parcelNumber)
          setTableValues(action)
        },
        onError: () => {
          setTitle('')
          setTableValues(null)
          showInfo.setFalse()
        },
      },
    )
  }

  const onHandleClose = () => {
    showInfo.setFalse()
    setTitle('')
    setValue('parcelId', '')
    setTableValues(null)
    onClose()
  }

  return (
    <Dialog
      title={t('parcel_actions_view_dialog.dialog_title')}
      open={isOpen}
      onClose={onHandleClose}
      maxWidth="md"
    >
      <DialogContent>
        <DialogFormWrapper
          form={form}
          isLastStep={true}
          isLoading={$findParcel.isLoading}
          isZeroStep={false}
          onSubmit={handleSubmit(onSubmit)}
          submitButtonLabel={t('buttons.track_parcel')}
        >
          <FormField>
            <ControlledTextField
              name="parcelId"
              control={control}
              required
              label={t('parcel_actions_view_dialog.search_help_text')}
              type="text"
              pattern={{
                value: parcelNumber.pattern.value,
                message: t(parcelNumber.pattern.message),
              }}
            />
          </FormField>
        </DialogFormWrapper>

        {showInfo.isTrue && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {t('parcel_actions_view_dialog.table_title') + ' ' + title}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {t('parcel_actions_view_dialog.date')}
                </TableCell>
                <TableCell align="center">
                  {t('parcel_actions_view_dialog.time')}
                </TableCell>
                <TableCell align="center">
                  {t('parcel_actions_view_dialog.action')}
                </TableCell>
                <TableCell align="center">
                  {t('parcel_actions_view_dialog.branch')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableValues !== null &&
                tableValues.action.map(value => (
                  <TableRow
                    key={uuidV4()}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{value.createdAtDate}</TableCell>
                    <TableCell align="center">{value.createdAtTime}</TableCell>
                    <TableCell align="center">{value.action}</TableCell>
                    <TableCell align="center">{value.branch || ''}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
    </Dialog>
  )
}
