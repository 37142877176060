import * as t from 'io-ts'
import { optionFromNullable } from 'io-ts-types'

export const TCity = t.type({
  cityId: t.string,
  nameRU: t.union([t.string, t.undefined]),
  typeUA: t.union([t.string, t.undefined]),
  nameUA: t.string,
  region: t.union([t.string, t.undefined]),
  district: t.union([t.string, t.undefined]),
})

export type City = t.TypeOf<typeof TCity>

export const TStreet = t.type({
  streetId: t.string,
  nameUA: t.string,
  nameRU: t.string,
  typeUA: optionFromNullable(t.string),
  typeRU: t.string,
})
export type Street = t.TypeOf<typeof TStreet>
