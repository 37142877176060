import { Branch, TBranch } from 'app/codecs/branch'
import { foldUserRole } from 'app/codecs/user'
import { env } from 'app/env'
import constate from 'constate'
import { isRight } from 'fp-ts/Either'
import { getOrDefault } from 'lib/nullable'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useCallback, useEffect, useRef, useState } from 'react'

import { TRefreshResult } from './codecs/auth'
import { UserRole } from './codecs/user'

export const useLogoutAcrossTabs = (callback: () => void) => {
  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout') {
        callback()
      }
    }
    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
    }
  }, [callback])

  return useCallback(() => {
    callback()
    localStorage.setItem('logout', Date.now().toString())
  }, [callback])
}

type AuthState =
  | {
      type: 'initial'
      role: null
      branch: null
      fullName: null
      printer: false
    }
  | {
      type: 'authenticated'
      accessToken: string
      fullName: string | null
      scopes: Array<string>
      role: UserRole
      branch?: Branch
      phone: string
      userId: string
      printer?: boolean
    }
  | {
      type: 'unauthenticated'
      role: null
      branch: null
      fullName: null
      printer: false
    }

const useAuth = () => {
  const [auth, setAuth] = useState<AuthState>({
    type: 'initial',
    role: null,
    branch: null,
    fullName: null,
    printer: false,
  })

  const $refresh = useMutation(
    'POST',
    '/api/pudo/tokens/refresh',
    TRefreshResult,
  )

  const timeoutRef = useRef<number | null>(null)

  const setAuthData = useCallback(
    (
      accessToken: string,
      expiresInSeconds: number,
      scopes: Array<string>,
      role: UserRole,
      fullName: string | null,
      userId: string,
      phone: string,
      branch?: Branch | null,
      printer?: boolean,
    ) => {
      setAuth({
        type: 'authenticated',
        fullName,
        accessToken,
        scopes,
        role,
        branch: branch ? branch : undefined,
        userId,
        phone,
        printer,
      })
      timeoutRef.current = window.setTimeout(() => {
        timeoutRef.current = null
        refresh()
      }, (expiresInSeconds - 60) * 1000)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const removeToken = useLogoutAcrossTabs(
    useCallback(() => {
      setAuth({
        type: 'unauthenticated',
        role: null,
        branch: null,
        fullName: null,
        printer: false,
      })
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current)
      }
      localStorage.removeItem('branch')
    }, []),
  )

  const mutateRefresh = $refresh.mutate
  const resetRefresh = $refresh.reset

  const getStorageBranch = useCallback(() => {
    const storageBranch = localStorage.getItem('branch')

    if (storageBranch) {
      const branch = JSON.parse(storageBranch)

      const decodeBranch = TBranch.decode(branch)

      if (isRight(decodeBranch)) {
        return decodeBranch.right
      }
    }
  }, [])

  const refresh = useCallback(() => {
    mutateRefresh(
      {
        body: {
          clientId: env.REACT_APP_APPLICATION_ID,
        },
      },
      {
        onSuccess: data => {
          const { user, accessToken, expires } = data
          const storageBranch = getStorageBranch()
          resetRefresh()

          const branch = foldUserRole({
            onOperator: () => user.branchResponse,
            onContentManager: () => user.branchResponse,
            onAdmin: () => storageBranch,
            onSuperAdmin: () => storageBranch,
          })(user.role.role)

          setAuthData(
            accessToken,
            expires,
            user.role.scopes,
            user.role.role,
            getOrDefault(user.fullName, null),
            user.userId,
            user.phone,
            branch,
            user.printer,
          )
        },
        onError: () => {
          removeToken()
          localStorage.removeItem('branch')
        },
      },
    )
  }, [mutateRefresh, getStorageBranch, resetRefresh, setAuthData, removeToken])

  const setBranch = (branch?: Branch) => {
    if (branch) {
      localStorage.setItem('branch', JSON.stringify(branch))
    }

    setAuth(prevState => {
      if (prevState.type === 'authenticated') {
        return { ...prevState, branch }
      }
      return prevState
    })
  }

  useEffect(() => {
    refresh()
  }, [refresh])

  return {
    auth,
    refresh,
    setBranch,
    setAuthData,
    removeToken,
  } as const
}

export const [AuthProvider, useAuthContext] = constate(useAuth)
