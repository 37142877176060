import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconMenuClose = (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      d="M8 14L12 10L16 14"
      stroke="#2C363F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
