import { Alert, Box } from '@material-ui/core'
import { Divider } from '@material-ui/core'
import { useAuthContext } from 'app/auth'
import { Parcel, TCheckName } from 'app/codecs/parcel'
import { IconErrorAlert } from 'assets/icons'
import { ControlledTextField } from 'components/form/controlled-text-field'
import { DialogFormWrapper } from 'components/form/dialog-form-wrapper'
import { FormField } from 'components/form/form-field'
import { nameStartWithCapitalUa } from 'components/form/validations'
import { Dialog } from 'components/ui/dialog'
import { useNotificationContext } from 'hooks/use-notification'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormMode } from './parcel-actions-form-dialog'
import { isParcelReceptionMode } from './utils'

export type FormValues = {
  firstName: string
  secondName: string
  patronymic: string
}

type Props = {
  mode: FormMode
  checkPayNameParcels: Array<Parcel>
  isOpen: boolean
  closeDialog: () => void
  onClose: () => void
}

export const CheckPaymentNameDialog = ({
  mode,
  checkPayNameParcels,
  isOpen,
  closeDialog,
  onClose,
}: Props) => {
  const { t } = useTranslation()
  const form = useForm<FormValues>()
  const { auth } = useAuthContext()

  const { handleSubmit, control, setValue, watch } = form

  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext()

  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(false)
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
  const [payerPhone, setPayerPhone] = useState('')
  const [submitBtnLabel, setSubmitBtnLabel] =
    useState<string>('buttons.save_name')

  const formFirstName = watch('firstName')
  const formSecondName = watch('secondName')
  const formPatronymic = watch('patronymic')

  const branchId = auth.branch?.branchId || ''

  const $checkName = useMutation('POST', '/api/pudo/payer/check', TCheckName)

  const $savePayName = useMutation('POST', '/api/pudo/payer/save')
  const $logChangeName = useMutation('POST', '/api/pudo/payer/log')

  /* eslint-disable */
  useEffect(() => {
    if (checkPayNameParcels.length > 0 && checkPayNameParcels !== null) {
      setPayerPhone(
        (isParcelReceptionMode(mode)
          ? checkPayNameParcels[0].senderPhone
          : checkPayNameParcels[0].receiverPhone) || '',
      )

      $checkName.mutate(
        {
          body: {
            phoneNumber: isParcelReceptionMode(mode)
              ? checkPayNameParcels[0].senderPhone
              : checkPayNameParcels[0].receiverPhone,
            international: checkPayNameParcels[0].international,
          },
        },
        {
          onSuccess: data => {
            if(!data) setIsDialogOpened(true)

            setValue('firstName', data.firstName)
            setValue('secondName', data.surname)
            setValue('patronymic', data.patronymic)
            setIsFieldsDisabled(true)
            setSubmitBtnLabel('buttons.correct_name')
          },
          onError: () => {
            setIsDialogOpened(true)
            showErrorNotification(t('notifications.error_no_payer_data'))
          }
        },
      )
    }
  }, [])
  /* eslint-enable */

  const onSubmit = () => {
    if (!isFieldsDisabled) {
      const bodyParams = {
        phoneNumber: payerPhone,
        firstName: formFirstName.replace(/\s+/g, ''),
        secondName: formSecondName.replace(/\s+/g, ''),
        patronymic: formPatronymic.replace(/\s+/g, ''),
      }
      $savePayName.mutate(
        {
          body: bodyParams,
        },
        {
          onSuccess: () => {
            const logBodyParams = {
              ...bodyParams,
              branchId: branchId,
              logStatus: 'OK',
            }
            $logChangeName.mutate(
              {
                body: logBodyParams,
              },
              {},
            )

            showSuccessNotification(t('notifications.success_send_payer_name'))
            closeDialog()
          },
        },
      )
    } else {
      closeDialog()
    }
  }

  const onError = () => {
    if (
      formFirstName !== undefined &&
      formSecondName !== undefined &&
      formPatronymic !== undefined
    ) {
      const logBodyParams = {
        phoneNumber: payerPhone,
        firstName: formFirstName,
        secondName: formSecondName,
        patronymic: formPatronymic,
        branchId: branchId,
        logStatus: 'DATA_INVALID',
      }
      $logChangeName.mutate(
        {
          body: logBodyParams,
        },
        {},
      )
    }
  }

  if (!isDialogOpened) return <></>

  return (
    <Dialog
      title={
        isParcelReceptionMode(mode)
          ? t('dialog_titles.recept_parcel')
          : t('dialog_titles.issue_parcel')
      }
      open={isOpen}
      onClose={onClose}
      zIndex={3}
    >
      <DialogFormWrapper
        form={form}
        isLastStep={true}
        isLoading={false}
        isZeroStep={false}
        onSubmit={handleSubmit(onSubmit, onError)}
        submitButtonLabel={t(submitBtnLabel)}
      >
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent="space-between"
        >
          <Alert icon={<IconErrorAlert />} severity="error">
            {t('notifications.error_type_sender_name')}
          </Alert>

          <Divider sx={{ py: 1 }} />
          <FormField>
            <ControlledTextField
              name="secondName"
              control={control}
              disabled={isFieldsDisabled}
              required
              label={t('form_fields.last_name')}
              type="text"
              pattern={{
                value: nameStartWithCapitalUa.pattern.value,
                message: t(nameStartWithCapitalUa.pattern.message),
              }}
            />
          </FormField>
          <FormField>
            <ControlledTextField
              name="firstName"
              control={control}
              disabled={isFieldsDisabled}
              required
              label={t('form_fields.name')}
              type="text"
              pattern={{
                value: nameStartWithCapitalUa.pattern.value,
                message: t(nameStartWithCapitalUa.pattern.message),
              }}
            />
          </FormField>
          <FormField>
            <ControlledTextField
              name="patronymic"
              control={control}
              disabled={isFieldsDisabled}
              required
              label={t('form_fields.middle_name')}
              type="text"
              pattern={{
                value: nameStartWithCapitalUa.pattern.value,
                message: t(nameStartWithCapitalUa.pattern.message),
              }}
            />
          </FormField>
        </Box>
      </DialogFormWrapper>
    </Dialog>
  )
}
