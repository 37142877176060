import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconParcelReturn = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.94737 1.14903C7.14846 1.03665 7.36014 0.980469 7.5824 0.980469C7.80466 0.980469 8.01404 1.03665 8.21053 1.14903L14.5263 4.79408C14.7263 4.91244 14.8816 5.06845 14.9921 5.2621C15.1026 5.45576 15.1579 5.66496 15.1579 5.88971V9.19765C14.9662 9.05499 14.7639 8.93154 14.551 8.82729C14.338 8.72303 14.1193 8.62877 13.8947 8.54449V6.5218L10.7579 8.35486C9.92982 8.55151 9.18246 8.9097 8.51579 9.42941C7.84912 9.94913 7.32632 10.5812 6.94737 11.3257V9.82974L1.26316 6.54287V13.0534L6.84211 16.2771C6.98246 16.572 7.14737 16.8565 7.33684 17.1304C7.52632 17.4043 7.74035 17.6606 7.97895 17.8994C7.81053 17.9697 7.63509 17.9942 7.45263 17.9732C7.27018 17.9521 7.10175 17.8924 6.94737 17.7941L0.631579 14.149C0.431579 14.0307 0.276316 13.8747 0.165789 13.681C0.0552632 13.4873 0 13.2781 0 13.0534V5.88971C0 5.66496 0.0552632 5.45576 0.165789 5.2621C0.276316 5.06845 0.431579 4.91244 0.631579 4.79408L6.94737 1.14903ZM7.57895 2.24465L1.95789 5.48938L7.57895 8.75518L13.1789 5.48938L7.57895 2.24465Z"
      fill="#2C363F"
    />
    <path
      d="M9.60042 14.2165L12.0038 16.5727L12.442 16.1264L10.8043 14.5208L14.4071 14.4866L14.4011 13.8551L10.7983 13.8893L12.4052 12.2529L11.9586 11.815L9.60042 14.2165ZM7.9972 14.0212C7.9868 12.9265 8.36693 11.9825 9.13759 11.1892C9.90827 10.3958 10.8456 9.99393 11.9496 9.98345C13.0536 9.97296 14.0026 10.357 14.7967 11.1354C15.5907 11.9139 15.9929 12.8504 16.0033 13.9451C16.0137 15.0398 15.6303 15.9839 14.8531 16.7772C14.0758 17.5706 13.1305 17.9726 12.0172 17.9832C10.9155 17.9936 9.97268 17.6096 9.18873 16.831C8.40477 16.0525 8.00759 15.1159 7.9972 14.0212Z"
      fill="#2C363F"
    />
  </SvgIcon>
)
