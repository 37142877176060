import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core'
import { IconClose } from 'assets/icons'
import { useTranslation } from 'react-i18next'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const ParcelsToSendBanner = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      title={t('dialog_titles.change_branch')}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={{ padding: '5px 5px 0px' }}>
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={onClose}>
              <IconClose />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        <Box>
          <Typography variant="h4" textAlign={'center'}>
            {t('parcels_to_send_banner.title')}
          </Typography>
        </Box>
        <Typography variant="body2">
          {t('parcels_to_send_banner.text1_1')}
          <span style={{ fontWeight: 'bold' }}>
            {t('parcels_to_send_banner.text1_bold')}
          </span>
          {t('parcels_to_send_banner.text1_2')}
          <Link
            href="https://www.youtube.com/watch?v=bQj1b6GCxec&t=3s"
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            {t('parcels_to_send_banner.text1_link_text')}
          </Link>
        </Typography>
        <Typography variant="body2">
          {t('parcels_to_send_banner.text2_1')}
          <span style={{ fontWeight: 'bold' }}>
            {t('parcels_to_send_banner.text2_bold')}
          </span>
          {t('parcels_to_send_banner.text2_2')}
          <Link
            href="https://www.youtube.com/watch?v=5zB5Sxzek7E"
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            {t('parcels_to_send_banner.text2_link_text')}
          </Link>
        </Typography>
        <Typography variant="body2">
          {t('parcels_to_send_banner.text3_1')}
          <Link
            href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTU4MjQ2N2ItMDA5OC00ZDU2LTkyZTEtOGM5MjkwZjEyYzdl%40thread.v2/0?context=%7b%22Tid%22%3a%2294a02df7-33fa-43c9-8cb7-d1c6f2bb1656%22%2c%22Oid%22%3a%22c92fa62c-9069-42f4-bcc4-d906684f3868%22%7d"
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            {t('parcels_to_send_banner.text3_link_text')}
          </Link>
        </Typography>
        <Button fullWidth onClick={() => onClose()}>
          {t('buttons.is_viewed')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
