import { IconProps, SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconCourierParcelGive = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4926 17.6566L13.4751 17.6744L13.4929 17.6919L16.3184 20.462L16.3362 20.4795L16.3537 20.4616L16.8689 19.937L16.8865 19.9191L16.8686 19.9016L14.9864 18.0563L19.1615 18.0167L19.1865 18.0165L19.1863 17.9915L19.1792 17.249L19.179 17.224L19.154 17.2242L14.9789 17.2639L16.8257 15.3832L16.8432 15.3654L16.8253 15.3478L16.3003 14.8331L16.2824 14.8156L16.2649 14.8334L13.4926 17.6566ZM11.6507 17.4443C11.6385 16.164 12.0828 15.0605 12.9843 14.1325C13.8856 13.2047 14.9812 12.7349 16.2724 12.7227C17.5637 12.7104 18.673 13.1592 19.6018 14.0698C20.5306 14.9804 21.0007 16.0752 21.0128 17.3553C21.025 18.6355 20.5769 19.739 19.6677 20.6671C18.7586 21.595 17.6536 22.0649 16.3514 22.0773C15.0629 22.0895 13.961 21.6407 13.0441 20.7301C12.1271 19.8194 11.6628 18.7245 11.6507 17.4443Z"
      fill={(props.sx as IconProps).color}
      stroke="#2C363F"
      strokeWidth="0.05"
    />
    <path
      d="M6.4043 4.13892C6.4043 2.33757 7.48511 0.896484 10.3673 0.896484C13.2494 0.896484 14.3303 2.33757 14.3303 4.13892"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6904 5.94043L14.4599 6.63215C14.3914 6.8339 14.1644 6.93478 13.9699 6.84831C13.2998 6.54208 11.7939 5.94043 10.3672 5.94043C8.74958 5.94043 7.39136 6.50966 6.76809 6.82309C6.57354 6.92037 6.33576 6.82309 6.26731 6.61414L6.04395 5.94043"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20605 4.49943C8.20605 4.49943 9.64714 4.13916 10.3677 4.13916C11.0882 4.13916 12.5293 4.49943 12.5293 4.49943"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4478 11.9283C12.8996 11.5284 13.6742 10.2963 13.9696 8.82275"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.76465 8.82275C7.06007 10.2963 7.83465 11.5284 9.29015 11.9283"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20549 11.7046C8.20549 11.7046 8.29555 12.9259 7.84521 13.8662"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3671 15.3078C9.24307 15.3078 8.20549 14.5872 7.84521 13.8667"
      stroke="#2C363F"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M7.84514 13.8667L4.43338 14.5836C2.43388 15.0052 1 16.7705 1 18.8132V21.4288"
      stroke="#2C363F"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
