import { ControlledPhoneTextField } from 'components/form/controlled-phone-text-field'
import { ControlledTextField } from 'components/form/controlled-text-field'
import { FormField } from 'components/form/form-field'
import { nameUaWithOptionalSymbol } from 'components/form/validations'
import { ParcelStepProps } from 'components/forms/create-parcel/steps/parcel-info-step'
import { useTranslation } from 'react-i18next'

export const SenderInfoStep = ({ control, clearErrors }: ParcelStepProps) => {
  const { t } = useTranslation()

  clearErrors()
  return (
    <>
      <FormField>
        <ControlledTextField
          name="sender.lastname"
          control={control}
          label={'form_fields.last_name'}
          type="text"
          required
          pattern={{
            value: nameUaWithOptionalSymbol.pattern.value,
            message: t(nameUaWithOptionalSymbol.pattern.message),
          }}
        />
      </FormField>
      <FormField>
        <ControlledTextField
          name="sender.firstname"
          control={control}
          label={'form_fields.first_name'}
          type="text"
          required
          pattern={{
            value: nameUaWithOptionalSymbol.pattern.value,
            message: t(nameUaWithOptionalSymbol.pattern.message),
          }}
        />
      </FormField>
      <FormField>
        <ControlledTextField
          name="sender.middleName"
          control={control}
          label={'form_fields.middle_name'}
          type="text"
          required
          pattern={{
            value: nameUaWithOptionalSymbol.pattern.value,
            message: t(nameUaWithOptionalSymbol.pattern.message),
          }}
        />
      </FormField>

      <FormField>
        <ControlledPhoneTextField
          control={control}
          required
          name="sender.number"
          label={'form_fields.sender_phone'}
        />
      </FormField>
    </>
  )
}
