import { Link, LinkProps } from '@material-ui/core'
import { allPageRoutes } from 'app/pages'
import { createLinkPreload } from 'lib/lazy-routing'
import { forwardRef, ReactNode } from 'react'
import { LinkProps as RouterLinkProps } from 'react-router-dom'

export const LinkPrefetch = createLinkPreload(allPageRoutes)

type Props = LinkProps & {
  to: RouterLinkProps['to']
  children: ReactNode
}

export const InternalLink = forwardRef<HTMLAnchorElement, Props>(
  ({ to, ...rest }: Props, ref) => {
    return <Link ref={ref} component={LinkPrefetch} to={to} {...rest} />
  },
)
