export const IconClientParcelReceptionLarge = () => (
  <svg
    width="52"
    height="54"
    viewBox="0 0 52 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M26 53.4113C39.933 53.4113 51.2279 46.8418 51.2279 38.7379C51.2279 30.634 39.933 24.0645 26 24.0645C12.0669 24.0645 0.771973 30.634 0.771973 38.7379C0.771973 46.8418 12.0669 53.4113 26 53.4113Z"
      fill="#0061AF"
    />
    <circle
      cx="23.1132"
      cy="17.3412"
      r="16.3412"
      fill="white"
      stroke="#0065AF"
      strokeWidth="2"
    />
    <path d="M23.1133 10.5098V24.1726" stroke="#0065AF" strokeWidth="2" />
    <path
      d="M16.2817 18.709L23.1131 25.085L29.9445 18.709"
      stroke="#0065AF"
      strokeWidth="2"
    />
    <path
      d="M37.5684 42.2088V31.8035C37.5684 31.3779 37.8249 30.9973 38.2222 30.8321L46.9692 27.2318C46.9843 27.2268 46.9994 27.2368 46.9994 27.2518V38.4833V38.4883L38.0563 42.5242C37.8299 42.6294 37.5684 42.4591 37.5684 42.2088Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M36.6613 30.591L27.8542 27.0061C27.839 27.0011 27.839 26.9813 27.8542 26.9713L37.3345 23.1428C37.461 23.093 37.5977 23.093 37.7192 23.1428L46.9312 26.9116C47.0223 26.9465 47.0223 27.0757 46.9312 27.1105L38.3974 30.591C37.8406 30.8297 37.213 30.8247 36.6613 30.591Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0277 26.9453C27.9377 26.9096 27.8428 26.9759 27.8428 27.0729L28.0177 38.2235C28.0177 38.5143 28.1926 38.7744 28.4574 38.8816L37.5522 42.5543C37.5622 42.5594 37.5722 42.5492 37.5672 42.5441L37.5422 31.4342C37.5422 31.1944 37.4023 30.9853 37.1874 30.8884L28.0277 26.9453Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M41.9888 29.23V33.7149L44.6412 32.5227V28.1152L41.9888 29.23Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M41.8717 29.2933L39.6313 28.1618L42.3816 26.9355L44.6416 28.0776L41.8717 29.2933Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M32.7054 28.9999L42.2837 24.9531L37.8912 23.1848C37.6632 23.0807 37.4048 23.0807 37.1768 23.1749L28.1942 27.0087C28.1182 27.0433 28.1182 27.1523 28.1942 27.182L32.7054 28.9999Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
  </svg>
)
