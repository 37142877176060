export const IconWarningBigCircle = () => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="54.4118"
      cy="54.4123"
      rx="25.9695"
      ry="25.9695"
      fill="#FE6975"
      fillOpacity="0.1"
    />
    <ellipse
      cx="54.6174"
      cy="54.6184"
      rx="39.3664"
      ry="39.3664"
      fill="#FE6975"
      fillOpacity="0.09"
    />
    <circle cx="54" cy="54" r="54" fill="#FE6975" fillOpacity="0.04" />
    <ellipse
      cx="54.6184"
      cy="54.6184"
      rx="12.5725"
      ry="12.5725"
      fill="#FE6975"
    />
    <path
      d="M54.4115 46.5801C55.2571 46.5801 55.9541 47.1939 56.0493 47.9846L56.0604 48.17V56.1199C56.0604 56.998 55.3222 57.7098 54.4115 57.7098C53.566 57.7098 52.869 57.0961 52.7738 56.3053L52.7627 56.1199V48.17C52.7627 47.2919 53.5009 46.5801 54.4115 46.5801Z"
      fill="white"
    />
    <path
      d="M54.4198 58.5342C55.3259 58.5342 56.0604 59.2724 56.0604 60.183C56.0604 61.0286 55.427 61.7256 54.6111 61.8208L54.4033 61.8319C53.4972 61.8319 52.7627 61.0937 52.7627 60.183C52.7627 59.3374 53.3961 58.6405 54.212 58.5453L54.4198 58.5342Z"
      fill="white"
    />
  </svg>
)
