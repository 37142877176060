import { useAuthContext } from 'app/auth'
import {
  pageRoutesAdmin,
  pageRoutesOperator,
  pageRoutesUnauth,
} from 'app/pages'
import { routes } from 'app/routes'
import { AppLayout } from 'components/layout'
import { PageNotFound } from 'components/page-not-found'
import { createRoutes } from 'lib/lazy-routing'
import { Logout } from 'pages/logout'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useTitle } from 'react-use'

const Title = ({ title }: { title: string }) => {
  const { t } = useTranslation()
  let envTitle
  const host = window.location.host

  if (host.includes('-dev')) envTitle = 'POINT24 DEV'
  else if (host.includes('-stage')) envTitle = 'POINT24 STAGE'
  else envTitle = 'POINT24'

  const subTitle = t(`page_titles.${title}`)
  const fullTitle = subTitle === '' ? envTitle : `${subTitle} - ${envTitle}`

  useTitle(fullTitle)
  return null
}

const PageIndex = () => {
  const { auth } = useAuthContext()

  if (auth.type === 'initial') {
    return null
  }

  if (auth.type === 'unauthenticated') {
    return (
      <Suspense fallback={null}>
        <Switch>
          {createRoutes(pageRoutesUnauth, Title)}
          <Redirect to={routes.login} />
        </Switch>
      </Suspense>
    )
  }

  const appRoutes = auth.branch ? pageRoutesOperator : pageRoutesAdmin

  return (
    <AppLayout>
      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path="/"
            component={() => (
              <Redirect to={auth.branch ? routes.home : routes.users} />
            )}
          />
          <Route exact path={routes.logout} component={Logout} />
          {createRoutes(appRoutes, Title)}
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </AppLayout>
  )
}

export default PageIndex
