import { IconProps } from '@material-ui/core'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconNotification = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="23"
    height="19"
    viewBox="-3 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8308 7.29015V16.0502H2.90625V7.15783C2.90625 5.6096 3.62082 4.23339 4.7456 3.3468C5.38077 2.81749 6.14827 2.47344 6.99516 2.32788C7.25982 2.28818 7.52447 2.26172 7.80236 2.26172C8.10671 2.26172 8.39783 2.28818 8.68895 2.34112C9.52261 2.48667 10.2769 2.84396 10.912 3.3468C11.0708 3.45266 11.2164 3.58499 11.362 3.73055C12.275 4.64361 12.8308 5.90072 12.8308 7.29015Z"
      stroke={(props.sx as IconProps).color}
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
    <path d="M14.6826 16.7117V15.3884" stroke="#2C363F" strokeMiterlimit="10" />
    <path
      d="M13.4912 3.34668C14.2455 4.45823 14.6822 5.79473 14.6822 7.22387V10.5188"
      stroke={(props.sx as IconProps).color}
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
    <path
      d="M1 10.5188V7.10477C1 5.71534 1.42345 4.43176 2.13801 3.34668"
      stroke={(props.sx as IconProps).color}
      strokeMiterlimit="10"
      strokeWidth="1.5"
    />
    <path
      d="M7.36936 1.99697L7.35903 1.98526L7.348 1.97423C7.22373 1.84996 7.15039 1.68366 7.15039 1.50735C7.15039 1.1351 7.44517 0.836368 7.8005 0.816406H7.88248C8.24653 0.836184 8.53228 1.13108 8.53228 1.50735C8.53228 1.69265 8.46186 1.86035 8.33442 2.00044C8.19433 2.12788 8.02663 2.1983 7.84134 2.1983C7.64901 2.1983 7.4697 2.11069 7.36936 1.99697Z"
      fill="black"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.5"
    />
    <path
      d="M8.77491 17.6353C8.59583 17.9617 8.24628 18.1835 7.8412 18.1835C7.43893 18.1835 7.09349 17.9632 6.91745 17.6353H8.77491Z"
      fill="black"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.5"
    />
    <path
      d="M1.56641 16.2117V15.8884H14.249V16.2117H1.56641Z"
      fill="black"
      stroke={(props.sx as IconProps).color}
      strokeWidth="1.5"
    />
  </SvgIcon>
)
