import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconQRScanner = (
  props: SvgIconProps & { strokecolor: string },
) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path d="M15.8402 0.175293H13.5852V2.4451H15.8402V0.175293Z" fill="black" />
    <path
      d="M27.1104 0.175293H22.6028V6.98244H29.3654V0.175293H27.1104ZM27.1104 4.71263H24.8555V2.44283H27.1104V4.71263Z"
      fill="black"
    />
    <path
      d="M9.07528 6.98243H11.3302V0.175293H4.56763V6.98243H9.07528ZM6.82033 2.4451H9.07528V4.7149H6.82033V2.4451Z"
      fill="black"
    />
    <path
      d="M20.3478 4.7149V2.4451V0.175293H18.0928V2.4451H15.8379V4.7149H18.0928V6.98244H20.3478V4.7149Z"
      fill="black"
    />
    <path
      d="M15.8379 11.522H18.0929V13.7896H20.3478V11.522V9.25223H18.0929V6.98242H15.8379H13.5852V9.25223H15.8379V11.522Z"
      fill="black"
    />
    <path
      d="M27.1103 11.522H24.8553V9.2522H22.6004V11.522V13.7895H20.3477V16.0593H22.6004H24.8553V13.7895H27.1103V16.0593H29.363V13.7895V11.522V9.2522H27.1103V11.522Z"
      fill="black"
    />
    <path
      d="M15.8379 16.0593H18.0928V13.7895H15.8379V11.522H13.5852V13.7895H9.07528V11.522H13.5852V9.2522H6.82258V13.7895H4.56763V16.0593H13.5852V20.5967H15.8379V16.0593Z"
      fill="black"
    />
    <path
      d="M18.0928 18.3291V20.5967H20.3477V18.3291V16.0593H18.0928V18.3291Z"
      fill="black"
    />
    <path
      d="M6.82258 18.3292H4.56763V25.1364H11.3302V18.3292H6.82258ZM9.07754 22.8666H6.82258V20.5968H9.07754V22.8666Z"
      fill="black"
    />
    <path
      d="M24.8553 18.3292H22.6003V25.1364H29.363V18.3292H24.8553ZM27.1103 22.8666H24.8553V20.5968H27.1103V22.8666Z"
      fill="black"
    />
    <path
      d="M15.8379 22.8665H13.5852V25.134H15.8379H18.0929V22.8665V20.5967H15.8379V22.8665Z"
      fill="black"
    />
    <rect
      x="0.0617676"
      y="12.2532"
      width="33.8182"
      height="1.61039"
      rx="0.805195"
      fill="white"
    />
    <path
      d="M0.0617676 13.0584C0.0617676 12.6137 0.422266 12.2532 0.866962 12.2532H4.49034V13.8636H0.866962C0.422265 13.8636 0.0617676 13.5031 0.0617676 13.0584Z"
      fill="black"
    />
    <path
      d="M33.8763 13.0807C33.8742 13.5254 33.512 13.8842 33.0674 13.8822L29.444 13.8654L29.4515 12.255L33.0748 12.2718C33.5195 12.2738 33.8783 12.636 33.8763 13.0807Z"
      fill="black"
    />
  </SvgIcon>
)
