export const IconInventoryLarge = () => (
  <svg
    width="52"
    height="56"
    viewBox="0 0 52 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M26 55.5148C39.933 55.5148 51.2279 48.9453 51.2279 40.8414C51.2279 32.7375 39.933 26.168 26 26.168C12.0669 26.168 0.771973 32.7375 0.771973 40.8414C0.771973 48.9453 12.0669 55.5148 26 55.5148Z"
      fill="#0061AF"
    />
    <path
      d="M33.936 36.4501V22.3101C33.936 21.7317 34.2846 21.2145 34.8246 20.99L46.711 16.0975C46.7315 16.0907 46.752 16.1043 46.752 16.1247V31.3874V31.3942L34.5991 36.8787C34.2915 37.0216 33.936 36.7903 33.936 36.4501Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M32.7034 20.6625L20.7352 15.7909C20.7146 15.7842 20.7146 15.7571 20.7352 15.7436L33.6182 10.5409C33.7901 10.4733 33.9759 10.4733 34.141 10.5409L46.6594 15.6625C46.7832 15.7098 46.7832 15.8855 46.6594 15.9328L35.0626 20.6625C34.306 20.9868 33.4531 20.9801 32.7034 20.6625Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M20.971 15.7125C20.8488 15.664 20.7197 15.7541 20.7197 15.8858L20.9574 31.0387C20.9574 31.4338 21.1951 31.7874 21.555 31.9329L33.9141 36.9238C33.9276 36.9308 33.9412 36.9169 33.9344 36.91L33.9005 21.8125C33.9005 21.4867 33.7103 21.2025 33.4184 21.0708L20.971 15.7125Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M39.9438 18.8137V24.9083L43.5484 23.2882V17.2988L39.9438 18.8137Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M39.7838 18.9032L36.7393 17.3656L40.4766 15.6992L43.5477 17.2512L39.7838 18.9032Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M27.3274 18.5002L40.3436 13.001L34.3745 10.598C34.0647 10.4566 33.7136 10.4566 33.4038 10.5845L21.1971 15.7944C21.0938 15.8415 21.0938 15.9896 21.1971 16.0299L27.3274 18.5002Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M16.9883 43.2313V29.0913C16.9883 28.513 17.3369 27.9958 17.8768 27.7713L29.7633 22.8787C29.7838 22.8719 29.8043 22.8855 29.8043 22.906V38.1687V38.1755L17.6513 43.66C17.3437 43.8029 16.9883 43.5715 16.9883 43.2313Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.7556 27.4438L3.78745 22.5722C3.76681 22.5654 3.76681 22.5384 3.78745 22.5249L16.6704 17.3222C16.8424 17.2546 17.0281 17.2546 17.1932 17.3222L29.7116 22.4438C29.8354 22.4911 29.8354 22.6668 29.7116 22.714L18.1149 27.4438C17.3583 27.7681 16.5054 27.7613 15.7556 27.4438Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M4.02324 22.4938C3.901 22.4452 3.77197 22.5354 3.77197 22.6671L4.00964 37.82C4.00964 38.2151 4.2473 38.5686 4.60721 38.7142L16.9663 43.7051C16.9799 43.712 16.9934 43.6982 16.9867 43.6912L16.9527 28.5938C16.9527 28.268 16.7626 27.9838 16.4706 27.8521L4.02324 22.4938Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.9961 25.595V31.6896L26.6006 30.0695V24.0801L22.9961 25.595Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M22.836 25.6845L19.7915 24.1468L23.5289 22.4805L26.6 24.0324L22.836 25.6845Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M10.3796 25.2815L23.3959 19.7822L17.4268 17.3793C17.117 17.2379 16.7659 17.2379 16.456 17.3658L4.24931 22.5756C4.14606 22.6227 4.14606 22.7708 4.24931 22.8112L10.3796 25.2815Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M26.5581 23.1203V11.0723C26.5581 10.5795 26.8551 10.1388 27.3152 9.94753L37.443 5.77884C37.4605 5.77306 37.478 5.78466 37.478 5.80206V18.8066V18.8125L27.123 23.4855C26.861 23.6073 26.5581 23.4101 26.5581 23.1203Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M25.5075 9.66727L15.3101 5.51643C15.2925 5.51068 15.2925 5.48766 15.3101 5.47613L26.287 1.04318C26.4335 0.985606 26.5918 0.985606 26.7324 1.04318L37.3987 5.40706C37.5042 5.44736 37.5042 5.59703 37.3987 5.63733L27.5177 9.66727C26.8731 9.94363 26.1464 9.93788 25.5075 9.66727Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.511 5.4469C15.4068 5.40553 15.2969 5.48232 15.2969 5.59454L15.4994 18.5056C15.4994 18.8422 15.7019 19.1434 16.0085 19.2675L26.5391 23.52C26.5507 23.5259 26.5622 23.5141 26.5564 23.5082L26.5275 10.6444C26.5275 10.3668 26.3655 10.1246 26.1167 10.0124L15.511 5.4469Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M31.6768 8.09348V13.2864L34.748 11.906V6.80273L31.6768 8.09348Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M31.5399 8.16553L28.9458 6.85538L32.1302 5.43555L34.747 6.75788L31.5399 8.16553Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M20.9265 7.82492L32.017 3.13927L26.931 1.09184C26.6671 0.971375 26.3679 0.971375 26.1039 1.08034L15.7032 5.51938C15.6152 5.55953 15.6152 5.68571 15.7032 5.72011L20.9265 7.82492Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
  </svg>
)
