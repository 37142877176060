import { TBranch } from 'app/codecs/branch'
import * as t from 'io-ts'
import { DateFromISOString, optionFromNullable } from 'io-ts-types'

import { TUserRoleComplex, TUserStatus } from '../user'

export const TUserAuth = t.intersection([
  t.type({
    userId: t.string,
    phone: t.string,
    lastVisited: optionFromNullable(DateFromISOString),
    lastPasswordChange: optionFromNullable(DateFromISOString),
    fullName: optionFromNullable(t.string),
    email: optionFromNullable(t.string),
    status: TUserStatus,
    createdAt: DateFromISOString,
    role: TUserRoleComplex,
    printer: t.boolean,
    sale: t.union([t.boolean, t.undefined]),
  }),
  t.partial({
    branchResponse: TBranch,
    creatorPhoneNumber: t.string,
  }),
])

export const TLoginResult = t.type({
  accessToken: t.string,
  expires: t.number,
  user: TUserAuth,
})

export const TCheckPasswordExpiredResult = t.type({
  correctPassword: t.boolean,
  passwordExpired: t.boolean,
})

export const TRefreshResult = t.type({
  accessToken: t.string,
  expires: t.number,
  user: TUserAuth,
})

export const TAuthQr = t.type({
  auth: t.boolean,
})

export const TCallAuth = t.type({
  collId: t.string,
})

export const TCallIdentification = t.type({
  verificationId: t.string,
})
