import { IconProps } from '@material-ui/core'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconUser = (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      d="M4 19.3333C4 18.1847 4.99089 17.1001 6.80239 16.24C8.54412 15.4131 10.6477 15 12 15C13.3523 15 15.4559 15.4131 17.1976 16.24C19.0091 17.1001 20 18.1847 20 19.3333V21H4V19.3333Z"
      stroke={(props.sx as IconProps).color}
      strokeWidth="2"
    />
    <path
      d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
      stroke={(props.sx as IconProps).color}
      strokeWidth="2"
    />
  </SvgIcon>
)
