import { City, Street } from 'app/codecs/address'
import { Branch } from 'app/codecs/branch'
import { UserRole } from 'app/codecs/user'
import { phonePrefix } from 'components/form/phone-input'

export const formatBranch = (language: string) => (branch: Branch) => {
  const description = language === 'ua' ? 'descriptionUA' : 'descriptionRU'
  return `№${branch.branchNumber},${' '}${branch.region[description]}${' '}${
    branch.region.addressType[description]
  },${' '}${branch.city[description]},${' '}${
    branch.street[description] ?? ''
  }${' '}${branch.building ?? ''}`
}

export const formatBranchShort = (language: string) => (branch: Branch) => {
  const description = language === 'ua' ? 'descriptionUA' : 'descriptionRU'
  return `№${branch.branchNumber},${' '}${
    branch.street[description] ?? ''
  }${' '}${branch.building ?? ''}`
}

type AddressDescription = {
  descriptionRU: string
  descriptionUA: string
}
export const formatAddress =
  (language: string) => (address: AddressDescription) => {
    const description = language === 'ua' ? 'descriptionUA' : 'descriptionRU'
    return address[description]
  }

export const formatCity = (language: string) => (city: City) => {
  const name = language === 'ua' ? 'nameUA' : 'nameRU'

  const type = city.typeUA ? `${city.typeUA[0]}.` : ''

  return `${type}${city[name]}, ${city.district} район, ${city.region} обл.`
}

export const formatStreet = (language: string) => (street: Street) => {
  const name = language === 'ua' ? 'nameUA' : 'nameRU'
  return `${street[name]}`
}

export const formatPhone = (phone: string) => {
  return `${phonePrefix}${phone}`
}

const roles = {
  SUPER_ADMIN: 'user_role.SUPER_ADMIN',
  ADMIN: 'user_role.ADMIN',
  OPERATOR: 'user_role.OPERATOR',
  CONTENT_MANGER: 'user_role.CONTENT_MANGER',
}

export const formatUserRole = (role: UserRole) => roles[role]

export const formatInventoryMail = ({
  branch,
  phone,
  found,
  notFound,
  added,
}: {
  branch: string
  phone: string
  found: string
  notFound: string
  added: string
}) => {
  return `mailto:inventory.point@meest-express.com.ua?subject=POINT24 ${branch} ${phone}&body=${found} %0D%0A${notFound} %0D%0A${added}`
}
