import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconCashbox = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4998 5.83333V9.16667M15.8332 3.33333V5C15.8332 5.22101 15.7454 5.43297 15.5891 5.58926C15.4328 5.74554 15.2209 5.83333 14.9998 5.83333H9.99984C9.77882 5.83333 9.56686 5.74554 9.41058 5.58926C9.2543 5.43297 9.1665 5.22101 9.1665 5V3.33333C9.1665 3.11232 9.2543 2.90036 9.41058 2.74408C9.56686 2.5878 9.77882 2.5 9.99984 2.5H14.9998C15.2209 2.5 15.4328 2.5878 15.5891 2.74408C15.7454 2.90036 15.8332 3.11232 15.8332 3.33333Z"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 14.1671L3.93333 9.73382C3.99132 9.56133 4.10416 9.41257 4.25464 9.31024C4.40511 9.20792 4.58494 9.15766 4.76667 9.16715H15.2333C15.408 9.1667 15.5785 9.22116 15.7205 9.32285C15.8626 9.42454 15.9691 9.5683 16.025 9.73382L17.5 14.1671M2.5 14.1671V16.6671C2.5 16.8882 2.5878 17.1001 2.74408 17.2564C2.90036 17.4127 3.11232 17.5005 3.33333 17.5005H16.6667C16.8877 17.5005 17.0996 17.4127 17.2559 17.2564C17.4122 17.1001 17.5 16.8882 17.5 16.6671V14.1671M2.5 14.1671H17.5"
      stroke="#2C363F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
