/* eslint-disable unicorn/better-regex */
import { TextFieldProps } from '@material-ui/core'
import { textFieldError } from 'components/form/utils'
import { TextField } from 'components/ui/text-field'
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseControllerProps,
  Validate,
  ValidationRule,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CustomNumberFormat } from '../custom-number-format'

type TFCustomRules<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Partial<{
  required: boolean
  min: number
  max: number
  label: string
  pattern: ValidationRule<RegExp>
  validate:
    | Validate<FieldPathValue<TFieldValues, TFieldName>>
    | Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>>>
}>
type TFProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  UseControllerProps<TFieldValues>,
  'rules'
> &
  TFCustomRules &
  Omit<
    TextFieldProps,
    | 'value'
    | 'onChange'
    | 'required'
    | 'maxLength'
    | 'minLength'
    | 'max'
    | 'min'
    | 'label'
  >
export const ControlledMoneyInput = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: TFProps<TFieldValues>,
) => {
  const { name, shouldUnregister, defaultValue, pattern, ...innerProps } = props
  const currency = 'UAH'
  const placeholder = '00000.00'

  const { t } = useTranslation()

  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control: props.control,
    name,
    shouldUnregister,
    defaultValue,
    rules: {
      required: innerProps.required,
      max: innerProps.max,
      min: innerProps.min,
      pattern,
    },
  })

  return (
    <TextField
      {...textFieldError(fieldState.error)}
      value={value || ''}
      onChange={onChange}
      label={t(props.label as string)}
      required={innerProps.required !== undefined}
      helperText={fieldState.error?.message || props.helperText}
      name={name}
      placeholder={placeholder}
      endAdornment={currency}
      inputComponent={CustomNumberFormat}
      inputProps={{
        isNumericString: true,
        decimalScale: 2,
        min: props.min,
        max: props.max,
      }}
    />
  )
}
