import { SelectOption } from 'app/options'
import { Select } from 'components/ui/select'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  disabled?: boolean
  required?: boolean
  options: ReadonlyArray<SelectOption>
  initialOption?: SelectOption
  fullWidth?: boolean
  isLoading?: boolean
  multiple?: boolean
  defaultValue?: any
}

export const ControlledSelect = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()
  const {
    field: { ...selectField },
    fieldState,
  } = useController({
    control: props.control,
    name: props.name,
    rules: { required: props.required },
    defaultValue: props.defaultValue,
  })
  return (
    <Select
      {...selectField}
      label={t(props.label)}
      required={props.required}
      name={props.name}
      error={fieldState.error !== undefined}
      initialOption={props.initialOption}
      disabled={props.disabled}
      isLoading={props.isLoading}
      options={props.options}
      multiple={props.multiple}
    />
  )
}
