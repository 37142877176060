import { IconProps } from '@material-ui/core'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconArchiveNotification = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="19"
    height="19"
    viewBox="-1 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.85 15.6825V5.2275C0.651667 5.19917 0.460417 5.0575 0.27625 4.8025C0.0920833 4.5475 0 4.27125 0 3.97375V1.275C0 0.949167 0.1275 0.655208 0.3825 0.393125C0.6375 0.131042 0.935 0 1.275 0H15.725C16.0508 0 16.3448 0.131042 16.6069 0.393125C16.869 0.655208 17 0.949167 17 1.275V3.97375C17 4.27125 16.9079 4.5475 16.7238 4.8025C16.5396 5.0575 16.3483 5.19917 16.15 5.2275V15.6825C16.15 16.0083 16.019 16.3094 15.7569 16.5856C15.4948 16.8619 15.2008 17 14.875 17H2.125C1.785 17 1.4875 16.8619 1.2325 16.5856C0.9775 16.3094 0.85 16.0083 0.85 15.6825ZM2.125 5.24875V15.725H14.875V5.24875H2.125ZM15.725 3.97375V1.275H1.275V3.97375H15.725ZM5.95 9.71125H11.05V8.43625H5.95V9.71125Z"
      fill="#2C363F"
      stroke={(props.sx as IconProps).color}
      strokeMiterlimit="10"
    />
  </SvgIcon>
)
