import { createSvgIcon } from '@material-ui/core'

export const IconDetails = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="9" y="18" width="11" height="2" rx="1" fill="#2C363F" />
    <rect x="9" y="11" width="11" height="2" rx="1" fill="#2C363F" />
    <rect x="9" y="4" width="11" height="2" rx="1" fill="#2C363F" />
    <rect x="4" y="18" width="3" height="2" rx="1" fill="#2C363F" />
    <rect x="4" y="11" width="3" height="2" rx="1" fill="#2C363F" />
    <rect x="4" y="4" width="3" height="2" rx="1" fill="#2C363F" />
  </svg>,
  'IconDetails',
)
