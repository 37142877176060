import * as t from 'io-ts'

export const TNomeclatureRow = t.type({
  nomenclatureId: t.string,
  nomenclature: t.string,
  packagingId: t.string,
  packaging: t.union([t.string, t.undefined]),
  measure: t.string,
  price: t.number,
  amount: t.number,
})

export const TParcelNomenclature = t.type({
  parcelId: t.string,
  nomenclatureId: t.string,
  parcelNumber: t.string,
  amount: t.number,
  price: t.number,
})

export const TProductSellingResponse = t.type({
  parcelId: t.string,
  parcelNumber: t.string,
})

export type NomeclatureRow = t.TypeOf<typeof TNomeclatureRow>
