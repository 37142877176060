import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconFAQ = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="20"
    height="20"
    viewBox="2 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_1987_5366)">
      <path
        d="M17 14.9688V18.0625C17 18.4422 16.6922 18.75 16.3125 18.75H14.0781"
        stroke="#2C363F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9.125V5.6875C17 5.3078 16.6922 5 16.3125 5H6.6875C6.3078 5 6 5.3078 6 5.6875V18.0625C6 18.4422 6.3078 18.75 6.6875 18.75H8.75"
        stroke="#2C363F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.75 9.125H13.5625" stroke="#2C363F" strokeLinecap="round" />
      <path
        d="M11.1562 18.75L17 11.5312"
        stroke="#2C363F"
        strokeLinecap="round"
      />
      <path d="M8.75 11.875H11.5" stroke="#2C363F" strokeLinecap="round" />
    </g>
  </SvgIcon>
)
