import { Reason, SelectReason } from 'components/ui/select-reason'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  required?: boolean
  reasons: Array<Reason>
}

export const ControlledSelectReason = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()

  const {
    field: { onBlur, onChange, value },
    fieldState,
  } = useController({
    control: props.control,
    name: props.name,
    rules: { required: props.required },
  })

  return (
    <SelectReason
      onChange={onChange}
      onBlur={onBlur}
      value={value as string}
      name={props.name}
      required={props.required}
      label={t(props.label)}
      error={fieldState.error !== undefined}
      reasons={props.reasons}
    />
  )
}
