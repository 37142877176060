import 'styles/index.css'

import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core'
import { ruRU, ukUA } from '@material-ui/core/locale'
import { AuthProvider } from 'app/auth'
import { env } from 'app/env'
import { muiTheme } from 'app/theme'
import { NotificationProvider } from 'components/notification-provider'
import { SystemDisability } from 'components/system-disability'
import { ReactPace } from 'lib/pace/ReactPace'
import { ComponentType, StrictMode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'

// It is a global configuration. You can change it per-query as well.
// Stale queries are re-fetched automatically in the background when:
// - New instances of the query mount.
// - The network is reconnected.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type Props = {
  Component: ComponentType
}

export const getLocaleByLanguage = (language: string) => {
  return language === 'ru' ? ruRU : ukUA
}

const App = ({ Component }: Props) => {
  const { i18n } = useTranslation()

  return (
    <StrictMode>
      <ThemeProvider
        theme={createTheme(muiTheme, getLocaleByLanguage(i18n.language))}
      >
        <CssBaseline />
        {env.REACT_APP_MSW === 'off' && (
          <ReactPace color={muiTheme.palette.primary.light} />
        )}
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={SystemDisability}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <NotificationProvider>
                  <Component />
                </NotificationProvider>
              </AuthProvider>
              <ReactQueryDevtools
                initialIsOpen={env.REACT_APP_STAGE === 'development'}
              />
            </QueryClientProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>
  )
}

export default App
