import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconList = (props: SvgIconProps & { strokecolor: string }) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 4.5C4.5 3.39543 5.39543 2.5 6.5 2.5H18.5C19.6046 2.5 20.5 3.39543 20.5 4.5V20.5C20.5 21.6046 19.6046 22.5 18.5 22.5H6.5C5.39543 22.5 4.5 21.6046 4.5 20.5L4.5 4.5Z"
      stroke={props.strokecolor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 7.5C8.94772 7.5 8.5 7.94772 8.5 8.5C8.5 9.05228 8.94772 9.5 9.5 9.5H15.5C16.0523 9.5 16.5 9.05228 16.5 8.5C16.5 7.94772 16.0523 7.5 15.5 7.5H9.5ZM8.5 12.5C8.5 11.9477 8.94772 11.5 9.5 11.5H15.5C16.0523 11.5 16.5 11.9477 16.5 12.5C16.5 13.0523 16.0523 13.5 15.5 13.5H9.5C8.94772 13.5 8.5 13.0523 8.5 12.5ZM8.5 16.5C8.5 15.9477 8.94772 15.5 9.5 15.5H15.5C16.0523 15.5 16.5 15.9477 16.5 16.5C16.5 17.0523 16.0523 17.5 15.5 17.5H9.5C8.94772 17.5 8.5 17.0523 8.5 16.5Z"
      fill="#0061AF"
    />
  </SvgIcon>
)
