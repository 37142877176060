import { Box } from '@material-ui/core'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const FormField = (props: Props) => {
  return (
    <Box mt={2} width={1}>
      {props.children}
    </Box>
  )
}
