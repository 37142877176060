import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconContainerSelling = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6562 10.2188C10.6562 10.5985 10.3485 10.9062 9.96875 10.9062C9.58904 10.9062 9.28125 10.5985 9.28125 10.2188C9.28125 9.83904 9.58904 9.53125 9.96875 9.53125C10.3485 9.53125 10.6562 9.83904 10.6562 10.2188Z"
      fill="#2C363F"
    />
    <path
      d="M7.21875 6.78125C7.21875 7.16096 6.91096 7.46875 6.53125 7.46875C6.15156 7.46875 5.84375 7.16096 5.84375 6.78125C5.84375 6.40156 6.15156 6.09375 6.53125 6.09375C6.91096 6.09375 7.21875 6.40156 7.21875 6.78125Z"
      fill="#2C363F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6771 6.0729C10.8784 6.27426 10.8784 6.60074 10.6771 6.8021L6.5521 10.9271C6.35074 11.1284 6.02426 11.1284 5.8229 10.9271C5.62153 10.7257 5.62153 10.3993 5.8229 10.1979L9.94792 6.0729C10.1493 5.87153 10.4757 5.87153 10.6771 6.0729Z"
      fill="#2C363F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.21054 1.10938H8.28946C9.87649 1.10937 11.1202 1.10936 12.0906 1.23983C13.0838 1.37337 13.8676 1.65204 14.4828 2.26722C15.098 2.8824 15.3766 3.66617 15.5102 4.65943C15.6406 5.62982 15.6406 6.87353 15.6406 8.46054V8.53946C15.6406 10.1265 15.6406 11.3702 15.5102 12.3406C15.3766 13.3338 15.098 14.1176 14.4828 14.7328C13.8676 15.348 13.0838 15.6266 12.0906 15.7602C11.1202 15.8906 9.87649 15.8906 8.28946 15.8906H8.21054C6.62353 15.8906 5.37982 15.8906 4.40943 15.7602C3.41617 15.6266 2.6324 15.348 2.01722 14.7328C1.40204 14.1176 1.12337 13.3338 0.989828 12.3406C0.859361 11.3702 0.859368 10.1265 0.859375 8.53946V8.46054C0.859368 6.87353 0.859361 5.62982 0.989828 4.65943C1.12337 3.66617 1.40204 2.8824 2.01722 2.26722C2.6324 1.65204 3.41617 1.37337 4.40943 1.23983C5.37982 1.10936 6.62353 1.10937 8.21054 1.10938ZM4.54684 2.26188C3.66793 2.38005 3.13806 2.60478 2.74643 2.99643C2.35478 3.38806 2.13005 3.91793 2.01188 4.79684C1.89172 5.69057 1.89063 6.86497 1.89063 8.5C1.89063 10.135 1.89172 11.3094 2.01188 12.2031C2.13005 13.0821 2.35478 13.6119 2.74643 14.0036C3.13806 14.3952 3.66793 14.62 4.54684 14.7381C5.44057 14.8583 6.61497 14.8594 8.25 14.8594C9.88501 14.8594 11.0594 14.8583 11.9531 14.7381C12.8321 14.62 13.3619 14.3952 13.7536 14.0036C14.1452 13.6119 14.37 13.0821 14.4881 12.2031C14.6083 11.3094 14.6094 10.135 14.6094 8.5C14.6094 6.86497 14.6083 5.69057 14.4881 4.79684C14.37 3.91793 14.1452 3.38806 13.7536 2.99643C13.3619 2.60478 12.8321 2.38005 11.9531 2.26188C11.0594 2.14172 9.88501 2.14063 8.25 2.14063C6.61497 2.14063 5.44057 2.14172 4.54684 2.26188Z"
      fill="#2C363F"
    />
  </SvgIcon>
)
