import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

// - PUDO palette specification: https://www.figma.com/file/ho1jbcZSkNhgwDWMRzErr2/Meest-PUDO?node-id=470%3A1540
const palette = {
  dark: '#2C363F',
  white: '#FFFFFF',
  grey: {
    dark: '#7B8085',
    medium: '#C1C5C7',
    blue: '#E0E3E7',
    light: '#F4F5F5',
  },
  blue: {
    dark: '#0061AF',
    medium: '#EBF1FB',
    grey: '#E6EDF8',
    light: '#F4F7FC',
  },
  pink: {
    dark: '#E60033',
    light: '#FE6975',
    lighter: '#FFBEC3',
  },
  green: {
    main: '#57C278',
    light: '#C6E6D0',
  },
  icon: {
    dark: '#1E407F',
    medium: '#6791E4',
    lighter: '#93B1EC',
    lightest: '#BED0F3',
  },
  yellow: {
    medium: '#FFCA0A',
  },
} as const

// - Default theme: https://next.material-ui.com/customization/default-theme/
// - Theme customization: https://next.material-ui.com/customization/theming/
// - PUDO design system: https://www.figma.com/file/ho1jbcZSkNhgwDWMRzErr2/Meest-PUDO?node-id=312%3A3
const baseTheme = createTheme({
  // TODO [dev]: Match the palette provided by the designer with the MUI palette. It can be extended if needed.
  palette: {
    divider: palette.grey.blue,
    primary: {
      main: palette.blue.dark,
      light: palette.grey.light,
    },
    secondary: {
      main: palette.dark,
      light: palette.pink.light,
    },
    error: {
      main: palette.pink.light,
      dark: palette.pink.dark,
      light: palette.pink.lighter,
    },
    warning: {
      main: palette.yellow.medium,
    },
    success: {
      main: palette.green.main,
      light: palette.green.light,
    },
    info: {
      main: palette.blue.medium,
      light: palette.blue.grey,
    },
    text: {
      primary: palette.dark,
      secondary: palette.grey.dark,
      disabled: palette.grey.medium,
    },
    background: {
      paper: palette.blue.light,
      default: palette.white,
    },
  },

  // - PUDO typography specification: https://www.figma.com/file/ho1jbcZSkNhgwDWMRzErr2/Meest-PUDO?node-id=488%3A857
  // TODO [designer]: Letter spacings not defined in the design system
  // TODO [designer]: Line heights for captions are 16px in spec and 14px in example
  // TODO [dev]: Changing fontSize and htmlFontSize works different with the redefined variants. Probably it should be fixed by MUI team.
  typography: {
    h1: {
      fontSize: '1.75rem',
      lineHeight: 2,
      fontWeight: 700,
      letterSpacing: '0em',
    },
    h2: {
      fontSize: '1.3125rem',
      lineHeight: 2,
      fontWeight: 700,
      letterSpacing: '0.00735em',
    },
    h3: {
      fontSize: '1.125rem',
      lineHeight: 1.25,
      fontWeight: 700,
      letterSpacing: '0em',
    },
    h4: {
      fontSize: '1rem',
      lineHeight: 1.1875,
      fontWeight: 700,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 500,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.25,
      fontWeight: 400,
    },
    caption1: {
      fontSize: '0.75rem',
      lineHeight: 1.7,
      fontWeight: 500,
      letterSpacing: '0.03333em',
    },
    caption2: {
      fontSize: '0.75rem',
      lineHeight: 1,
      fontWeight: 400,
      letterSpacing: '0.03333em',
    },
    button: {
      fontSize: '1rem',
      lineHeight: 1.1875,
      fontWeight: 700,
      letterSpacing: '0.0075em',
    },
  },

  shape: {
    // Border radius of paper, buttons, inputs etc.
    borderRadius: 10,
  },

  // `theme.spacing(1) === '8px'`
  spacing: 8,

  // The default values are [0, 600, 900, 1200, 1536]
  breakpoints: {},

  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 5px 8px rgba(45, 71, 122, 0.16)',
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.75rem',
          lineHeight: 0.975,
          fontWeight: 700,
          padding: '15px 30px',
          borderRadius: '5px',
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        outlined: {
          border: '1px solid',
          borderColor: palette.grey.blue,
          boxShadow: '0px 2px 4px rgba(61, 115, 221, 0.15)',
        },
        sizeMedium: {
          height: '44px',
        },
        sizeSmall: {
          height: '40px',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '-9px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white,
          borderBottom: `1px solid ${palette.blue.light}`,
          padding: '10px',
          lineHeight: '20px',
        },
        head: {
          borderTop: `1px solid ${palette.blue.light}`,
          borderColor: palette.blue.light,
          color: palette.grey.dark,
          fontWeight: 700,
          padding: '19px 10px',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '0px 8px 0px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '44px',
        },
        input: {
          height: '11px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
  },
})

export const muiTheme = responsiveFontSizes(baseTheme)
