import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconSuccess = (props: SvgIconProps & { strokecolor: string }) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <circle cx="12" cy="12" r="9" stroke={props.strokecolor} strokeWidth="2" />
    <path
      d="M7 12.1471L11.0733 15.75L17.625 8.875"
      stroke={props.strokecolor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
