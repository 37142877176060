import { ExtractRouteParams } from 'react-router'

export const routes = {
  index: '/',
  login: '/login',
  logout: '/logout',
  settings: '/settings',
  users: '/users',
  clientParcelReception: '/client-parcel-reception',
  courierParcelIssuance: '/courier-parcel-issuance',
  courierParcelReception: '/courier-parcel-reception',
  clientParcelIssuance: '/client-parcel-issuance',
  documents: '/documents',
  containerSelling: '/container-selling',
  inventory: '/inventory',
  terminals: '/terminals',
  clientParcelReturn: '/client-parcel-return',
  branches: '/branches',
  calculator: '/calculator',
  events: '/users/:userId/events',
  faq: '/faq',
  cashbox: '/cashbox',
  createNotification: '/create-notification',
  archiveNotification: '/archive-notification',
  myNotifications: '/my-notifications',
  home: '/home',
} as const

export type RouteParams<T extends keyof typeof routes> = ExtractRouteParams<
  typeof routes[T]
>
