export const IconBranchesLarge = () => (
  <svg
    width="52"
    height="51"
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M25.9999 50.63C11.9166 50.63 0.499844 44.0605 0.499844 35.9566C0.499844 27.8527 11.9166 21.2832 25.9999 21.2832C40.0833 21.2832 51.5 27.8527 51.5 35.9566C51.5 44.0605 40.0833 50.63 25.9999 50.63Z"
      fill="#0061AF"
    />
    <path
      d="M45.3678 12.7598V13.0915L48.8145 15.0605V14.7288L45.3678 12.7598Z"
      fill="#0065AF"
    />
    <path
      d="M45.3678 15.8574V16.1891L48.8145 18.1581V17.8264L45.3678 15.8574Z"
      fill="#0065AF"
    />
    <path
      d="M46.2309 12.1777L49.9619 14.2741V19.5389L46.2309 17.416V12.1777Z"
      fill="white"
    />
    <path
      d="M46.2309 13.5059L49.9619 15.5996V19.5402L46.2309 17.4173V13.5059Z"
      fill="#0065AF"
    />
    <path
      d="M46.576 12.8281L49.5908 14.5211V14.9696L46.576 13.2554V12.8281Z"
      fill="#0065AF"
    />
    <path d="M35.3277 34L45.4355 28L35.8331 23L35.3277 34Z" fill="white" />
    <path
      d="M50.3883 14.148L50.0626 14.3321V19.3672L50.3883 19.183V14.148Z"
      fill="white"
      stroke="#0065AF"
      strokeWidth="0.2"
    />
    <path
      d="M50.4883 19.2395L49.9626 19.5367V15.596L50.4883 15.2988V19.2395Z"
      fill="#0065AF"
    />
    <path
      d="M50.2846 13.9768L46.7598 11.9974L46.4363 12.1777L49.9624 14.1589L50.2846 13.9768Z"
      fill="white"
      stroke="#0065AF"
      strokeWidth="0.2"
    />
    <path
      d="M45.0373 28.2338V15.7715L25.9535 5.05266V17.3771L45.0373 28.2338Z"
      fill="white"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <path
      d="M44.116 15.8887L44.8965 16.3265V29.2497L44.116 28.8065V15.8887Z"
      fill="#0065AF"
    />
    <path
      opacity="0.5"
      d="M44.5049 16.3828V16.8392L44.1025 16.6084L44.5049 16.3828Z"
      fill="white"
    />
    <path
      d="M30.0224 17.0229V16.7363L36.9023 20.6504L36.6502 20.7937L30.0224 17.0229Z"
      fill="white"
    />
    <path
      d="M29.9537 17.7773L35.9512 21.1873L35.6964 21.3305L29.9537 18.0639V17.7773Z"
      fill="white"
    />
    <path
      d="M30.0237 16.3362V15.7656L37.7646 20.168L37.2604 20.4519L30.0237 16.3362Z"
      fill="white"
    />
    <path
      d="M33.0266 16.0829V10.3086L44.1016 16.6083L38.9946 19.4769L33.0266 16.0829Z"
      fill="white"
    />
    <path
      d="M21.4064 42.1712V29.709L2.32528 18.9902V31.3146L21.4064 42.1712Z"
      fill="#0065AF"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <path d="M22.1857 42L30.7773 36.5V25L22.1857 29.5V42Z" fill="white" />
    <path
      d="M30.6189 23.584L31.3994 24.0218V36.945L30.6189 36.4992V23.584Z"
      fill="#0065AF"
    />
    <path
      d="M45.3898 16.4644L45.0844 16.6356V28.9297L45.3898 28.755V16.4644Z"
      fill="white"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <path
      d="M31.5979 36.6029L35.7121 34.2594V21.9004L31.5979 24.2125V36.6029Z"
      fill="white"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <path
      d="M26.4554 26.5875L26.7344 26.4336V39.0781L26.4554 39.2347V26.5875Z"
      fill="#0065AF"
    />
    <path
      d="M22.5527 29.0605V41.9757L21.6059 42.5144V29.5913L22.5527 29.0605Z"
      fill="#0065AF"
    />
    <path
      d="M46.0273 11.7246L22.0454 25.3855V30.1301L46.0273 16.4879V11.7246Z"
      fill="#0065AF"
    />
    <path
      d="M1.53304 13.6895L22.0469 25.3866V30.1313L1.53304 18.4633V13.6895Z"
      fill="#0065AF"
    />
    <path
      d="M34.8486 26.4834L32.1369 28.0304V27.1388L34.8486 25.6289V26.4834Z"
      fill="white"
    />
    <path
      d="M25.4974 0.575821L2.53959 13.69L22.0614 24.8406L45.0193 11.7264L25.4974 0.575821Z"
      fill="white"
      stroke="#0065AF"
    />
    <path
      d="M3.74279 14.1361L2.85497 13.6293L25.52 0.753906L44.7383 11.7293L43.8505 12.2335L25.52 1.76494L3.74279 14.1361Z"
      fill="white"
    />
    <path
      d="M43.6486 12.2281L25.52 1.87485L3.94495 14.1312L22.0735 24.4871L43.6486 12.2281Z"
      fill="white"
      stroke="#0065AF"
      strokeWidth="0.2"
    />
    <path
      d="M47.9482 15.8427V17.0554L46.583 16.2752V15.0625L47.9482 15.8427ZM46.819 15.3385L47.2911 16.0258L47.7766 15.8904L46.8163 15.3385H46.819ZM47.841 16.851V15.9807L47.2643 16.1399L46.6876 15.3226V16.1929L47.8383 16.851H47.841Z"
      fill="white"
    />
    <path
      d="M49.1875 16.5372L48.1387 15.9375V16.0834L49.1875 16.6805V16.5372Z"
      fill="white"
    />
    <path
      d="M49.3701 17.2982L48.147 16.6003V16.457L49.3701 17.1549V17.2982Z"
      fill="white"
    />
    <path
      d="M49.6143 17.9832L48.139 17.1394V16.9961L49.6143 17.8399V17.9832Z"
      fill="white"
    />
  </svg>
)
