import 'react-app-polyfill/stable'

import { env } from 'app/env'
import { initI18n } from 'app/i18n'
import { reportWebVitals } from 'app/report-web-vitals'
import { i18n as I18n } from 'i18next'
import ReactDOM from 'react-dom'

import PageIndex from './pages'
import App from './pages/_app'

async function setupI18n(): Promise<I18n> {
  const i18n = await initI18n({
    debug: env.REACT_APP_DEBUG_I18N === 'on',
  })

  return i18n
}

async function setupMSW(): Promise<void> {
  if (env.REACT_APP_MSW === 'on' || env.REACT_APP_MSW === 'quiet') {
    const { worker } = await import('./mocks/browser')
    await worker.start({
      quiet: env.REACT_APP_MSW === 'quiet',
    })
  }
}

function setupWebVitals(): void {
  if (env.REACT_APP_REPORT_WEB_VITALS === 'on') {
    reportWebVitals(console.log)
  }
}

async function main() {
  setupWebVitals()
  await Promise.all([setupI18n(), setupMSW()])

  ReactDOM.render(
    <App Component={PageIndex} />,
    document.querySelector('#root'),
  )
}

main()
