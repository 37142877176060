import { useAuthContext } from 'app/auth'
import { isLeft } from 'fp-ts/Either'
import { useNotificationContext } from 'hooks/use-notification'
import { Mixed, TypeOf } from 'io-ts'
import reporter from 'io-ts-reporters'
import { devConsoleLog } from 'lib/dev-console-log'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery as useReactQuery, UseQueryOptions } from 'react-query'

import { ErrorMap, MutationError, RestMethod, throwError } from './common'

export const useQuery = <
  C extends Mixed,
  H extends Mixed | undefined = undefined,
>(
  method: RestMethod,
  url: string,
  codec: C,
  {
    rawBody,
    headersCodec,
    options,
    customQueryKey,
  }: {
    rawBody?: {} | null
    headersCodec?: H | null
    options?: UseQueryOptions<TypeOf<C>, MutationError>
    customQueryKey?: Array<string> | string
  } = {},
) => {
  const body = rawBody ? JSON.stringify(rawBody) : undefined

  const { auth, removeToken } = useAuthContext()
  const { showErrorNotification } = useNotificationContext()
  const { t } = useTranslation()

  const token = auth?.type === 'authenticated' ? auth.accessToken : null

  const tokenReference = useRef(token)
  useEffect(() => {
    tokenReference.current = token
  }, [token])

  type Result = H extends Mixed
    ? {
        body: TypeOf<C>
        headers: TypeOf<H>
      }
    : TypeOf<C>

  const prevData = useRef<Result | null>(null)

  const queryKey = customQueryKey ? customQueryKey : [method, url, body]

  const queryFn = async () => {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Screen-Width': `${screen.width}`,
      'Screen-Height': `${screen.height}`,
    })

    const token = tokenReference.current

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    try {
      const response = await window.fetch(url, {
        method,
        body,
        headers,
      })

      if (response.ok) {
        try {
          let json: any = await response.json()
          if (url === `/api/pudo/documents/total/count`) {
            let getPlaceAndQuantity = (
              contactType: string,
              documentType: string,
            ) => {
              for (const el of json) {
                if (
                  el.contactType === contactType &&
                  el.documentType === documentType
                ) {
                  return el
                }
              }
              return {
                quantity: 0,
                place: 0,
              }
            }
            const newJson = {
              enteredPostCount:
                getPlaceAndQuantity('FROM_CUSTOMER', 'ENTERED').quantity || 0,
              enteredPlaceCount:
                getPlaceAndQuantity('FROM_CUSTOMER', 'ENTERED').place || 0,
              warehouseDeliveryPostCount:
                getPlaceAndQuantity('TO_CUSTOMER', 'WAREHOUSE_DELIVERY')
                  .quantity || 0,
              warehouseDeliveryPlaceCount:
                getPlaceAndQuantity('TO_CUSTOMER', 'WAREHOUSE_DELIVERY')
                  .place || 0,
              receptionFromCourierPostCount:
                getPlaceAndQuantity('FROM_COURIER', 'TRANSFER').quantity || 0,
              receptionFromCourierPlaceCount:
                getPlaceAndQuantity('FROM_COURIER', 'TRANSFER').place || 0,
              transferFromCourierPostCount:
                getPlaceAndQuantity('TO_COURIER', 'TRANSFER').quantity || 0,
              transferFromCourierPlaceCount:
                getPlaceAndQuantity('TO_COURIER', 'TRANSFER').place || 0,
            }
            json = newJson
          }
          const decodedJson = codec.decode(json)
          if (isLeft(decodedJson)) {
            devConsoleLog(reporter.report(decodedJson))
            return throwError({ type: 'failed_to_decode_json', json })
          }

          if (headersCodec) {
            const decodedHeaders = headersCodec.decode(
              Object.fromEntries(response.headers.entries()),
            )

            if (isLeft(decodedHeaders)) {
              return throwError({
                type: 'failed_to_decode_headers',
                headers: response.headers,
              })
            }

            return {
              body: decodedJson.right,
              headers: decodedHeaders.right,
            }
          }
          return decodedJson.right
        } catch (error) {
          if (error.type === undefined) {
            throwError({
              type: 'failed_to_parse_json',
              response,
              error,
            })
          }

          throw error
        }
      }

      if (response.status >= 500) {
        const json = await response.json()
        const errorText = json.data.details || json.message
        showErrorNotification(errorText)
        throwError({
          type: 'server_error',
          status: response.status,
          message: errorText,
        })
      }

      if (response.status === 404) {
        throwError({ type: 'not_found' })
      }

      if (response.status === 401) {
        removeToken()
      }

      if (response.status >= 400) {
        const json = await response.json()
        const errorText = json.data.details || json.message

        const errorValue = ErrorMap.get(
          json.data.details === undefined ? json.code : json.data.details,
        )

        if (errorText === 'One or more parcel are missed') {
          showErrorNotification(t('notifications.missed_parcels_error'))
        } else if (errorValue !== undefined) {
          showErrorNotification(t(errorValue))
        } else showErrorNotification(errorText)

        throwError({ type: 'client_error', message: json.errorText })
      }
    } catch (error) {
      if (error.type === undefined) {
        throwError({ type: 'network_error', error })
      }

      throw error
    }
  }

  const result = useReactQuery<Result, MutationError>(queryKey, queryFn, {
    retry: false,
    ...options,
  })

  const { data } = result

  if (data) {
    prevData.current = data
  }

  return { ...result, prevData: prevData.current }
}
