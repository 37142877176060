import promiseRetry from 'promise-retry'
import { ComponentType } from 'react'
import lazy, { PreloadableComponent } from 'react-lazy-with-preload'

export type LazyRoute<T extends ComponentType<any> = ComponentType<any>> = {
  title: string
  path: string[]
  component: PreloadableComponent<T>
  exact: boolean
}

export const lazyRoute = <T extends ComponentType<any>>({
  title,
  path,
  factory,
  exact = true,
}: {
  title: string
  path: string | string[]
  factory: () => Promise<{ default: T }>
  exact?: boolean
}): LazyRoute<T> => ({
  title: title,
  path: Array.isArray(path) ? path : [path],
  component: lazy(() =>
    promiseRetry(retry => factory().catch(retry), {
      retries: 5,
    }),
  ),
  exact,
})
