import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconDocuments = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none' }}
    width="17"
    height="17"
    viewBox="-1 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#cip0_1987_5319)">
      <path
        d="M11.2002 0.533333L11.4961 0.0895731L11.3617 0H11.2002V0.533333ZM14.4002 2.66667H14.9336V2.38124L14.6961 2.2229L14.4002 2.66667ZM13.3336 12.8H4.80023V13.8667H13.3336V12.8ZM4.26689 12.2667V1.6H3.20023V12.2667H4.26689ZM4.80023 1.06667H11.2002V0H4.80023V1.06667ZM13.8669 2.66667V12.2667H14.9336V2.66667H13.8669ZM10.9043 0.977093L14.1043 3.11043L14.6961 2.2229L11.4961 0.0895731L10.9043 0.977093ZM4.80023 12.8C4.50568 12.8 4.26689 12.5612 4.26689 12.2667H3.20023C3.20023 13.1503 3.91657 13.8667 4.80023 13.8667V12.8ZM13.3336 13.8667C14.2172 13.8667 14.9336 13.1503 14.9336 12.2667H13.8669C13.8669 12.5612 13.6281 12.8 13.3336 12.8V13.8667ZM4.26689 1.6C4.26689 1.30545 4.50568 1.06667 4.80023 1.06667V0C3.91657 0 3.20023 0.716345 3.20023 1.6H4.26689ZM1.06689 3.73333V14.4H2.13356V3.73333H1.06689ZM2.66689 16H11.2002V14.9333H2.66689V16ZM2.66689 3.2H3.73356V2.13333H2.66689V3.2ZM12.8002 14.4V13.3333H11.7336V14.4H12.8002ZM11.2002 16C12.0839 16 12.8002 15.2836 12.8002 14.4H11.7336C11.7336 14.6945 11.4947 14.9333 11.2002 14.9333V16ZM1.06689 14.4C1.06689 15.2836 1.78324 16 2.66689 16V14.9333C2.37235 14.9333 2.13356 14.6945 2.13356 14.4H1.06689ZM2.13356 3.73333C2.13356 3.43878 2.37235 3.2 2.66689 3.2V2.13333C1.78324 2.13333 1.06689 2.84967 1.06689 3.73333H2.13356Z"
        fill="#2C363F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1987_5319">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
)
