import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconLoader = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00202266 12.3065C0.115638 6.23636 5.07986 1.3914 11.0666 1.50185C17.0004 1.60935 21.4495 5.81519 21.4132 11.8147H23.7536C23.9116 11.972 24 12.1832 24 12.403C24 12.6228 23.9116 12.834 23.7536 12.9913L20.9572 15.9601C20.8801 16.0377 20.7877 16.0993 20.6855 16.1414C20.5834 16.1835 20.4736 16.2053 20.3626 16.2053C20.2517 16.2053 20.1418 16.1835 20.0397 16.1414C19.9375 16.0993 19.8451 16.0377 19.768 15.9601L17.1549 12.9913C16.9962 12.8343 16.9073 12.6231 16.9073 12.403C16.9073 12.1829 16.9962 11.9717 17.1549 11.8147H19.309C19.3439 6.89904 15.8869 3.59151 11.0272 3.50316C6.12966 3.41185 2.07133 7.37617 1.9759 12.3419C1.88197 17.234 5.67975 21.2911 10.4788 21.4958V23.5C4.57541 23.2968 -0.111592 18.3163 0.00202266 12.3065Z"
    />
  </SvgIcon>
)
