import { InputAdornment } from '@material-ui/core'
import { IconAlert } from 'assets/icons'
import { TextField, TextFieldProps } from 'components/ui/text-field'
import { useBoolean } from 'hooks/use-boolean'
import { forwardRef } from 'react'

import { ToggleVisibilityButton } from './toggle-visibility-button'

export const PasswordInput = forwardRef((props: TextFieldProps, ref) => {
  const visibility = useBoolean(false)

  return (
    <TextField
      {...props}
      ref={ref}
      type={visibility.isTrue ? 'text' : 'password'}
      endAdornment={
        props.error ? (
          <IconAlert />
        ) : (
          <InputAdornment position="end">
            <ToggleVisibilityButton
              isVisible={visibility.isTrue}
              onClick={visibility.toggle}
            />
          </InputAdornment>
        )
      }
    />
  )
})

PasswordInput.displayName = 'PasswordInput'
