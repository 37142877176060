import { Places } from 'components/forms/create-parcel/parcel-form'
import { useEffect, useRef } from 'react'

const arePlacesChanged = (
  prevPlaces: Places[],
  currentPlaces: Places[],
  fields: Array<keyof Places>,
): boolean => {
  // If array lengths are different, consider it changed
  if (prevPlaces.length !== currentPlaces.length) return true

  // Compare each place object for specified fields
  return prevPlaces.some((prevPlace, index) => {
    const currentPlace = currentPlaces[index]
    return fields.some(field => prevPlace[field] !== currentPlace[field])
  })
}

/**
 * Custom hook to track changes in places array
 * @param places - Current places array
 * @param onPlacesChange - Callback function to execute when places change
 * @param trackedFields - Array of fields to track for changes
 */
export const usePlacesChanges = (
  places: Places[],
  onPlacesChange: () => void,
  trackedFields: Array<keyof Places> = ['size', 'insurance'],
) => {
  // Use ref to store previous places state
  const prevPlacesRef = useRef<Places[]>([])

  useEffect(() => {
    // Check if tracked fields have changed
    if (arePlacesChanged(prevPlacesRef.current, places, trackedFields)) {
      onPlacesChange()
    }

    // Update ref with current places
    prevPlacesRef.current = places.map(place => ({ ...place }))
  }, [places, onPlacesChange, trackedFields])
}
