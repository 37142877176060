export const IconHelpCircle = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.082" cy="16" r="16" fill="white" />
    <path
      d="M13.2274 12.8222C13.3714 12.4524 13.5722 12.1329 13.8302 11.8622C14.089 11.5902 14.4011 11.3794 14.7666 11.2283C15.1315 11.0755 15.5397 11 15.9915 11C16.5742 11 17.0605 11.0799 17.4508 11.2405C17.8406 11.4007 18.1545 11.6003 18.3929 11.8377C18.6308 12.0761 18.8015 12.3328 18.904 12.6069C19.0062 12.8824 19.0577 13.1395 19.0577 13.377C19.0577 13.7704 19.0062 14.0951 18.904 14.3496C18.8015 14.6041 18.674 14.8215 18.5221 15.0022C18.3698 15.1829 18.2021 15.337 18.017 15.464C17.8328 15.5915 17.6582 15.7185 17.4941 15.846C17.3299 15.973 17.1841 16.1184 17.0571 16.2825C16.9296 16.4462 16.8493 16.6518 16.817 16.8976V17.3656H15.1547V16.8112C15.1792 16.4584 15.2472 16.1633 15.3577 15.9254C15.4686 15.6876 15.5977 15.4841 15.7453 15.3156C15.8933 15.148 16.0492 15.0018 16.2137 14.8786C16.3774 14.7551 16.5289 14.632 16.669 14.5102C16.8079 14.3862 16.9213 14.2513 17.0078 14.1033C17.0937 13.9562 17.1327 13.7703 17.1243 13.5489C17.1243 13.1713 17.0322 12.8924 16.8471 12.7121C16.6629 12.5309 16.4062 12.441 16.0775 12.441C15.8562 12.441 15.6655 12.4837 15.5052 12.5698C15.3449 12.6558 15.214 12.7715 15.111 12.915C15.0085 13.0591 14.9325 13.2276 14.8836 13.4197C14.8342 13.6135 14.8098 13.8196 14.8098 14.0422H13C13.0078 13.5979 13.0833 13.1923 13.2274 12.8222ZM16.9152 18.1046V20H14.9818V18.1046H16.9152Z"
      fill="#0065AF"
    />
  </svg>
)
