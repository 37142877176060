import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { drawerWidth } from 'components/layout'
import { ReactNode } from 'react'

import { headerHeight } from './app-layout'

type Props = {
  children: ReactNode
  onChooseBranchDialog: () => void
}

export const AppContent = (props: Props) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const contentUp900 = useMediaQuery('(min-width:900px)')
  const contentUp1100 = useMediaQuery('(min-width:1024px)')
  const contentUp1430 = useMediaQuery('(min-width:1430px)')

  const contentHeightUp684 = useMediaQuery('(min-height:684px)')
  const contentHeightUp915 = useMediaQuery('(min-height:915px)')

  const contentHeight = !contentHeightUp684
    ? '90vh'
    : !contentHeightUp915
    ? '91.3vh'
    : contentUp1430
    ? '93.5vh'
    : contentUp1100
    ? '93.5vh'
    : contentUp900
    ? '93.5vh'
    : '94.092vh'

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      height="100%"
      width={mdDown ? 1 : `calc(100% - ${drawerWidth})`}
      mt={headerHeight}
    >
      <Grid
        container
        item
        height={contentHeight}
        justifyContent="center"
        alignItems="center"
      >
        {props.children}
      </Grid>
    </Grid>
  )
}
