import { createSvgIcon } from '@material-ui/core'

export const IconKebabMenu = createSvgIcon(
  <>
    <rect x="10" y="10" width="4" height="4" rx="2" />
    <rect x="10" y="2" width="4" height="4" rx="2" />
    <rect x="10" y="18" width="4" height="4" rx="2" />
  </>,
  'IconKebabMenu',
)
