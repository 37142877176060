import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { cashBoxPath } from 'app/app-menu'
import { IconClose } from 'assets/icons'
import { IconWarningBigCircle } from 'assets/icons/warning-bix-circle'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const CheckCashboxDialog = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Dialog
      title={t('dialog_titles.change_branch')}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={{ padding: '5px 5px 0px' }}>
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={onClose}>
              <IconClose />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        <IconWarningBigCircle />
        <Box>
          <Typography variant="h4" textAlign={'center'}>
            {t('dialog_check_cashbox.main_text_1')}
          </Typography>
          <Typography variant="h4" textAlign={'center'}>
            {t('dialog_check_cashbox.main_text_2')}
          </Typography>
        </Box>
        <Typography variant="body2" textAlign={'center'}>
          {t('dialog_check_cashbox.info_text')}
        </Typography>
        <Button
          fullWidth
          onClick={() => {
            onClose()
            history.push(cashBoxPath)
          }}
        >
          {t('buttons.cashbox')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
