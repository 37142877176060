import { ConfirmationDialog } from 'components/confirmation-dialog'
import { useBoolean } from 'hooks/use-boolean'
import { ReactNode } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Dialog } from '../ui/dialog'

type Props<TFieldValues> = {
  title: string
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  form: UseFormReturn<TFieldValues>
  closeDialogTitle: string
}

export const DialogForm = <TFieldValues,>(props: Props<TFieldValues>) => {
  const dialogClose = useBoolean(false)
  const { isDirty, isSubmitSuccessful } = props.form.formState
  const { t } = useTranslation()

  const onCloseHandler = () => {
    if (isDirty && !isSubmitSuccessful) {
      return dialogClose.setTrue()
    }

    props.onClose()
    props.form.reset()
  }

  return (
    <>
      <Dialog
        open={dialogClose.isFalse && props.isOpen}
        title={t(props.title)}
        onClose={onCloseHandler}
      >
        {props.children}
      </Dialog>

      <ConfirmationDialog
        isOpen={dialogClose.isTrue}
        onClose={dialogClose.setFalse}
        title={props.closeDialogTitle}
        onConfirm={() => {
          props.form.reset()
          dialogClose.setFalse()
          props.onClose()
        }}
      />
    </>
  )
}
