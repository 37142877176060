/**
 * Module purpose:
 * Decode environment variables from `process.env` and export them as a plain object.
 *
 * Good to know:
 * - If requested variables can't be decoded, the program is immediately terminated (in runtime).
 * - All the variables must be prefixed with `REACT_APP_`, otherwise they will be discarded by Webpack.
 * - The variables can be listed either in `.env` file or injected from outside.
 *
 * ❗ Security warning:
 * ❗ Do not store sensitive information in the .env file.
 * ❗ It serves exclusively for the development and testing purposes.
 */

import { literal, string, union } from 'io-ts'
import { getEnvVariables } from 'lib/env-variables'

export const env = getEnvVariables({
  /**
   * Application ID is used to identify the app during the authentication process.
   * For example, this web app, or mobile app, or any other client should have their own ID.
   */
  REACT_APP_APPLICATION_ID: string,
  /**
   * Application version is pulled from package.json.
   * It may be useful to display the version somewhere in the app footer.
   */
  REACT_APP_APPLICATION_VERSION: string,
  /**
   * Should not be confused with `process.env.NODE_ENV`.
   * This variable doesn't depend on how the app was run (npm start, npm test, npm run build).
   * - `'development'` means that the app is meant to be used in the development mode (you can display some debugging information).
   * - `'testing'` means that the app is meant to be used in the mode suitable for public testing (you can display some statistics and metrics).
   * - `'staging'` means that the app is meant to be used as a mirror of the production mode.
   * - `'production'` means that the app is meant to be used in the production mode (all non-production elements must be disabled).
   */
  REACT_APP_STAGE: union([
    literal('development'),
    literal('testing'),
    literal('staging'),
    literal('production'),
  ]),
  /**
   * Configures the Mock Service Worker library.
   * - `'on'` - Enables MSW in the default mode.
   * - `'quiet'` - Disables the logging of captured requests into browser's console.
   * - `'off'` - Disables MSW.
   */
  REACT_APP_MSW: union([literal('on'), literal('off'), literal('quiet')]),
  /**
   * Configures the i18n debugging mode.
   * - `'on'` - Enables debugging mode.
   * - `'off'` - Disables debugging mode.
   */
  REACT_APP_DEBUG_I18N: union([literal('on'), literal('off')]),
  /**
   * Whether to enable Web Vitals reporting.
   * - `'on'` - Enables Web Vitals reporting.
   * - `'off'` - Disables Web Vitals reporting.
   */
  REACT_APP_REPORT_WEB_VITALS: union([literal('on'), literal('off')]),
})
