export const BrandLogo = () => {
  return (
    <svg
      width="123"
      height="30"
      viewBox="0 1.5 123 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.699 20.7752V25H89.4789V21.4252L96.9503 13.4306C97.6102 12.6615 98.1424 11.9736 98.5468 11.367C98.9512 10.7495 99.2439 10.1916 99.4248 9.6933C99.6164 9.19499 99.7122 8.74543 99.7122 8.34462C99.7122 7.66215 99.6111 7.09343 99.4089 6.63846C99.2173 6.17265 98.9299 5.82058 98.5468 5.58226C98.1743 5.34394 97.7113 5.22478 97.1579 5.22478C96.6044 5.22478 96.1202 5.38727 95.7051 5.71225C95.29 6.03724 94.9654 6.48138 94.7313 7.04469C94.5078 7.60799 94.396 8.24171 94.396 8.94584H89C89 7.49425 89.3459 6.16723 90.0377 4.96479C90.7401 3.76236 91.714 2.80366 92.9592 2.08869C94.2044 1.3629 95.6466 1 97.2856 1C98.9991 1 100.436 1.27082 101.596 1.81246C102.756 2.3541 103.629 3.13947 104.214 4.16859C104.81 5.18687 105.108 6.41638 105.108 7.85714C105.108 8.68043 104.98 9.47122 104.725 10.2295C104.47 10.9878 104.102 11.7407 103.624 12.4882C103.145 13.2248 102.559 13.9831 101.867 14.763C101.186 15.543 100.409 16.3717 99.5366 17.2492L96.5353 20.7752H105.699Z"
        fill="white"
      />
      <path
        d="M122 15.7542V19.979H108.269L107.95 16.6317L117.289 1.34123H121.536L116.922 9.02708L113.09 15.7542H122ZM122.653 1.34123V25H117.273V1.34123H122.653Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81 8.06415L79.8571 6.16226H76.5238V10.0566H79.7619L80.9048 8.06415H81ZM67.0952 10.0566H69.9524V17.8453C69.9524 21.9208 71 25 75.8571 25C77.5714 25 79 24.5472 79.8571 24.2755V19.9283C77 20.4717 75.5714 20.5623 75.5714 17.0302V1H69.9524V6.16226H67.4762L67 10.0566H67.0952Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82 1H78L82 8L78 15H82L86 8L82 1Z"
        fill="white"
      />
      <path
        d="M5.19689 16.7273V25H0V1H8.19171C14.0933 1 17 3.54545 17 8.63636C17 11.0909 16.1192 13 14.4456 14.4545C12.772 15.9091 10.4819 16.7273 7.7513 16.7273H5.28497H5.19689ZM5.19689 5.18182V12.6364H7.2228C10.0415 12.6364 11.3627 11.3636 11.3627 8.81818C11.3627 6.27273 9.95337 5.09091 7.2228 5.09091H5.19689V5.18182Z"
        fill="white"
      />
      <path
        d="M26.8555 24.9141C24.0457 24.9141 21.9512 24.2273 20.3707 22.6818C18.7902 21.1364 18 19.1616 18 16.5C18 13.8384 18.7902 11.6919 20.4585 10.2323C22.1268 8.77273 24.322 8 27.1317 8C29.9415 8 32.0488 8.77273 33.6293 10.2323C35.2098 11.6919 36 13.7525 36 16.2424C36 18.904 35.2098 21.0505 33.6293 22.596C32.0488 24.1414 29.7659 24.9141 27.0439 24.9141H26.8555ZM26.9453 11.7778C25.716 11.7778 24.761 12.2929 24.1463 13.0657C23.5317 13.8384 23.1805 15.0404 23.1805 16.5C23.1805 19.5909 24.4098 21.1364 27.0439 21.1364C29.678 21.1364 30.7317 19.5051 30.7317 16.3283C30.7317 13.3232 29.5024 11.7778 27.0439 11.7778H26.9453Z"
        fill="white"
      />
      <path
        d="M42 5.32491C41.0909 5.32491 40.3636 5.05415 39.8182 4.51264C39.2727 3.97112 39 3.33935 39 2.61733C39 1.89531 39.2727 1.17329 39.8182 0.722022C40.3636 0.270758 41.0909 0 42 0C42.9091 0 43.6364 0.270758 44.1818 0.722022C44.7273 1.26354 45 1.89531 45 2.61733C45 3.33935 44.7273 4.06137 44.1818 4.60289C43.6364 5.1444 42.9091 5.32491 42 5.32491ZM44.6364 25H39.3636V8.03249H44.6364V25Z"
        fill="white"
      />
      <path
        d="M64.7422 25H59.7077V15.2832C59.7077 12.7213 58.8244 11.3962 56.9696 11.3962C56.0863 11.3962 55.3797 11.7496 54.7615 12.4563C54.1432 13.163 53.8782 14.0464 53.8782 15.0182V25H48.7554V7.95101H53.8782V10.6012C55.1148 8.56939 56.8813 7.59766 59.1777 7.59766C62.8873 7.59766 64.7422 9.89448 64.7422 14.4881V25Z"
        fill="white"
      />
    </svg>
  )
}
