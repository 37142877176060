import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { IconCheck, IconUnCheck } from 'assets/icons'

export type Props = CheckboxProps & {
  label?: React.ReactNode
}

export const Checkbox = ({ label, ...rest }: Props) => {
  const controlLabel = label ? (
    <Typography variant="body2">{label}</Typography>
  ) : (
    ''
  )

  return (
    <FormControlLabel
      disabled={rest.disabled}
      label={controlLabel}
      control={
        <MuiCheckbox
          {...rest}
          color="primary"
          disableRipple
          icon={<IconUnCheck />}
          checkedIcon={<IconCheck />}
        />
      }
    />
  )
}
