export const IconClientParcelReturnLarge = () => (
  <svg
    width="52"
    height="54"
    viewBox="0 0 52 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M26.0005 53.4484C39.9335 53.4484 51.2284 46.8789 51.2284 38.775C51.2284 30.6711 39.9335 24.1016 26.0005 24.1016C12.0674 24.1016 0.772461 30.6711 0.772461 38.775C0.772461 46.8789 12.0674 53.4484 26.0005 53.4484Z"
      fill="#0061AF"
    />
    <circle
      cx="23.1126"
      cy="17.3769"
      r="16.3412"
      transform="rotate(179.88 23.1126 17.3769)"
      fill="white"
      stroke="#0065AF"
      strokeWidth="2"
    />
    <path
      d="M25.0155 20.6317L17.2978 20.6714L20.5137 24.042L20.5795 24.111L20.5137 24.1801L19.6659 25.069L19.5935 25.1449L19.5211 25.069L14.7001 20.0161L14.6343 19.9471L14.7001 19.878L19.6048 14.7366L19.6772 14.6608L19.7495 14.7366L20.5977 15.6256L20.6635 15.6946L20.5977 15.7636L17.3061 19.2147L24.3099 19.1792V19.1744H24.4099H25.2721C27.5839 19.1744 29.4736 17.2012 29.4736 14.7656C29.4736 12.3308 27.5839 10.3573 25.2721 10.3573H22.5144H22.4144V10.2573V9V8.9H22.5144H25.2715C28.3644 8.9 30.8725 11.5356 30.8725 14.7656C30.8725 17.9963 28.3644 20.6317 25.2715 20.6317H25.0157H25.0155Z"
      fill="#0065AF"
      stroke="#0065AF"
      strokeWidth="0.2"
    />
    <path
      d="M37.5684 42.2185V31.8132C37.5684 31.3876 37.8249 31.0071 38.2222 30.8418L46.9692 27.2415C46.9843 27.2365 46.9994 27.2465 46.9994 27.2616V38.4931V38.4981L38.0563 42.534C37.8299 42.6391 37.5684 42.4689 37.5684 42.2185Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M36.6613 30.6008L27.8542 27.0159C27.839 27.0109 27.839 26.991 27.8542 26.9811L37.3345 23.1525C37.461 23.1028 37.5977 23.1028 37.7192 23.1525L46.9312 26.9214C47.0223 26.9562 47.0223 27.0855 46.9312 27.1203L38.3974 30.6008C37.8406 30.8395 37.213 30.8345 36.6613 30.6008Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0277 26.9551C27.9377 26.9194 27.8428 26.9857 27.8428 27.0826L28.0177 38.2333C28.0177 38.5241 28.1926 38.7842 28.4574 38.8913L37.5522 42.564C37.5622 42.5691 37.5722 42.5589 37.5672 42.5538L37.5422 31.4439C37.5422 31.2042 37.4023 30.9951 37.1874 30.8981L28.0277 26.9551Z"
      fill="white"
      stroke="#0062AD"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M41.9893 29.2398V33.7246L44.6417 32.5325V28.125L41.9893 29.2398Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M41.8722 29.3031L39.6318 28.1716L42.3821 26.9453L44.642 28.0874L41.8722 29.3031Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
    <path
      d="M32.7058 29.0096L42.2842 24.9628L37.8917 23.1946C37.6637 23.0905 37.4053 23.0905 37.1773 23.1846L28.1947 27.0184C28.1187 27.0531 28.1187 27.1621 28.1947 27.1918L32.7058 29.0096Z"
      fill="#0062AD"
      stroke="#0062AD"
      strokeMiterlimit="10"
    />
  </svg>
)
