import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconMap = (props: SvgIconProps & { strokecolor: string }) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      d="M12.3982 23.448C11.1586 21.6509 10.1095 20.1565 9.22099 18.8908C7.64464 16.6454 6.57355 15.1197 5.84043 13.9006C5.27986 12.9685 4.95456 12.2741 4.76279 11.6265C4.57329 10.9866 4.5 10.3447 4.5 9.5C4.5 5.08171 8.08171 1.5 12.5 1.5C16.9183 1.5 20.5 5.08171 20.5 9.5C20.5 10.3447 20.4267 10.9866 20.2372 11.6265C20.0454 12.2741 19.7201 12.9685 19.1596 13.9006C18.4264 15.1197 17.3554 16.6454 15.779 18.8908C14.8906 20.1563 13.8418 21.6503 12.6025 23.4469C12.5531 23.5178 12.4465 23.5177 12.3973 23.4466L11.5751 24.0157L12.3982 23.448ZM12.5 14.25C15.1234 14.25 17.25 12.1234 17.25 9.5C17.25 6.87664 15.1234 4.75 12.5 4.75C9.87664 4.75 7.75 6.87664 7.75 9.5C7.75 12.1234 9.87664 14.25 12.5 14.25Z"
      stroke={props.strokecolor}
      strokeWidth="2"
    />
  </SvgIcon>
)
