import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { adminDefaultPath, operatorDefaultPath } from 'app/app-menu'
import { useAuthContext } from 'app/auth'
import { isAdminRole } from 'app/codecs/user'
import { IconHelpCircle } from 'assets/icons'
import { IconMenu } from 'assets/icons/menu'
import { IconProfileCircle } from 'assets/icons/profile-circle'
import { BrandLogo } from 'components/brand-logo'
import { Profile } from 'components/forms/profile'
import { ParcelActionsDialog } from 'components/parcel-actions-dialog'
import { SupportDialog } from 'components/support-dialog'
import { useBoolean } from 'hooks/use-boolean'
import { useBooleanContext } from 'hooks/use-boolean-context'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { formatBranch } from 'meest-domain/format'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { drawerWidth, smDrawerWidth } from './app-layout'

type Props = {
  onChooseBranchDialog: () => void
}

export const AppContentHeader = ({ onChooseBranchDialog }: Props) => {
  const helpDialog = useBoolean()
  const profileDialog = useBoolean()
  const history = useHistory()

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const theme = useTheme()
  const { auth } = useAuthContext()
  const drawer = useBooleanContext()

  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const isAdmin = isAdminRole(auth.role)

  const formattedBranch = useMemo(
    () =>
      auth.branch
        ? `${formatBranch(language)(auth.branch)}, ${
            auth.branch.branchType.weightLimit
          }`
        : null,
    [auth.branch, language],
  )

  const $getBranchList = useMutation('POST', '/api/pudo/data/branches')

  const [isOpenParcelActionDialog, setIsOpenParcelActionDialog] =
    useState(false)

  return (
    <Stack height={'100%'} display={'flex'} flexDirection={'row'}>
      <Box
        width={smDown ? smDrawerWidth : drawerWidth}
        pl={1.5}
        display={'flex'}
        alignItems={'center'}
      >
        <Hidden mdUp>
          <Grid item>
            <IconButton
              onClick={drawer.toggle}
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
            >
              <IconMenu />
            </IconButton>
          </Grid>
        </Hidden>
        <Grid item>
          <Box
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() =>
              history.push(auth.branch ? operatorDefaultPath : adminDefaultPath)
            }
          >
            <IconButton
              disableFocusRipple
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <BrandLogo />
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        width={`calc(100% - ${smDown ? smDrawerWidth : drawerWidth})`}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        pl={2}
        pr={1}
      >
        <Box pr={1}>
          <Grid item>
            {formattedBranch && !mdDown && (
              <Typography
                color="white"
                fontSize={'0.875rem'}
                variant="caption1"
              >
                {formattedBranch}
              </Typography>
            )}
          </Grid>
        </Box>
        <Box display={'flex'} flexDirection={'row'}>
          {!mdDown && isAdmin && (
            <Grid
              item
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={1}
              mr={1}
            >
              <Grid item>
                <LoadingButton
                  onClick={() => $getBranchList.mutate({})}
                  disableRipple
                  sx={{
                    padding: 1,
                    backgroundColor: '#005096',
                    color: 'white',
                    '&:hover': { backgroundColor: '#89A6CC' },
                  }}
                  size="small"
                  loading={$getBranchList.isLoading}
                >
                  {t('buttons.reload_branch')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button
                  onClick={onChooseBranchDialog}
                  disableRipple
                  sx={{
                    padding: 1,
                    backgroundColor: '#005096',
                    color: 'white',
                    '&:hover': { backgroundColor: '#89A6CC' },
                  }}
                  size="small"
                >
                  {t('buttons.change_branch')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setIsOpenParcelActionDialog(true)}
                  disableRipple
                  sx={{
                    padding: 1,
                    backgroundColor: 'white',
                    color: '#0065AF',
                    '&:hover': { color: 'white' },
                  }}
                  size="small"
                >
                  {t('buttons.track_parcel')}
                </Button>
                <ParcelActionsDialog
                  isOpen={isOpenParcelActionDialog}
                  onClose={() => setIsOpenParcelActionDialog(false)}
                />
              </Grid>
            </Grid>
          )}

          {!mdDown && !isAdmin && (
            <Grid
              item
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={1}
              mr={1}
            >
              <Grid item>
                <Button
                  onClick={() => setIsOpenParcelActionDialog(true)}
                  disableRipple
                  sx={{
                    padding: 1,
                    backgroundColor: 'white',
                    color: '#0065AF',
                    '&:hover': { color: 'white' },
                  }}
                  size="small"
                >
                  {t('buttons.track_parcel')}
                </Button>
                <ParcelActionsDialog
                  isOpen={isOpenParcelActionDialog}
                  onClose={() => setIsOpenParcelActionDialog(false)}
                />
              </Grid>
            </Grid>
          )}

          <Grid item>
            <IconButton
              onClick={helpDialog.setTrue}
              sx={{
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              <IconHelpCircle />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={profileDialog.setTrue}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              <IconProfileCircle />
              <Typography
                color="#0065AF"
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                }}
              >
                {auth.fullName ? auth.fullName.charAt(0) : ''}
              </Typography>
            </IconButton>
          </Grid>
        </Box>
      </Box>

      <SupportDialog
        title={t('dialog_support.phone_title')}
        open={helpDialog.isTrue}
        onClose={helpDialog.setFalse}
        phoneNumbers={['+380674338830', '+380504338830']}
        isPhoneButton
      />
      <Profile dialogState={profileDialog} />
    </Stack>
  )
}
