import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconEdit = (props: SvgIconProps) => (
  <SvgIcon {...props} fill="none">
    <path
      d="M11 19.4829H20"
      stroke={props.htmlColor ?? '#2C363F'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8273 4.56194C16.2968 4.0773 16.9811 3.88802 17.6225 4.06541C18.2638 4.24281 18.7648 4.75991 18.9366 5.42195C19.1085 6.08399 18.9251 6.79037 18.4556 7.27502L7.50439 18.5795L4 19.4839L4.8761 15.8664L15.8273 4.56194Z"
      stroke={props.htmlColor ?? '#2C363F'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)
