import { Box } from '@material-ui/core'
import { IconAlert } from 'assets/icons'
import { TextField } from 'components/ui/text-field'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export const phonePrefix = '+380'

export const isNumericString = (value: string) => {
  return value
    .split('')
    .every(symbol => Number.isInteger(Number.parseInt(symbol, 10)))
}

type Props = {
  value: string
  onChange: (value: string) => void
  onBlur: (event: unknown) => void
  error?: boolean
  helperText?: ReactNode
  required?: boolean
  placeholder?: string
  label: string
  name: string
}

export const PhoneInput = (props: Props) => {
  const { t } = useTranslation()

  return (
    <TextField
      value={props.value}
      onChange={e => {
        const { value } = e.target

        if (isNumericString(value)) {
          props.onChange(value)
        }
      }}
      required={props.required}
      onBlur={props.onBlur}
      name={props.name}
      label={t(props.label)}
      placeholder={props.placeholder}
      type="tel"
      startAdornment={<Box lineHeight="initial">{phonePrefix}</Box>}
      autoComplete="tel"
      error={props.error}
      helperText={props.helperText}
      maxLength={9}
      endAdornment={props.error && <IconAlert />}
    />
  )
}
