import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  useTheme,
} from '@material-ui/core'
import { useAuthContext } from 'app/auth'
import { TBranch } from 'app/codecs/branch'
import { selectOption } from 'app/options'
import {
  IconAddParcelPlace,
  IconCross,
  IconDocsParcel,
  IconLParcel,
  IconMParcel,
  IconSParcel,
  IconXsParcel,
} from 'assets/icons'
import { ControlledCreditCardInput } from 'components/form/controlled-credit-card-input'
import { ControlledMoneyInput } from 'components/form/controlled-money-input'
import { ControlledRadioGroup } from 'components/form/controlled-radio-group'
import { ControlledSelectCard } from 'components/form/controlled-select-card'
import { FormField } from 'components/form/form-field'
import { cardForCOD } from 'components/form/validations'
import { ParcelFieldValues } from 'components/forms/create-parcel/parcel-form'
import { Switch } from 'components/ui/switch'
import { useBoolean } from 'hooks/use-boolean'
import { usePlacesChanges } from 'hooks/use-places-changes'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { isBranchAvailableForPlaces } from 'meest-domain/utils'
import { useEffect, useState } from 'react'
import {
  Control,
  useFieldArray,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type ParcelStepProps = {
  control: Control<ParcelFieldValues>
  watch: UseFormWatch<ParcelFieldValues>
  clearErrors: UseFormClearErrors<ParcelFieldValues>
  setValue: UseFormSetValue<ParcelFieldValues>
}

export const ParcelInfoStep = ({
  control,
  watch,
  setValue,
}: ParcelStepProps) => {
  const { t } = useTranslation()
  const { auth } = useAuthContext()

  const maxInsurance = auth.branch?.insuranceMax

  const theme = useTheme()

  const afterPay = watch('deliveryCash.cod')
  const places = watch('places')
  const deliveryPlaceIdValue = watch('receiver.deliveryPlaceId.value')

  const $currentBranch = useMutation(
    'GET',
    '/api/pudo/branches/:branchId',
    TBranch,
  )

  const cod = useBoolean(afterPay ? true : false)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'places',
  })

  const [sumInsurance, setSumInsurance] = useState(0)

  useEffect(() => {
    setSumInsurance(
      places
        .map(item => Number(item.insurance))
        .reduce((prev, curr) => prev + curr, 0),
    )
  }, [places])

  const getMaxInsuranceForPlace = (index: number) => {
    if (maxInsurance == 0 || maxInsurance === undefined) return 0
    const currentInsurance = places[index]?.insurance || 0
    return maxInsurance - sumInsurance + Number(currentInsurance)
  }

  const handlePlacesChange = () => {
    if (deliveryPlaceIdValue) {
      $currentBranch.mutate(
        { params: { branchId: deliveryPlaceIdValue } },
        {
          onSuccess: branch => {
            if (
              !isBranchAvailableForPlaces(
                branch.branchType.weightLimit!,
                branch.insuranceMax!,
                places,
              )
            )
              setValue('receiver.deliveryPlaceId', null)
          },
        },
      )
    }
  }

  usePlacesChanges(places, handlePlacesChange)

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Box
            key={item.id}
            sx={{
              position: 'relative',
            }}
          >
            {index > 0 && (
              <>
                <Divider sx={{ marginY: '10px' }} />
                <IconButton
                  size="small"
                  onClick={() => remove(index)}
                  sx={{
                    maxWidth: '1.6rem',
                    maxHeight: '1.6rem',
                    position: 'absolute',
                    right: 0,
                    zIndex: 1,
                  }}
                >
                  <IconCross strokecolor={theme.palette.secondary.main} />
                </IconButton>
              </>
            )}
            <FormField>
              <ControlledSelectCard
                label={'form_fields.size'}
                control={control}
                name={`places.${index}.size` as const}
                required
                cards={[
                  {
                    title: 'Docs',
                    description: '31 х 22 х 3 см до 0,5 кг',
                    icon: <IconDocsParcel />,
                    value: 'DOCUMENTS',
                    disabled: true,
                  },
                  {
                    title: 'XS',
                    description: '23 х 16 х 10 см до 1 кг',
                    icon: <IconXsParcel />,
                    value: 'XS',
                    disabled: true,
                  },
                  {
                    title: 'S',
                    description: '33 х 23 х 10 см до 2 кг',
                    icon: <IconSParcel />,
                    value: 'S',
                  },
                  {
                    title: 'M',
                    description: '39 х 34 х 28 см до 10 кг',
                    icon: <IconMParcel />,
                    value: 'M',
                  },
                  {
                    title: 'L',
                    description: '70 х 42 х 40 см до 30 кг',
                    icon: <IconLParcel />,
                    value: 'L',
                    fullSize: true,
                  },
                ]}
              />
            </FormField>
            <FormField>
              <ControlledMoneyInput
                name={`places.${index}.insurance` as const}
                control={control}
                label={'form_fields.insurance'}
                min={1}
                {...(getMaxInsuranceForPlace(index) != 0 && {
                  max: getMaxInsuranceForPlace(index),
                })}
                {...(maxInsurance != 0 && {
                  helperText: t('validations.insurance', { maxInsurance }),
                })}
                required
              />
            </FormField>
          </Box>
        )
      })}
      <Box my={2}>
        <Button
          size="small"
          fullWidth
          variant="contained"
          onClick={() => {
            append({ size: undefined, insurance: '500' })
          }}
          startIcon={
            <IconAddParcelPlace
              sx={{
                color: theme.palette.background.default,
              }}
            />
          }
        >
          {t('parcel_options.add_parcel_place')}
        </Button>
      </Box>
      <FormField>
        <ControlledRadioGroup
          control={control}
          label={'form_fields.payer'}
          name="receiverPay"
          required
          options={[
            selectOption(t('form_fields.sender'), 'false'),
            selectOption(t('form_fields.receiver'), 'true'),
          ]}
        />
      </FormField>
      <FormField>
        <FormControlLabel
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            ml: 0,
            mr: 0,
          }}
          labelPlacement="start"
          control={
            <Switch
              checked={cod.isTrue}
              onChange={e => {
                cod.toggle()
                if (!e.target.checked) {
                  setValue('afterPay', null)
                  setValue('deliveryCash.cod', '')
                  setValue('branchForCOD.branchCity', null)
                  setValue('branchForCOD.branchId', null)
                  setValue('deliveryCash.cardNumber', '')
                }
              }}
            />
          }
          label={t('form_fields.cod')}
        />
      </FormField>

      {cod.isTrue && (
        <>
          <FormField>
            <ControlledCreditCardInput
              name="deliveryCash.cardNumber"
              control={control}
              label={t('form_fields.card_for_COD')}
              required
              pattern={{
                value: cardForCOD.pattern.value,
                message: t(cardForCOD.pattern.message),
              }}
            />
          </FormField>

          <FormField>
            <ControlledMoneyInput
              name="deliveryCash.cod"
              control={control}
              label={'form_fields.COD_sum'}
              min={1}
              max={sumInsurance}
              required
              helperText={t('validations.cod')}
            />
          </FormField>
        </>
      )}
    </>
  )
}
