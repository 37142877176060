import { Alert, IconButton, Snackbar, useTheme } from '@material-ui/core'
import { IconClose, IconError, IconSuccess } from 'assets/icons'
import {
  NotificationProvider as Provider,
  useNotificationContext,
} from 'hooks/use-notification'
import { ReactNode } from 'react'

export type Severity = 'error' | 'success' | 'warning'

const Notification = () => {
  const { message, closeMessage } = useNotificationContext()

  const theme = useTheme()

  const iconMapping: {
    [key: string]: ReactNode
  } = {
    success: <IconSuccess strokecolor={theme.palette.success.main} />,
    warning: <IconError strokecolor={theme.palette.warning.main} />,
    error: <IconError strokecolor={theme.palette.error.main} />,
  }

  if (message === null) {
    return null
  }

  return (
    <Snackbar
      open={true}
      autoHideDuration={6000}
      onClose={closeMessage}
      sx={{ marginTop: '46.5vh' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity={message.type}
        sx={{ display: 'flex', alignItems: 'center', height: '65px' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            sx={{ px: 0.5, py: 0, mb: '4px' }}
            onClick={closeMessage}
          >
            <IconClose />
          </IconButton>
        }
        icon={iconMapping[message.type]}
      >
        {message.text}
      </Alert>
    </Snackbar>
  )
}

type Props = {
  children: ReactNode
}

export const NotificationProvider = ({ children }: Props) => {
  return (
    <Provider>
      <Notification />
      {children}
    </Provider>
  )
}
