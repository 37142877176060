import {
  Autocomplete,
  ListItemText,
  TextFieldProps,
  useTheme,
} from '@material-ui/core'
import Tooltip from '@mui/material/Tooltip'
import { AutocompleteOption } from 'app/options'
import { useUuid } from 'hooks/use-uuid'
import { Mixed, TypeOf } from 'io-ts'
import { useQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import debounce from 'lodash.debounce'
import { useMemo, useState } from 'react'

import { FormControl } from '../form-control'
import { InputBase } from '../input-base'
import { MenuItem } from '../menu-item'
import { ListboxComponent } from './list-box'

export type RawBody = {
  [k: string]: unknown
}

type Props<C extends Mixed> = {
  label?: string
  option: AutocompleteOption
  disabled?: boolean
  size?: TextFieldProps['size']
  required?: boolean
  optionsUrl: string
  optionsCodec: C
  name: string
  formatOptions: (options: TypeOf<C>) => Array<AutocompleteOption>
  onChange: (value: AutocompleteOption) => void
  onBlur?: () => void
  error?: boolean
  helperText?: string
  loading?: boolean
  queryParam?: string
  serverSearch?: boolean
  searchBodyParam?: string
  rawBody?: RawBody
  showTip?: boolean
}

export const AutocompleteSingle = <C extends Mixed>(props: Props<C>) => {
  const [inputValue, setInputValue] = useState('')

  const params = new URLSearchParams()

  if (props.queryParam) {
    params.set(props.queryParam, inputValue)
  }

  const rawBody = {
    ...(props.rawBody ? props.rawBody : undefined),
    ...(props.searchBodyParam
      ? { [props.searchBodyParam]: inputValue }
      : undefined),
  }

  const $data = useQuery(
    props.searchBodyParam ? 'POST' : 'GET',
    props.serverSearch
      ? props.optionsUrl + inputValue
      : concatQueryParams(props.optionsUrl, params),
    props.optionsCodec,
    {
      rawBody: props.searchBodyParam || props.rawBody ? rawBody : undefined,
    },
  )

  const { data, isLoading } = $data

  const options = useMemo(
    () => (data ? props.formatOptions(data) : []),
    [data, props],
  )

  const id = useUuid()

  const theme = useTheme()

  return (
    <FormControl
      label={props.label}
      required={props.required}
      helperText={props.helperText}
      error={props.error}
      htmlFor={id}
    >
      <Autocomplete
        id={id}
        fullWidth
        options={options}
        disabled={props.disabled}
        loading={isLoading}
        value={props.option}
        ListboxComponent={ListboxComponent}
        onChange={(_event, option) => {
          props.onChange(option)
        }}
        onInputChange={debounce((_event, newInputValue) => {
          setInputValue(newInputValue)
        }, 500)}
        ListboxProps={{
          style: {
            padding: 0,
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 5px 12px rgba(128, 134, 140, 0.2)',
            borderRadius: '10px',
          },
        }}
        disableClearable
        onBlur={props.onBlur}
        forcePopupIcon={false}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) =>
          option.label === value.label || option.value === value.value
        }
        renderOption={(optionProps, option) => (
          <MenuItem
            {...optionProps}
            onClick={e => {
              if (optionProps.onClick) {
                optionProps.onClick(e)

                props.onChange(option)
              }
            }}
            value={option.label}
            sx={{ color: option.option === 'closedBranch' ? 'red' : '' }}
          >
            {props.showTip && (
              <Tooltip
                title={option.label}
                disableInteractive={true}
                placement="top"
              >
                <ListItemText
                  disableTypography
                  sx={{
                    paddingY: theme.spacing(0.5),
                    textOverflow: 'ellipsis',
                    fontSize: theme.typography.body2.fontSize,
                    overflow: 'hidden',
                    fontWeight: 400,
                  }}
                >
                  {option.label}
                </ListItemText>
              </Tooltip>
            )}
            {!props.showTip && (
              <ListItemText
                disableTypography
                sx={{
                  paddingY: theme.spacing(0.5),
                  textOverflow: 'ellipsis',
                  fontSize: theme.typography.body2.fontSize,
                  overflow: 'hidden',
                  fontWeight: 400,
                }}
              >
                {option.label}
              </ListItemText>
            )}
          </MenuItem>
        )}
        renderInput={params => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { InputLabelProps, InputProps, ...rest } = params
          return (
            <InputBase
              {...rest}
              ref={InputProps.ref}
              name={props.name}
              className={InputProps.className}
              endAdornment={InputProps.endAdornment}
              startAdornment={InputProps.startAdornment}
              error={props.error}
            />
          )
        }}
      />
    </FormControl>
  )
}
