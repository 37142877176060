import {
  DialogActions,
  DialogContent,
  DialogProps,
  useTheme,
} from '@material-ui/core'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { Dialog } from 'components/ui/dialog'
import { useBoolean } from 'hooks/use-boolean'
import { Handler } from 'lib/react-handy-types'
import { scrollToView } from 'lib/scroll-to-view'
import { ReactNode, useRef } from 'react'
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<TFieldValues extends FieldValues, T> = Omit<
  DialogProps,
  'onClose' | 'onSubmit'
> & {
  actions: ReactNode
  children?: React.ReactNode
  child?: React.ReactNode
  onClose: Handler
  data?: T
  onSubmit: SubmitHandler<TFieldValues>
  closeDialogTitle?: string
  form: UseFormReturn<TFieldValues>
  title: string
  saveStateOnClose?: boolean
  headAction?: ReactNode
  isBlockTopBorder?: boolean
}

export const FormDialog = <TFieldValues extends FieldValues, T>(
  props: Props<TFieldValues, T>,
) => {
  const confirmationDialog = useBoolean(false)
  const { errors } = props.form.formState
  const formEl = useRef(null)
  const theme = useTheme()

  const { t } = useTranslation()

  const handleSubmit = () => {
    scrollToView(errors)

    return props.form.handleSubmit(props.onSubmit)
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={confirmationDialog.setTrue}
        title={t(props.title)}
        headAction={props.headAction}
      >
        <form
          noValidate
          ref={formEl}
          onSubmit={handleSubmit()}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'overlay',
          }}
        >
          <DialogActions
            sx={{
              px: { xs: 3, md: 8 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {props.child}
          </DialogActions>
          <DialogContent
            sx={{
              overflowY: 'auto',
              px: { xs: 3, md: 8 },
              py: 0,
              flexGrow: 1,
            }}
          >
            {props.children}
          </DialogContent>
          <DialogActions
            sx={{
              px: { xs: 3, md: 8 },
              py: 2,
              display: 'flex',
              flexDirection: 'column',
              borderTop: props.isBlockTopBorder
                ? `1px solid ${theme.palette.primary.main}`
                : '',
            }}
            disableSpacing
          >
            {props.actions}
          </DialogActions>
        </form>
      </Dialog>
      {props.closeDialogTitle && (
        <ConfirmationDialog
          isOpen={confirmationDialog.isTrue}
          onClose={confirmationDialog.setFalse}
          title={props.closeDialogTitle}
          onConfirm={() => {
            if (!props.saveStateOnClose) {
              props.form?.reset()
            }
            confirmationDialog.setFalse()
            props.onClose()
          }}
        />
      )}
    </>
  )
}
