import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { IconClose } from 'assets/icons'
import LogoTelegram from 'assets/icons/logo-telegram.png'
import LogoViber from 'assets/icons/logo-viber.png'
import QrTelegram from 'assets/icons/qr-telegram.png'
import QrViber from 'assets/icons/qr-viber.png'
import { useTranslation } from 'react-i18next'

const getTelHref = (tel: string) => {
  return `tel:${tel.replace(/\+\s-/g, '')}`
}

type Props = {
  open: boolean
  title: string
  onClose: () => void
  phoneNumbers: Array<string>
  isPhoneButton?: boolean
  text?: string
}

export const SupportDialog = (props: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      sx={{
        p: !smDown ? 2 : 0,
      }}
      PaperProps={{
        sx: { p: 3 },
      }}
      fullScreen={smDown}
    >
      <DialogTitle sx={{ padding: '0px 0px 16px' }}>
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="close"
              onClick={props.onClose}
              sx={{ p: 0 }}
            >
              <IconClose />
            </IconButton>
          </Box>
          <Box paddingTop={1} px={8}>
            <Typography variant="h3" textAlign="center">
              {props.title}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {props.text && (
          <DialogContentText
            sx={{
              padding: '0px 0px 16px',
              textAlign: 'center',
              marginBottom: 0,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {props.text}
            </Typography>
          </DialogContentText>
        )}
        <Typography variant="body2" textAlign="center">
          {t('dialog_support.choose_variant')}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Divider />
          </Box>
          <Typography variant="body2" color="textSecondary" px={1}>
            {t('dialog_support.chat_bot')}
          </Typography>
          <Box flex={1}>
            <Divider />
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '2rem' }}
        >
          {smDown ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              alignItems={'center'}
            >
              <a href="viber://pa?chatURI=meestposhtamvsupport">
                <img width="50" src={LogoViber} alt="logo-viber" />
              </a>
              <a href="viber://pa?chatURI=meestposhtamvsupport">
                <img width={150} src={QrViber} alt="qr-viber" />
              </a>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              alignItems={'center'}
            >
              <img width="50" src={LogoViber} alt="logo-viber" />
              <img width={smDown ? 150 : 200} src={QrViber} alt="qr-viber" />
            </Box>
          )}
          {smDown ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              alignItems={'center'}
            >
              <a href="https://t.me/MeestPoshtaMVSupport_bot">
                <img width="50" src={LogoTelegram} alt="logo-telegram" />
              </a>
              <a href="https://t.me/MeestPoshtaMVSupport_bot">
                <img width={150} src={QrTelegram} alt="qr-telegram" />
              </a>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              alignItems={'center'}
            >
              <img width="50" src={LogoTelegram} alt="logo-telegram" />
              <img
                width={smDown ? 150 : 200}
                src={QrTelegram}
                alt="qr-telegram"
              />
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Divider />
          </Box>
          <Typography variant="body2" color="textSecondary" px={1}>
            {t('dialog_support.or_by_phone')}
          </Typography>
          <Box flex={1}>
            <Divider />
          </Box>
        </Box>
        <Stack
          textAlign="center"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            gap: '2rem',
          }}
        >
          {props.phoneNumbers.map(number => (
            <Typography
              key={number}
              variant="h2"
              color="primary"
              textAlign="center"
            >
              {number}
            </Typography>
          ))}
        </Stack>
      </DialogContent>
      {props.isPhoneButton && (
        <DialogActions sx={{ p: 0 }}>
          <Button
            fullWidth
            size="large"
            href={getTelHref(t('dialog_support.support_phone_number'))}
          >
            {t('dialog_support.button_call')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
