import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  useTheme,
} from '@material-ui/core'
import { useUuid } from 'hooks/use-uuid'
import { useTranslation } from 'react-i18next'

import { FormControl, FormControlProps } from '../form-control'

export type Option = {
  label: string
  value: string | number
}

export type RadioGroupProps = Omit<FormControlProps, 'children' | 'htmlFor'> &
  MuiRadioGroupProps & {
    options: ReadonlyArray<Option>
    isLoading?: boolean
    disabled?: boolean
    sxFormControlLabel?: FormControlLabelProps['sx']
  }

export const RadioGroup = (props: RadioGroupProps) => {
  const id = useUuid()
  const theme = useTheme()

  const { t } = useTranslation()

  return (
    <FormControl
      htmlFor={id}
      label={props.label}
      error={props.error}
      helperText={props.helperText}
      required={props.required}
    >
      <MuiRadioGroup
        id={id}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        sx={{ display: 'flex', flexDirection: 'row', ...props.sx }}
      >
        {props.options.map(option => (
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio />}
            label={t(option.label)}
            sx={{
              '&:first-of-type': { ml: theme.spacing(-1) },
              ...props.sxFormControlLabel,
            }}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  )
}

RadioGroup.displayName = 'RadioGroup'
