import { CircularProgress, Divider, Stack, Typography } from '@material-ui/core'
import { Box } from '@material-ui/system'
import { useAuthContext } from 'app/auth'
import { foldService } from 'app/codecs/parcel'
import { TDeliveryValue } from 'app/codecs/parcel'
import { ParcelStepProps } from 'components/forms/create-parcel/steps/parcel-info-step'
import { DualItems } from 'components/ui/dual-items'
import { getOrHyphen } from 'lib/nullable'
import { useQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import { formatPhone } from 'meest-domain/format'
import { useMemo } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { setAdditionalOptions } from '../index'

// TODO Memo prevents component re-render. Optimize this component when will have free time.
export const ParcelDetailsStep = memo(
  ({ watch, setValue }: ParcelStepProps) => {
    const { auth } = useAuthContext()
    const parcel = watch()
    const { t } = useTranslation()

    const deliveryParams = auth.branch
      ? new URLSearchParams({ branchId: auth.branch.branchId })
      : undefined

    const bodyParams = {
      places: parcel.places.map(item => {
        return {
          size: item.size,
          insurance: Number(item.insurance),
        }
      }),
      cod: Number(parcel.deliveryCash?.cod) || undefined,
      receiver: {
        ...(parcel.receiver.service &&
          foldService({
            onBranch: () => ({
              deliveryPlaceId: parcel.receiver.deliveryPlaceId?.value,
            }),
            onCourier: () => ({
              deliveryPlaceId:
                parcel.receiver.addressRequest.city?.value.cityId,
            }),
            onParcelMachine: () => ({
              deliveryPlaceId: parcel.receiver.deliveryPlaceId?.value,
            }),
          })(parcel.receiver.service)),

        service: parcel.receiver.service,
      },
      options: setAdditionalOptions(parcel),
    }

    const $deliveryValue = useQuery(
      'POST',
      concatQueryParams('/api/pudo/parcels/calculate', deliveryParams),
      TDeliveryValue,
      {
        rawBody: bodyParams,
        options: {
          onSuccess: () => setValue('isCreateError', false),
          onError: () => setValue('isCreateError', true),
        },
      },
    )

    const addOptionsValue = useMemo(() => {
      if (parcel.branchAdditionalOption) {
        return t('form_fields.UNPACKING_AT_BRANCH')
      }
      if (parcel.courierAdditionalOptions.length > 0) {
        return parcel.courierAdditionalOptions
          .map(option => t(`form_fields.${option}`))
          .join(', ')
      }
    }, [parcel.branchAdditionalOption, parcel.courierAdditionalOptions, t])

    const serviceValue = useMemo(() => {
      if (parcel.receiver.service) {
        return foldService({
          onBranch: () => t('user.branch'),
          onCourier: () => t('service_options.courier'),
          onParcelMachine: () => t('service_options.parcel_machine'),
        })(parcel.receiver.service)
      }
    }, [parcel.receiver.service, t])

    const placeValues = parcel.places.map((item, index) => {
      const labelField = `${t('form_fields.place')} ${index + 1}`
      const valueField = `${t('form_fields.place_size')} - ${getOrHyphen(
        item.size === 'DOCUMENTS' ? 'Docs' : item.size,
      )}, ${t('form_fields.place_insurance')} - ${getOrHyphen(
        item.insurance,
      )} грн`

      return {
        label: labelField,
        value: valueField,
      }
    })

    const parcelDetails = [
      ...placeValues.map(item => item),
      {
        label: 'form_fields.payer',
        value:
          parcel.receiverPay === 'true'
            ? t('parcel.receiver')
            : t('parcel.sender'),
      },
      {
        label: 'form_fields.COD_sum',
        value: parcel.deliveryCash?.cod,
      },
      {
        label: 'form_fields.card_for_COD',
        value: parcel.deliveryCash?.cardNumber,
      },
      {
        label: 'form_fields.sender_phone',
        value: formatPhone(parcel.sender.number),
      },
      {
        label: 'form_fields.receiver_name',
        value:
          parcel.receiver.lastname +
          ' ' +
          parcel.receiver.firstname +
          ' ' +
          parcel.receiver.middleName,
      },
      {
        label: 'form_fields.receiver_phone',
        value: formatPhone(parcel.receiver.number),
      },
      {
        label: 'form_fields.service',
        value: getOrHyphen(serviceValue),
      },
      ...(parcel.receiver.service
        ? foldService({
            onBranch: () => [
              {
                label: 'user.branch',
                value:
                  getOrHyphen(parcel.receiver.branchCity?.label) +
                  ', ' +
                  getOrHyphen(parcel.receiver.deliveryPlaceId?.label),
              },
            ],
            onCourier: () => [
              {
                label: 'form_fields.city',
                value: getOrHyphen(parcel.receiver.addressRequest?.city?.label),
              },
              {
                label: 'form_fields.street',
                value: getOrHyphen(
                  parcel.receiver.addressRequest?.street?.label,
                ),
              },
              {
                label: 'form_fields.building',
                value: getOrHyphen(parcel.receiver.addressRequest?.building),
              },
              {
                label: 'form_fields.corps',
                value: getOrHyphen(parcel.receiver.addressRequest?.corps),
              },
              {
                label: 'form_fields.flat',
                value: getOrHyphen(parcel.receiver.addressRequest?.flat),
              },
            ],
            onParcelMachine: () => [
              {
                label: 'service_options.parcel_machine',
                value: getOrHyphen(parcel.receiver.deliveryPlaceId?.label),
              },
            ],
          })(parcel.receiver.service)
        : []),
      {
        label: 'form_fields.add_option',
        value: getOrHyphen(addOptionsValue),
      },
      {
        label: 'form_fields.notes',
        value: parcel.notes,
      },
    ]

    return (
      <>
        <DualItems view="row-centered" items={parcelDetails} />
        <Divider sx={{ mt: 3 }} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
        >
          <Typography textAlign="end" flex={1} pr={3}>
            {t('form_fields.delivery_value')}
          </Typography>
          {$deliveryValue.isLoading ? (
            <Box flex={1}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Typography flex={1}>
              {$deliveryValue.data?.costServices}
            </Typography>
          )}
        </Stack>
      </>
    )
  },
)
