export const IconDocumentsLarge = () => (
  <svg
    width="48"
    height="60"
    viewBox="0 0 48 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M23.5438 65.5135C36.1961 65.5135 46.4527 59.4952 46.4527 52.0712C46.4527 44.6472 36.1961 38.6289 23.5438 38.6289C10.8914 38.6289 0.634766 44.6472 0.634766 52.0712C0.634766 59.4952 10.8914 65.5135 23.5438 65.5135Z"
      fill="#0061AF"
    />
    <path
      d="M37.7165 44.2851L13.2555 58.6975L11.7705 59.5728C11.747 59.5848 11.7235 59.5992 11.7023 59.6112C11.6624 59.6304 11.6201 59.6496 11.5778 59.6615C11.5285 59.6807 11.4744 59.6975 11.4227 59.7047C11.3875 59.7143 11.3546 59.7167 11.3193 59.7215C11.3123 59.7215 11.3052 59.7263 11.2982 59.7215C11.2559 59.7287 11.2089 59.7311 11.1666 59.7311C11.0162 59.7311 10.8776 59.7023 10.7765 59.6424C10.5369 59.5009 10.2854 59.0956 10.2196 58.7383L6.09817 36.8846C6.08878 36.8391 6.08643 36.7887 6.08643 36.7407C6.08643 36.7024 6.08643 36.6616 6.09582 36.6232V36.6184C6.10522 36.5585 6.11697 36.4985 6.13577 36.441C6.15457 36.381 6.18042 36.3259 6.20861 36.2707C6.22506 36.2347 6.24621 36.2012 6.26971 36.1676C6.29555 36.1292 6.32375 36.0957 6.3543 36.0621C6.38015 36.0309 6.41069 36.0021 6.44124 35.9781C6.47179 35.9518 6.50468 35.9302 6.53758 35.911L32.4836 20.6233C32.5047 20.6113 32.5282 20.5969 32.5517 20.5849C32.5752 20.5729 32.5987 20.5609 32.6246 20.5513C32.6363 20.5465 32.6457 20.5417 32.6598 20.5393C32.6927 20.5297 32.7232 20.5273 32.7538 20.5273C32.9159 20.5393 33.0522 20.652 33.2143 20.748C33.5222 20.9326 33.79 21.0933 33.79 21.0933C33.8323 21.1125 33.8723 21.1413 33.9052 21.1796C33.9498 21.2276 33.9874 21.2899 34.0109 21.3619C34.0227 21.393 34.0274 21.4242 34.0344 21.453L38.1559 43.3067C38.2217 43.664 38.0266 44.1004 37.7188 44.2827L37.7165 44.2851Z"
      fill="#27DEBF"
    />
    <path
      d="M37.7165 44.2851L13.2555 58.6975L11.7705 59.5728C11.747 59.5848 11.7235 59.5992 11.7023 59.6112C11.6624 59.6304 11.6201 59.6496 11.5778 59.6615C11.5285 59.6807 11.4744 59.6975 11.4227 59.7047C11.3875 59.7143 11.3546 59.7167 11.3193 59.7215C11.3123 59.7215 11.3052 59.7263 11.2982 59.7215C11.2559 59.7287 11.2089 59.7311 11.1666 59.7311C11.0162 59.7311 10.8776 59.7023 10.7765 59.6424C10.5369 59.5009 10.2854 59.0956 10.2196 58.7383L6.09817 36.8846C6.08878 36.8391 6.08643 36.7887 6.08643 36.7407C6.08643 36.7024 6.08643 36.6616 6.09582 36.6232V36.6184C6.10522 36.5585 6.11697 36.4985 6.13577 36.441C6.15457 36.381 6.18042 36.3259 6.20861 36.2707C6.22506 36.2347 6.24621 36.2012 6.26971 36.1676C6.29555 36.1292 6.32375 36.0957 6.3543 36.0621C6.38015 36.0309 6.41069 36.0021 6.44124 35.9781C6.47179 35.9518 6.50468 35.9302 6.53758 35.911L32.4836 20.6233C32.5047 20.6113 32.5282 20.5969 32.5517 20.5849C32.5752 20.5729 32.5987 20.5609 32.6246 20.5513C32.6363 20.5465 32.6457 20.5417 32.6598 20.5393C32.6927 20.5297 32.7232 20.5273 32.7538 20.5273C32.9159 20.5393 33.0522 20.652 33.2143 20.748C33.5222 20.9326 33.79 21.0933 33.79 21.0933C33.8323 21.1125 33.8723 21.1413 33.9052 21.1796C33.9498 21.2276 33.9874 21.2899 34.0109 21.3619C34.0227 21.393 34.0274 21.4242 34.0344 21.453L38.1559 43.3067C38.2217 43.664 38.0266 44.1004 37.7188 44.2827L37.7165 44.2851Z"
      fill="#27DEBF"
    />
    <path
      d="M11.7001 59.6096C11.3969 59.7582 10.9998 59.7774 10.7743 59.6455C10.5346 59.504 10.2832 59.0988 10.2174 58.7415L6.0959 36.8878C6.0583 36.6887 6.1053 36.4633 6.20634 36.2715L7.07809 36.7847C6.97706 36.9765 6.93241 37.2019 6.96766 37.401L11.0891 59.257C11.1526 59.5856 11.4157 59.7319 11.7001 59.6096Z"
      fill="#0065AF"
    />
    <path
      d="M33.2094 20.7504C33.5173 20.935 33.7851 21.0957 33.7851 21.0957C33.6606 21.0357 33.5055 21.0453 33.3504 21.1365L7.40443 36.4242C7.26814 36.5033 7.15771 36.6328 7.07781 36.7815L6.20605 36.2683C6.2225 36.2323 6.24365 36.1988 6.26715 36.1652C6.293 36.1268 6.32119 36.0933 6.35174 36.0597C6.40578 35.9997 6.46688 35.947 6.53502 35.9086L32.481 20.6209C32.5257 20.5945 32.5727 20.5705 32.622 20.5513C32.6337 20.5465 32.6432 20.5417 32.6572 20.5393C32.6901 20.5297 32.7207 20.5273 32.7512 20.5273C32.9134 20.5393 33.0497 20.652 33.2118 20.748L33.2094 20.7504Z"
      fill="#0065AF"
    />
    <path
      d="M11.0895 59.258L6.96805 37.4019C6.89991 37.0446 7.09729 36.6081 7.40511 36.4259L33.3511 21.1381C33.6589 20.9559 33.9644 21.0974 34.0302 21.4547L38.1517 43.3107C38.2198 43.6681 38.0224 44.1045 37.7146 44.2868L11.7686 59.5745C11.4608 59.7568 11.1553 59.6153 11.0895 59.258Z"
      fill="#27DEBF"
    />
    <path
      d="M11.0895 59.258L6.96805 37.4019C6.89991 37.0446 7.09729 36.6081 7.40511 36.4259L33.3511 21.1381C33.6589 20.9559 33.9644 21.0974 34.0302 21.4547L38.1517 43.3107C38.2198 43.6681 38.0224 44.1045 37.7146 44.2868L11.7686 59.5745C11.4608 59.7568 11.1553 59.6153 11.0895 59.258Z"
      fill="#FBFBFB"
    />
    <path
      d="M23.1582 39.9151C23.0266 39.8383 22.9209 39.6417 22.9209 39.4762V10.8815C22.9209 10.7161 23.0337 10.5146 23.1747 10.4331L40.7673 0.0662242C40.9083 -0.0153104 41.1268 -0.0201065 41.2584 0.0566319C41.39 0.13337 41.4957 0.330013 41.4957 0.49548V29.0877C41.4957 29.2532 41.3829 29.4546 41.2419 29.5362L23.647 39.9031C23.506 39.9846 23.2875 39.9894 23.1559 39.9127L23.1582 39.9151Z"
      fill="#EBEBEB"
    />
    <path
      d="M41.4956 0.450103C41.4745 0.318209 41.3711 0.272646 41.2442 0.346986L23.6492 10.7139C23.5787 10.7546 23.5129 10.829 23.4707 10.9081L22.9937 10.6275C23.0383 10.546 23.1041 10.4741 23.1746 10.4333L40.7672 0.0664112C40.9058 -0.0175214 41.1291 -0.0223176 41.2583 0.0544208C41.3758 0.126363 41.4768 0.294228 41.4956 0.450103Z"
      fill="#0065AF"
    />
    <path
      d="M23.6145 39.9234C23.4758 39.9881 23.2785 39.9881 23.1563 39.9138C23.0247 39.837 22.9189 39.6404 22.9189 39.4749V10.8851C22.9189 10.8011 22.9471 10.7124 22.9918 10.6309L23.4688 10.9114C23.4218 10.993 23.3936 11.0817 23.3936 11.1656V39.7579C23.3936 39.909 23.4899 39.9785 23.6121 39.9258L23.6145 39.9234Z"
      fill="#0065AF"
    />
    <path
      d="M41.1432 29.3643L23.8498 39.5535V39.5492L23.596 39.7003V11.1601C23.596 11.1194 23.6111 11.0614 23.6442 11.0027C23.6775 10.9437 23.7181 10.9032 23.7499 10.8848L23.7513 10.884L41.2985 0.545239V29.0881C41.2985 29.1288 41.2834 29.1869 41.2503 29.2456C41.217 29.3045 41.1764 29.3451 41.1446 29.3634L41.1432 29.3643Z"
      fill="#F5F5F5"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <path
      d="M24.6812 23.7399C24.6812 23.9606 24.8738 24.0229 25.1135 23.8838L39.783 15.2412C40.0227 15.0997 40.2154 14.8095 40.2154 14.5889C40.2154 14.3683 40.0227 14.3059 39.783 14.445L25.1135 23.0877C24.8738 23.2292 24.6812 23.5193 24.6812 23.7399Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 26.1267C24.6812 26.3473 24.8738 26.4096 25.1135 26.2705L39.783 17.6279C40.0227 17.4864 40.2154 17.1962 40.2154 16.9756C40.2154 16.755 40.0227 16.6926 39.783 16.8317L25.1135 25.4744C24.8738 25.6159 24.6812 25.906 24.6812 26.1267Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 28.5153C24.6812 28.736 24.8738 28.7983 25.1135 28.6592L39.783 20.0166C40.0227 19.8751 40.2154 19.5849 40.2154 19.3643C40.2154 19.1437 40.0227 19.0813 39.783 19.2204L25.1135 27.8631C24.8738 28.0045 24.6812 28.2947 24.6812 28.5153Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 30.9001C24.6812 31.1207 24.8738 31.1831 25.1135 31.044L39.783 22.4013C40.0227 22.2598 40.2154 21.9697 40.2154 21.749C40.2154 21.5284 40.0227 21.4661 39.783 21.6052L25.1135 30.2478C24.8738 30.3893 24.6812 30.6795 24.6812 30.9001Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 16.5778C24.6812 16.7985 24.8738 16.8608 25.1135 16.7217L39.783 8.07906C40.0227 7.93757 40.2154 7.6474 40.2154 7.42678C40.2154 7.20616 40.0227 7.14381 39.783 7.2829L25.1135 15.9256C24.8738 16.067 24.6812 16.3572 24.6812 16.5778Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 18.9646C24.6812 19.1852 24.8738 19.2475 25.1135 19.1084L39.783 10.4658C40.0227 10.3243 40.2154 10.0341 40.2154 9.8135C40.2154 9.59288 40.0227 9.53053 39.783 9.66961L25.1135 18.3123C24.8738 18.4538 24.6812 18.7439 24.6812 18.9646Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 14.1931C24.6812 14.4137 24.8738 14.476 25.1135 14.337L39.783 5.69429C40.0227 5.5528 40.2154 5.26264 40.2154 5.04201C40.2154 4.82139 40.0227 4.75904 39.783 4.89813L25.1135 13.5408C24.8738 13.6823 24.6812 13.9724 24.6812 14.1931Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 21.3513C24.6812 21.5719 24.8738 21.6342 25.1135 21.4952L39.783 12.8525C40.0227 12.711 40.2154 12.4208 40.2154 12.2002C40.2154 11.9796 40.0227 11.9172 39.783 12.0563L25.1135 20.699C24.8738 20.8405 24.6812 21.1307 24.6812 21.3513Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 33.2888C24.6812 33.5094 24.8738 33.5717 25.1135 33.4327L39.783 24.79C40.0227 24.6485 40.2154 24.3583 40.2154 24.1377C40.2154 23.9171 40.0227 23.8547 39.783 23.9938L25.1135 32.6365C24.8738 32.778 24.6812 33.0682 24.6812 33.2888Z"
      fill="#0065AF"
    />
    <path
      d="M24.6812 35.6794C24.6812 35.9 24.8738 35.9624 25.1135 35.8233L39.783 27.1806C40.0227 27.0391 40.2154 26.749 40.2154 26.5283C40.2154 26.3077 40.0227 26.2454 39.783 26.3845L25.1135 35.0271C24.8738 35.1686 24.6812 35.4588 24.6812 35.6794Z"
      fill="#0065AF"
    />
    <path
      d="M29.0244 47.2451C28.8928 47.1684 28.7871 46.9718 28.7871 46.8063V18.2116C28.7871 18.0462 28.8999 17.8447 29.0409 17.7632L46.6335 7.3963C46.7745 7.31477 46.993 7.30997 47.1246 7.38671C47.2562 7.46345 47.3619 7.66009 47.3619 7.82556V36.4178C47.3619 36.5833 47.2491 36.7847 47.1081 36.8663L29.5132 47.2331C29.3722 47.3147 29.1537 47.3195 29.0221 47.2427L29.0244 47.2451Z"
      fill="#EBEBEB"
    />
    <path
      d="M47.3618 7.78018C47.3407 7.64829 47.2373 7.60272 47.1104 7.67706L29.5154 18.0439C29.445 18.0847 29.3792 18.1591 29.3369 18.2382L28.8599 17.9576C28.9045 17.8761 28.9703 17.8041 29.0408 17.7634L46.6334 7.39649C46.772 7.31256 46.9953 7.30776 47.1245 7.3845C47.242 7.45644 47.343 7.62431 47.3618 7.78018Z"
      fill="#0065AF"
    />
    <path
      d="M29.4807 47.2534C29.342 47.3182 29.1447 47.3182 29.0225 47.2438C28.8909 47.1671 28.7852 46.9705 28.7852 46.805V18.2151C28.7852 18.1312 28.8134 18.0425 28.858 17.9609L29.335 18.2415C29.288 18.323 29.2598 18.4118 29.2598 18.4957V47.088C29.2598 47.239 29.3561 47.3086 29.4783 47.2558L29.4807 47.2534Z"
      fill="#0065AF"
    />
    <path
      d="M47.0094 36.6943L29.716 46.8836V46.8793L29.4622 47.0304V18.4902C29.4622 18.4495 29.4773 18.3914 29.5104 18.3328C29.5437 18.2738 29.5843 18.2333 29.6161 18.2149L29.6175 18.2141L47.1647 7.87532V36.4182C47.1647 36.4589 47.1496 36.517 47.1165 36.5756C47.0832 36.6346 47.0426 36.6751 47.0108 36.6935L47.0094 36.6943Z"
      fill="#F5F5F5"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <path
      d="M30.5474 31.072C30.5474 31.2926 30.74 31.355 30.9797 31.2159L45.6492 22.5732C45.8889 22.4317 46.0816 22.1415 46.0816 21.9209C46.0816 21.7003 45.8889 21.6379 45.6492 21.777L30.9797 30.4197C30.74 30.5612 30.5474 30.8514 30.5474 31.072Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 33.4587C30.5474 33.6793 30.74 33.7417 30.9797 33.6026L45.6492 24.9599C45.8889 24.8184 46.0816 24.5283 46.0816 24.3076C46.0816 24.087 45.8889 24.0247 45.6492 24.1638L30.9797 32.8064C30.74 32.9479 30.5474 33.2381 30.5474 33.4587Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 35.8474C30.5474 36.068 30.74 36.1303 30.9797 35.9913L45.6492 27.3486C45.8889 27.2071 46.0816 26.9169 46.0816 26.6963C46.0816 26.4757 45.8889 26.4133 45.6492 26.5524L30.9797 35.1951C30.74 35.3366 30.5474 35.6267 30.5474 35.8474Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 38.2321C30.5474 38.4528 30.74 38.5151 30.9797 38.376L45.6492 29.7334C45.8889 29.5919 46.0816 29.3017 46.0816 29.0811C46.0816 28.8605 45.8889 28.7981 45.6492 28.9372L30.9797 37.5799C30.74 37.7213 30.5474 38.0115 30.5474 38.2321Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 23.9099C30.5474 24.1305 30.74 24.1928 30.9797 24.0538L45.6492 15.4111C45.8889 15.2696 46.0816 14.9794 46.0816 14.7588C46.0816 14.5382 45.8889 14.4758 45.6492 14.6149L30.9797 23.2576C30.74 23.3991 30.5474 23.6892 30.5474 23.9099Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 26.2966C30.5474 26.5172 30.74 26.5796 30.9797 26.4405L45.6492 17.7978C45.8889 17.6563 46.0816 17.3662 46.0816 17.1455C46.0816 16.9249 45.8889 16.8626 45.6492 17.0016L30.9797 25.6443C30.74 25.7858 30.5474 26.076 30.5474 26.2966Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 21.5251C30.5474 21.7457 30.74 21.8081 30.9797 21.669L45.6492 13.0263C45.8889 12.8848 46.0816 12.5947 46.0816 12.374C46.0816 12.1534 45.8889 12.0911 45.6492 12.2302L30.9797 20.8728C30.74 21.0143 30.5474 21.3045 30.5474 21.5251Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 28.6833C30.5474 28.9039 30.74 28.9663 30.9797 28.8272L45.6492 20.1845C45.8889 20.043 46.0816 19.7529 46.0816 19.5322C46.0816 19.3116 45.8889 19.2493 45.6492 19.3884L30.9797 28.031C30.74 28.1725 30.5474 28.4627 30.5474 28.6833Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 40.6208C30.5474 40.8414 30.74 40.9038 30.9797 40.7647L45.6492 32.122C45.8889 31.9805 46.0816 31.6904 46.0816 31.4697C46.0816 31.2491 45.8889 31.1868 45.6492 31.3259L30.9797 39.9685C30.74 40.11 30.5474 40.4002 30.5474 40.6208Z"
      fill="#0065AF"
    />
    <path
      d="M30.5474 43.0114C30.5474 43.2321 30.74 43.2944 30.9797 43.1553L45.6492 34.5127C45.8889 34.3712 46.0816 34.081 46.0816 33.8604C46.0816 33.6398 45.8889 33.5774 45.6492 33.7165L30.9797 42.3592C30.74 42.5006 30.5474 42.7908 30.5474 43.0114Z"
      fill="#0065AF"
    />
    <path
      d="M38.6044 23.1156C38.6044 23.1156 38.5903 23.1036 38.5621 23.0916L37.7209 22.5904C37.6152 22.5305 37.4718 22.5401 37.3121 22.6336L38.6044 23.1156ZM38.6044 23.1156L38.4993 23.2393L38.4749 23.268L38.4748 23.268L38.4994 23.2391L38.6044 23.1156ZM38.5642 23.4921V23.6921H38.569L38.5783 43.7175C38.5783 43.7175 38.5783 43.7175 38.5783 43.7176C38.5783 43.8645 38.5264 44.0405 38.4336 44.2043C38.3407 44.3683 38.2175 44.5013 38.0941 44.5739L38.094 44.5739L11.9767 59.9623C11.8625 60.0294 11.7997 60.019 11.7755 60.0086L10.8882 59.4837C10.8661 59.4676 10.8235 59.4156 10.8235 59.2762V34.9285C10.8235 34.7816 10.8754 34.6055 10.9682 34.4417C11.0611 34.2776 11.1844 34.1447 11.3077 34.0721L11.3078 34.0721L18.4628 29.8587L18.4634 29.8583C18.5903 29.7829 18.7061 29.7929 18.7887 29.843L19.0923 30.027V30.019L19.6061 30.3248L19.6585 30.3574C19.723 30.3974 19.7816 30.4656 19.813 30.5684L20.3005 32.5776L20.364 32.8395L20.5963 32.7028L37.4131 22.8062C37.5343 22.7352 37.5981 22.7514 37.621 22.7637L38.4597 23.2634L38.4714 23.2704L38.4839 23.2757C38.4839 23.2757 38.4839 23.2757 38.484 23.2757L38.4843 23.276L38.4947 23.2828C38.5175 23.2978 38.5642 23.35 38.5642 23.4921Z"
      fill="#0065AF"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <path
      d="M19.7506 30.1755C19.6002 30.0915 19.4123 30.0939 19.229 30.1995L12.074 34.4129C11.9165 34.5088 11.7709 34.6695 11.6628 34.8565L10.791 34.3385C10.8968 34.1515 11.0401 33.9884 11.2022 33.8949L18.3572 29.6815C18.5452 29.5712 18.7379 29.5784 18.8883 29.6671L19.7483 30.1755H19.7506Z"
      fill="#0065AF"
    />
    <path
      d="M38.5624 23.0899C38.4613 23.0467 38.3251 23.0635 38.1817 23.1475L21.3481 33.0587C21.0262 33.2482 20.6784 33.1091 20.5727 32.7446L20.4951 32.5287L37.3123 22.6319C37.404 22.5767 37.4932 22.5527 37.5708 22.5527C37.6272 22.5527 37.6789 22.5671 37.7212 22.5911H37.7235L38.5647 23.0923L38.5624 23.0899Z"
      fill="#0065AF"
    />
    <path
      d="M11.7048 60.1926C11.7048 60.1926 11.6884 60.1878 11.679 60.183L10.779 59.6506C10.6827 59.5859 10.6216 59.4564 10.6216 59.2741V34.9265C10.6216 34.7346 10.6874 34.5284 10.7931 34.3438L11.6625 34.8617C11.5568 35.0488 11.4933 35.2598 11.4933 35.4469V59.8713C11.4933 60.0223 11.5826 60.1423 11.7048 60.1926Z"
      fill="#0065AF"
    />
  </svg>
)
