import { Box, Grid, Theme } from '@material-ui/core'
import { FormControl } from 'components/ui/form-control'
import { useUuid } from 'hooks/use-uuid'

export type Reason = {
  title: string
  value: string
}

type Props = {
  label: string
  required?: boolean
  error: boolean
  onChange: (value: string) => void
  onBlur: () => void
  reasons: Array<Reason>
  value: string
  name: string
  helperText?: string
}

export const SelectReason = (props: Props) => {
  const id = useUuid()

  return (
    <FormControl
      htmlFor={id}
      label={props.label}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
    >
      <Grid container spacing={1.5} data-name={props.name}>
        {props.reasons.map(reason => {
          const selected = {
            border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
            background: (theme: Theme) => theme.palette.background.paper,
            color: (theme: Theme) => theme.palette.primary.main,
            fontSize: (theme: Theme) => theme.typography.body1.fontSize,
            fontWeight: 500,
          }

          const notSelected = {
            border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
            background: (theme: Theme) => theme.palette.background.default,
            fontSize: (theme: Theme) => theme.typography.body2.fontSize,
            color: (theme: Theme) => theme.palette.primary.main,
          }

          const onClick = () => props.onChange(reason.value)

          return (
            <Grid
              key={reason.value}
              item
              xs={6}
              sx={{ '&:last-child': { minWidth: '100%' } }} // It's a temporary solution. Change this approach if you need to use the view of this component in the other parts of the application.
            >
              <Box
                width={1}
                minHeight="52px"
                px={1}
                borderRadius={1}
                boxShadow="0px 2px 4px rgba(61, 115, 221, 0.15)"
                component="button"
                type="button"
                sx={props.value === reason.value ? selected : notSelected}
                onClick={onClick}
              >
                {reason.title}
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </FormControl>
  )
}
