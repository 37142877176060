import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconLogout = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <mask
      id="mask0_2099_11324"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2099_11324)">
      <path
        d="M6.25 19C5.91667 19 5.625 18.875 5.375 18.625C5.125 18.375 5 18.0833 5 17.75V5.25C5 4.91667 5.125 4.625 5.375 4.375C5.625 4.125 5.91667 4 6.25 4H12.3125V5.25H6.25V17.75H12.3125V19H6.25ZM16.375 15.1458L15.4792 14.25L17.6042 12.125H10.3125V10.875H17.5625L15.4375 8.75L16.3333 7.85417L20 11.5208L16.375 15.1458Z"
        fill="#2C363F"
      />
    </g>
  </SvgIcon>
)
