import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconError = (props: SvgIconProps & { strokecolor: string }) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <circle cx="12" cy="12" r="8" fill={props.strokecolor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9933 7.88338C12.9355 7.38604 12.5128 7 12 7C11.4477 7 11 7.44772 11 8V13L11.0067 13.1166C11.0645 13.614 11.4872 14 12 14C12.5523 14 13 13.5523 13 13V8L12.9933 7.88338ZM13.01 16C13.01 15.4477 12.5623 15 12.01 15L11.8834 15.0067C11.386 15.0645 11 15.4872 11 16C11 16.5523 11.4477 17 12 17L12.1266 16.9933C12.624 16.9355 13.01 16.5128 13.01 16Z"
      fill="white"
    />
  </SvgIcon>
)
