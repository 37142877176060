export const LoginLogo = () => {
  return (
    <svg
      width="177"
      height="36"
      viewBox="0 0 177 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M152.207 29.9158V35.9995H128.85V30.8517L139.609 19.3395C140.559 18.232 141.325 17.2414 141.908 16.3679C142.49 15.4787 142.911 14.6754 143.172 13.9578C143.448 13.2402 143.586 12.5929 143.586 12.0157C143.586 11.033 143.44 10.214 143.149 9.55883C142.873 8.88807 142.459 8.38109 141.908 8.03791C141.371 7.69473 140.704 7.52314 139.907 7.52314C139.111 7.52314 138.413 7.75712 137.816 8.2251C137.218 8.69308 136.75 9.33264 136.413 10.1438C136.091 10.955 135.93 11.8675 135.93 12.8815H128.16C128.16 10.7912 128.658 8.88027 129.654 7.14876C130.666 5.41725 132.068 4.03672 133.861 3.00717C135.655 1.96203 137.731 1.43945 140.091 1.43945C142.559 1.43945 144.628 1.82943 146.298 2.60939C147.969 3.38935 149.226 4.52029 150.069 6.00222C150.927 7.46854 151.356 9.23905 151.356 11.3137C151.356 12.4993 151.172 13.638 150.804 14.73C150.436 15.8219 149.908 16.906 149.218 17.9824C148.528 19.0431 147.685 20.1351 146.689 21.2582C145.708 22.3814 144.59 23.5747 143.333 24.8382L139.011 29.9158H152.207Z"
        fill="#0065AF"
      />
      <path
        d="M175.68 22.6855V28.7692H155.908L155.448 23.9491L168.897 1.93083H175.012L168.368 12.9985L162.85 22.6855H175.68ZM176.621 1.93083V35.9995H168.874V1.93083H176.621Z"
        fill="#0065AF"
      />
      <g clipPath="url(#clip0_2355_1580)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.64 11.6118L114.994 8.87311H110.194V14.481H114.857L116.503 11.6118H116.64ZM96.6171 14.481H100.731V25.6967C100.731 31.5653 102.24 35.9995 109.234 35.9995C111.703 35.9995 113.76 35.3474 114.994 34.9561V28.6962C110.88 29.4787 108.823 29.6091 108.823 24.5229V1.43945H100.731V8.87311H97.1657L96.48 14.481H96.6171Z"
          fill="#0065AF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.08 1.43945H112.32L118.08 11.5195L112.32 21.5995H118.08L123.84 11.5195L118.08 1.43945Z"
          fill="#0065AF"
        />
        <path
          d="M7.48352 24.0867V35.9995H0V1.43945H11.7961C20.2943 1.43945 24.48 5.10491 24.48 12.4358C24.48 15.9704 23.2116 18.7195 20.8017 20.814C18.3917 22.9085 15.0939 24.0867 11.1619 24.0867H7.61036H7.48352ZM7.48352 7.46127V18.1958H10.4008C14.4597 18.1958 16.3623 16.3631 16.3623 12.6976C16.3623 9.03218 14.3328 7.33036 10.4008 7.33036H7.48352V7.46127Z"
          fill="#0065AF"
        />
        <path
          d="M38.6718 35.8759C34.6257 35.8759 31.6097 34.8868 29.3338 32.6614C27.0579 30.4359 25.9199 27.5923 25.9199 23.7595C25.9199 19.9268 27.0579 16.8359 29.4602 14.7341C31.8626 12.6323 35.0235 11.5195 39.0696 11.5195C43.1156 11.5195 46.1502 12.6323 48.4261 14.7341C50.702 16.8359 51.8399 19.8032 51.8399 23.3886C51.8399 27.2213 50.702 30.3123 48.4261 32.5377C46.1502 34.7632 42.8628 35.8759 38.9431 35.8759H38.6718ZM38.8012 16.9595C37.031 16.9595 35.6557 17.7013 34.7707 18.8141C33.8856 19.9268 33.3798 21.6577 33.3798 23.7595C33.3798 28.2104 35.15 30.4359 38.9431 30.4359C42.7363 30.4359 44.2536 28.0868 44.2536 23.5123C44.2536 19.185 42.4834 16.9595 38.9431 16.9595H38.8012Z"
          fill="#0065AF"
        />
        <path
          d="M60.4802 7.66787C59.1711 7.66787 58.1238 7.27798 57.3383 6.4982C56.5529 5.71841 56.1602 4.80866 56.1602 3.76895C56.1602 2.72924 56.5529 1.68953 57.3383 1.03971C58.1238 0.389892 59.1711 0 60.4802 0C61.7892 0 62.8365 0.389892 63.622 1.03971C64.4074 1.81949 64.8002 2.72924 64.8002 3.76895C64.8002 4.80866 64.4074 5.84837 63.622 6.62816C62.8365 7.40794 61.7892 7.66787 60.4802 7.66787ZM64.2765 36H56.6838V11.5668H64.2765V36Z"
          fill="#0065AF"
        />
        <path
          d="M93.2285 35.9988H85.9788V22.0066C85.9788 18.3175 84.7069 16.4094 82.036 16.4094C80.7641 16.4094 79.7466 16.9183 78.8563 17.9359C77.966 18.9536 77.5844 20.2257 77.5844 21.625V35.9988H70.2075V11.4483H77.5844V15.2645C79.365 12.3387 81.9088 10.9395 85.2157 10.9395C90.5576 10.9395 93.2285 14.2469 93.2285 20.8617V35.9988Z"
          fill="#0065AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2355_1580">
          <rect width="125.28" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
