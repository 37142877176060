import { createSvgIcon } from '@material-ui/core'

export const IconUnCheck = createSvgIcon(
  <svg fill="none" viewBox="0 0 26 26">
    <defs />
    <rect
      width="24"
      height="24"
      x="1"
      y="1"
      stroke="#7B8085"
      strokeWidth="2"
      rx="5"
    />
  </svg>,
  'IconUnCheck',
)
