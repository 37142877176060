import {
  Box,
  Button,
  InputBase,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { IconClose } from 'assets/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'

import { Dialog } from '../ui/dialog'

type Props = {
  open: boolean
  isBarcode: boolean
  isMiniSticker?: boolean
  onClose: () => void
  onUpdate: (result: string) => void
}

export const ScannerDialog = (props: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const [codeValue, setCodeValue] = useState('')
  const [disabledAcceptBtn, setDisabledAcceptBtn] = useState(
    props.isMiniSticker ? true : false,
  )

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      sendBarcode()
    }
  }

  const sendBarcode = () => {
    props.onUpdate(
      props.isBarcode && codeValue.length > 13
        ? codeValue.slice(0, -9)
        : codeValue,
    )

    props.onClose()
  }

  const clearScanner = () => {
    setCodeValue('')
  }

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      justifyContent="flex-start"
    >
      <Box px={3} pb={2}>
        {props.isMiniSticker && (
          <Typography pb={1} variant="body2" color="textSecondary">
            {t('identification.sticker_main') + ' *'}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1em',
            gap: '1em',
          }}
        >
          <div
            style={{
              width: '100%',
              border: '1px solid #7C8084',
              borderRadius: '10px',
              padding: '15px',
              display: 'flex',
              flexDirection: 'row',
              gap: '1em',
            }}
          >
            <InputBase
              fullWidth
              value={codeValue}
              onChange={e => {
                if (props.isMiniSticker === true) {
                  e.target.value.length == 7
                    ? setDisabledAcceptBtn(false)
                    : setDisabledAcceptBtn(true)
                }
                setCodeValue(e.target.value)
              }}
              onKeyPress={handleKeyPress}
              placeholder={
                props.isMiniSticker
                  ? t('ui.ministicker_enter')
                  : t('ui.barcode_enter')
              }
            />
            {codeValue.length > 0 && (
              <IconClose
                onClick={clearScanner}
                style={{ opacity: '0.6', cursor: 'pointer' }}
              />
            )}
          </div>
          <Button
            fullWidth={smDown}
            color="primary"
            autoFocus
            onClick={sendBarcode}
            disabled={disabledAcceptBtn}
          >
            {t('dialog_actions.confirm')}
          </Button>
        </Box>

        <BarcodeScannerComponent
          width="100%"
          delay={10}
          stopStream={!props.open}
          onUpdate={(_err, result) => {
            if (result) {
              const text = result.getText()

              props.onUpdate(
                props.isBarcode && text.length > 13 ? text.slice(0, -9) : text,
              )
              props.onClose()
            }
          }}
        />
      </Box>
    </Dialog>
  )
}
