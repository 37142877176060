import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconMParcel = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ width: '44px', height: '44px' }}
    fill="none"
    viewBox="0 0 60 65"
  >
    <g opacity="0.2">
      <path
        d="M36.2037 36.0611L30.7938 35.4533L0 53.1974L15.7938 62.3943L21.2038 62.9997L52 45.0629L36.2037 36.0611Z"
        fill="#1B1C20"
      />
    </g>
    <path
      d="M51.2528 45.0678L36.2039 36.4911V19.3252L51.2504 28.0329L51.2528 45.0678Z"
      fill="#B0906E"
    />
    <path
      d="M6.15479 53.8077V36.7703L36.2037 19.3252V36.4911L6.15479 53.8077Z"
      fill="#A38361"
    />
    <path
      d="M21.2037 62.5676L6.15479 53.808L36.2037 36.4914L51.2527 45.0682L21.2037 62.5676Z"
      fill="#CBAB88"
    />
    <path
      d="M6.15479 36.7707L21.2037 45.5328V62.5677L6.15479 53.8081V36.7707Z"
      fill="#B19171"
    />
    <path
      d="M51.2503 28.0334L51.2528 45.0683L21.2039 62.5678V45.5329L51.2503 28.0334Z"
      fill="#CBAB88"
    />
    <path
      d="M21.2038 45.4286L6.19897 36.6937L36.2038 19.219L51.2062 27.9539L21.2038 45.4286Z"
      fill="#D8B895"
    />
    <path
      d="M6.19889 36.6937L21.2037 45.4286V45.5324L6.15479 36.7703L6.19889 36.6937Z"
      fill="#C09F80"
    />
    <path
      d="M51.2062 27.9543L51.2503 28.0334L21.2039 45.5328V45.4291L51.2062 27.9543Z"
      fill="#E5C6A2"
    />
    <path
      d="M14.5517 41.6081L44.6006 24.1062L43.2652 23.3303L13.2163 40.8297L14.5517 41.6081Z"
      fill="#E5CEB4"
    />
    <path
      d="M14.5517 44.1359V41.6081L13.2163 40.8322V43.3576L14.5517 44.1359Z"
      fill="#E5CEB4"
    />
    <path
      d="M40.8027 41.7192C40.8027 41.2077 41.5059 40.9532 41.7191 40.9433L41.7852 40.0513C41.0722 40.1748 39.9672 41.0569 39.9696 42.327C39.9549 43.5897 41.3172 42.6087 41.3172 43.2067C41.3172 43.8368 40.249 44.1481 40.0186 44.0962L39.8986 45.0204C40.6924 45.0229 42.1969 44.0246 42.1552 42.6211C42.1405 41.2472 40.8052 42.285 40.8052 41.7192M38.992 43.2413C38.992 42.1294 37.8845 42.7718 37.8943 43.8936L38.992 43.2413ZM37.887 44.7585C37.9458 45.7098 39.0778 44.8647 39.5654 44.3631L39.6879 45.1439C38.5436 46.419 37.0147 46.9403 37.0147 44.9216C37.0147 42.2282 39.852 40.2489 39.852 43.093C39.852 43.308 39.8349 43.5082 39.8226 43.607L37.887 44.7585ZM41.8661 40.8568L42.3022 40.5973V42.29C42.3022 43.1795 42.4664 43.7454 43.1916 43.3129C43.4538 43.1573 43.6694 42.9398 43.7919 42.799V41.8477C43.3582 42.2158 43.1475 42.3764 43.1475 41.5956V38.1115L42.2998 38.6156V39.7449L41.9298 39.9648L41.8636 40.8568H41.8661ZM43.2945 40.0068L43.7944 39.7103L43.9683 39.1765L43.7968 38.8553L43.2945 39.1543V40.0068ZM32.2148 49.7573L32.9548 45.3046L33.1533 49.2853L33.9814 48.7911L33.7291 43.7132L32.4354 44.4817L31.8008 48.3735L31.1662 45.2378L29.8725 46.0063L29.6201 51.3832L30.4483 50.889L30.6467 46.6735L31.3842 50.249L32.2148 49.7548V49.7573Z"
      fill="#0053A3"
    />
    <path
      d="M36.0054 45.0159C36.0054 43.904 34.8979 44.5464 34.9077 45.6682L36.0054 45.0159ZM34.9003 46.5331C34.9591 47.4844 36.0911 46.6393 36.5787 46.1377L36.7012 46.9186C35.557 48.1936 34.0281 48.715 34.0281 46.6962C34.0281 44.0028 36.8654 42.0236 36.8654 44.8676C36.8654 45.0826 36.8482 45.2828 36.836 45.3816L34.9003 46.5331Z"
      fill="#E50033"
    />
    <path
      d="M44.2427 37.4621L44.8724 38.6383L44.2427 40.5731L43.6155 40.9462L44.2452 39.0114L43.6155 37.8352L44.2427 37.4621Z"
      fill="#E50033"
    />
    <path
      d="M44.3408 27.1415L35.922 32.0439C35.0939 31.6782 35.2409 30.2771 35.6893 28.7822C35.9147 28.6537 36.3949 28.3745 37.4461 27.7617C37.1716 28.4832 36.9977 29.0738 37.0369 29.227C37.054 29.2937 37.1006 29.3604 37.1594 29.3851C37.3064 29.4419 40.6166 27.4627 44.4584 25.224C44.3433 25.649 44.2796 26.0814 44.2796 26.499C44.2796 26.7164 44.2967 26.9339 44.3384 27.1439L44.3408 27.1415Z"
      fill="#0053A3"
    />
    <path
      d="M47.6412 21.2306C48.0896 21.0552 48.5355 21.0082 48.952 21.1293C49.0843 21.1664 49.2117 21.2207 49.3367 21.2924C49.6013 21.4456 49.8659 21.6013 50.1306 21.7545C50.8705 22.1869 51.0984 23.0962 51.0592 23.9462C50.9269 26.8224 47.9523 30.094 46.0045 28.9623C45.7398 28.8091 45.4752 28.6534 45.2106 28.5002C44.8578 28.2951 44.6226 27.9813 44.4756 27.6156C44.4143 27.4649 44.3702 27.3068 44.3383 27.1412C44.2967 26.9312 44.2795 26.7137 44.2795 26.4963C44.2795 26.0787 44.3432 25.6487 44.4584 25.2213C44.6593 24.475 45.017 23.7411 45.4679 23.1012C45.5634 22.9652 45.6639 22.8318 45.7668 22.7058C46.323 22.0213 46.9821 21.4876 47.6412 21.2306ZM47.8739 28.6658C50.0938 27.7194 51.4046 24.2106 50.5961 22.7972C50.4025 22.4587 50.0717 22.177 49.7042 22.0806C49.3342 21.9843 48.93 22.0782 48.5698 22.2314C46.3548 23.1753 45.0391 26.6841 45.8476 28.0999C46.0412 28.4385 46.372 28.7226 46.7395 28.8165C47.1095 28.9129 47.5138 28.819 47.8739 28.6658Z"
      fill="#4D4D4D"
    />
    <path
      d="M50.5986 22.7972C51.4047 24.2106 50.0939 27.7194 47.8765 28.6658C47.5163 28.819 47.1096 28.9129 46.742 28.8165C46.3745 28.7202 46.0437 28.4385 45.8502 28.0999C45.0441 26.6841 46.3574 23.1753 48.5723 22.2314C48.9325 22.0782 49.3392 21.9843 49.7067 22.0806C50.0743 22.177 50.405 22.4612 50.5986 22.7972ZM49.6773 24.601C49.7043 23.5336 48.7389 23.111 47.7172 24.1538C47.2223 24.6603 46.8695 25.3374 46.7837 26.0713C46.7494 26.3628 46.7567 26.6692 46.8989 26.9238C47.6217 28.1914 49.6308 26.4024 49.6773 24.601Z"
      fill="#333333"
    />
    <path
      d="M47.7194 24.1563C48.7412 23.1135 49.7065 23.536 49.6796 24.6035C49.633 26.4073 47.6239 28.1938 46.9011 26.9262C46.7565 26.6742 46.7516 26.3678 46.7859 26.0737C46.8717 25.3399 47.2245 24.6603 47.7194 24.1563Z"
      fill="#4D4D4D"
    />
    <path
      d="M47.6412 16.9608C47.6412 16.7878 47.6363 16.7952 47.6265 16.7359C47.5359 16.0366 47.0556 15.283 46.4945 14.9543C44.1987 13.6175 41.9054 12.2807 39.6096 10.9464C39.3498 10.7957 39.046 10.7265 38.8083 10.8599C39.1097 10.6845 39.4111 10.5091 39.7125 10.3336C39.8595 10.2471 40.0922 10.2496 40.2368 10.3336C42.8977 11.8829 45.5586 13.4322 48.2195 14.9815C48.6335 15.2237 48.9521 15.5993 48.9521 16.0786V21.1293C48.5355 21.0082 48.0872 21.0577 47.6412 21.2306C47.6412 18.1987 47.6437 17.2771 47.6412 16.9608Z"
      fill="#4D4D4D"
    />
    <path
      d="M43.6964 21.1091C43.6964 21.1091 43.6939 21.1091 43.6915 21.1116C43.6915 21.1116 43.6915 21.1116 43.689 21.1116C43.689 21.1116 43.689 21.1116 43.6866 21.1116C43.6866 21.1116 43.6841 21.1116 43.6817 21.1141C43.1034 21.4501 42.5374 21.8059 41.9518 22.1321C40.8174 22.7647 39.83 23.2119 38.8671 24.7316C38.4236 25.4284 37.838 26.7257 37.4484 27.7561C36.3973 28.3689 35.9171 28.6481 35.6917 28.7766C36.1229 27.3335 36.831 25.804 37.2181 25.0207C37.9997 23.4343 39.2714 21.8331 40.7929 20.946L47.6436 16.9554C47.6485 17.2717 47.6436 18.1934 47.6436 21.2253C46.9845 21.4823 46.3254 22.0185 45.7692 22.7004H45.7668C45.7668 22.3422 45.7913 20.2789 45.7594 20.1949C45.6442 19.8984 45.3625 20.1232 45.1003 20.2888C44.9533 20.3827 43.8605 21.0103 43.6988 21.0992L43.6964 21.1091Z"
      fill="#0053A3"
    />
    <path
      d="M47.6411 16.9608L40.7904 20.9514C39.2664 21.8385 37.9972 23.4397 37.2156 25.026C36.8309 25.8093 36.1204 27.3389 35.6892 28.7819C35.4343 28.9252 35.503 28.8808 35.4343 28.9079C34.7311 29.1946 33.8515 29.1452 33.3125 28.8313C33.7462 27.0918 34.5915 25.2213 35.1746 24.0426C36.1547 22.0559 37.7424 20.0544 39.6437 18.9499L46.4969 14.9593C47.0604 15.2879 47.5382 16.0416 47.6289 16.7409C47.6362 16.7977 47.6411 16.7928 47.6436 16.9657L47.6411 16.9608Z"
      fill="#0E65A0"
    />
    <path
      d="M46.4945 14.9565L39.6413 18.9471C37.3455 17.6103 35.0522 16.2735 32.7563 14.9392L39.6095 10.9486C41.9053 12.2854 44.1986 13.6222 46.4945 14.9565ZM42.0744 14.3338C42.3267 14.1955 42.3292 14.0324 42.1209 13.8297C42.116 13.511 42.0964 13.1576 41.9739 12.8982C41.7901 12.5152 41.305 12.1643 40.8052 12.105C40.5552 12.0729 40.3396 12.1322 40.1044 12.231C39.7246 12.3892 39.1856 12.7129 38.6931 12.9995C36.9314 14.0423 36.4096 14.2473 36.3826 15.9128C36.2674 15.9894 36.1964 16.0882 36.2527 16.2142C36.3655 16.4762 36.8702 16.7331 37.1887 16.8221C37.6101 16.9382 37.9311 16.8023 38.0463 16.6442C38.1418 16.5132 38.0561 16.3847 37.9409 16.2711C37.9581 15.2555 38.0316 15.1937 39.8104 14.1633C39.8912 14.1164 40.1534 13.9582 40.4401 13.7976C40.5528 14.0348 41.0134 14.2671 41.2364 14.3437C41.5206 14.445 41.8073 14.4796 42.0719 14.3363L42.0744 14.3338Z"
      fill="#0053A3"
    />
    <path
      d="M43.6969 21.1095C43.6969 21.1095 43.6942 21.1123 43.6914 21.1123C43.6914 21.1123 43.6914 21.1123 43.6942 21.1123L43.6969 21.1095Z"
      fill="#0053A3"
    />
    <path
      d="M43.6942 21.1096C43.6942 21.1096 43.6942 21.1096 43.6914 21.1096C43.6914 21.1096 43.6942 21.1096 43.6969 21.1068L43.6942 21.1096Z"
      fill="#0053A3"
    />
    <path
      d="M43.6896 21.1117C43.6896 21.1117 43.6868 21.1144 43.6841 21.1144C43.6841 21.1144 43.6841 21.1144 43.6868 21.1144C43.6868 21.1144 43.6868 21.1144 43.6896 21.1144V21.1117Z"
      fill="#0053A3"
    />
    <path
      d="M43.682 21.1173C43.682 21.1173 43.682 21.1173 43.6792 21.1173C43.6792 21.1173 43.682 21.1173 43.6847 21.1145L43.682 21.1173Z"
      fill="#0053A3"
    />
    <path
      d="M42.121 13.8301C42.3293 14.0327 42.3268 14.1958 42.0744 14.3341C41.8074 14.4799 41.5232 14.4429 41.2389 14.3415C41.016 14.2625 40.5578 14.0302 40.4426 13.7955C40.5014 13.7633 40.5602 13.7287 40.619 13.6966C40.8469 14.0104 41.7388 14.4478 42.0793 14.0673C42.1063 14.0376 42.121 14.008 42.121 13.9758V13.9684C42.121 13.9215 42.121 13.877 42.121 13.8301Z"
      fill="#4D4D4D"
    />
    <path
      d="M42.1209 13.8301C42.1209 13.877 42.1209 13.9215 42.1209 13.966V13.9758C42.1209 14.008 42.1037 14.0376 42.0792 14.0673C41.7386 14.4478 40.8492 14.0104 40.6189 13.6991C40.5601 13.7312 40.5013 13.7633 40.4425 13.7955C40.1558 13.9561 39.8912 14.1142 39.8128 14.1612C38.0315 15.194 37.958 15.2533 37.9433 16.2689C37.9433 16.3109 37.9433 16.3554 37.9433 16.3999C37.9433 16.474 37.8796 16.5308 37.8184 16.5704C37.5366 16.7458 37.1764 16.6272 36.8726 16.4691C36.7207 16.39 36.3875 16.1775 36.3875 16.007C36.3875 15.9724 36.3875 15.9427 36.3875 15.9106C36.4144 14.2452 36.9363 14.0401 38.698 12.9973C39.1904 12.7132 39.7295 12.3895 40.1093 12.2289C40.3445 12.13 40.5601 12.0707 40.81 12.1028C41.3098 12.1646 41.795 12.5155 41.9787 12.896C42.1037 13.1555 42.1209 13.5064 42.1258 13.8276L42.1209 13.8301Z"
      fill="#4D4D4D"
    />
    <path
      d="M39.6095 10.9459L32.7564 14.9366C32.2271 15.2454 31.6905 15.6433 31.1809 16.1103C31.2495 15.8088 31.323 15.5074 31.4039 15.1985C31.5876 15.07 31.769 14.9563 31.9454 14.855C34.2338 13.5232 36.5198 12.1913 38.8083 10.8595C39.046 10.726 39.3498 10.7952 39.6095 10.9459Z"
      fill="#0053A3"
    />
    <path
      d="M37.941 16.2689C38.0562 16.3801 38.1419 16.5111 38.0463 16.642C37.9312 16.8026 37.6102 16.9361 37.1888 16.8199C36.8703 16.7334 36.3631 16.4765 36.2528 16.2121C36.1989 16.0861 36.2675 15.9872 36.3827 15.9106C36.3827 15.9427 36.3827 15.9724 36.3827 16.0045C36.3827 16.175 36.7159 16.3875 36.8678 16.4666C37.1716 16.6247 37.5318 16.7433 37.8136 16.5679C37.8748 16.5284 37.9385 16.474 37.9385 16.3999C37.9385 16.3529 37.9385 16.3084 37.9385 16.2664L37.941 16.2689Z"
      fill="#4D4D4D"
    />
    <path
      d="M29.9974 22.2709C29.9067 22.1968 29.8234 22.1152 29.7475 22.0287C29.0835 21.2726 28.8997 20.089 28.9389 18.8585C28.9536 18.3964 28.9977 17.9269 29.0614 17.4723C29.1104 17.1263 29.1692 16.7878 29.2305 16.4666C30.0733 12.13 32.793 6.0786 33.0552 3.13567C33.0797 2.85892 33.0993 2.42403 32.9817 2.17693C32.8788 1.95701 32.5897 1.81123 32.2246 1.9323C31.3499 2.22141 30.6197 3.64469 30.2645 4.50706C30.191 4.68497 30.0292 4.81593 29.7867 4.8357C29.7524 4.83817 29.7156 4.83817 29.6764 4.8357C29.3848 4.8184 28.934 4.66026 28.7184 4.46505C28.7061 4.45517 28.6939 4.44281 28.6841 4.43046C28.5175 4.25502 28.4832 4.03263 28.5591 3.84731C29.3015 2.02373 30.6344 -0.0420067 32.5872 -1.51165e-07C33.4227 0.0197677 34.2166 0.467014 34.616 1.25278C35.8558 3.69658 32.7857 9.93332 31.4038 15.199C31.3229 15.5054 31.247 15.8093 31.1808 16.1083C30.9922 16.941 30.8574 17.7416 30.801 18.4878C30.7692 18.9301 30.7422 19.6714 30.8917 20.22C30.9211 20.3262 30.9554 20.4251 30.9995 20.514C31.0877 20.6944 31.2078 20.8328 31.3719 20.8921C31.3719 20.8921 31.3744 20.8921 31.3768 20.8921C31.813 21.0502 31.9281 21.663 31.6856 22.1523C31.4944 22.5427 31.0926 22.8046 30.7275 22.6737C30.4458 22.5748 30.2057 22.4365 29.9998 22.2635L29.9974 22.2709Z"
      fill="#4D4D4D"
    />
    <path
      d="M33.7437 32.6614C33.8343 32.7132 33.925 32.7676 34.0156 32.8195C33.9715 32.7997 33.9299 32.78 33.8882 32.7553C33.8809 32.7528 33.876 32.7478 33.8711 32.7454C33.8637 32.7404 33.8564 32.738 33.849 32.733C33.8123 32.7108 33.7755 32.6861 33.7437 32.6614Z"
      fill="#4D4D4D"
    />
    <path
      d="M33.8888 32.7576C33.8888 32.7576 33.8762 32.75 33.8711 32.7474C33.8762 32.75 33.8838 32.755 33.8888 32.7576Z"
      fill="#0053A3"
    />
    <path
      d="M33.849 32.7355C31.5679 31.4061 29.2868 30.0792 27.0033 28.7498C26.9984 28.7473 26.9935 28.7449 26.9861 28.7399C26.9739 28.7325 26.9616 28.7251 26.9494 28.7177C26.5892 28.4928 26.3711 28.1271 26.256 27.6922C26.1604 27.329 26.1334 26.9139 26.1579 26.4666C26.1873 25.9502 26.2829 25.3917 26.425 24.8185C26.8293 25.0631 28.2038 25.8588 33.31 28.8313C33.1679 29.4021 33.0699 29.9606 33.0429 30.4745C33.0184 30.9218 33.0454 31.3369 33.1409 31.7001C33.2463 32.098 33.4374 32.439 33.7437 32.6638C33.778 32.6885 33.8123 32.7132 33.849 32.7355Z"
      fill="#333333"
    />
    <path
      d="M32.7563 14.937C32.227 15.2459 31.6904 15.6437 31.1808 16.1108C30.9921 16.9435 30.8574 17.7441 30.801 18.4903C30.7692 18.9326 30.7422 19.6739 30.8917 20.2225C31.2445 20.0693 31.612 20.0569 31.9404 20.1755C32.1045 20.2348 32.2711 20.2917 32.4353 20.351C32.4353 20.351 32.4377 20.351 32.4402 20.351C33.3688 20.6821 33.4644 21.8533 33.0086 22.7775C32.6485 23.5064 31.9061 24.0302 31.1686 23.7683C31.0264 23.7164 30.8819 23.667 30.7398 23.6151C30.137 23.4001 29.7352 22.7775 29.7499 22.0238C29.0859 21.2677 28.9022 20.0841 28.9414 18.8535H28.9365C28.7012 19.2341 28.4832 19.6294 28.2872 20.0273C27.7089 21.2034 26.8587 23.0715 26.425 24.8135C26.4659 24.8382 26.4659 24.8382 26.425 24.8135C26.2829 25.3868 26.1849 25.9452 26.158 26.4617C28.4538 27.7985 30.7471 29.1353 33.0429 30.4696C33.0699 29.9556 33.1679 29.3972 33.31 28.8264L33.3394 28.7127C33.7731 26.9732 34.5915 25.2188 35.1721 24.0377C36.1522 22.051 37.7399 20.0495 39.6413 18.945C37.3454 17.6082 35.0521 16.2714 32.7563 14.937Z"
      fill="#0B68B5"
    />
    <path
      d="M33.31 28.8313C33.8491 29.1452 34.7287 29.1946 35.4319 28.9079C35.498 28.8808 35.4319 28.9252 35.6867 28.7819C35.2383 30.2793 35.0913 31.6804 35.9195 32.0436L44.3383 27.1412C44.3701 27.3043 44.4142 27.4649 44.4755 27.6156C41.5108 29.3404 38.5461 31.0676 35.5838 32.7923C35.3461 32.9307 35.0668 33.0122 34.7728 33.0122C34.5572 33.0122 34.3367 32.9702 34.1235 32.8763C34.0451 32.8417 33.9642 32.8022 33.8907 32.7577C33.9324 32.78 33.974 32.8022 34.0181 32.822C33.9275 32.7701 33.8368 32.7157 33.7462 32.6638C33.4424 32.4365 33.2488 32.098 33.1434 31.7001C32.9376 30.9193 33.0454 29.9013 33.3125 28.8313H33.31Z"
      fill="#4D4D4D"
    />
    <path
      d="M32.4402 20.3534C33.3688 20.6845 33.4644 21.8558 33.0086 22.7799C32.6485 23.5089 31.9061 24.0327 31.1686 23.7708C31.0264 23.7189 30.8819 23.6695 30.7398 23.6176C30.137 23.4026 29.7352 22.7799 29.7499 22.0263C29.8259 22.1128 29.9092 22.1943 29.9998 22.2684C30.2277 23.3606 31.4969 23.3853 32.009 22.345C32.4867 21.3789 31.8987 20.3089 31.0019 20.5165C30.9578 20.4276 30.9235 20.3287 30.8941 20.2225C31.247 20.0693 31.6145 20.0569 31.9428 20.1755C32.107 20.2348 32.2736 20.2917 32.4377 20.351C32.4377 20.351 32.4402 20.351 32.4426 20.351L32.4402 20.3534Z"
      fill="#333333"
    />
    <path
      d="M30.7277 22.6811C31.0928 22.8096 31.4922 22.5501 31.6857 22.1597C31.9283 21.6705 31.8131 21.0577 31.377 20.8995C31.377 20.8995 31.3746 20.8995 31.3721 20.8995C31.2079 20.8402 31.0879 20.7018 30.9997 20.5215C31.8964 20.3139 32.4845 21.3813 32.0067 22.35C31.4946 23.3903 30.2279 23.3655 29.9976 22.2734C30.2034 22.4463 30.4435 22.5847 30.7253 22.6836L30.7277 22.6811Z"
      fill="#4D4D4D"
    />
    <path
      d="M29.0614 17.4725C28.9977 17.9272 28.9536 18.3967 28.9389 18.8588H28.934C28.6988 19.2393 28.4807 19.6346 28.2847 20.0325C27.7065 21.2087 26.8562 23.0767 26.4226 24.8188C26.2363 24.7076 26.2559 24.71 26.2143 24.6705C26.0305 24.5049 25.9399 24.2924 25.9668 24.0898C26.3687 22.5183 27.116 20.8751 27.7261 19.6198C28.0911 18.8761 28.542 18.1446 29.0614 17.4701V17.4725Z"
      fill="#0053A3"
    />
    <path
      d="M26.256 27.6894C26.3712 28.1243 26.5893 28.49 26.9494 28.7149C25.5014 27.8426 25.4744 26.019 25.9694 24.0892C25.9449 24.2918 26.0355 24.5043 26.2168 24.6699C26.2585 24.7094 26.2389 24.7045 26.4251 24.8182C26.1605 25.8881 26.0502 26.9086 26.256 27.6894Z"
      fill="#4D4D4D"
    />
    <path
      d="M20.7137 41.1887L22.5318 40.1311L19.4397 38.3322L17.95 39.1996C17.8348 39.2663 17.7147 39.3256 17.5922 39.3725L20.7137 41.1912V41.1887ZM18.7659 43.7116L19.1775 42.869L9.02888 36.9609L8.61725 37.8035L18.7659 43.7116ZM22.8871 40.0817C21.7943 39.4442 20.7015 38.8091 19.6087 38.1716C19.4691 38.0901 19.3858 37.9492 19.3858 37.7837V37.2722C19.3858 37.203 19.3588 37.1091 19.2583 37.0498L18.9864 36.8917H18.9815C18.9692 36.9411 18.9521 36.9905 18.9349 37.0399L19.0133 37.0869L17.8225 37.7812C17.3497 38.058 16.9821 38.5398 16.8645 38.9401C16.6367 38.8091 14.6447 37.6478 14.1375 37.3537C14.1007 37.3315 14.0738 37.3167 14.0542 37.3043C13.9415 37.2376 14.0272 37.0696 14.0272 37.0696C14.0615 36.9831 14.1081 36.8941 14.1644 36.8052L13.9807 36.8892C13.8778 36.9485 13.77 37.0202 13.6842 37.0992L10.5627 35.2806L12.3807 34.223L15.2597 35.9008C15.3332 35.8588 15.4067 35.8168 15.4777 35.7723C14.5369 35.2238 13.6769 34.7246 12.7335 34.1761C12.5498 34.0698 12.3121 34.0871 12.1234 34.1983C11.5183 34.5517 10.9106 34.905 10.3054 35.2559C9.70513 35.6068 9.18324 36.1874 8.88677 36.7978C8.78141 37.0152 8.67605 37.2302 8.5707 37.4452C8.47269 37.6453 8.43839 37.8801 8.62705 37.9913C12.0107 39.9606 15.392 41.93 18.7757 43.8994C18.9374 43.9933 19.1481 43.9216 19.3098 43.8252C20.4687 43.1506 21.6277 42.4761 22.7841 41.8015C22.9704 41.6928 23.11 41.4951 23.11 41.2776V40.4721C23.11 40.309 23.0267 40.1608 22.8846 40.0792L22.8871 40.0817ZM22.892 41.5668L19.7753 43.3829L19.9223 43.0815C20.1772 42.5601 20.6157 42.0906 21.0739 41.8213L22.892 40.7637V41.5692V41.5668Z"
      fill="#333333"
    />
    <path
      d="M22.8945 40.7612V41.5668L19.7778 43.3829L19.9248 43.0815C20.1797 42.5601 20.6182 42.0906 21.0764 41.8213L22.8945 40.7637V40.7612Z"
      fill="#4D4D4D"
    />
    <path
      d="M22.5318 40.1308L20.7138 41.1884L17.5923 39.3697C17.7172 39.3228 17.8349 39.2635 17.95 39.1968L19.4397 38.3294L22.5318 40.1283V40.1308Z"
      fill="#4D4D4D"
    />
    <path
      d="M19.1774 42.869L18.7658 43.7116L8.61719 37.8035L9.02882 36.9584L19.1774 42.869Z"
      fill="#4D4D4D"
    />
    <path
      d="M18.8074 36.3159C18.8467 36.3975 18.8565 36.4889 18.8565 36.5779C18.8565 36.6841 18.8369 36.8126 18.7903 36.9485V36.9559L18.3395 36.6965L17.5579 37.1511C17.7024 36.4691 18.2684 36.0466 18.5453 36.101C18.6555 36.1232 18.756 36.2097 18.805 36.3159H18.8074Z"
      fill="#0053A3"
    />
    <path
      d="M18.7243 35.8412C18.5626 35.7473 18.3445 35.7819 18.1632 35.861C17.6977 36.0636 17.2861 36.7307 17.1929 37.2299C16.8009 37.0025 16.4138 36.7678 16.0169 36.5454C15.9483 36.5059 15.9728 36.4688 15.9728 36.4688C16.034 36.1476 16.2006 35.8239 16.4113 35.5719C16.4456 35.5298 16.4824 35.4903 16.5191 35.4532C16.7789 35.1913 17.09 35.0332 17.3694 35.09C17.3449 35.1592 17.3179 35.2259 17.2934 35.2951L17.2836 35.3322C17.2763 35.389 17.2934 35.4557 17.3522 35.515C17.5335 35.7003 17.9672 35.6559 18.2735 35.6559L18.4107 35.6608C18.5136 35.7201 18.6165 35.7794 18.7194 35.8387L18.7243 35.8412Z"
      fill="#0053A3"
    />
    <path
      d="M18.7242 35.8411C18.9496 35.9745 19.0379 36.2389 19.0379 36.5033C19.0379 36.6343 19.0183 36.7652 18.9839 36.8912C18.9717 36.9431 18.9545 36.9925 18.9374 37.042L18.7879 36.9555V36.9481C18.8369 36.8097 18.8565 36.6837 18.8565 36.5774C18.8565 36.4885 18.8467 36.397 18.8075 36.3155C18.7561 36.2092 18.6581 36.1203 18.5478 36.1005C18.2709 36.0462 17.705 36.4687 17.5604 37.1507L17.4256 37.2298C17.3644 37.2643 17.2615 37.2718 17.1929 37.2298C17.286 36.7306 17.6976 36.0635 18.1631 35.8608C18.3445 35.7818 18.5625 35.7472 18.7242 35.8411Z"
      fill="#0053A3"
    />
    <path
      d="M17.1928 37.2299C17.2614 37.2694 17.3643 37.2644 17.4256 37.2299L17.5603 37.1508L18.3419 36.6961L18.7879 36.9556L18.9373 37.0421L19.0157 37.089L17.825 37.7834C17.3496 38.0601 16.9845 38.5419 16.8669 38.9422C16.6391 38.8088 14.6471 37.6499 14.1399 37.3559C14.2796 36.8987 14.6838 36.4466 15.093 36.2069L16.2838 35.515L16.4088 35.5743C16.1956 35.8288 16.0314 36.15 15.9702 36.4713C15.9702 36.4713 15.9457 36.5083 16.0143 36.5479C16.4088 36.7703 16.7983 37.005 17.1904 37.2323L17.1928 37.2299Z"
      fill="#4D4D4D"
    />
    <path
      d="M15.4827 35.7703C15.6738 35.6591 15.8625 35.5479 16.0536 35.4392C16.1761 35.3675 16.404 35.3873 16.5216 35.454C16.4848 35.4911 16.4481 35.5306 16.4138 35.5726L16.2888 35.5133L15.098 36.2052C14.6888 36.4449 14.2821 36.8971 14.1449 37.3542C14.1082 37.3344 14.0812 37.3171 14.0616 37.3048C13.9489 37.2381 14.0346 37.07 14.0346 37.07C14.0689 36.9835 14.1155 36.8946 14.1719 36.8056C14.336 36.5462 14.5835 36.2966 14.8677 36.1311L15.2671 35.8988C15.3406 35.8568 15.4141 35.8148 15.4851 35.7703H15.4827Z"
      fill="#4D4D4D"
    />
    <path
      d="M15.2646 35.8983L14.8652 36.1306C14.581 36.2962 14.3336 36.5457 14.1694 36.8052L13.9856 36.8892C13.8827 36.9485 13.7749 37.0177 13.6867 37.0992L10.5652 35.2806L12.3832 34.223L15.2646 35.9008V35.8983Z"
      fill="#4D4D4D"
    />
    <path
      d="M18.4155 35.6631L18.2783 35.6582C17.9696 35.6582 17.5384 35.7002 17.3571 35.5173C17.2983 35.458 17.2811 35.3888 17.2885 35.3345L17.2983 35.2974C17.3228 35.2282 17.3473 35.1615 17.3742 35.0923C21.096 25.0033 24.8178 14.9119 28.5421 4.82286C28.6058 4.65236 28.623 4.60541 28.6842 4.43492C28.6842 4.43492 28.7063 4.45716 28.7185 4.46704C28.9366 4.66225 29.385 4.82039 29.6765 4.83769C29.7157 4.83769 29.7525 4.83769 29.7868 4.83769C29.7231 5.01065 29.7108 5.04525 29.6471 5.21574C25.9033 15.3665 22.157 25.5173 18.4131 35.668L18.4155 35.6631Z"
      fill="#9ABDE6"
    />
    <path
      d="M45.7643 22.7033H45.7667C45.6638 22.8343 45.5634 22.9652 45.4678 23.1012C45.0194 23.7411 44.6592 24.475 44.4583 25.2212C43.1891 25.9601 41.9812 26.6692 40.9227 27.287C40.2906 27.6552 39.7148 27.9912 39.2125 28.2803C38.0046 28.9771 37.2279 29.4071 37.1568 29.3799C37.098 29.3552 37.0515 29.2909 37.0343 29.2218C36.9951 29.0686 37.1691 28.478 37.4435 27.7565C37.8331 26.7285 38.4187 25.4288 38.8621 24.7345C39.8251 23.2173 40.8125 22.7676 41.9469 22.135C42.0792 22.0609 42.2091 21.9867 42.3414 21.9101C42.7334 21.6803 43.1205 21.4431 43.5126 21.2133C43.5665 21.1812 43.6228 21.1491 43.6767 21.117C43.6767 21.117 43.6767 21.117 43.6792 21.117C43.6792 21.117 43.6792 21.117 43.6816 21.117C43.6816 21.117 43.6841 21.117 43.6865 21.1145C43.6865 21.1145 43.689 21.1145 43.6914 21.112C43.8531 21.0206 44.9459 20.393 45.0929 20.3015C45.3551 20.136 45.6369 19.9111 45.752 20.2076C45.7839 20.2917 45.7594 22.3549 45.7594 22.7083L45.7643 22.7033Z"
      fill="#9ABDE6"
    />
    <path
      d="M42.3414 21.9151L40.9252 27.2895C40.293 27.6576 39.7172 27.9937 39.215 28.2828C38.007 28.9796 37.2303 29.4096 37.1593 29.3824C37.1005 29.3577 37.0539 29.2934 37.0367 29.2242C36.9975 29.071 37.1715 28.4805 37.4459 27.7589C37.8355 26.731 38.4211 25.4313 38.8646 24.7369C39.8275 23.2198 40.8149 22.77 41.9493 22.1375C42.0817 22.0633 42.2115 21.9892 42.3438 21.9126L42.3414 21.9151Z"
      fill="#B2CCED"
    />
    <path
      d="M37.1668 29.4389C37.1668 29.4389 37.1471 29.4364 37.1398 29.4339C37.054 29.3993 37.0026 29.3129 36.9854 29.2412C36.9291 29.0287 37.2182 28.2108 37.3946 27.7438C37.7523 26.7999 38.3502 25.4483 38.8182 24.7119C39.7076 23.3109 40.5994 22.8216 41.6334 22.2533C41.729 22.2014 41.8245 22.1495 41.9201 22.0951C42.2754 21.8975 42.6306 21.6825 42.9761 21.4774C43.1427 21.3786 43.3142 21.2748 43.4833 21.1735C43.5323 21.1438 44.9215 20.3482 45.0661 20.2543C45.0931 20.237 45.12 20.2197 45.147 20.2024C45.3307 20.0838 45.5022 19.9701 45.6468 20.017C45.7154 20.0393 45.7669 20.0961 45.8012 20.1851C45.8232 20.2419 45.8257 20.7633 45.8159 22.2582C45.8159 22.446 45.8134 22.5967 45.8134 22.6832L45.8379 22.703L45.8036 22.7425C45.7007 22.8686 45.6027 22.9995 45.5071 23.1354C45.0465 23.7902 44.701 24.5192 44.505 25.2382L44.5001 25.2605L44.4805 25.2703C43.6107 25.7769 42.7825 26.2612 41.9838 26.7307C40.9204 27.3534 40.0016 27.8896 39.2371 28.3319C37.5171 29.3252 37.2403 29.4413 37.1643 29.4413L37.1668 29.4389ZM43.5372 21.2624C43.3681 21.3613 43.1966 21.465 43.03 21.5639C42.6845 21.7714 42.3293 21.9839 41.9715 22.1841C41.8735 22.2385 41.778 22.2903 41.6824 22.3422C40.6607 22.9031 39.7786 23.3875 38.9039 24.7638C38.4408 25.4952 37.8454 26.8369 37.4902 27.7784C37.1496 28.6803 37.0565 29.1102 37.0834 29.2115C37.0981 29.2659 37.1324 29.3104 37.1692 29.3302C37.3309 29.3054 38.7251 28.5024 39.1857 28.2355C39.9526 27.7932 40.869 27.257 41.9323 26.6343C42.7262 26.1698 43.547 25.6879 44.4119 25.1863C44.6104 24.4623 44.9583 23.7309 45.4238 23.0712C45.5145 22.9402 45.6125 22.8142 45.7105 22.6906C45.7105 22.6066 45.7105 22.451 45.713 22.2533C45.7179 21.6256 45.7277 20.321 45.7056 20.2197C45.6835 20.1628 45.6541 20.1282 45.6174 20.1159C45.5194 20.0838 45.3601 20.1875 45.2058 20.2889C45.1788 20.3061 45.1519 20.3234 45.1225 20.3407C44.9804 20.4322 43.5911 21.2278 43.5347 21.2599L43.5372 21.2624Z"
      fill="#4D4D4D"
    />
  </SvgIcon>
)
