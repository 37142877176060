import { useAuthContext } from 'app/auth'
import { isLeft } from 'fp-ts/Either'
import { useNotificationContext } from 'hooks/use-notification'
import { Mixed, TypeOf } from 'io-ts'
import reporter from 'io-ts-reporters'
import { devConsoleLog } from 'lib/dev-console-log'
import { useTranslation } from 'react-i18next'
import {
  useMutation as useReactMutation,
  UseMutationOptions,
} from 'react-query'
import { ExtractRouteParams, generatePath } from 'react-router'

import {
  concatQueryParams,
  ErrorMap,
  MutationError,
  RestMethod,
  throwError,
} from './common'

type Body = { [key: string]: any }
type MutationType = 'json' | 'file'

const createBody = (
  body: Body | undefined,
  type: MutationType,
): string | FormData | undefined => {
  if (body === undefined) {
    return undefined
  }

  if (type === 'json') {
    return JSON.stringify(body)
  }

  if (type === 'file') {
    const formData = new FormData()

    for (const [key, value] of Object.entries(body)) {
      if (Array.isArray(value)) {
        for (const file of value) formData.append(key, file)
      } else {
        formData.append(key, value)
      }
    }

    return formData
  }
}

type Input<Path extends string> = keyof ExtractRouteParams<Path> extends never
  ? {
      params?: undefined
      body?: Body
      search?: URLSearchParams
    }
  : {
      params: ExtractRouteParams<Path>
      body?: Body
      search?: URLSearchParams
    }

export const useMutation = <
  Path extends string,
  C extends Mixed | undefined = undefined,
>(
  method: RestMethod,
  url: Path,
  codec?: C,
  {
    type = 'json',
    options,
  }: {
    type?: MutationType
    options?: UseMutationOptions<
      C extends Mixed ? TypeOf<C> : null,
      MutationError,
      Input<Path>,
      unknown
    >
  } = {},
  retry?: boolean | number | ((failureCount: number, error: any) => boolean),
) => {
  const { auth, removeToken } = useAuthContext()
  const { showErrorNotification } = useNotificationContext()
  const token = auth?.type === 'authenticated' ? auth.accessToken : null
  const { t } = useTranslation()

  return useReactMutation<
    C extends Mixed ? TypeOf<C> : null,
    MutationError,
    Input<Path>
  >(
    async ({ params, body, search }) => {
      const headers = new Headers({
        'Screen-Width': `${screen.width}`,
        'Screen-Height': `${screen.height}`,
      })

      if (type === 'json') {
        headers.set('Content-Type', 'application/json')
      } else if (type === 'file') {
        headers.set('Content-Type', 'multipart/form-data')
      }

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      const fullUrl = concatQueryParams(generatePath(url, params), search)

      try {
        const response = await window.fetch(fullUrl, {
          method,
          body: createBody(body, type),
          headers,
        })

        if (response.ok) {
          try {
            if (codec === undefined) {
              return null
            }

            const json: unknown = await response.json()

            const decodedJson = codec.decode(json)

            if (isLeft(decodedJson)) {
              devConsoleLog(reporter.report(decodedJson))
              return throwError({ type: 'failed_to_decode_json', json })
            }

            return decodedJson.right
          } catch (error) {
            if (error.type === undefined) {
              throwError({
                type: 'failed_to_parse_json',
                response: response,
                error,
              })
            }

            throw error
          }
        }

        if (response.status >= 500) {
          const json = await response.json()
          const errorText = json.data.details || json.message
          showErrorNotification(errorText)
          throwError({
            type: 'server_error',
            status: response.status,
            message: errorText,
          })
        }

        if (response.status === 404) {
          const json = await response.json()
          throwError({
            type: 'not_found',
            message: json.message,
            code: json.code,
          })
        }

        if (response.status === 401) {
          removeToken()
        }

        if (response.status >= 400) {
          const json = await response.json()
          const errorText = json.data.details || json.message

          const errorValue = ErrorMap.get(
            json.data.details === undefined ? json.code : json.data.details,
          )

          if (errorText === 'One or more parcel are missed') {
            showErrorNotification(t('notifications.missed_parcels_error'))
          } else if (errorValue !== undefined) {
            showErrorNotification(t(errorValue))
          } else showErrorNotification(errorText)

          throwError({
            type: 'client_error',
            message: errorText,
            code: json.code,
          })
        }
      } catch (error) {
        if (error.type === undefined) {
          throwError({ type: 'network_error', error })
        }

        throw error
      }
    },
    { retry: retry, retryDelay: 3000, ...options },
  )
}
