export const IconTerminalsLarge = () => (
  <svg
    width="51"
    height="66"
    viewBox="0 0 51 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M25.5718 65.5148C39.5047 65.5148 50.7996 58.9453 50.7996 50.8414C50.7996 42.7375 39.5047 36.168 25.5718 36.168C11.6387 36.168 0.34375 42.7375 0.34375 50.8414C0.34375 58.9453 11.6387 65.5148 25.5718 65.5148Z"
      fill="#0061AF"
    />
    <g clipPath="url(#clip0_1938_11960)">
      <path
        d="M39.0713 46.8748L18.8213 58.424L11.8151 54.4049L39.0713 45.9348V46.8748Z"
        fill="white"
        stroke="#0065AF"
      />
      <path
        d="M18.8206 57.09L39.5713 45.2552L31.322 40.5273L10.5713 52.3621L18.8206 57.09Z"
        fill="#0065AF"
      />
      <path
        d="M14.3076 30.3192L22.9328 12.2719L16.7 52.7119L14.3076 51.3492V30.3192Z"
        fill="white"
        stroke="#0065AF"
      />
      <path
        d="M37.0842 44.7646L19.321 54.9104V34.3422V34.2875L19.3092 34.2341L14.3452 11.8158L32.1991 1.62225L37.0842 23.6799V44.7646Z"
        fill="white"
        stroke="#0065AF"
      />
      <path
        d="M13.7768 11.5651L32.54 0.852488L31.0317 0L12.2686 10.717L13.7768 11.5651Z"
        fill="#0065AF"
      />
      <path
        d="M19.1624 33.5713L14.3452 11.8158L32.1991 1.62225L37.0164 23.3737L19.1624 33.5713Z"
        fill="white"
        stroke="#0065AF"
      />
      <path
        d="M15.5445 32.5422L12.3389 11.4997L12.2686 10.7168L13.7768 11.5649L18.8205 34.3429V55.7725L15.5445 53.9023V32.5422Z"
        fill="#0065AF"
      />
      <path
        d="M16.3584 12.8529L30.4736 4.78906L34.9984 22.3434L20.8832 30.4028L16.3584 12.8529Z"
        fill="#FBFBFB"
      />
      <path
        d="M18.3206 58.1373L11.0713 53.9787V53.2261L18.3206 57.3808V58.1373Z"
        fill="white"
        stroke="#0065AF"
      />
      <path
        d="M28.7893 10.8262L29.2774 12.6921L21.0237 17.4025L20.54 15.554L28.7893 10.8262Z"
        fill="#0065AF"
      />
      <path
        d="M29.5589 13.8008L30.0469 15.6623L21.7933 20.3771L21.3096 18.5286L29.5589 13.8008Z"
        fill="#0065AF"
      />
      <path
        d="M30.3279 16.7715L30.816 18.6374L22.5623 23.3478L22.083 21.5037L30.3279 16.7715Z"
        fill="#0065AF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1938_11960">
        <rect
          width="29"
          height="59"
          fill="white"
          transform="translate(10.5713)"
        />
      </clipPath>
    </defs>
  </svg>
)
