import {
  InputBase as MuiInputBase,
  InputBaseProps,
  styled,
} from '@material-ui/core'

export const InputBase = styled(MuiInputBase)<InputBaseProps>(({ theme }) => ({
  border: '1px solid #D5D5D5',
  background: theme.palette.common.white,
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.2, 2),

  '&:hover': {
    border: '1px solid #D5D5D5',
  },

  '&.Mui-disabled': {
    background: '#F8F8F8',
  },

  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },

  '&.Mui-error': {
    borderColor: theme.palette.error.main,
  },

  '&.MuiInputBase-adornedEnd': {
    '& .MuiInputBase-input': {
      paddingRight: theme.spacing(1),
    },
  },

  '&.MuiInputBase-adornedStart': {
    '& .MuiInputBase-input': {
      paddingLeft: 0,
    },
  },

  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
}))
