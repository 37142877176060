import './pace.css'

import { GlobalStyles } from '@material-ui/core'
import { useEffect } from 'react'

import { pace } from './pace'

type Props = {
  color: string
}

export const ReactPace = (props: Props) => {
  useEffect(() => {
    pace()
  }, [])

  return (
    <GlobalStyles
      styles={{
        '.pace .pace-progress': {
          background: props.color,
        },
      }}
    />
  )
}
