import constate from 'constate'

import { useBoolean } from '../use-boolean'

export const useBooleanCtx = () => {
  const state = useBoolean(true)

  return state
}

export const [BooleanProvider, useBooleanContext] = constate(useBooleanCtx)
