import { IconProps, SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconClientParcelReception = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.94737 0.668558C7.14846 0.556186 7.36014 0.5 7.5824 0.5C7.80466 0.5 8.01404 0.556186 8.21053 0.668558L14.5263 4.31361C14.7263 4.43197 14.8816 4.58798 14.9921 4.78164C15.1026 4.97529 15.1579 5.18449 15.1579 5.40924V8.71718C14.9662 8.57452 14.7639 8.45107 14.551 8.34682C14.338 8.24256 14.1193 8.1483 13.8947 8.06402V6.04133L10.7579 7.87439C9.92982 8.07104 9.18246 8.42923 8.51579 8.94895C7.84912 9.46867 7.32632 10.1008 6.94737 10.8452V9.34927L1.26316 6.0624V12.5729L6.84211 15.7966C6.98246 16.0916 7.14737 16.376 7.33684 16.6499C7.52632 16.9238 7.74035 17.1802 7.97895 17.419C7.81053 17.4892 7.63509 17.5138 7.45263 17.4927C7.27018 17.4716 7.10175 17.4119 6.94737 17.3136L0.631579 13.6686C0.431579 13.5502 0.276316 13.3942 0.165789 13.2005C0.0552632 13.0069 0 12.7977 0 12.5729V5.40924C0 5.18449 0.0552632 4.97529 0.165789 4.78164C0.276316 4.58798 0.431579 4.43197 0.631579 4.31361L6.94737 0.668558ZM7.57895 1.76418L1.95789 5.00891L7.57895 8.27472L13.1789 5.00891L7.57895 1.76418ZM12.2105 15.8177L14.5895 13.4368L14.1474 12.9943L12.5263 14.6167V11.0138H11.8947V14.6167L10.2737 12.9943L9.83158 13.4368L12.2105 15.8177ZM12 17.419C10.9053 17.419 9.96491 17.0299 9.17895 16.2517C8.39298 15.4735 8 14.5324 8 13.4284C8 12.3243 8.39298 11.379 9.17895 10.5924C9.96491 9.80578 10.9053 9.41248 12 9.41248C13.0947 9.41248 14.0351 9.80485 14.8211 10.5896C15.607 11.3743 16 12.3234 16 13.4368C16 14.5385 15.607 15.4777 14.8211 16.2542C14.0351 17.0307 13.0947 17.419 12 17.419Z"
      fill={(props.sx as IconProps).color}
    />
  </SvgIcon>
)
