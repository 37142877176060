import { SwipeableDrawer, useMediaQuery, useTheme } from '@material-ui/core'
import { useBooleanContext } from 'hooks/use-boolean-context'
import { ReactNode, useEffect } from 'react'

import { headerHeight } from './app-layout'

const iOS = 'browser' in process && /iPad|iPhone|iPod/.test(navigator.userAgent)

type Props = {
  variant: 'temporary' | 'persistent'
  children: ReactNode
}

export const AppDrawer = (props: Props) => {
  const drawer = useBooleanContext()

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  /* eslint-disable */
  useEffect(() => {
    drawer.setTrue()
  }, [mdUp])
  /* eslint-enable */

  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SwipeableDrawer
      variant={props.variant}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      ModalProps={{ keepMounted: true }}
      open={drawer.isTrue}
      onClose={drawer.setFalse}
      onOpen={drawer.setTrue}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          marginTop: mdDown ? 0 : headerHeight,
          height: mdDown ? '100%' : `calc(100% - ${headerHeight})`,
        },
      }}
    >
      {props.children}
    </SwipeableDrawer>
  )
}
