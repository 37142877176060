import { createSvgIcon } from '@material-ui/core'

export const IconMenu = createSvgIcon(
  <svg
    width="27"
    height="21"
    viewBox="0 0 27 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.516602" width="27" height="4" fill="white" />
    <rect y="8.5166" width="27" height="4" fill="white" />
    <rect y="16.5166" width="27" height="4" fill="white" />
  </svg>,
  'IconMenu',
)
