import { textFieldError } from 'components/form/utils'
import { password_form, password_form_old } from 'components/form/validations'
import { useRef } from 'react'
import {
  Control,
  FieldValues,
  Path,
  useController,
  ValidationRule,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PasswordInput } from '../password-input'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  fullWidth?: boolean
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  required?: boolean
  label: string
  disabled?: boolean
  pattern?: ValidationRule<RegExp>
  isHelperText?: boolean
  isOldPasswordCheck?: boolean
  doNotCheckPattern?: boolean
}

export const ControlledPasswordTextField = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()
  const ref = useRef()

  const {
    field: { ...inputField },
    fieldState,
  } = useController({
    control: props.control,
    name: props.name,
    rules: {
      required: props.required,
      pattern: props.doNotCheckPattern
        ? props.pattern
        : props.pattern ?? {
            value: props.isOldPasswordCheck
              ? password_form_old.pattern.value
              : password_form.pattern.value,
            message: t(
              props.isOldPasswordCheck
                ? password_form_old.pattern.message
                : password_form.pattern.message,
            ),
          },
    },
  })

  return (
    <PasswordInput
      helperText={props.isHelperText && t('validations.password_form')}
      {...textFieldError(fieldState.error)}
      {...inputField}
      required={props.required}
      ref={ref}
      label={t(props.label)}
    />
  )
}
