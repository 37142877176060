import { IconButton } from '@material-ui/core'
import { IconEyeClose, IconEyeOpen } from 'assets/icons'
import { MouseEvent } from 'react'

type Properties = {
  isVisible: boolean
  onClick: () => void
}

const preventDefault = (event: MouseEvent) => {
  event.preventDefault()
}

export const ToggleVisibilityButton = ({ isVisible, onClick }: Properties) => {
  return (
    <IconButton
      size="small"
      onClick={onClick}
      onMouseDown={preventDefault}
      title={isVisible ? 'Hide password' : 'Show password'}
      sx={{ p: 0 }}
    >
      {isVisible ? (
        <IconEyeOpen fontSize="small" />
      ) : (
        <IconEyeClose fontSize="small" />
      )}
    </IconButton>
  )
}
