export type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS'

export type FatalError =
  | { type: 'network_error'; error: TypeError }
  | { type: 'failed_to_decode_json'; json: unknown }
  | { type: 'failed_to_parse_json'; response: Response; error: Error }
  | { type: 'server_error'; status: number; message?: string }
  | { type: 'failed_to_decode_headers'; headers: Headers }

export type MutationError =
  | FatalError
  | { type: 'not_found'; message?: string; code?: string }
  | { type: 'client_error'; message?: string; code?: string }

export const throwError = (error: MutationError) => {
  throw error
}

export const concatQueryParams = (
  url: string,
  params?: URLSearchParams,
): string => {
  if (params === undefined) {
    return url
  }
  const paramsString = params.toString()
  return paramsString.length === 0 ? url : `${url}?${paramsString}`
}

export const ErrorMap = new Map([
  [
    'error_invalid_argument_verification',
    'notifications.error_invalid_argument_verification',
  ],
  ['error_json_processing', 'notifications.error_json_processing'],
  ['error_message_not_readable', 'notifications.error_message_not_readable'],
  [
    'error_method_argument_not_valid',
    'notifications.error_method_argument_not_valid',
  ],
  ['error_constraints_violation', 'notifications.error_constraints_violation'],
  ['error_access_denied', 'notifications.error_access_denied'],
  [
    'error_invalid_request_argument',
    'notifications.error_invalid_request_argument',
  ],
  ['error_client_not_found', 'notifications.error_client_not_found'],
  ['error_invalid_token', 'notifications.error_invalid_token'],
  ['error_role_not_found', 'notifications.error_role_not_found'],
  ['error_token_expired', 'notifications.error_token_expired'],
  ['error_user_not_found', 'notifications.error_user_not_found'],
  [
    'error_user_with_phone_not_found',
    'notifications.error_user_with_phone_not_found',
  ],
  ['error_user_on_branch', 'notifications.error_user_on_branch'],
  ['error_user_not_active', 'notifications.error_user_not_active'],
  ['error_password_generation', 'notifications.error_password_generation'],
  ['error_password_validation', 'notifications.error_password_validation'],
  [
    'error_incorrect_old_password',
    'notifications.error_incorrect_old_password',
  ],
  ['error_incorrect_credentials', 'notifications.error_incorrect_credentials'],
  [
    'error_refresh_token_not_found',
    'notifications.error_refresh_token_not_found',
  ],
  [
    'error_confirmation_request_not_found',
    'notifications.error_confirmation_request_not_found',
  ],
  ['error_token_generation', 'notifications.error_token_generation'],
  ['feign_request_failed', 'notifications.feign_request_failed'],
  [
    'error_user_with_given_phone_already_exist',
    'notifications.error_user_with_given_phone_already_exist',
  ],
  ['error_some_invalid_scopes', 'notifications.error_some_invalid_scopes'],
  [
    'error_phone_number_not_valid',
    'notifications.error_phone_number_not_valid',
  ],
  ['error_action_not_allowed', 'notifications.error_action_not_allowed'],
  ['error_sms_code_invalid', 'notifications.error_sms_code_invalid'],
  ['rsa_key_preparation', 'notifications.rsa_key_preparation'],
  ['error_branch_not_found', 'notifications.error_branch_not_found'],
  ['error_branch_not_exist', 'notifications.error_branch_not_exist'],
  ['error_branch_not_choose', 'notifications.error_branch_not_choose'],
  [
    'error_branch_delivery_impossible',
    'notifications.error_branch_delivery_impossible',
  ],
  ['error_parcel_not_found', 'notifications.error_parcel_not_found'],
  [
    'error_parcel_already_refused',
    'notifications.error_parcel_already_refused',
  ],
  ['error_parcel_not_paid', 'notifications.error_parcel_not_paid'],
  ['error_parcel_already_paid', 'notifications.error_parcel_already_paid'],
  ['error_search_parcel_failed', 'notifications.error_search_parcel_failed'],
  ['sender_not_authentication', 'notifications.sender_not_authentication'],
  ['error_courier_not_found', 'notifications.error_courier_not_found'],
  ['error_parcel_list_empty', 'notifications.error_parcel_list_empty'],
  ['error_parcel_missed', 'notifications.error_parcel_missed'],
  ['error_parcel_overweight', 'notifications.error_parcel_overweight'],
  [
    'error_different_phone_number',
    'notifications.error_different_phone_number',
  ],
  ['error_sms_missed', 'notifications.error_sms_missed'],
  ['error_document_preparation', 'notifications.error_document_preparation'],
  ['error_document_not_found', 'notifications.error_document_not_found'],
  ['duplicate_request_exception', 'notifications.duplicate_request_exception'],
  ['error_insufficient_funds', 'notifications.error_insufficient_funds'],
  ['error_cash_missed', 'notifications.error_cash_missed'],
  ['error_sms_not_send', 'notifications.error_sms_not_send'],
  [
    'error_cash_payment_not_found',
    'notifications.error_cash_payment_not_found',
  ],
  ['error_empty_list', 'notifications.error_empty_list'],
  ['error_cashbox_not_available', 'notifications.error_cashbox_not_available'],
  ['error_branch_closed', 'notifications.error_branch_closed'],
  ['error_branch_overweight', 'notifications.error_branch_overweight'],
  ['error_eps_absent', 'notifications.error_eps_absent'],
  ['error_s3_integration_error', 'notifications.error_s3_integration_error'],
  ['file_upload_error', 'notifications.file_upload_error'],
  ['empty_file_error', 'notifications.empty_file_error'],
  [
    'invalid_file_extension_error',
    'notifications.invalid_file_extension_error',
  ],
  ['file_reading_error', 'notifications.file_reading_error'],
  [
    'file_without_extension_error',
    'notifications.file_without_extension_error',
  ],
  ['error_module_not_found', 'notifications.error_module_not_found'],
  ['error_parcel_list_huge', 'notifications.error_parcel_list_huge'],
  ['error_file_not_found', 'notifications.error_file_not_found'],
  ['error_parcel_stop_exception', 'notifications.error_parcel_stop_exception'],
  [
    'error_package_already_exists_exception',
    'notifications.error_package_already_exists_exception',
  ],
  [
    'error_invalid_argument_verification',
    'notifications.error_invalid_argument_verification',
  ],
  [
    'error_client_verification_not_found',
    'notifications.error_client_verification_not_found',
  ],
  ['error_parcel_status_invalid', 'notifications.error_parcel_status_invalid'],
  [
    'error_receiver_parcel_invalid',
    'notifications.error_receiver_parcel_invalid',
  ],
  ['city_not_served', 'notifications.city_not_served'],
  ['[noDoorService]', 'notifications.noDoorService'],
  [
    'error_user_not_verified_by_call',
    'notifications.error_user_not_verified_by_call',
  ],
])
