import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconInventory = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="1 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1567 4.42556C11.0542 4.392 10.9434 4.392 10.8408 4.42556L3.43904 6.84857C3.32789 6.88494 3.23645 6.95717 3.17623 7.04957L1.20559 9.53402C1.1059 9.65974 1.07454 9.82512 1.12148 9.97764C1.16843 10.1302 1.28785 10.2508 1.44174 10.3012L3.10168 10.8446L3.10303 15.0803C3.10323 15.7138 3.51601 16.2761 4.12789 16.4764L10.8135 18.6653C10.8726 18.6886 10.9357 18.7004 10.9992 18.7004C11.0634 18.7006 11.1271 18.6888 11.1869 18.6653L17.8721 16.4765C18.4841 16.2761 18.8969 15.7136 18.897 15.08L18.8973 10.845L20.5584 10.3012C20.7124 10.2508 20.8318 10.1302 20.8787 9.97764C20.9256 9.82512 20.8943 9.65974 20.7946 9.53402L18.7984 7.01728C18.7389 6.94039 18.6563 6.88062 18.5585 6.84857L11.1567 4.42556ZM3.77622 7.89004L10.1724 9.98387L8.82439 11.6834L2.42819 9.58957L3.77622 7.89004ZM11 9.21946L16.8204 7.31412L10.9988 5.40839L5.1784 7.31368L11 9.21946ZM4.10226 15.08L4.10101 11.1717L8.84348 12.7242C9.04432 12.7899 9.26549 12.7242 9.39559 12.5602L10.4993 11.1687L10.4992 17.5279L4.44383 15.5454C4.2399 15.4786 4.10231 15.2911 4.10226 15.08ZM17.898 11.1721L13.1567 12.7242C12.9559 12.7899 12.7347 12.7242 12.6046 12.5602L11.499 11.1663L11.5008 17.528L17.5561 15.5454C17.7602 15.4786 17.8978 15.2911 17.8978 15.0799L17.898 11.1721ZM13.1758 11.6834L11.8272 9.98323L18.2234 7.8894L19.572 9.58957L13.1758 11.6834Z"
      fill="#333333"
    />
  </SvgIcon>
)
