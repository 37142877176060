import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import { useAuthContext } from 'app/auth'
import { FormControl } from 'components/ui/form-control'
import { useUuid } from 'hooks/use-uuid'
import { isCardDisabled } from 'meest-domain/utils'

export type Card = {
  title: string
  value: string
  description: string
  icon: React.ReactNode
  fullSize?: boolean
  disabled?: boolean
}

type Props = {
  label: string
  required?: boolean
  error: boolean
  onChange: (value: string) => void
  onBlur: () => void
  cards: Array<Card>
  name: string
  value: string
  helperText?: string
}

export const SelectCards = (props: Props) => {
  const theme = useTheme()
  const id = useUuid()
  const { auth } = useAuthContext()
  const branchWeightLimit = auth.branch?.branchType.weightLimit

  return (
    <FormControl
      htmlFor={id}
      label={props.label}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
    >
      <Grid container spacing={1} data-name={props.name}>
        {props.cards.map(card => {
          const isSelectedCard = card.value === props.value
          let isDisabled = isCardDisabled(branchWeightLimit!, card.value)

          const style = isSelectedCard
            ? { bgcolor: theme.palette.primary.main }
            : { bgcolor: theme.palette.background.paper }

          const titleColor = isDisabled
            ? theme.palette.text.secondary
            : isSelectedCard
            ? theme.palette.background.default
            : theme.palette.error.dark

          const descriptionColor = isSelectedCard
            ? theme.palette.background.default
            : theme.palette.text.secondary

          return (
            <Grid key={card.value} item xs={card.fullSize ? 12 : 6}>
              <Box
                component="button"
                type="button"
                disabled={isDisabled}
                display="flex"
                width={1}
                borderRadius="10px"
                p="12px 12px 6px 18px"
                onBlur={props.onBlur}
                onClick={() => props.onChange(card.value)}
                border="none"
                sx={{
                  cursor: isDisabled ? '' : 'pointer',
                  opacity: isDisabled ? 0.5 : 1,
                  filter: isDisabled ? 'grayscale(100%)' : 'none',
                  ...style,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography color={titleColor} variant="h4">
                      {card.title}
                    </Typography>
                    <Typography color={descriptionColor} variant="body2">
                      {card.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {card.icon}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </FormControl>
  )
}
