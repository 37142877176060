import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconNewNotificationSnackbar = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{ fill: 'none', height: '100%', width: '100%' }}
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="51.8723" height="53" rx="5" fill="#FFBE0B" />
    <path
      d="M31.0842 22.3684V18.1368C31.0842 17.5053 30.5789 17 29.9474 17H14.1579C13.5263 17 13 17.5053 13 18.1368V33.6316L16.2632 30.3263H18.8105"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M36.556 23.505V38.9997L33.356 35.6945H19.9244C19.3139 35.6945 18.8086 35.1892 18.8086 34.5576V30.3261H29.9454C30.577 30.3261 31.0823 29.8208 31.0823 29.1892V22.3682H35.4191C36.0507 22.3682 36.556 22.8734 36.556 23.505Z"
      stroke="white"
      strokeMiterlimit="10"
    />
  </SvgIcon>
)
