import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconEyeOpen = (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 13C3 13 6.27273 7 12 7C17.7273 7 21 13 21 13C21 13 17.7273 19 12 19C6.27273 19 3 13 3 13Z"
      stroke="#2C363F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="13"
      r="3"
      stroke="#2C363F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
