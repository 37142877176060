import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconScanner = (props: SvgIconProps & { strokecolor: string }) => (
  <SvgIcon {...props} sx={{ fill: 'none' }}>
    <path d="M7.0559 0.836792H4.91479V19.9682H7.0559V0.836792Z" fill="black" />
    <path
      d="M8.91087 0.836792H7.84131V19.9682H8.91087V0.836792Z"
      fill="black"
    />
    <path d="M15.654 0.836792H14.5845V19.9682H15.654V0.836792Z" fill="black" />
    <path
      d="M11.1593 0.836792H9.62451V19.9682H11.1593V0.836792Z"
      fill="black"
    />
    <path
      d="M13.4793 0.836792H12.0161V19.9682H13.4793V0.836792Z"
      fill="black"
    />
    <path
      d="M18.4732 0.836792H16.6184V19.9682H18.4732V0.836792Z"
      fill="black"
    />
    <path
      d="M21.6838 0.836792H19.5427V19.9682H21.6838V0.836792Z"
      fill="black"
    />
    <path d="M23.8248 0.836792H22.469V19.9682H23.8248V0.836792Z" fill="black" />
    <path d="M26.4313 0.836792H24.825V19.9682H26.4313V0.836792Z" fill="black" />
    <path
      d="M29.8904 0.836792H27.7493V19.9682H29.8904V0.836792Z"
      fill="black"
    />
    <rect
      x="0.493408"
      y="9.59741"
      width="33.8182"
      height="1.61039"
      rx="0.805195"
      fill="white"
    />
    <path
      d="M0.493408 10.4026C0.493408 9.95791 0.853906 9.59741 1.2986 9.59741H4.92198V11.2078H1.2986C0.853906 11.2078 0.493408 10.8473 0.493408 10.4026V10.4026Z"
      fill="black"
    />
    <path
      d="M34.3079 10.4249C34.3058 10.8696 33.9437 11.2284 33.499 11.2264L29.8757 11.2096L29.8831 9.59927L33.5064 9.61602C33.9511 9.61807 34.31 9.98023 34.3079 10.4249V10.4249Z"
      fill="black"
    />
  </SvgIcon>
)
