import { TypeOf } from 'io-ts'
import { useQuery } from 'lib/rest-query'

import { TGenericSuggestions } from './codecs/generic'

export type SelectOption = {
  label: string
  value: string | number
}

export type AutocompleteOption = {
  label: string
  value: string | object
  option?: string | null
}

export const useOptions = (name: string) => {
  return useQuery('GET', `/api/${name}/options`, TGenericSuggestions)
}

export const selectOption = (label: string, value: string): SelectOption => {
  return {
    label,
    value,
  }
}

export const toSelectOptions = (
  data: TypeOf<typeof TGenericSuggestions> | undefined,
): Array<SelectOption> => {
  return data?.map(option => selectOption(option.id, option.name)) ?? []
}
