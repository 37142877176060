import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import { operatorDefaultPath } from 'app/app-menu'
import { useAuthContext } from 'app/auth'
import { ChooseBranchDialog } from 'components/choose-branch-dialog'
import { CreateParcelDialog } from 'components/forms/create-parcel'
import { AppContent } from 'components/layout/app-content'
import { AppDrawer } from 'components/layout/app-drawer'
import { AppMenu } from 'components/layout/app-menu'
import { useBoolean } from 'hooks/use-boolean'
import { BooleanProvider } from 'hooks/use-boolean-context'
import { formatBranch } from 'meest-domain/format'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AppContentHeader } from './app-content-header'

export const smDrawerWidth = '180px'
export const drawerWidth = '300px'
export const headerHeight = '60px'

type Props = {
  children: ReactNode
}

export const AppLayout = (props: Props) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const { setBranch, auth } = useAuthContext()
  const {
    i18n: { language },
  } = useTranslation()
  const history = useHistory()

  const chooseBranchDialog = useBoolean(false)

  const createParcelDialog = useBoolean(false)

  const defaultBranchOption = auth.branch
    ? {
        label: formatBranch(language)(auth.branch),
        value: auth.branch.branchId,
      }
    : { label: '', value: '' }

  return (
    <>
      <BooleanProvider>
        <Box display="flex" flexDirection={'column'}>
          <Box
            width={'100%'}
            height={headerHeight}
            sx={{
              backgroundColor: theme.palette.primary.main,
              position: 'fixed',
              zIndex: 100,
            }}
          >
            <AppContentHeader
              onChooseBranchDialog={chooseBranchDialog.setTrue}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            <Box width={mdDown ? 0 : drawerWidth}>
              <AppDrawer variant={mdDown ? 'temporary' : 'persistent'}>
                <AppMenu
                  onChooseBranchDialog={chooseBranchDialog.setTrue}
                  onCreateParcelDialog={createParcelDialog.setTrue}
                />
              </AppDrawer>
            </Box>

            <AppContent onChooseBranchDialog={chooseBranchDialog.setTrue}>
              {props.children}
            </AppContent>
          </Box>
        </Box>
        {auth.branch && (
          <CreateParcelDialog
            isOpen={createParcelDialog.isTrue}
            onClose={createParcelDialog.setFalse}
            branchId={auth.branch.branchId}
          />
        )}
      </BooleanProvider>

      {chooseBranchDialog.isTrue && (
        <ChooseBranchDialog
          isOpen={true}
          onClose={chooseBranchDialog.setFalse}
          branch={defaultBranchOption}
          onSubmit={branch => {
            setBranch(branch)
            chooseBranchDialog.setFalse()
            history.push(operatorDefaultPath)
          }}
        />
      )}
    </>
  )
}
