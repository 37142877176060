import { lazyRoute } from 'lib/lazy-routing'

import { toCamelCase, toPascalCase } from './utils'

export const createPageGenerator = (routes: Record<string, string>) => {
  return <Singular extends string, Plural extends string = `${Singular}s`>(
    singular: Singular,
    plural: Plural,
  ) => {
    return [
      lazyRoute({
        title: `${plural}`,
        path: routes[toCamelCase(plural)],
        factory: () => import(`pages/${plural}`),
      }),
      lazyRoute({
        title: `${plural}.new`,
        path: routes[`create${toPascalCase(singular)}`],
        factory: () => import(`pages/${plural}/new`),
      }),
      lazyRoute({
        title: `${plural}.edit`,
        path: routes[`edit${toPascalCase(singular)}`],
        factory: () => import(`pages/${plural}/[${toCamelCase(singular)}Id]`),
      }),
      lazyRoute({
        title: `${plural}.edit`,
        path: routes[`edit${toPascalCase(singular)}`],
        factory: () =>
          import(`pages/${plural}/[${toCamelCase(singular)}Id/history]`),
      }),
    ] as const
  }
}

export const simplePageGenerator = (routes: Record<string, string>) => {
  return (pageTitle: string) => {
    return [
      lazyRoute({
        title: `${pageTitle}`,
        path: routes[toCamelCase(pageTitle)],
        factory: () => import(`pages/${pageTitle}`),
      }),
    ] as const
  }
}

export const childPageGenerator = (routes: Record<string, string>) => {
  return (parentPage: string, childPage: string) => {
    return [
      lazyRoute({
        title: `${childPage}`,
        path: routes[toCamelCase(childPage)],
        factory: () => import(`pages/${parentPage}/${childPage}`),
      }),
    ] as const
  }
}
