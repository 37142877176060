import * as t from 'io-ts'

export const TTableHeaders = t.type({
  count: t.union([t.string, t.undefined]),
})

export const TGenericSuggestions = t.array(
  t.type({
    id: t.string,
    name: t.string,
  }),
)
