import { AutocompleteOption } from 'app/options'
import { AutocompleteMultiple } from 'components/ui/autocomplete-multiple'
import { Mixed, TypeOf } from 'io-ts'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AutocompleteSingle, RawBody } from '../../ui/autocomplete-single'

type Props<C extends Mixed, TFieldValues extends FieldValues = FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  optionsUrl: string
  optionsCodec: C
  formatOptions: (options: TypeOf<C>) => Array<AutocompleteOption>
  label: string
  disabled?: boolean
  required?: boolean
  queryParam?: string
  serverSearch?: boolean
  searchBodyParam?: string
  rawBody?: RawBody
  selectAll?: boolean
  multiple?: boolean
  showTip?: boolean
}

export const ControlledAutocomplete = <
  C extends Mixed,
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<C, TFieldValues>,
) => {
  const { t } = useTranslation()

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: props.control,
    name: props.name,
    rules: { required: props.required },
  })

  return props.multiple ? (
    <AutocompleteMultiple
      onChange={onChange}
      selectedOptions={value as Array<AutocompleteOption>}
      optionsUrl={props.optionsUrl}
      optionsCodec={props.optionsCodec}
      name={props.name}
      required={props.required}
      disabled={props.disabled}
      label={t(props.label)}
      error={Boolean(error)}
      helperText={(error && error.message) || undefined}
      formatOptions={props.formatOptions}
      queryParam={props.queryParam}
      serverSearch={props.serverSearch}
      searchBodyParam={props.searchBodyParam}
      rawBody={props.rawBody}
    />
  ) : (
    <AutocompleteSingle
      onChange={onChange}
      option={value as AutocompleteOption}
      optionsUrl={props.optionsUrl}
      optionsCodec={props.optionsCodec}
      name={props.name}
      required={props.required}
      disabled={props.disabled}
      label={t(props.label)}
      error={Boolean(error)}
      helperText={(error && error.message) || undefined}
      formatOptions={props.formatOptions}
      queryParam={props.queryParam}
      serverSearch={props.serverSearch}
      searchBodyParam={props.searchBodyParam}
      rawBody={props.rawBody}
      showTip={props.showTip}
    />
  )
}
