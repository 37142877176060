import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const IconMapParcelMachine = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21.5 9C21.5 13.9706 15.5 20.25 12.125 24C8.75 20.25 3.5 13.9706 3.5 9C3.5 4.02944 7.52944 0 12.5 0C17.4706 0 21.5 4.02944 21.5 9Z"
      fill="#E60033"
    />
    <circle cx="12.5" cy="9" r="7" fill="white" />
    <rect x="7.75" y="4.5" width="9.5" height="9" fill="#E60033" />
    <rect x="8.08984" y="4.83887" width="2.375" height="1.35714" fill="white" />
    <rect x="10.8037" y="4.83887" width="2.375" height="1.35714" fill="white" />
    <rect x="8.08984" y="6.53516" width="2.375" height="1.35714" fill="white" />
    <rect x="10.8037" y="6.53516" width="2.375" height="1.35714" fill="white" />
    <rect x="8.08984" y="8.23145" width="2.375" height="1.35714" fill="white" />
    <rect x="10.8037" y="8.23145" width="2.375" height="1.35714" fill="white" />
    <rect x="8.08984" y="9.92773" width="2.375" height="1.35714" fill="white" />
    <rect x="10.8037" y="9.92773" width="2.375" height="1.35714" fill="white" />
    <rect x="8.08984" y="11.625" width="2.375" height="1.35714" fill="white" />
    <rect x="10.8037" y="11.625" width="2.375" height="1.35714" fill="white" />
    <rect
      x="13.8574"
      y="4.83887"
      width="2.71429"
      height="2.71429"
      fill="white"
    />
  </SvgIcon>
)
