import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const SystemDisability = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const history = useHistory()

  return (
    <Dialog
      title={t('dialog_system_disability.attention')}
      open
      onClick={() => {
        history.go(0)
      }}
      maxWidth="sm"
      disableEscapeKeyDown
      fullScreen={mdDown}
    >
      <DialogContent>
        <Box my={3}>
          <Typography
            textAlign={'center'}
            color="#FE6975"
            sx={{
              fontSize: '1.5rem',
              fontWeight: '700',
            }}
          >
            {t('dialog_system_disability.attention')}
          </Typography>
          <Typography textAlign={'center'}>
            {t('dialog_system_disability.text')}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            fullWidth
            color="primary"
            autoFocus
            onClick={() => {
              history.go(0)
            }}
          >
            {t('dialog_system_disability.close_btn')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
