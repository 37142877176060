import { capitalize } from '@material-ui/core'

type Separator = '-'

export type CamelCase<T extends string> =
  T extends `${Separator}${infer Suffix}`
    ? CamelCase<Suffix>
    : T extends `${infer Prefix}${Separator}`
    ? CamelCase<Prefix>
    : T extends `${infer Prefix}${Separator}${infer Suffix}`
    ? CamelCase<`${Prefix}${Capitalize<Suffix>}`>
    : T

export const toPascalCase = (str: string): string => {
  return str.split('-').map(capitalize).join('')
}

export const toCamelCase = (str: string): string => {
  if (str === '') {
    return ''
  }
  const pascal = toPascalCase(str)
  return `${pascal[0].toLowerCase()}${pascal.slice(1)}`
}

export { capitalize }
