import { Severity } from 'components/notification-provider'
import constate from 'constate'
import { useCallback, useState } from 'react'

const useNotification = () => {
  const [message, setMessage] = useState<{
    type: Severity
    text: string
  } | null>(null)

  const showSuccessNotification = useCallback(
    (text: string) => setMessage({ type: 'success', text }),
    [],
  )

  const showWarningNotification = useCallback(
    (text: string) => setMessage({ type: 'warning', text }),
    [],
  )
  const showErrorNotification = useCallback(
    (text: string) => setMessage({ type: 'error', text }),
    [],
  )

  const closeMessage = () => setMessage(null)

  return {
    message,
    closeMessage,
    showSuccessNotification,
    showWarningNotification,
    showErrorNotification,
  } as const
}

export const [NotificationProvider, useNotificationContext] =
  constate(useNotification)
