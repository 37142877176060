import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { IconClose } from 'assets/icons'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  setOpenProfileDialog: () => void
}

export const ChangePasswordDialog = (props: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      sx={{
        p: !smDown ? 2 : 0,
      }}
      PaperProps={{
        sx: { p: 3 },
      }}
      fullScreen={smDown}
    >
      <DialogTitle sx={{ padding: '5px 5px 0px' }}>
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={props.onClose}>
              <IconClose />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography variant="h3" textAlign="center">
          {t('dialog_titles.password_change_needed')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
        <Button
          fullWidth={smDown}
          size="small"
          onClick={() => {
            props.onClose()
            props.setOpenProfileDialog()
          }}
        >
          {t('buttons.change_password')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
