import { Button, Divider, Stack, useTheme } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { IconCross } from 'assets/icons'
import { IconTerminalsWhite } from 'assets/icons/terminals-white'
import { PARCEL_ARREARS_QUERY_KEY } from 'components/forms/parcel-actions'
import {
  FormMode,
  ParcelIdentificationFieldValues,
} from 'components/forms/parcel-actions/parcel-actions-form-dialog'
import { Dispatch, SetStateAction } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import {
  isParcelIssuanceMode,
  isParcelReceptionMode,
  isParcelReturnMode,
} from './utils'

type Props = {
  mode: FormMode
  isParcelsLoading: boolean
  parcelIds: Array<string>
  isSubmitting: boolean
  onPayParcels: () => void
  onRefuseParcel: () => void
  isSeveralParcels: boolean
  showPaymentControls: boolean
  clientPhone?: string | null
  setClientPhone?: Dispatch<SetStateAction<string | null>>
  control: Control<ParcelIdentificationFieldValues>
  disableReception?: boolean
}

export const DialogActions = (props: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return (
    <>
      {props.showPaymentControls && (
        <Stack
          width={1}
          direction={{ xs: 'column-reverse', md: 'row' }}
          spacing={1}
        >
          <Stack alignItems="center" width={1} spacing={1}>
            <Stack direction="row" spacing={1} width="100%">
              <Button
                target="_blank"
                href="https://serv.meest.com/services/pay_terminals/"
                size="small"
                sx={{
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  borderRadius: '8px',
                  padding: 0,
                }}
              >
                <IconTerminalsWhite />
              </Button>
              <LoadingButton
                variant="outlined"
                size="small"
                loading={props.isParcelsLoading}
                onClick={() =>
                  queryClient.invalidateQueries([
                    PARCEL_ARREARS_QUERY_KEY,
                    ...props.parcelIds.sort(),
                  ])
                }
                fullWidth
              >
                {t('buttons.update')}
              </LoadingButton>
              <Button onClick={props.onPayParcels} size="small" fullWidth>
                {t('buttons.pay')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}

      {!props.showPaymentControls && (
        <LoadingButton
          type="submit"
          fullWidth
          loading={props.isSubmitting}
          size="small"
          disabled={isParcelReceptionMode(props.mode) && props.disableReception}
        >
          {t(
            isParcelReceptionMode(props.mode)
              ? 'buttons.accept'
              : isParcelReturnMode(props.mode)
              ? 'dialog_actions.confirm'
              : 'dialog_titles.issue_parcel',
          )}
        </LoadingButton>
      )}
      {!props.isSeveralParcels && isParcelIssuanceMode(props.mode) && (
        <Button
          variant="contained"
          fullWidth
          size="small"
          startIcon={<IconCross strokecolor={theme.palette.secondary.main} />}
          onClick={props.onRefuseParcel}
          sx={{
            fontSize: theme.typography.body2.fontSize,
            bgcolor: theme.palette.error.light,
            color: theme.palette.secondary.main,
            fontWeight: 400,
            mt: 1,
            ml: '0px !important',
            '&:hover': {
              bgcolor: theme.palette.error.main,
            },
          }}
        >
          {t('buttons.rejection')}
        </Button>
      )}
      <Divider sx={{ mt: 2 }} />
    </>
  )
}
