import { Choice } from 'components/ui/choice'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  required?: boolean
  onFocus?: () => void
}

export const ControlledChoice = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()

  const {
    field: { onBlur, onChange, value },
    fieldState,
  } = useController({
    control: props.control,
    name: props.name,
    rules: { required: props.required },
  })

  return (
    <Choice
      onFocus={props.onFocus}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      required={props.required}
      name={props.name}
      label={t(props.label)}
      error={fieldState.error !== undefined}
    />
  )
}
