import * as t from 'io-ts'

export const TPaymentType = t.union([
  t.literal('CASH_ON_DELIVERY'),
  t.literal('DELIVERY'),
])

const TCashPaymentDept = t.type({
  parcelId: t.string,
  amount: t.number,
  parcelNumber: t.string,
  paymentType: TPaymentType,
})

export const TCashToPay = t.type({
  cashPaymentDept: t.array(TCashPaymentDept),
  totalAmount: t.number,
})
