import { MeestDocumentType } from 'meest-domain/utils'

const passwordRegexOld = /^.{4,12}$/
const passwordRegex = /^(?=.*[A-Za-z]).{5,12}$/

const buildingRegex = /^.{1,6}$/

const corpsRegex = /^.{1,3}$/

const flatRegex = /^[\dA-яєіїґ]+$/i

const passportRegex = /^.{8}$/
const idCardRegex = /^.{9}$/
const parcelNumberRegex = /^.{4,30}$/
const driverRegex = /^[A-ZЁЄІЇА-ЯҐ]{3}\d{6}$/

const notesRegex = /^.{1,1000}$/

// eslint-disable-next-line security/detect-unsafe-regex
const cardForCODRegex = /^(\d{4}\s){3}\d{4}$/
const stickerRegex = /^.{7}$/
const cashboxRegex = /^.{8}$/

const emailRegex =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/

// eslint-disable-next-line no-useless-escape
const nameRegex = /^([\d '+,A-Za-zʼЁЄІЇА-яёєіїҐґ\-]){2,100}$/
// eslint-disable-next-line no-useless-escape
const nameUaRegex = /^[ '+,ʼЁЄІЇА-яёєіїҐґ\-]{2,100}$/
const nameUaStartWithCapitalRegex =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^[ЄІЇА-ЩЬЮЯҐ][а-щьюяєіїґ]*(?:[–-][ЄІЇА-ЩЬЮЯҐ][а-щьюяєіїґ]*)?$/
const nameUaWithOptionalSymbolRegex =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^(?=.{2,100}$)[ЄІЇА-ЩЬЮЯҐ][а-щьюяєіїґ]*(?:["'`–-][ЄІЇА-ЩЬЮЯа-щьюяєіїҐґ][а-щьюяєіїґ]*)?$/

const phoneRegex = /\d{9}/

const appNameRegex = /^(.){1,50}$/

const packageNameRegex = /^([\d._a-z]){1,30}$/

const jwtTokenRegex = /^[\w=-]+\.[\w=-]+\.?[\w+./=-]*$/

export const required = {
  required: true,
}

export const email = {
  pattern: {
    value: emailRegex,
    message: 'validations.email',
  },
}

export const phone = {
  pattern: {
    value: phoneRegex,
    message: 'validations.phone',
  },
}

export const cardForCOD = {
  pattern: {
    value: cardForCODRegex,
    message: 'validations.phone_form',
  },
}

export const cashbox = {
  pattern: {
    value: cashboxRegex,
    message: 'validations.cashbox',
  },
}

export const miniSticker = {
  pattern: {
    value: stickerRegex,
    message: 'validations.mini_sticker',
  },
}

export const building = {
  pattern: {
    value: buildingRegex,
    message: 'validations.building',
  },
}
export const corps = {
  pattern: {
    value: corpsRegex,
    message: 'validations.corps',
  },
}
export const flat = {
  pattern: {
    value: flatRegex,
    message: 'validations.flat',
  },
}

export const passport = {
  pattern: {
    value: passportRegex,
    message: 'validations.passport',
  },
}

export const id_card = {
  pattern: {
    value: idCardRegex,
    message: 'validations.id_card',
  },
}

export const driver = {
  pattern: {
    value: driverRegex,
    message: 'validations.driver',
  },
}

export const parcelNumber = {
  pattern: {
    value: parcelNumberRegex,
    message: 'validations.parcel_number',
  },
}

export const notes = {
  pattern: {
    value: notesRegex,
    message: 'validations.notes',
  },
}

export const phone_form = {
  pattern: {
    value: phoneRegex,
    message: 'validations.phone_form',
  },
}

export const password_form_old = {
  pattern: {
    value: passwordRegexOld,
    message: 'validations.password_form_old',
  },
}

export const password_form = {
  pattern: {
    value: passwordRegex,
    message: 'validations.password_form',
  },
}

export const name = {
  pattern: {
    value: nameRegex,
    message: 'validations.name',
  },
}

export const nameUa = {
  pattern: {
    value: nameUaRegex,
    message: 'validations.name_ua',
  },
}

export const nameStartWithCapitalUa = {
  pattern: {
    value: nameUaStartWithCapitalRegex,
    message: 'validations.name_ua',
  },
}

export const nameUaWithOptionalSymbol = {
  pattern: {
    value: nameUaWithOptionalSymbolRegex,
    message: 'validations.name_ua_with_symbols',
  },
}

export const password = {
  pattern: {
    value: passwordRegex,
    message: 'validations.password_main',
  },
}

export const packageName = {
  pattern: {
    value: packageNameRegex,
    message: 'validations.package_name',
  },
}

export const appName = {
  pattern: {
    value: appNameRegex,
    message: 'validations.app_name',
  },
}

export const jwtToken = {
  pattern: {
    value: jwtTokenRegex,
    message: 'validations.jwt_token',
  },
}

export const minLength = (
  value: number,
  message: string = `Please lengthen this text to ${value} characters or more`,
) => {
  return { value, message }
}

export const maxLength = (
  value: number,
  message: string = `Please shorten this text to ${value} characters or less`,
) => {
  return { value, message }
}

export const minLengthTrimmed = (
  minLengthValue: number,
  message: string = `Please lengthen this text to ${minLengthValue} characters or more`,
  required: boolean = true,
) => {
  return {
    minLengthTrimmed: (value: string) => {
      if (!required && value.length === 0) {
        return true
      }
      if (value.trim().length < minLengthValue) {
        return message
      }
    },
  }
}

export const getDocumentValidationPattern = (
  documentType?: MeestDocumentType,
) => {
  if (
    documentType &&
    (documentType === 'PASSPORT' || documentType === 'TRAVEL_PASSPORT')
  ) {
    return passport
  }

  if (documentType && documentType === 'DRIVER_LICENSE') {
    return driver
  }

  if (documentType && documentType === 'ID') {
    return id_card
  }
}
