import {
  Box,
  Breakpoint,
  Dialog as MuiDialog,
  DialogTitle,
  Hidden,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { IconBack, IconClose } from 'assets/icons'
import { ReactNode } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  title?: string
  children: ReactNode
  headAction?: ReactNode
  justifyContent?: string
  maxWidth?: Breakpoint | false
  zIndex?: number
  fullPage?: boolean
}

export const Dialog = (props: Props) => {
  const theme = useTheme()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      props.onClose()
    }
  }

  return (
    <MuiDialog
      open={props.open}
      onClose={onClose}
      fullScreen={smDown}
      maxWidth={props.maxWidth}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          width: {
            xs: '100%',
            sm: props.fullPage ? 'auto' : 540,
            md: props.fullPage ? 'auto' : 900,
          },
          display: ' flex',
          flexDirection: 'column',
          justifyContent: props.justifyContent
            ? props.justifyContent
            : 'space-between',
          zIndex: props.zIndex ? props.zIndex : 'auto',
        },
      }}
    >
      <DialogTitle
        sx={{
          pt: { xs: 1, sm: 2 },
          pb: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent={
            props.headAction && smDown ? 'space-between' : 'center'
          }
          alignItems="center"
          textAlign="center"
          position={{ xs: 'relative', sm: 'static' }}
        >
          <Hidden smUp>
            <IconButton
              sx={
                props.headAction ? undefined : { position: 'absolute', left: 0 }
              }
              onClick={props.onClose}
            >
              <IconBack strokecolor={theme.palette.secondary.main} />
            </IconButton>
          </Hidden>
          {props.title && <Box>{props.title}</Box>}
          {props.headAction && <Hidden smUp>{props.headAction}</Hidden>}
          <Hidden smDown>
            <Box position="absolute" top="10px" right="18px">
              <IconButton sx={{ p: 0 }} onClick={props.onClose}>
                <IconClose />
              </IconButton>
            </Box>
          </Hidden>
        </Stack>
      </DialogTitle>

      {props.children}
    </MuiDialog>
  )
}
