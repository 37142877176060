export const IconCashboxLarge = () => (
  <svg
    width="56"
    height="52"
    viewBox="0 0 56 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1294"
      d="M29.1395 51.0936C41.7918 51.0936 52.0484 45.0753 52.0484 37.6513C52.0484 30.2273 41.7918 24.209 29.1395 24.209C16.4871 24.209 6.23047 30.2273 6.23047 37.6513C6.23047 45.0753 16.4871 51.0936 29.1395 51.0936Z"
      fill="#0061AF"
    />
    <path
      d="M13.7841 25.7792L29.5452 16.2845C30.6174 15.639 31.1508 14.2189 31.0912 12.4508C31.0831 12.1954 31.0614 11.9315 31.0289 11.6621L15.2678 21.1568C15.3003 21.4262 15.322 21.69 15.3301 21.9454C15.3897 23.7136 14.8563 25.1337 13.7841 25.7792Z"
      fill="#0065AF"
    />
    <path
      d="M10.4811 25.1072C10.4976 25.1154 10.5159 25.1252 10.5335 25.1348C11.4882 25.638 12.3641 25.7719 13.0736 25.5524L13.0739 25.5523C14.1932 25.2068 14.8957 23.9318 14.8282 21.9636L14.7799 20.5545L15.7052 21.6183L25.8534 33.2853L25.8535 33.2855C26.9731 34.574 28.2789 35.6742 29.7236 36.551C29.7237 36.551 29.7238 36.551 29.7238 36.5511L37.7383 41.4097L11.485 11.254L1.10667 19.0765L1.41756 19.6886L10.438 25.0875C10.4385 25.0877 10.4389 25.0878 10.4393 25.088C10.458 25.0958 10.4739 25.1036 10.4811 25.1072ZM10.6935 25.767C10.5634 25.7099 10.4328 25.6469 10.3021 25.578L10.5612 25.1634C10.5637 25.1654 10.5665 25.1677 10.5695 25.1703L10.57 25.1707C10.575 25.1749 10.6027 25.1983 10.6316 25.2337L10.6321 25.2344C10.643 25.2477 10.6901 25.3055 10.719 25.3942C10.7349 25.4431 10.757 25.5365 10.733 25.6514C10.7244 25.6923 10.711 25.731 10.6935 25.767Z"
      fill="#0065AF"
      stroke="#0065AF"
    />
    <path
      d="M12.3737 25.4121C12.6011 25.4121 12.815 25.3812 13.0154 25.3194C14.0741 24.9939 14.6697 23.7421 14.6102 21.9768L14.5344 19.7063C14.5344 19.6614 14.4992 19.6249 14.4504 19.6277C14.4071 19.6277 14.3719 19.667 14.3746 19.7147L14.4504 21.9852C14.5073 23.672 13.9549 24.8592 12.9694 25.1651C12.3439 25.3587 11.5397 25.2184 10.6949 24.7693C10.657 24.7497 10.6191 24.7301 10.5785 24.7104L1.67314 19.3779C1.63523 19.3555 1.5865 19.3695 1.56483 19.4088C1.54317 19.4481 1.55671 19.4986 1.59462 19.521L10.5081 24.8564C10.5514 24.876 10.5866 24.8956 10.6191 24.9125C11.2392 25.2409 11.843 25.4121 12.371 25.4121H12.3737Z"
      fill="#0065AF"
    />
    <mask id="path-5-inside-1_1938_11843" fill="white">
      <path d="M39.7235 43.1986L55.4846 33.7067L55.999 32.2305L40.2379 41.7223L39.7235 43.1986Z" />
    </mask>
    <path
      d="M39.7235 43.1986L55.4846 33.7067L55.999 32.2305L40.2379 41.7223L39.7235 43.1986Z"
      fill="white"
    />
    <path
      d="M39.7235 43.1986L40.2394 44.0552L37.8684 45.4831L38.7792 42.8695L39.7235 43.1986ZM55.4846 33.7067L56.4289 34.0358L56.31 34.377L56.0005 34.5634L55.4846 33.7067ZM55.999 32.2305L55.4831 31.3738L57.8541 29.9459L56.9433 32.5595L55.999 32.2305ZM40.2379 41.7223L39.2936 41.3933L39.4125 41.0521L39.722 40.8657L40.2379 41.7223ZM39.2076 42.3419L54.9687 32.8501L56.0005 34.5634L40.2394 44.0552L39.2076 42.3419ZM54.5403 33.3777L55.0547 31.9014L56.9433 32.5595L56.4289 34.0358L54.5403 33.3777ZM56.5149 33.0871L40.7538 42.579L39.722 40.8657L55.4831 31.3738L56.5149 33.0871ZM41.1822 42.0514L40.6678 43.5277L38.7792 42.8695L39.2936 41.3933L41.1822 42.0514Z"
      fill="#0065AF"
      mask="url(#path-5-inside-1_1938_11843)"
    />
    <path
      d="M54.5017 30.5371L55.999 32.2295L40.2379 41.7213L38.765 40.0374L54.5017 30.5371Z"
      fill="#0065AF"
    />
    <path
      d="M12.3294 9.41769L37.6071 37.9919L52.4855 29.0085L26.9745 0.640645L12.3294 9.41769Z"
      fill="white"
      stroke="#0065AF"
    />
    <path
      d="M48.107 25.005L29.7195 4.21947C29.3729 3.82936 28.8124 3.73955 28.3711 4.00617L16.1705 11.3538C15.7481 11.6092 15.6588 12.2042 15.9918 12.5775L34.3901 33.3714C34.7367 33.7616 35.2972 33.8514 35.7385 33.5847L47.9283 26.2259C48.3506 25.9705 48.4373 25.3755 48.107 25.0022V25.005Z"
      fill="#424242"
    />
    <path
      d="M48.107 25.005L29.7195 4.21947C29.3729 3.82936 28.8124 3.73955 28.3711 4.00617L16.1705 11.3538C15.7481 11.6092 15.6588 12.2042 15.9918 12.5775L34.3901 33.3714C34.7367 33.7616 35.2972 33.8514 35.7385 33.5847L47.9283 26.2259C48.3506 25.9705 48.4373 25.3755 48.107 25.0022V25.005Z"
      fill="#0065AF"
    />
    <path
      d="M47.2402 25.4942L28.9421 4.8125L16.858 12.09L35.1642 32.7857L47.2402 25.4942Z"
      fill="#FEFEFE"
    />
    <path
      d="M39.7229 43.1967L40.2373 41.7205L11.5554 9.29883V10.573L39.7229 43.1967Z"
      fill="white"
    />
    <path
      d="M0.470638 18.9282L0.0022192 18.0076L11.5557 9.29883V10.573L0.470638 18.9282Z"
      fill="#0065AF"
    />
    <path
      d="M37.9345 39.4236L38.7539 39.8125L54.0729 30.5644L53.2804 30.2059L37.9345 39.4236Z"
      fill="white"
      stroke="#0065AF"
      strokeWidth="0.4"
    />
    <mask id="path-15-inside-2_1938_11843" fill="white">
      <path d="M53.2612 29.627L53.2666 29.9778L37.5109 39.4416V39.0907L53.2612 29.627Z" />
    </mask>
    <path
      d="M53.2612 29.627L53.2666 29.9778L37.5109 39.4416V39.0907L53.2612 29.627Z"
      fill="white"
    />
    <path
      d="M53.2612 29.627L53.0552 29.2841L53.6504 28.9264L53.6611 29.6208L53.2612 29.627ZM53.2666 29.9778L53.6666 29.9716L53.6701 30.202L53.4726 30.3207L53.2666 29.9778ZM37.5109 39.4416L37.7169 39.7845L37.1109 40.1484V39.4416H37.5109ZM37.5109 39.0907H37.1109V38.8644L37.3049 38.7479L37.5109 39.0907ZM53.6611 29.6208L53.6666 29.9716L52.8666 29.9839L52.8612 29.6331L53.6611 29.6208ZM53.4726 30.3207L37.7169 39.7845L37.305 39.0987L53.0606 29.6349L53.4726 30.3207ZM37.1109 39.4416V39.0907H37.9109V39.4416H37.1109ZM37.3049 38.7479L53.0552 29.2841L53.4672 29.9698L37.7169 39.4336L37.3049 38.7479Z"
      fill="#0065AF"
      mask="url(#path-15-inside-2_1938_11843)"
    />
    <path
      d="M53.2617 29.125V29.6274L37.5115 39.0912V38.6337L53.2617 29.125Z"
      fill="#0065AF"
    />
    <path
      d="M32.4614 14.8887L35.4561 18.1527L31.1807 20.7348L28.2511 17.4258L32.4614 14.8887Z"
      fill="#0065AF"
    />
    <path
      d="M34.0645 22.0509L33.7774 21.7422L29.9191 24.0632L30.2115 24.3776L34.0645 22.0509Z"
      fill="#FEFEFE"
    />
    <path
      d="M17.166 16.4043L14.3095 18.3156L13.9439 18.5345L16.7978 16.6232L17.166 16.4043Z"
      fill="#616161"
    />
    <path
      d="M32.7218 18.5193C32.7597 18.5193 32.7976 18.5081 32.8301 18.4828C32.8707 18.452 32.8978 18.4043 32.9059 18.3509L33.0819 16.7624C33.0927 16.6586 33.0223 16.5631 32.9195 16.5519C32.8193 16.5407 32.7272 16.6137 32.7164 16.7203L32.5648 18.1012L29.627 17.4669C29.5268 17.4444 29.432 17.5118 29.4104 17.6128C29.3887 17.7167 29.4537 17.8149 29.5512 17.8373L32.6866 18.5137C32.6866 18.5137 32.711 18.5165 32.7245 18.5165L32.7218 18.5193Z"
      fill="white"
    />
  </svg>
)
