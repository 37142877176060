import { DeepMap, FieldError, FieldValues } from 'react-hook-form'

//TODO: remove 'any' types
export const scrollToView = (errors: DeepMap<FieldValues, FieldError>) => {
  const elements = Object.values(errors)
    .map((element: any) => {
      return element.ref
        ? document.getElementsByName(element.ref.name)[0] ??
            document.querySelector(`[data-name="${element.ref?.name}"]`)
        : document.getElementsByName(
            (Object.values(element) as any)[0].ref?.name,
          )[0] ??
            document.querySelector(
              `[data-name="${(Object.values(element) as any)[0].ref?.name}"]`,
            )
    })
    .filter(el => !!el)

  elements.sort((a, b) => b.scrollHeight - a.scrollHeight)
  elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}
