import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from '@material-ui/core'
import { Handler } from 'lib/react-handy-types'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog } from '../ui/dialog'

type Props = {
  isOpen: boolean
  onClose: Handler
  title: string
  text?: string
  children?: ReactNode
  onConfirm: Handler
  hint?: string
  confirm?: string
  attention?: string
}

export const ConfirmationDialog = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog title={t(props.title)} open={props.isOpen} onClose={props.onClose}>
      {props.text && (
        <DialogContent>
          <span style={{ color: 'red' }}>{props.attention}</span>
          {
            <DialogContentText sx={{ textAlign: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  color: '#32353A',
                }}
              >
                <p>{t(props.text)}</p>
                <p>{props.hint} </p>
                <span style={{ textAlign: 'center' }}>{props.confirm}</span>
              </div>
            </DialogContentText>
          }
          {props.children}
        </DialogContent>
      )}

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} width={1} spacing={1}>
          <Button fullWidth color="primary" autoFocus onClick={props.onClose}>
            {t('dialog_confirm.no')}
          </Button>
          <Button fullWidth variant="text" onClick={props.onConfirm}>
            {t('dialog_confirm.yes')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
